import * as React from 'react';
const ChemicalsSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} {...props}>
    <path
      fill='none'
      stroke='#0C2138'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M9.073 3h6m-5 6h4m-4 0V3m0 6-4 11a.7.7 0 0 0 .5 1h11a.7.7 0 0 0 .5-1l-4-11m0 0V3'
    />
  </svg>
);
export default ChemicalsSvg;
