import React, { useEffect, useState, Suspense, useRef, useCallback, useContext, lazy } from 'react';
import { bindActionCreators } from 'redux';
import { useForm, FormProvider } from 'react-hook-form';
import { connect } from 'react-redux';
import packageInfo from '../package.json';

import { authenticatedRoutes, exceptionRoutes } from './constants/paths';
import styles from './App.module.css';

// Containers
const AuthenticatedTopComponent = lazy(
  () =>
    import(
      './components/Authenticated/AuthenticatedTopComponent/AuthenticatedTopComponent' /* webpackChunkName: "auth-routes" */
    )
);
const NonAuthenticatedTopComponent = lazy(
  () =>
    import(
      './components/NonAuthenticated/NonAuthenticatedTopComponent/NonAuthenticatedTopComponent' /* webpackChunkName: "non-auth-routes" */
    )
);

import ExceptionRoutesTopComponent from './components/Exceptions/ExceptionRoutesTopComponent';
import WholePageLoaderWrapper from './components/UI/Loader/WholePageLoaderWrapper';

// Error handler
import useHandleGlobalErrors from './assets/helpers/handleGlobalErrors';
import usePrevious from './assets/helpers/usePrevious';
import { withRouter } from './assets/helpers/withRouter';
import setHandleErrors from './store/actions/setHandleError';
import GlobalErrorModal from './components/UI/GlobalErrorModal/GlobalErrorModal';
import ModalComponent from './components/UI/ModalComponent/ModalComponent';
import useInternetConnection from './hooks/useInternetConnection';
import WholePageLoader from './components/UI/Loader/WholePageLoader';
import useAppVersion from './hooks/useAppVersion';
// import Button from './components/UI/Button/Button';
import UpdateModalComponent from './components/UI/UpdateModalComponent/UpdateModalComponent';
import { useNavigate } from 'react-router-dom';
import { getUserCompanies } from './store/actions/User/userCompanies';
import reloadReduxState from './store/actions/reloadReduxState';
import { companySuspendedModalHide } from './store/actions/companySuspendedModal';
import FormDrawer from './components/UI/FormDrawer/FormDrawer';
import UserEmailNotVerified from './components/UI/UserEmailNotVerified/UserEmailNotVerified';
import UpgradeInfo from './components/Exceptions/UpgradeInfo/UpgradeInfo';
import ReactGA from 'react-ga4';
import { isEqual } from 'lodash';
import CookiePolicySvg from './assets/images/svg/CookiePolicySvg';
import CookieSettings from './components/UI/CookiePolicy/CookieSettings';
import { defaultCookieValues, removeAllCookies, handleCookie } from './components/UI/CookiePolicy/CookiePolicy';
import PrivacyPolicy from './components/NonAuthenticated/PrivacyPolicy/PrivacyPolicy';
import CookiePolicyDocument from './components/NonAuthenticated/CookiePolicyDocument/CookiePolicyDocument';
import AppComponentContext from './contexts/AppComponentContext';
import SessionExpiredForm from './components/UI/SessionExpiredForm/SessionExpiredForm';
import { tooManyRequestsModalHide } from './store/actions/tooManyRequestsModal';

const version = packageInfo.version;

const App = ({
  handleErrors,
  middlewareError,
  loggedIn,
  setHandleErrors,
  getUserCompanies,
  reloadReduxState,
  companySuspendedModalOpened,
  companySuspendedModalHide,
  tooManyRequestsModalOpened,
  tooManyRequestsModalHide
}) => {
  useEffect(() => {
    if (new URLSearchParams(window.location.search).get('acceptAllCookies')) {
      const stateToSave = {
        statistic: true,
        marketing: true,
        unclassified: true,
        chat: true
      };

      if (!isEqual(JSON.parse(localStorage.getItem('cookies')), stateToSave)) {
        localStorage.setItem('cookies', JSON.stringify(stateToSave));
        window.location.reload();
      }
    }
  }, []);

  const strings = useContext(AppComponentContext).strings;

  const url = useRef('');
  const navigate = useNavigate();
  const [isOffline, checkConnection] = useInternetConnection();
  const { showNewVersionMessage, hideNewVersionMessage, data } = useAppVersion();

  const [deactivatedCompModal, setDeactivatedCompModal] = useState(false);
  const [notVerifiedUserEmail, setNotVerifiedUserEmail] = useState(false);
  const [showCookieSettings, setShowCookieSettings] = useState(false);
  const [showCookiePolicy, setShowCookiePolicy] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTou, setShowTou] = useState(false);
  const handleGlobalError = useHandleGlobalErrors();
  const prevProp = usePrevious(handleErrors);
  const prevMiddlewareError = usePrevious(middlewareError);

  // Needed to initialize unclassified in App.js because we are rendering whole script tag if it's accepted
  const { marketing, chat } = JSON.parse(localStorage?.getItem('cookies')) || {};

  useEffect(() => {
    if (marketing) {
      ReactGA.initialize([
        {
          trackingId: 'G-074J69T5HZ'
        }
      ]);

      const googleTagManager = document.createElement('script');

      let googleTagManagerCode = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MT3FZL8');`;

      googleTagManager.appendChild(document.createTextNode(googleTagManagerCode));
      document.body.appendChild(googleTagManager);
    }
    if (chat) {
      const zohoLiveChat = document.createElement('script');
      zohoLiveChat.setAttribute('type', 'text/javascript');
      let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "cea67b38dcff16598a0aebe2be4bcacb844708b6c77488deb650d2a2c9e241e547b52a2e43100f69b4ef8ea66cf0022f", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

      zohoLiveChat.appendChild(document.createTextNode(code));
      document.body.appendChild(zohoLiveChat);
    }
  }, [marketing, chat, loggedIn]);

  useEffect(() => {
    Object.values(authenticatedRoutes).forEach((value) => {
      if (
        value + '/' === '/' + location.pathname.split('/')[1] + '/' ||
        value + '/' === location.pathname.split('/')[1] + '/'
      ) {
        url.current = location.pathname + location.search;
      }
    });
  }, []);

  // On global errors redirect to dashboard and display error there.
  useEffect(() => {
    if (handleErrors) {
      if (!handleErrors?.hash) {
        const errorCode = handleErrors && handleErrors?.errors[0].error_code;
        if (errorCode && Number(errorCode) === 1000002) {
          handleGlobalError(errorCode);
          setDeactivatedCompModal(true);
        }
        if (errorCode && Number(errorCode) === 1000003) {
          setNotVerifiedUserEmail(true);
        }
      }
    }
  }, [handleGlobalError, handleErrors, prevProp]);

  useEffect(() => {
    if (prevMiddlewareError !== middlewareError) {
      handleGlobalError(middlewareError, navigate, location);
    }
  }, [handleGlobalError, prevMiddlewareError, middlewareError, navigate]);

  useEffect(() => {
    if (middlewareError === 2000000) {
      setSessionExpired(true);
    }
  }, [middlewareError]);

  const unauthenticatedLayoutSelector = () => {
    const route = `/${location.pathname.split('/')[1]}`;
    for (const value of Object.values(exceptionRoutes)) {
      if (route === value) {
        return true;
      }
    }
    return false;
  };

  // FOR COOKIES

  const documentSectionRefs = {
    privacyPolicy: {
      testContainer: useRef(),
      dataControlerSection: useRef(),
      dataProcessorSection: useRef(),
      yourRightsSection: useRef(),
      personalDataSection: useRef(),
      whatDataSection: useRef()
    },
    termsOfUse: {
      definitions: useRef(),
      termination: useRef(),
      klevercargoFee: useRef(),
      limitationOfLiability: useRef(),
      notices: useRef(),
      governingLaw: useRef(),
      licence: useRef()
    }
  };

  const formMethods = useForm({
    defaultValues: defaultCookieValues,
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const { handleSubmit } = formMethods;

  const onSubmit = useCallback((data) => {
    removeAllCookies();
    handleCookie(data);
  }, []);

  const layoutSelector = () => {
    const layout = !loggedIn ? (
      unauthenticatedLayoutSelector() ? (
        <ExceptionRoutesTopComponent />
      ) : (
        <>
          <Suspense fallback={<WholePageLoader />}>
            <NonAuthenticatedTopComponent />
          </Suspense>
          <div className={styles.cookieSettings} onClick={() => setShowCookieSettings(true)}>
            <CookiePolicySvg className={styles.svg} />
            <span>{strings.cookieSettings}</span>
          </div>

          {showCookieSettings ? (
            <FormDrawer closeDrawer={setShowCookieSettings} formTitle={strings.cookieSettings}>
              <FormProvider {...formMethods}>
                <form className={styles.cookieForm} onSubmit={handleSubmit(onSubmit)}>
                  <CookieSettings
                    handleModal={setShowCookieSettings}
                    setShowCookiePolicy={setShowCookiePolicy}
                    setShowPrivacy={setShowPrivacy}
                  />
                </form>
              </FormProvider>
            </FormDrawer>
          ) : null}
          {showPrivacy ? (
            <FormDrawer closeDrawer={setShowPrivacy} formTitle={strings.privacyPolicy}>
              <PrivacyPolicy
                setSelf={setShowPrivacy}
                stateHandler={setShowTou}
                documentSectionRefs={documentSectionRefs}
                showTou={showTou}
              />
            </FormDrawer>
          ) : null}
          {showCookiePolicy ? (
            <FormDrawer closeDrawer={setShowCookiePolicy} formTitle={strings.cookiePolicy}>
              <CookiePolicyDocument showPrivacy={setShowPrivacy} showTou={setShowTou} />
            </FormDrawer>
          ) : null}
        </>
      )
    ) : (
      <Suspense fallback={<WholePageLoader />}>
        <AuthenticatedTopComponent sendToUrl={url} />
      </Suspense>
    );
    return layout;
  };

  const handleDeactivatedCompanyClose = useCallback(() => {
    setDeactivatedCompModal(false);
    setHandleErrors(false);
    getUserCompanies();
  }, [getUserCompanies, setHandleErrors]);

  const suspendedCompanyBlueBtn = useCallback(() => {
    navigate(authenticatedRoutes.BILLING);
    companySuspendedModalHide();
  }, [companySuspendedModalHide, navigate]);

  const tooManyRequestsBlueBtn = useCallback(() => {
    navigate(authenticatedRoutes.DASHBOARD);
    tooManyRequestsModalHide();
  }, [navigate, tooManyRequestsModalHide]);

  useEffect(() => {
    const handleStorageChange = () => {
      reloadReduxState();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [reloadReduxState]);

  return (
    <>
      <WholePageLoaderWrapper />
      {tooManyRequestsModalOpened ? (
        <ModalComponent
          isOpen={true}
          title={strings.tooManyRequests}
          message={strings.tooManyRequestsMessage}
          blueFunction={tooManyRequestsBlueBtn}
          blueButtonText={strings.goToDashboard}
          redButtonText={strings.close}
          redFunction={tooManyRequestsModalHide}
          style='danger'
        />
      ) : null}
      {handleErrors && handleErrors?.hash && !tooManyRequestsModalOpened ? (
        <GlobalErrorModal
          isOpen={true}
          title={strings.apiFailErrorTitle}
          message={`${strings.apiFailErrorMessage}`}
          blueButtonText={strings.sendReport}
          redButtonText={strings.cancel}
          closeModal={() => setHandleErrors(false)}
          style='danger'
          error={handleErrors}
        />
      ) : null}
      <SessionExpiredForm isOpen={sessionExpired} />
      {deactivatedCompModal ? (
        <GlobalErrorModal
          isOpen={true}
          title={strings.deactivatedFromCompany}
          message={strings.formatString(strings.deactivatedFromCompanyMessage, {
            company_name: handleErrors?.errors?.[0]?.company_name
          })}
          redButtonText={strings.ok}
          closeModal={handleDeactivatedCompanyClose}
          style='danger'
          error={handleErrors}
          deactivatedCompany={true}
          setDeactivatedCompModal={setDeactivatedCompModal}
        />
      ) : null}
      {!sessionExpired ? <Suspense fallback={<WholePageLoader />}>{layoutSelector()}</Suspense> : null}
      {/* No internet connection */}
      {isOffline && !tooManyRequestsModalOpened ? (
        <ModalComponent
          isOpen={true}
          title={strings.noConnection}
          message={strings.noConnectionMessage}
          blueButtonText={strings.tryAgain}
          blueFunction={checkConnection}
          onlyBlueButton
          style='tertiary'
        />
      ) : null}
      {/* Company suspended */}
      {companySuspendedModalOpened ? (
        <ModalComponent
          isOpen={true}
          title={strings.companySuspended}
          message={strings.companySuspendedText}
          blueFunction={suspendedCompanyBlueBtn}
          blueButtonText={strings.usageAndBilling}
          redButtonText={strings.close}
          redFunction={companySuspendedModalHide}
          style='danger'
        />
      ) : null}

      {/* Service worker update available */}
      <UpdateModalComponent />
      {notVerifiedUserEmail ? (
        <FormDrawer formTitle={strings.emailNotVerified} closeDrawer={setNotVerifiedUserEmail}>
          <UserEmailNotVerified setNotVerifiedUserEmail={setNotVerifiedUserEmail} />
        </FormDrawer>
      ) : null}

      {/* New version message */}
      {/* eslint-disable-next-line */}
      {/* {true ? ( */}
      {showNewVersionMessage ? (
        <FormDrawer headerStyle={'primary'} formTitle={strings.versionUpdated} closeDrawer={hideNewVersionMessage}>
          <UpgradeInfo version={version} hideNewVersionMessage={hideNewVersionMessage} data={data} />
        </FormDrawer>
      ) : null}
    </>
  );
};

function mapStateToProps({
  session,
  middlewareError,
  handleErrors,
  companySuspendedModalState,
  tooManyRequestsModalState
}) {
  return {
    loggedIn: session.loggedInStatus,
    middlewareError: middlewareError.error,
    handleErrors: handleErrors.error,
    companySuspendedModalOpened: companySuspendedModalState?.opened,
    tooManyRequestsModalOpened: tooManyRequestsModalState?.opened
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setHandleErrors,
      getUserCompanies,
      reloadReduxState,
      companySuspendedModalHide,
      tooManyRequestsModalHide
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
