import * as React from 'react';

function ExpirySvg(props) {
  return (
    <svg {...props} width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.22914 9.19999C7.47914 5.61999 11.4591 3.23999 15.9991 3.23999C23.0191 3.23999 28.7091 8.92999 28.7091 15.95C28.7091 22.97 23.0191 28.66 15.9991 28.66C9.97914 28.66 4.93914 24.48 3.61914 18.86'
        stroke='#005B9E'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.9995 24.43C20.6829 24.43 24.4795 20.6333 24.4795 15.95C24.4795 11.2666 20.6829 7.46997 15.9995 7.46997C11.3162 7.46997 7.51953 11.2666 7.51953 15.95C7.51953 20.6333 11.3162 24.43 15.9995 24.43Z'
        stroke='#005B9E'
        strokeWidth='0.8'
        strokeLinejoin='round'
      />
      <path d='M16 9.21997V16.04H20.45' stroke='#005B9E' strokeWidth='0.8' strokeLinejoin='round' />
      <path
        d='M3.39062 12.22L3.50062 10.87L3.61062 9.51001L4.73062 10.28L5.85062 11.05L4.62062 11.64L3.39062 12.22Z'
        fill='#005B9E'
        stroke='#005B9E'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ExpirySvg;
