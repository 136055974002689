import * as React from 'react';

function ImportExportSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} viewBox='0 0 24 24' {...props}>
      <path
        d='M20.9173 13.4923L15.2345 18.5467C15.1056 18.6614 14.9016 18.5699 14.9016 18.3973V15.9913H2.99999V13.1413H17.6481V13.1428H20.7843C20.9682 13.1428 21.0546 13.3701 20.9173 13.4923Z'
        style={{ fill: 'var(--secondary)' }}
      />
      <path
        d='M3.06439 10.2736L8.7533 5.21885C8.88229 5.10424 9.08614 5.19581 9.08614 5.36836V7.77467H21.0001V10.6247H6.33695V10.6231H3.19723C3.0133 10.6231 2.92689 10.3957 3.06439 10.2736Z'
        style={{ fill: 'var(--primary)' }}
      />
    </svg>
  );
}

export default ImportExportSvg;
