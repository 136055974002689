import { API_LOGIN_REQUEST, API_LOGIN_SUCCESS, API_LOGIN_FAILURE } from '../../../constants/actions';

const initialState = {
  isFetching: null,
  error: null,
  timestamp: null,
  response: {},
  status: 'idle'
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        response: {},
        timestamp: new Date().toUTCString(),
        status: 'fetching'
      };
    case API_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        response: action.response,
        timestamp: new Date().toUTCString(),
        status: 'success'
      };
    case API_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        response: {},
        timestamp: new Date().toUTCString(),
        status: 'fail'
      };
    default:
      return state;
  }
}
