import React, { useState } from 'react';
import styles from './Toggle.module.css';
import { useFormContext } from 'react-hook-form';

const Toggle = ({ name, readOnly, onClick, defaultValue = false, className }) => {
  const { register } = useFormContext() || {};
  const registerProps = readOnly ? undefined : register(name);
  const [isChecked, setIsChecked] = useState(defaultValue);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className={[styles.checkBox, readOnly ? styles.disabled : '', className ? className : ''].join(' ')}>
      <input
        className={styles.input}
        onClick={(e) => onClick && onClick(e)}
        {...registerProps}
        name={name}
        type='checkbox'
        checked={isChecked}
        onChange={handleChange}
      />
    </div>
  );
};

export default Toggle;
