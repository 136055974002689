import LocalizedStrings from 'react-localization';

export const languages = {
  en_GB: 'en_GB',
  sr_RS: 'sr_RS',
  de_DE: 'de_DE',
  es_ES: 'es_ES',
  fr_FR: 'fr_FR',
  it_IT: 'it_IT',
  nl_NL: 'nl_NL',
  pl_PL: 'pl_PL',
  sk_SK: 'sk_SK',
  tr_TR: 'tr_TR',
  bg_BG: 'bg_BG',
  hr_HR: 'hr_HR',
  hu_HU: 'hu_HU',
  al_AL: 'al_AL',
  si_SI: 'si_SI',
  gr_GR: 'gr_GR',
  mk_MK: 'mk_MK'
};

export const initLanguageObject = {
  [languages.en_GB]: {},
  [languages.sr_RS]: {},
  [languages.de_DE]: {},
  [languages.es_ES]: {},
  [languages.fr_FR]: {},
  [languages.it_IT]: {},
  [languages.nl_NL]: {},
  [languages.pl_PL]: {},
  [languages.sk_SK]: {},
  [languages.tr_TR]: {},
  [languages.bg_BG]: {},
  [languages.hr_HR]: {},
  [languages.hu_HU]: {},
  [languages.al_AL]: {},
  [languages.si_SI]: {},
  [languages.gr_GR]: {},
  [languages.mk_MK]: {}
};

const strings = new LocalizedStrings(initLanguageObject);

export default strings;
