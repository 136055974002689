import * as React from 'react';

function ThreePLSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} viewBox='0 0 24 24' {...props}>
      <path
        d='M 7.49992,4.20842 C 6.28877,4.90767 5.25724,5.87956 4.48718,7.04696 3.71711,8.21437 3.2297,9.54517 3.06361,10.9338 c -0.16609,1.3886 -0.00629,2.7968 0.46673,4.1129 0.47302,1.3161 1.24623,2.5038 2.2583,3.469 L 7.84006,16.3646 C 7.16225,15.7182 6.64441,14.9228 6.32761,14.0413 6.01082,13.1599 5.9038,12.2168 6.01503,11.2868 6.12627,10.3568 6.4527,9.46554 6.96843,8.68369 7.48417,7.90185 8.17501,7.25095 8.98615,6.78264 Z M 21,12 C 21,10.6094 20.6778,9.23768 20.0585,7.99253 19.4393,6.74738 18.54,5.66265 17.4311,4.82347 16.3223,3.98428 15.034,3.41347 13.6675,3.15582 12.3009,2.89817 10.8933,2.9607 9.55497,3.33849 l 0.80753,2.86066 c 0.8963,-0.25301 1.8391,-0.29489 2.7543,-0.12233 0.9152,0.17255 1.7779,0.55484 2.5206,1.11686 0.7426,0.56203 1.3449,1.2885 1.7596,2.12241 C 17.8117,10.15 18.0275,11.0687 18.0275,12 Z'
        style={{ fill: 'var(--primary)' }}
      />
      <path
        d='M7.49996 19.7955C8.68867 20.4818 10.0186 20.8871 11.388 20.9804C12.7575 21.0738 14.1301 20.8526 15.4009 20.3339C16.6717 19.8153 17.8071 19.0128 18.7201 17.9879C19.6331 16.963 20.2996 15.7428 20.6686 14.4207L17.8056 13.6217C17.5585 14.5071 17.1121 15.3243 16.5006 16.0107C15.8892 16.6971 15.1288 17.2345 14.2777 17.5819C13.4266 17.9293 12.5073 18.0774 11.5901 18.0149C10.673 17.9523 9.7823 17.6809 8.98619 17.2213L7.49996 19.7955Z'
        style={{ fill: 'var(--secondary)' }}
      />
    </svg>
  );
}

export default ThreePLSvg;
