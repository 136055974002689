import * as React from 'react';

const RightToDataPortSvg = (props) => (
  <svg id='Layer_19' data-name='Layer 19' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>
        {
          '.cls-4{stroke:#231f20;stroke-miterlimit:10;stroke-width:.8px;fill:none;stroke-linecap:round}.cls-6{fill:#010101}'
        }
      </style>
    </defs>
    <path
      style={{
        stroke: '#010101',
        strokeLinejoin: 'round',
        strokeWidth: '1.6px',
        fill: 'none'
      }}
      d='M2.82 4.77h26.34v22.4H2.82z'
    />
    <circle className='cls-6' cx={6.01} cy={6.52} r={0.45} />
    <circle className='cls-6' cx={7.02} cy={6.52} r={0.45} />
    <circle className='cls-6' cx={5.01} cy={6.52} r={0.45} />
    <path
      style={{
        fill: '#010101',
        stroke: '#231f20',
        strokeMiterlimit: 10,
        strokeWidth: '.8px'
      }}
      d='M3.34 7.99h25.23'
    />
    <path
      style={{
        fill: 'none',
        stroke: '#231f20',
        strokeMiterlimit: 10,
        strokeWidth: '.8px'
      }}
      d='m23.34 12.06-.91 3.42-.92 3.41-1.99-1.98-.04-.99h-.94l-2.03-2.03 3.41-.92 3.42-.91z'
    />
    <path className='cls-4' d='M5.78 18.64s.19.35.54.84' />
    <path
      d='M6.97 20.3c1.13 1.26 2.99 2.64 5.32 1.85 1.39-.47 3.56-2.4 4.86-3.63'
      style={{
        strokeLinecap: 'round',
        fill: 'none',
        stroke: '#231f20',
        strokeMiterlimit: 10,
        strokeWidth: '.8px',
        strokeDasharray: '0 0 2.1 1.05'
      }}
    />
    <path className='cls-4' d='M17.54 18.15c.44-.43.71-.71.71-.71' />
  </svg>
);

export default RightToDataPortSvg;
