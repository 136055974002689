import { SET_IS_USER_VERIFIED } from '../../../constants/actions';

const initialState = {
  verified: true
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_IS_USER_VERIFIED:
      // We check for permissions on app load. If that API call returns an error unverified, we set the user as unverified until he reloads the app
      return {
        ...state,
        verified: action.isVerified
      };
    default:
      return state;
  }
}
