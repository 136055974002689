import { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userIdSelector, HtSelector, EtSelector, CtSelector, companyIdSelector } from '../store/selectors';
import { wholePageLoaderHide, wholePageLoaderShow } from '../store/actions/wholePageLoaderState';
import { companySuspendedModalShow } from '../store/actions/companySuspendedModal';
import { tooManyRequestsModalShow } from '../store/actions/tooManyRequestsModal';
import api from '../assets/helpers/api';

const companySuspendedErrorCode = 10069;

export const apiStatus = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};

/**
 * @returns [apiRequest, responseData, status, error]
 */
const useAPI = ({ url, method, callback = null, formatApiData, hideLoader = false }) => {
  const [responseData, setResponseData] = useState(null);
  const [status, setStatus] = useState(apiStatus.IDLE);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);
  const Ht = useSelector(HtSelector);
  const Et = useSelector(EtSelector);
  const Ct = useSelector(CtSelector);
  const companyId = useSelector(companyIdSelector);

  const headerData = useMemo(
    () => ({
      userId,
      Ht,
      Et,
      Ct,
      companyId
    }),
    [userId, Ht, Et, Ct, companyId]
  );

  const memoizedCallback = useCallback(callback, [callback]);
  const memoizedFormatApiData = useCallback(formatApiData, [formatApiData]);

  const apiRequest = useCallback(
    async (data = {}) => {
      if (!url) return;

      try {
        if (!hideLoader) {
          dispatch(wholePageLoaderShow());
        }
        setStatus(apiStatus.FETCHING);
        const response = await api(url, data, method, headerData);
        const resData = memoizedFormatApiData ? memoizedFormatApiData(response?.data) : response?.data;
        setResponseData(resData);

        if (memoizedCallback) {
          memoizedCallback(response);
        }
        setStatus(apiStatus.SUCCESS);
        return resData;
      } catch (error) {
        if (error?.errors?.[0]?.error_code === companySuspendedErrorCode) {
          setStatus(apiStatus.IDLE);
          dispatch(companySuspendedModalShow());
        } else if (error.code === 429) {
          setStatus(apiStatus.IDLE);
          if (error?.errors) {
            setError(error.errors);
          }
          dispatch(tooManyRequestsModalShow());
        } else {
          setStatus(apiStatus.FAIL);
          setResponseData(error);
          if (error?.errors) {
            setError(error.errors);
          }
        }
      } finally {
        dispatch(wholePageLoaderHide());
      }
    },
    [dispatch, method, url, hideLoader, memoizedCallback, headerData, memoizedFormatApiData]
  );

  return [apiRequest, responseData, status, error];
};

export default useAPI;
