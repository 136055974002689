import { cargoSubtypes, companySubtypes, vehicleSubtypes, warehouseSubtypes } from '../../constants/NotificationCodes';
import {
  HOT_RELOAD_SUBSCRIBE,
  HOT_RELOAD_UNSUBSCRIBE,
  HOT_RELOAD_BULK_SUBSCRIBE,
  HOT_RELOAD_BULK_UNSUBSCRIBE
} from '../../constants/actions';
import store from '../store';

function subscribeAction(data) {
  return {
    type: HOT_RELOAD_SUBSCRIBE,
    data: data
  };
}

function unsubscribeAction(data) {
  return { type: HOT_RELOAD_UNSUBSCRIBE, data: data };
}

function bulkSubscribeAction(data) {
  return {
    type: HOT_RELOAD_BULK_SUBSCRIBE,
    data: data
  };
}

function bulkUnsubscribeAction(data) {
  return { type: HOT_RELOAD_BULK_UNSUBSCRIBE, data: data };
}

export function subscribe(data) {
  return (dispatch) => {
    return dispatch(subscribeAction(data));
  };
}

export function unsubscribe(data) {
  return (dispatch) => {
    return dispatch(unsubscribeAction(data));
  };
}

export function bulkSubscribe(data) {
  return (dispatch) => {
    return dispatch(bulkSubscribeAction(data));
  };
}

export function bulkUnsubscribe(data) {
  return (dispatch) => {
    return dispatch(bulkUnsubscribeAction(data));
  };
}

const standardCargoBidNotif = [
  cargoSubtypes.CARGO_BID_ACCEPT,
  cargoSubtypes.CARGO_BID_DECLINE,
  cargoSubtypes.CARGO_BID_WITHDRAW,
  cargoSubtypes.CARGO_BID_OFFER_EXPIRED,
  cargoSubtypes.CARGO_BID_SEND_PRICE,
  cargoSubtypes.CARGO_BID_OFFER_CLOSED,
  cargoSubtypes.CARGO_BID_OFFER_CLOSED_WHEN_ACCEPTED,
  cargoSubtypes.CARGO_BID_DECLINE_FINAL,
  cargoSubtypes.TIME_LIMIT_BID_ON_CARGO_OFFER,
  cargoSubtypes.TIME_LIMIT_CARGO_BID_SEND_PRICE
];

const standardVehicleBidNotif = [
  vehicleSubtypes.VEHICLE_BID_SEND_PRICE,
  vehicleSubtypes.VEHICLE_BID_DECLINE_BID,
  vehicleSubtypes.VEHICLE_BID_ACCEPT_PRICE,
  vehicleSubtypes.VEHICLE_BID_DECLINE_PRICE,
  vehicleSubtypes.VEHICLE_BID_WITHDRAW_BID,
  vehicleSubtypes.VEHICLE_OFFER_WITHDRAW_PRICE,
  vehicleSubtypes.VEHICLE_BID_OFFER_EXPIRED,
  vehicleSubtypes.VEHICLE_BID_OFFER_CLOSED,
  vehicleSubtypes.VEHICLE_BID_OFFER_CLOSED_WHEN_ACCEPTED,
  vehicleSubtypes.VEHICLE_BID_DECLINE_FINAL,
  vehicleSubtypes.TIME_LIMIT_BID_ON_VEHICLE_OFFER,
  vehicleSubtypes.TIME_LIMIT_VEHICLE_BID_SEND_PRICE
];

const standardWarehBidNotif = [
  warehouseSubtypes.WAREHOUSE_BID_SEND_PRICE,
  warehouseSubtypes.WAREHOUSE_BID_DECLINE_BID,
  warehouseSubtypes.WAREHOUSE_BID_ACCEPT_PRICE,
  warehouseSubtypes.WAREHOUSE_BID_DECLINE_PRICE,
  warehouseSubtypes.WAREHOUSE_BID_WITHDRAW_BID,
  warehouseSubtypes.WAREHOUSE_OFFER_WITHDRAW_PRICE,
  warehouseSubtypes.WAREHOUSE_BID_OFFER_CLOSED,
  warehouseSubtypes.WAREHOUSE_BID_OFFER_CLOSED_WHEN_ACCEPTED,
  warehouseSubtypes.WAREHOUSE_BID_DECLINE_FINAL,
  warehouseSubtypes.TIME_LIMIT_BID_ON_WAREHOUSE_OFFER,
  warehouseSubtypes.TIME_LIMIT_WAREHOUSE_BID_SEND_PRICE
];

export const hotReloadType = {
  FIND_CARGO_OFFERS: 1,
  FIND_VEHICLE_OFFERS: 2,
  FIND_WAREHOUSE_OFFERS: 3,
  CARGO_OFFER_BIDS: 4,
  VEHICLE_OFFER_BIDS: 5,
  WAREHOUSE_OFFER_BIDS: 6,
  CARGO_MY_BIDS: 7,
  VEHICLE_MY_BIDS: 8,
  WAREHOUSE_MY_BIDS: 9,
  CARGO_BID_VIEW: 10,
  VEHICLE_BID_VIEW: 11,
  WAREHOUSE_BID_VIEW: 12,
  NEW_USERS_TUNNEL: 13
};

export const hotReloadTypeData = {
  [hotReloadType.FIND_CARGO_OFFERS]: {
    propertyName: 'cargo_offers',
    idPropertyName: 'id',
    notifyIdName: 'offer_id',
    notifyOn: [cargoSubtypes.CARGO_BID_EXPIRED, ...standardCargoBidNotif]
  },
  [hotReloadType.FIND_VEHICLE_OFFERS]: {
    propertyName: 'vehicle_offers',
    idPropertyName: 'id',
    notifyIdName: 'offer_id',
    notifyOn: [vehicleSubtypes.VEHICLE_BID_EXPIRED, ...standardVehicleBidNotif]
  },
  [hotReloadType.FIND_WAREHOUSE_OFFERS]: {
    propertyName: 'warehouse_offers',
    idPropertyName: 'id',
    notifyIdName: 'offer_id',
    notifyOn: [warehouseSubtypes.WAREHOUSE_BID_EXPIRED, ...standardWarehBidNotif]
  },
  [hotReloadType.CARGO_OFFER_BIDS]: {
    propertyName: '',
    idPropertyName: 'bid_id',
    notifyIdName: 'bid_id',
    notifyOn: [cargoSubtypes.CARGO_BID_EXPIRED_ON_YOUR_OFFER, ...standardCargoBidNotif]
  },
  [hotReloadType.VEHICLE_OFFER_BIDS]: {
    propertyName: '',
    idPropertyName: 'bid_id',
    notifyIdName: 'bid_id',
    notifyOn: [vehicleSubtypes.VEHICLE_BID_EXPIRED_ON_YOUR_OFFER, ...standardVehicleBidNotif]
  },
  [hotReloadType.WAREHOUSE_OFFER_BIDS]: {
    propertyName: 'warehouse_offer_bids',
    idPropertyName: 'bid_id',
    notifyIdName: 'bid_id',
    notifyOn: [warehouseSubtypes.WAREHOUSE_BID_EXPIRED_ON_YOUR_OFFER, ...standardWarehBidNotif]
  },
  [hotReloadType.CARGO_MY_BIDS]: {
    propertyName: 'cargo_bids',
    idPropertyName: 'bid_id',
    notifyIdName: 'bid_id',
    notifyOn: [cargoSubtypes.CARGO_BID_EXPIRED, ...standardCargoBidNotif]
  },
  [hotReloadType.VEHICLE_MY_BIDS]: {
    propertyName: 'vehicle_bids',
    idPropertyName: 'bid_id',
    notifyIdName: 'bid_id',
    notifyOn: [vehicleSubtypes.VEHICLE_BID_EXPIRED, ...standardVehicleBidNotif]
  },
  [hotReloadType.WAREHOUSE_MY_BIDS]: {
    propertyName: 'my_bids',
    idPropertyName: 'bid_id',
    notifyIdName: 'bid_id',
    notifyOn: [warehouseSubtypes.WAREHOUSE_BID_EXPIRED, ...standardWarehBidNotif]
  },
  [hotReloadType.CARGO_BID_VIEW]: {
    propertyName: '',
    idPropertyName: 'id',
    notifyIdName: 'bid_id',
    notifyOn: [cargoSubtypes.CARGO_BID_EXPIRED_ON_YOUR_OFFER, cargoSubtypes.CARGO_BID_EXPIRED, ...standardCargoBidNotif]
  },
  [hotReloadType.VEHICLE_BID_VIEW]: {
    propertyName: '',
    idPropertyName: 'bid_id',
    notifyIdName: 'bid_id',
    notifyOn: [
      vehicleSubtypes.VEHICLE_BID_EXPIRED_ON_YOUR_OFFER,
      vehicleSubtypes.VEHICLE_BID_EXPIRED,
      ...standardVehicleBidNotif
    ]
  },
  [hotReloadType.WAREHOUSE_BID_VIEW]: {
    propertyName: '',
    idPropertyName: 'bid_id',
    notifyIdName: 'bid_id',
    notifyOn: [
      warehouseSubtypes.WAREHOUSE_BID_EXPIRED_ON_YOUR_OFFER,
      warehouseSubtypes.WAREHOUSE_BID_EXPIRED,
      ...standardWarehBidNotif
    ]
  },
  [hotReloadType.NEW_USERS_TUNNEL]: {
    propertyName: '',
    idPropertyName: '',
    notifyIdName: undefined,
    notifyOn: [
      companySubtypes.COMPANY_DECLINE_JOIN_REQUEST,
      companySubtypes.COMPANY_ACCEPT_JOIN_REQUEST,
      companySubtypes.VERIFIED_COMPANY,
      companySubtypes.REJECTED_COMPANY
    ]
  }
};

/**
 * Searches for data that is related to type so that proper subscribers can be notified
 * @param {*} data
 * @returns
 */
function notificationToNotifyData(data) {
  const messageSubtype = data.message_subtype;

  const types = Object.values(hotReloadType).filter((value) => {
    const notifyOn = hotReloadTypeData[value].notifyOn;
    return notifyOn.includes(messageSubtype);
  });

  return new NotifyData(data.payload, types);
}

export function notify(notificationData) {
  const data = notificationToNotifyData(notificationData);
  const { payload, types } = data;

  const { subscribers } = store.getState().hotReloadObserver;

  if (!subscribers.length) return;

  subscribers.forEach((subscriber) => {
    const { type } = subscriber;
    const subscriberTypeData = hotReloadTypeData[type];
    const notifySubscriberId = payload?.[subscriberTypeData.notifyIdName]; // get id based on type, for example id can be bid_id, offer_id from payload

    if (subscriber.id == notifySubscriberId && types.includes(subscriber.type)) {
      subscriber.reload({ [subscriberTypeData.idPropertyName]: notifySubscriberId });
    }
  });
}

/**
 *
 * @param {*} id
 * @param {*} type
 * @param {*} reload Function that will be called with data on notify
 */
export function HotReloadSubscriber(id, type, reload) {
  this.id = id;
  this.type = type;
  this.reload = reload;
}

function NotifyData(payload, types) {
  this.payload = payload;
  this.types = types;
}

export default {
  subscribe,
  bulkSubscribe,
  unsubscribe,
  bulkUnsubscribe,
  notify
};
