import * as React from 'react';

const RightToCorrectionSvg = (props) => (
  <svg id='Layer_32' data-name='Layer 32' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>{'.cls-5{fill:#010101}'}</style>
    </defs>
    <path
      style={{
        strokeLinejoin: 'round',
        strokeWidth: '1.6px',
        fill: 'none',
        stroke: '#010101'
      }}
      d='M2.83 4.8h26.34v22.4H2.83z'
    />
    <path
      style={{
        fill: '#010101',
        stroke: '#231f20',
        strokeMiterlimit: 10,
        strokeWidth: '.8px'
      }}
      d='M3.6 8.5h25.22'
    />
    <circle className='cls-5' cx={6.27} cy={7.03} r={0.45} />
    <circle className='cls-5' cx={7.28} cy={7.03} r={0.45} />
    <circle className='cls-5' cx={5.27} cy={7.03} r={0.45} />
    <circle
      cx={16.14}
      cy={17}
      r={6.49}
      style={{
        fill: 'none',
        stroke: '#010101',
        strokeMiterlimit: 10,
        strokeWidth: '.8px'
      }}
    />
    <path
      style={{
        strokeWidth: '1.6px',
        strokeLinecap: 'round',
        fill: 'none',
        stroke: '#010101',
        strokeMiterlimit: 10
      }}
      d='M12.55 16.91 15 19.36l4.72-4.72'
    />
  </svg>
);

export default RightToCorrectionSvg;
