import * as React from 'react';

const LogoAnimatedSvg = (props) => (
  <svg
    id='eUgo5dCyI731'
    className='loaderIcon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 128 128'
    shapeRendering='geometricPrecision'
    textRendering='geometricPrecision'
    {...props}
  >
    <rect
      width='128'
      height='128'
      rx='6'
      ry='6'
      transform='matrix(.999961 0 0 1 0.002496 0)'
      fill='#005b9e'
      strokeWidth='0'
    />
    <path
      d='M46.647993,27.549281c10.570762,11.146498,35.08169,36.880955,35.08169,36.880955'
      transform='matrix(.951228 0 0 0.995214-13.743563 0.215654)'
      fill='none'
      stroke='#fff'
      strokeWidth='10.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g id='eUgo5dCyI734_tr' transform='translate(63.643543,64.337527) rotate(0)'>
      <path
        d='M81.729683,64.430236L46.647992,100.632891'
        transform='scale(0.951228,0.995214) translate(-81.729677,-64.430236)'
        fill='none'
        stroke='#fff'
        strokeWidth='10.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default LogoAnimatedSvg;
