import {
  HOT_RELOAD_SUBSCRIBE,
  HOT_RELOAD_UNSUBSCRIBE,
  HOT_RELOAD_BULK_SUBSCRIBE,
  HOT_RELOAD_BULK_UNSUBSCRIBE
} from '../../../constants/actions';

// Subscriber example {id: 1, type: 'CARDO_OFFER'}
const initialState = {
  subscribers: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case HOT_RELOAD_SUBSCRIBE:
      return {
        ...state,
        subscribers: [...state.subscribers, action.data]
      };
    case HOT_RELOAD_UNSUBSCRIBE:
      return {
        ...state,
        subscribers: state.subscribers.filter(({ id, type }) => {
          return action.data.id !== id && action.data.type !== type;
        })
      };
    case HOT_RELOAD_BULK_SUBSCRIBE:
      return {
        ...state,
        subscribers: [...state.subscribers, ...action.data]
      };
    case HOT_RELOAD_BULK_UNSUBSCRIBE:
      return {
        ...state,
        subscribers: state.subscribers.filter(({ type }) => {
          return action.data.type !== type;
        })
      };

    default:
      return state;
  }
}
