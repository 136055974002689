import React from 'react';
import styles from './StickyFooter.module.css';

const StickyFooter = ({ className = '', children }) => {
  return <div className={`${styles.footer} ${className}`}>{children}</div>;
};

export const FooterButtons = ({ className = '', children }) => {
  return <div className={`${styles.buttons} ${className}`}>{children}</div>;
};

export default StickyFooter;
