import React, { useContext } from 'react';
import config from '../../../../../constants/config';
import AppComponentContext from '../../../../../contexts/AppComponentContext';
import styles from './ListDocuments.module.css';

const ListDocuments = ({ documents, hasCmrGenerator, setCmrDrawer = () => {} }) => {
  const localization = useContext(AppComponentContext).strings;

  const listDocuments = () => {
    if (!hasCmrGenerator && (!documents || !documents.length)) {
      return <span className={styles.document}>{localization.noDocumentsAttached}</span>;
    }

    return documents?.map(({ name, location, type }, i) => {
      return (
        <div key={i} className={styles.document}>
          <span className={styles.type}>{localization[type]}</span>
          <a target='_blank' rel='noreferrer' href={config.CDN_URL + '' + location}>
            {`${name}.${location?.split('.').pop()}`}
          </a>
        </div>
      );
    });
  };

  return (
    <div className={styles.documentList}>
      <h3 className={styles.title}>{localization.documentationUploaded}</h3>
      <div className={styles.documents}>
        {hasCmrGenerator ? (
          <div className={styles.document} onClick={() => setCmrDrawer(true)}>
            <span className={styles.type}>{localization.CMR}</span>
            <p>{localization.viewCmrGenerated}</p>
          </div>
        ) : null}
        {listDocuments()}
      </div>
    </div>
  );
};

export default ListDocuments;
