import * as React from 'react';

function RailIcon(props) {
  const fill = props.style && props.style.fill ? props.style.fill : 'var(--primaryDarkSvg)';
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
      <path
        d='M8.5 16C9.32843 16 10 15.3284 10 14.5C10 13.6716 9.32843 13 8.5 13C7.67157 13 7 13.6716 7 14.5C7 15.3284 7.67157 16 8.5 16Z'
        fill={fill}
      />
      <path
        d='M15.5 16C16.3284 16 17 15.3284 17 14.5C17 13.6716 16.3284 13 15.5 13C14.6716 13 14 13.6716 14 14.5C14 15.3284 14.6716 16 15.5 16Z'
        fill={fill}
      />
      <path
        d='M18.87 3.34C18.5689 2.95797 18.1925 2.64189 17.7642 2.41138C17.3358 2.18087 16.8647 2.04085 16.38 2H7.62C7.13163 2.03588 6.65638 2.17472 6.2255 2.4074C5.79462 2.64008 5.41786 2.96132 5.12 3.35C4.45486 4.07942 4.0596 5.01465 4 6V18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H7L5 22H7.32L8 21H16L16.68 22H19L17 19H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18V6C19.9542 5.00658 19.5532 4.06263 18.87 3.34ZM7.62 4H16.34C16.7399 4.09706 17.0935 4.33044 17.34 4.66C17.4306 4.7678 17.5141 4.88137 17.59 5H6.39C6.46222 4.87578 6.54592 4.7586 6.64 4.65C6.87575 4.32033 7.22459 4.08895 7.62 4ZM6 8V7H18V10H6V8ZM18 17H6V12H18V17Z'
        fill={fill}
      />
    </svg>
  );
}

export default RailIcon;
