import * as React from 'react';

const RightOfAccessSvg = (props) => (
  <svg id='Layer_30' data-name='Layer 30' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>{'.cls-2{stroke-width:.8px;fill:none;stroke:#231f20;stroke-linejoin:round;stroke-linecap:round}'}</style>
    </defs>
    <path
      style={{
        strokeWidth: '1.6px',
        fill: 'none',
        stroke: '#231f20',
        strokeLinejoin: 'round'
      }}
      d='M28.22 28.26H3.67V14.11h4.96L9.81 16h18.41v12.26z'
    />
    <path
      style={{
        fill: 'none',
        stroke: '#231f20',
        strokeLinejoin: 'round',
        strokeWidth: '.8px'
      }}
      d='M6.45 13.7V3.42H26.4v12.4'
    />
    <path className='cls-2' d='M8.69 5.96h15.86M8.69 8.5h15.86M8.69 11.04h15.86' />
  </svg>
);

export default RightOfAccessSvg;
