import { SET_DRAWER_STATE, SET_DRAWER_CALLBACK, SET_DRAWER_DATA } from '../../../constants/actions';

const initialState = {
  addVehicleOfferDrawer: {
    opened: false,
    callback: null
  },
  addCargoOfferDrawer: {
    opened: false,
    callback: null
  },
  addWarehouseOfferDrawer: {
    opened: false,
    callback: null
  },
  addCompanyDrawer: {
    opened: false,
    callback: null
  },
  userInviteDrawer: {
    opened: false,
    callback: null
  },
  verifiedCompanyDrawer: {
    opened: false,
    callback: null
  },
  addCompanyDocDrawer: {
    opened: false,
    callback: null
  },
  addCmrDrawer: {
    opened: false,
    callback: null
  },
  touDrawer: {
    opened: false,
    callback: null
  },
  ppDrawer: {
    opened: false,
    callback: null
  },
  faqDrawer: {
    opened: false,
    callback: null
  },
  dpaDrawer: {
    opened: false,
    callback: null,
    data: {
      companyName: '',
      companyAddress: '',
      userName: '',
      userEmail: ''
    }
  },
  contactUsDrawer: {
    opened: false,
    callback: null
  },
  chatsDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  chooseSubscriptionDrawer: {
    opened: false,
    callback: null
  },
  addPredefinedVehicleDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  addPredefinedCargoDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  addPredefinedWarehouseDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  addEventPlannerDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  viewEventPlannerDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  editEventPlannerDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  viewDocumentDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  addContactDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  addContactCategory: {
    opened: false,
    callback: null,
    data: {}
  },
  viewContactDrawer: {
    opened: false,
    callback: null,
    data: {}
  },
  editContactDrawer: {
    opened: false,
    callback: null,
    data: {}
  }

  // Initialize more drawers here similarly
};

export default function reducer(state = initialState, action = {}) {
  // Extracting action details
  const { type, opened, callback, data } = action;

  // Finding the key that matches the action type for state or callback or data
  const drawerKeyState = Object.keys(SET_DRAWER_STATE).find((key) => SET_DRAWER_STATE[key] === type);
  const drawerKeyCallback = Object.keys(SET_DRAWER_CALLBACK).find((key) => SET_DRAWER_CALLBACK[key] === type);
  const drawerKeyData = Object.keys(SET_DRAWER_DATA).find((key) => SET_DRAWER_DATA[key] === type);
  const drawerKey = drawerKeyState || drawerKeyCallback || drawerKeyData;

  if (!drawerKey) {
    return state; // If no drawerKey matches, return current state
  }
  switch (type) {
    case SET_DRAWER_STATE[drawerKey]:
      return {
        ...state,
        [drawerKey]: {
          ...state[drawerKey],
          opened: opened,
          ...(data && { data })
        }
      };
    case SET_DRAWER_CALLBACK[drawerKey]:
      return {
        ...state,
        [drawerKey]: {
          ...state[drawerKey],
          callback: callback
        }
      };
    case SET_DRAWER_DATA[drawerKey]:
      return {
        ...state,
        [drawerKey]: {
          ...state[drawerKey],
          data
        }
      };
    default:
      return state;
  }
}
