import * as React from 'react';

function ManufacturingSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} viewBox='0 0 24 24' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1257 9.94825C16.0498 9.97862 16 10.0522 16 10.1339V8.29395C16 8.15245 15.8571 8.0557 15.7257 8.10825L11.1257 9.94825C11.0498 9.97862 11 10.0522 11 10.1339V8.29395C11 8.15245 10.8571 8.0557 10.7257 8.10825L6.12572 9.94825C6.04979 9.97862 6 10.0522 6 10.1339V14.9985C5.44772 14.9985 5 15.4463 5 15.9985V18.9985C5 19.5508 5.44772 19.9985 6 19.9985L6.2 19.9985H10.8H11.2H15.8L20 19.9985C20.5523 19.9985 21 19.5508 21 18.9985V18.3497V15.9985V8.29395C21 8.15245 20.8571 8.0557 20.7257 8.10825L16.1257 9.94825ZM8.2 11.9985C8.08954 11.9985 8 12.0881 8 12.1985V13.7985C8 13.909 8.08954 13.9985 8.2 13.9985H9.8C9.91046 13.9985 10 13.909 10 13.7985V12.1985C10 12.0881 9.91046 11.9985 9.8 11.9985H8.2ZM12.2 11.9985C12.0895 11.9985 12 12.0881 12 12.1985V13.7985C12 13.909 12.0895 13.9985 12.2 13.9985H13.8C13.9105 13.9985 14 13.909 14 13.7985V12.1985C14 12.0881 13.9105 11.9985 13.8 11.9985H12.2ZM16 12.1985C16 12.0881 16.0895 11.9985 16.2 11.9985H17.8C17.9105 11.9985 18 12.0881 18 12.1985V13.7985C18 13.909 17.9105 13.9985 17.8 13.9985H16.2C16.0895 13.9985 16 13.909 16 13.7985V12.1985Z'
        style={{ fill: 'var(--primary)' }}
      />
      <path
        d='M3.46973 4.96877C3.4866 4.42891 3.92911 4 4.46924 4H5.53076C6.07089 4 6.5134 4.4289 6.53027 4.96877L6.96777 18.9688C6.98541 19.533 6.53279 20 5.96826 20H4.03174C3.46721 20 3.01459 19.533 3.03223 18.9688L3.46973 4.96877Z'
        style={{ fill: 'var(--secondary)' }}
      />
    </svg>
  );
}

export default ManufacturingSvg;
