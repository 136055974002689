import { TOO_MANY_REQUESTS_MODAL_SHOW, TOO_MANY_REQUESTS_MODAL_HIDE } from '../../constants/actions';

export function tooManyRequestsModalShow(state) {
  return (dispatch) => {
    return dispatch({
      state,
      type: TOO_MANY_REQUESTS_MODAL_SHOW
    });
  };
}

export function tooManyRequestsModalHide(state) {
  return (dispatch) => {
    return dispatch({
      state,
      type: TOO_MANY_REQUESTS_MODAL_HIDE
    });
  };
}
