import React from 'react';
import { useRef } from 'react';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import ConsentSvg from '../../../assets/images/svg/ConsentSvg';
import CookiesSvg from '../../../assets/images/svg/CookiesSvg';
import DataControllerSvg from '../../../assets/images/svg/DataControllerSvg';
import DataProcessorSvg from '../../../assets/images/svg/DataProcessorSvg';
import GdprSvg from '../../../assets/images/svg/GdprSvg';
import LogoSvg from '../../../assets/images/svg/LogoSvg';
import ManagerSvg from '../../../assets/images/svg/ManagerSvg';
import MemberSvg from '../../../assets/images/svg/MemberSvg';
import PersonalDataSvg from '../../../assets/images/svg/PersonalDataSvg';
import ProcessingSvg from '../../../assets/images/svg/ProcessingSvg';
import RightOfAccessSvg from '../../../assets/images/svg/RightOfAccessSvg';
import RightToCorrectionSvg from '../../../assets/images/svg/RightToCorrectionSvg';
import RightToDataPortSvg from '../../../assets/images/svg/RightToDataPortSvg';
import RightToErasureSvg from '../../../assets/images/svg/RightToErasureSvg';
import RightToLodgeSvg from '../../../assets/images/svg/RightToLodgeSvg';
import RightToObjProcessingSvg from '../../../assets/images/svg/RightToObjProcessingSvg';
import RightToRestrictionSvg from '../../../assets/images/svg/RightToRestrictionSvg';
import RightToWithdrawSvg from '../../../assets/images/svg/RightToWithdrawSvg';
import SeatSvg from '../../../assets/images/svg/SeatSvg';
import SubProcessorSvg from '../../../assets/images/svg/SubProcessorSvg';
import UserLegalSvg from '../../../assets/images/svg/UserLegalSvg';
import YouSvg from '../../../assets/images/svg/YouSvg';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = ({ setSelf, stateHandler, documentSectionRefs, showTou, setSection }) => {
  const { isPC, isMobile } = useWindowDimensions();
  const { privacyPolicy, termsOfUse } = documentSectionRefs || {};
  const privacyPolicyRef = useRef();

  const handleDrawerState = () => {
    !showTou ? stateHandler(true) : setSelf(false);
  };

  const scrollToSection = (elementRef) => {
    elementRef.current.scrollIntoView({
      behavior: 'smooth',
      inline: 'start'
    });
  };

  const closeAndSetSection = (ref) => {
    setSection(ref);
    handleDrawerState();
  };

  return (
    <>
      <div className={[styles.wrapper, !isPC ? styles.responsiveWidth : ''].join(' ')} ref={privacyPolicyRef}>
        <div className={styles.container}>
          <div className={styles.header}>
            <LogoSvg className={styles.logo} />
          </div>
          <div className={styles.body}>
            <p className={styles.headingData}>PRIVACY POLICY</p>
            <p style={{ textAlign: 'end' }}>Last updated on May, 2023</p>
            <p>Welcome to KleverCargo’s Privacy Policy!</p>
            <p>
              Please note that this Privacy Policy applies to personal data that is collected and processed in the
              course of providing Service (as defined in{' '}
              <span
                onClick={() => closeAndSetSection(termsOfUse.definitions)}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Section 2 of the Terms of Use
              </span>
              ) by <b>KleverCargo Services B.V.</b>, with registered seat at Kingsfordweg 151-201, 1043 GR Amsterdam,
              the Netherlands, CIN 88795594, TIN NL864781659B01, (hereinafter: “<b>KleverCargo</b>”, or “<b>we</b>”).
            </p>
            <p>
              KleverCargo, as a Data Controller, collects and processes personal data relating to interactions on the
              Platform (as defined in the Definition Section of the{' '}
              <span
                onClick={handleDrawerState}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Terms of Use
              </span>
              ). This Privacy Policy describes how KleverCargo uses and protects any information that you share with us
              in relation to our Platform.
            </p>
            <p>
              We believe in full transparency, which is why we keep our Privacy Policy simple and easy to understand.
            </p>
            <p>
              We strongly urge you to read this Privacy Policy and make sure that you fully understand and agree with
              it. If you do not agree to this Privacy Policy, please do not access, or otherwise use KleverCargo. In
              case there is anything that you would like to ask us regarding this Privacy Policy, please send your
              inquiry to privacy@klevercargo.com.
            </p>
            <p>
              Along with the{' '}
              <span
                onClick={handleDrawerState}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Terms of Use
              </span>{' '}
              this Privacy Policy represents a contract between you and the KleverCargo. Thus, any capitalized but
              undefined term in this Privacy Policy shall have the meaning given to it in the Definitions Section of the{' '}
              <span
                onClick={handleDrawerState}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Terms of Use
              </span>
              .
            </p>
            <p className={styles.headingData}>CONTENT</p>
            <ol className={styles.orderedListBlue}>
              <li>DEFINITIONS</li>
              <li>DATA CONTROLLER AND DATA PROCESSOR</li>
              <li>WHAT DATA DO WE PROCESS ABOUT YOU AND WHEN?</li>
              <li>WHAT DO WE NOT DO?</li>
              <li>PERSONAL DATA SECURITY</li>
              <li>WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</li>
              <li>INTERNATIONAL TRANSFER OF YOUR PERSONAL DATA</li>
              <li>HOW LONG DO WE KEEP YOUR DATA?</li>
              <li>YOUR RIGHTS</li>
              <li>CHANGES TO PRIVACY POLICY</li>
            </ol>
            <div className={styles.sectionWrapper}>
              <p className={styles.title}>1. DEFINITIONS</p>
              <div className={styles.table}>
                {!isMobile ? (
                  <div className={styles.row}>
                    <div className={[styles.term, styles.termTitle].join(' ')} style={{ height: 50 }}>
                      <p>TERM </p>
                    </div>
                    <div
                      className={styles.meaning}
                      style={{ backgroundColor: 'var(--primary)', color: 'var(--white)' }}
                    >
                      <p>MEANING</p>
                    </div>
                  </div>
                ) : null}

                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>Consent </p>
                    <ConsentSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>
                      Your explicit consent on the processing of personal data. Persons who are 16 years of age or older
                      may give free consent to the processing of their personal data.
                    </p>
                  </div>
                </div>
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>Cookies</p>
                    <CookiesSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>
                      Cookies and other similar technologies (e.g. web beacons, LocalStorage, etc.) are small pieces of
                      data stored on your device (computer or mobile device). This information is used to track your use
                      of the Platform and to compile statistical reports on Platform activity.
                    </p>
                  </div>
                </div>
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>Data Controller </p>
                    <DataControllerSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>
                      An entity that alone or jointly with others determines the purposes and means of the processing of
                      personal data.
                    </p>
                  </div>
                </div>
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>Data Processor </p>
                    <DataProcessorSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>Any natural or legal person who processes the data on behalf of the controller.</p>
                  </div>
                </div>
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>Data Subject, or you</p>
                    <YouSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>
                      Any natural person that shares personal data with us via Platform, or in relation to Platform
                      (e.g. via email).
                    </p>
                  </div>
                </div>
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>{'“Employer”'}</p>
                    <ManagerSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>The Client who made your Personal Data available to us and who is using the Service.</p>
                  </div>
                </div>

                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>{'“Employee”'}</p>
                    <UserLegalSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>
                      An individual engaged as an employee, consultant, driver or contractor of Client, and registered
                      on the Platform by the Client, with the purpose to provide Client’s services.
                    </p>
                  </div>
                </div>

                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>{'“GDPR”'}</p>
                    <GdprSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>General Data Protection Regulation 2016/679.</p>
                  </div>
                </div>

                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>Personal data or data</p>
                    <PersonalDataSvg />
                  </div>
                  <div className={styles.meaning}>
                    <span>
                      Any information relating to an identified or identifiable natural person; an identifiable natural
                      person is one who can be identified, either directly or indirectly. Therefore,{' '}
                      <b>
                        data about a company or any legal entity is not considered to be personal data but registering
                        on behalf of a legal entity may include sharing personal data.
                      </b>{' '}
                      For example, information about one-person companies may constitute personal data where it allows
                      the identification of a natural person. The rules also apply to all personal data relating to
                      natural persons in the course of professional activity, such as the employees of a company or
                      organization, and business e-mail addresses like “firstname.surname@company.com”. This Privacy
                      Policy does not apply to information from which no individual can reasonably be identified
                      (anonymized information).
                    </span>
                  </div>
                </div>

                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={styles.term}>
                    <p>Processing</p>
                    <ProcessingSvg />
                  </div>
                  <div className={styles.meaning}>
                    <p>
                      Any operation or set of operations that is performed on personal data or sets of personal data.
                      This includes activities such as collection, recording, organization, structuring, storage,
                      adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination
                      or otherwise making available, alignment or combination, restriction, erasure, or destruction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.title}>2. DATA CONTROLLER AND DATA PROCESSOR</p>
              <p>
                In relation to your personal data processed via the Platform, KleverCargo may be either a Data
                Controller or Data Processor.
              </p>
              <p>
                When KleverCargo acts in the capacity of a Data Controller, KleverCargo determines the purposes and
                means of the processing of personal data. The purpose of data processing is the reason why we process
                your personal data. The table in Section{' '}
                <span
                  onClick={() => scrollToSection(privacyPolicy.dataControlerSection)}
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                >
                  3.1
                </span>{' '}
                of the Privacy Policy presents the purposes and legal basis for data processing. In those cases,
                KleverCargo is responsible for your personal data.
              </p>
              <p>
                Apart from Section{' '}
                <span
                  onClick={() => scrollToSection(privacyPolicy.dataProcessorSection)}
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                >
                  3.2
                </span>
                , this Privacy Policy primarily contains information on processing your data in the capacity of a Data
                Controller. Should you have any inquiries, or you wish to exercise any of the rights of a Data Subject
                stipulated in{' '}
                <span
                  onClick={() => scrollToSection(privacyPolicy.yourRightsSection)}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Section 9
                </span>
                , please contact us:
              </p>
              <ul>
                <li>
                  <span>
                    <b>KleverCargo Services B.V.</b>
                    Kingsfordweg 151-201, 1043 GR Amsterdam
                  </span>
                </li>
                <li>Email: privacy@klevercargo.com</li>
              </ul>
              <p>
                Given that KleverCargo strongly supports fair personal data processing, despite being only a Data
                Processor in the below-listed cases, KleverCargo made an additional effort to explain such personal data
                processing via Platform - in Section{' '}
                <span
                  onClick={() => scrollToSection(privacyPolicy.dataProcessorSection)}
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                >
                  3.2
                </span>{' '}
                of this Privacy Policy
              </p>
              <p>
                The information contained therein outlines how personal data processing via KleverCargo functions in
                general. But if you wish to send an inquiry, or exercise any of the rights which you may have under the
                applicable data protection law as the Data Subject, please contact the Client directly, as they hold the
                position of Data Controller.
              </p>
              <p>
                Since KleverCargo is a company operating under the laws of the Netherlands and falls under the scope of
                application of the GDPR, KleverCargo as a Data Processor is obliged to sign the Data Protection Addendum
                to the{' '}
                <span
                  onClick={handleDrawerState}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Terms of Use
                </span>{' '}
                ({'"DPA"'}), with the Client as a Data Controller. The DPA reflects the agreement between you and the
                KleverCargo regarding the terms which govern the processing of personal data under
                {"KleverCargo's"}{' '}
                <span
                  onClick={handleDrawerState}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Terms of Use
                </span>
                . Signing the DPA will be considered as an amendment to the Contract (within the meaning of the
                Definitions Section of the{' '}
                <span
                  onClick={handleDrawerState}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Terms of Use
                </span>
                ) and will be considered to form a part of the Contract. If you represent a Client that does not fall
                under the application of the GDPR, KleverCargo processes your personal data under the DPA concluded
                between KleverCargo and you, that includes the Standard Contractual Clauses adopted by the European
                Commission, as applicable.
              </p>
              <p>
                DPA is available on the Platform, and the Client will sign it when creating an account of the Company it
                represents. The Client can also download a PDF version of the DPA on the platform.
              </p>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.title} ref={privacyPolicy.whatDataSection}>
                3. WHAT DATA DO WE PROCESS ABOUT YOU AND WHEN?
              </p>
              <p>We may collect and receive information about you in various ways:</p>
              <ul>
                <li>
                  Information you provide using the KleverCargo (for example, by creating an Account on KleverCargo).
                </li>
                <li> Information you decide to provide through getting in touch with us</li>
                <li>Information we collect using cookies and similar technologies as explained below.</li>
              </ul>
              <b>Personal data we may collect automatically</b>
              <p>Each time you use Platform we may automatically collect the following information:</p>
              <ul>
                <li>
                  <span>
                    At the time of logging in, we store the data in local storage, such as the{' '}
                    <b>user id, the hashtag</b> that we generate ourselves and is created at{' '}
                    <b>the time of logging in, the date of logging in and the date when you will be logged out</b>{' '}
                    automatically. Namely, we have created the functionality that every user is automatically logged out
                    of Platform after exactly 8 hours after being logged in.
                  </span>
                </li>
                <li>
                  when you use KleverCargo, we will keep a record of the details of that usage, including the date,
                  time, location, frequency and duration of the usage;
                </li>
                <li>
                  any comments, opinions and/or feedback you provide to us regarding KleverCargo or other Users/Clients;
                </li>
                <li>
                  technical information about your computer or mobile device for system administration and analysis,
                  including your IP address, URL clickstreams, unique device identifiers, operating system, and network
                  and browser type;
                </li>
                <li>
                  other information about your use of KleverCargo, including the pages you have viewed, the duration
                  spent on the Platform and User/Client Content you have uploaded to the Platform.
                </li>
              </ul>
              <p>
                This information we collect via different technologies, such as LocalStorage. Please note that this
                information is ‘strictly necessary’ and the storage of (or access to) information is essential for the
                purpose of providing the Service requested by the user.
              </p>
              <p>
                This information is also required to comply with other legislation that applies to KleverCargo, for
                example, the security requirements of data protection law.
              </p>
              <p>
                Therefore, the lawful basis for the processing is Article 6 par. 1 b) - processing is necessary for the
                performance of a contract to which the data subject is party or in order to take steps at the request of
                the data subject prior to entering into a contract.
              </p>
              <p
                className={styles.title}
                style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}
                ref={privacyPolicy.dataControlerSection}
              >
                3.1 KleverCargo AS DATA CONTROLLER
              </p>
              {!isMobile ? (
                <div className={styles.table}>
                  <div className={styles.row}>
                    <div className={`${styles.fragment} ${styles.fragmentTitle}`}>DATA WE COLLECT</div>
                    <div className={`${styles.fragment} ${styles.fragmentTitle}`}>PURPOSE</div>
                    <div className={`${styles.fragment} ${styles.fragmentTitle}`}>LEGAL BASIS</div>
                    <div className={`${styles.fragment} ${styles.fragmentTitle}`}>RETENTION PERIOD</div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.fragment}>
                      <p>First and last name, email address, password, country code and phone number</p>
                      <p>Optional data, that you may add upon the registration:</p>
                      <ul style={{ paddingInlineStart: '20px' }}>
                        <li>Username</li>
                        <li>Photograph/logo</li>
                        <li>Your company, and the status in company (Manager, employee, etc.)</li>
                      </ul>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        Creating and maintaining an Account at KleverCargo according to the{' '}
                        <span
                          onClick={handleDrawerState}
                          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          Terms of Use.
                        </span>
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        Processing is necessary for the performance of the Contract (as defined in Section 2 of the
                        Terms of Use). Without providing first and last name, email address, password, country code and
                        phone number, you cannot create an Account. Optional data is not required for the Account to
                        operate; however, as the KleverCargo is intended for B2B relations, the core functionalities of
                        the Platform will be available only to those Accounts that are linked with a company – Client
                        Account.
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        Until the Account is deleted in accordance with the{' '}
                        <span
                          onClick={handleDrawerState}
                          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          Terms of Use.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.fragment}>
                      <b>Additional Data</b> <p>i.e., data you decide to share with us by contacting us.</p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        If you send us an inquiry or otherwise request support, we will collect the data you decide to
                        share with us.
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        Processing of personal data is either necessary to provide a Service or part thereof or the
                        processing is based on your consent.
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        If the processing is based on your consent, we keep the information until you withdraw your
                        consent or for one year, whichever date comes first.
                      </p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.fragment}>
                      <b>Information necessary for identification</b>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        To allow Data Subjects to exercise their rights in accordance with this Privacy Policy, as
                        defined in{' '}
                        <span
                          onClick={() => scrollToSection(privacyPolicy.yourRightsSection)}
                          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          Section 9.
                        </span>
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        Processing is necessary for compliance with a legal obligation to which the Data Controller is
                        subject.
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>We keep this information for a period of one year.</p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.fragment}>
                      <b>Other personal data</b>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        For the prevention and detection of fraud, money laundering or other crimes or to respond to a
                        binding request from a public authority or court.
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>The processing is necessary to comply with legal and regulatory obligations.</p>
                    </div>
                    <div className={styles.fragment}>
                      <p>In accordance with the applicable statutory deadlines.</p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.fragment}>
                      <b>Client’s phone number</b>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        We might contact you via the phone number registered on the Platform to inform you about the
                        offers available on the Platform that you might be interested in or to speed up the agreement
                        process between the interested parties.
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>The processing is based both on KleverCargo’s and the Client’s legitimate interests.</p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        Until you object to processing or until the Account is deleted in accordance with the{' '}
                        <span
                          onClick={handleDrawerState}
                          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          Terms of Use
                        </span>
                        .
                      </p>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.fragment}>
                      <b>Payment information</b>
                      <p>
                        Card holder name, Card number, Expiration date, Security code Billing Information: First name,
                        Last name, Company, VAT ID, Contact Email, Address, Country, State, City, ZIP code
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        When subscribing to any of the Standard plans or when choosing Pay as You Go plan, this
                        information is being collected by a third-party processor.
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        Processing is necessary for the performance of the Contract and payment of our Services (as
                        defined in{' '}
                        <span
                          onClick={handleDrawerState}
                          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          Section 1 of the Terms of Use
                        </span>
                        ) .
                      </p>
                    </div>
                    <div className={styles.fragment}>
                      <p>
                        We keep only the last four digits of the credit card number under subscription billing info
                        until such Agreement is terminated and for the period necessary to comply with the applicable
                        financial and tax accounting and other statutory obligations in accordance with the applicable
                        law{' '}
                        <span
                          onClick={() => closeAndSetSection(termsOfUse.governingLaw)}
                          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          (Section 20 of the Terms of Use)
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={[styles.table, styles.tableResponsive].join(' ')}>
                  <div className={styles.fragmentTitle}>DATA WE COLLECT</div>
                  <div className={styles.responsiveDescription}>
                    <p>First and last name, email address, password, country code and phone number</p>
                    <p>Optional data, that you may add upon the registration:</p>
                  </div>

                  <div className={styles.fragmentTitle}>PURPOSE</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      Creating and maintaining an Account at KleverCargo according to the{' '}
                      <span
                        onClick={handleDrawerState}
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        Terms of Use.
                      </span>
                    </p>
                  </div>

                  <div className={styles.fragmentTitle}>LEGAL BASIS</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      Processing is necessary for the performance of the Contract (as defined in Section 2 of the Terms
                      of Use). Without providing first and last name, email address, password, country code and phone
                      number, you cannot create an Account. Optional data is not required for the Account to operate;
                      however, as the KleverCargo is intended for B2B relations, the core functionalities of the
                      Platform will be available only to those Accounts that are linked with a company – Client Account.
                    </p>
                  </div>
                  <div className={styles.fragmentTitle}>RETENTION PERIOD</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      Until the Account is deleted in accordance with the{' '}
                      <span
                        onClick={handleDrawerState}
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        Terms of Use.
                      </span>
                    </p>
                  </div>
                  <div className={styles.fragmentTitle}>DATA WE COLLECT</div>
                  <div className={styles.responsiveDescription}>
                    <b>Billing info</b>
                  </div>
                  <div className={styles.fragmentTitle}>PURPOSE</div>
                  <div className={styles.responsiveDescription}>
                    <b>--</b>
                  </div>
                  <div className={styles.fragmentTitle}>LEGAL BASIS</div>
                  <div className={styles.responsiveDescription}>
                    <b>--</b>
                  </div>
                  <div className={styles.fragmentTitle}>RETENTION PERIOD</div>

                  <div className={styles.responsiveDescription}>
                    <b>--</b>
                  </div>
                  <div className={styles.fragmentTitle}>DATA WE COLLECT</div>
                  <div className={styles.responsiveDescription}>
                    <b>Additional Data</b> <p>i.e., data you decide to share with us by contacting us.</p>
                  </div>
                  <div className={styles.fragmentTitle}>PURPOSE</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      If you send us an inquiry or otherwise request support, we will collect the data you decide to
                      share with us.
                    </p>
                  </div>
                  <div className={styles.fragmentTitle}>LEGAL BASIS</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      Processing of personal data is either necessary to provide a Service or part thereof or the
                      processing is based on your consent.
                    </p>
                  </div>
                  <div className={styles.fragmentTitle}>RETENTION PERIOD</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      If the processing is based on your consent, we keep the information until you withdraw your
                      consent or for one year, whichever date comes first.
                    </p>
                  </div>

                  <div className={styles.fragmentTitle}>DATA WE COLLECT</div>
                  <div className={styles.responsiveDescription}>
                    <b>Information necessary for identification</b>
                  </div>
                  <div className={styles.fragmentTitle}>PURPOSE</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      To allow Data Subjects to exercise their rights in accordance with this Privacy Policy, as defined
                      in{' '}
                      <span
                        onClick={() => scrollToSection(privacyPolicy.yourRightsSection)}
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        Section 9.
                      </span>
                    </p>
                  </div>

                  <div className={styles.fragmentTitle}>LEGAL BASIS</div>
                  <div className={styles.responsiveDescription}>
                    {' '}
                    <p>
                      Processing is necessary for compliance with a legal obligation to which the Data Controller is
                      subject.
                    </p>
                  </div>
                  <div className={styles.fragmentTitle}>RETENTION PERIOD</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      <p>We keep this information for a period of one year.</p>
                    </p>
                  </div>
                  <div className={styles.fragmentTitle}>DATA WE COLLECT</div>
                  <div className={styles.responsiveDescription}>
                    <b>Other personal data</b>
                  </div>
                  <div className={styles.fragmentTitle}>PURPOSE</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      For the prevention and detection of fraud, money laundering or other crimes or to respond to a
                      binding request from a public authority or court.
                    </p>
                  </div>

                  <div className={styles.fragmentTitle}>LEGAL BASIS</div>
                  <div className={styles.responsiveDescription}>
                    <p>The processing is necessary to comply with legal and regulatory obligations.</p>
                  </div>
                  <div className={styles.fragmentTitle}>RETENTION PERIOD</div>
                  <div className={styles.responsiveDescription}>
                    <p>
                      <p>In accordance with the applicable statutory deadlines.</p>
                    </p>
                  </div>
                </div>
              )}
            </div>
            <p
              className={styles.title}
              style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}
              ref={privacyPolicy.dataProcessorSection}
            >
              3.2 KleverCargo AS DATA PROCESSOR
            </p>
            <p>
              As previously stated, concerning some of your personal data processed on the Platform, KleverCargo is a
              Data Processor, and the Client is the Data Controller. KleverCargo processes personal data following
              instructions from the Data Controller under the{' '}
              <span
                onClick={handleDrawerState}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Terms of Use
              </span>
              , and DPA (if any). The purpose of such personal data processing includes but is not limited to: adding
              users to your company account, creation of transport request, submission of vehicle, offering and
              providing a warehouse space, and enabling the conclusion of an adequate agreement between the Clients.
            </p>
            <p>
              As a processor, KleverCargo is permitted to collect, use, disclose and/or otherwise process your personal
              data only in accordance with its contracts with the Client.
            </p>
            <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
              3.2.1 Processing prior to using the Service
            </p>
            <ol type='a'>
              <li>
                <b>Employee’s data</b>
              </li>
              <ul>
                <li>
                  The Client, your Employer shares your email address and your role at the company to enable you to
                  access the Service.
                </li>
                <li>
                  If you have any questions regarding the legal basis for such personal data processing, please contact
                  your Employer who added you to the KleverCargo.
                </li>
              </ul>
              <li>
                <b>Customer’s data</b>
              </li>
              <ul>
                <li>
                  The Client, your service provider shares your name, email, phone, and address to add your details to
                  the Platform and organize transport of cargo.
                </li>
                <li>
                  If you have any questions regarding the legal basis for such personal data processing, please contact
                  the Client who provided you the Client’s services.
                </li>
              </ul>
            </ol>
            <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
              3.2.2 Processing during the usage of the Platform
            </p>
            <ol type='a'>
              <li>
                <b>Employee’s data and third person’s data</b>
              </li>
            </ol>
            <p>
              If you decide to accept the invitation sent to your email to use KleverCargo, you will be required to
              create an account. To create the account, you will need to confirm your name and last name, share your
              email address and password, as well as the mobile phone number.
            </p>
            <p>
              You manage personal data you share via your account such as name and last name, photo, email address, and
              role at the company.
            </p>
            <p>
              Admins can view and edit everything. Managers have the same permissions as Admins except the permission to
              delete Company information. Members can see all the sections, unless the Admin or the Manager denied such
              a permission to the Member; Members do not have permission to invite other users to the team, nor they can
              see the financial data. Drivers can only see the agreement and documents appointed to him. Accountants can
              only see billing data. For more information about the available roles, please visit the Platform.
            </p>
            <p>
              Moreover, the Client may add personal information about third persons outside its company, such as persons
              who receive or deliver the goods.
            </p>
            <p>
              If you have any questions regarding the legal basis for such personal data processing, please contact your
              Employer who added you to the Platform or a Company with whom you work with.
            </p>
            <div className={styles.sectionWrapper}>
              <p className={styles.title}>4. WHAT DO WE NOT DO?</p>
              <p>KleverCargo will never:</p>
              <ul>
                <li>Sell any kind of personal information or data.</li>
                <li>
                  Disclose this information to marketers or third parties not specified in{' '}
                  <span
                    onClick={() => scrollToSection(privacyPolicy.personalDataSection)}
                    style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Section 6
                  </span>{' '}
                  of the Privacy Policy
                </li>
                <li>Process your data in any way other than stated in this Privacy Policy.</li>
              </ul>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.title}>5. PERSONAL DATA SECURITY</p>
              <p>
                We take administrative, technical, organizational, and other measures to ensure the appropriate level of
                security of personal data we process. Upon assessing whether a measure is adequate and which level of
                security is appropriate, we consider the nature of the personal data we are processing and the nature of
                the processing operations we perform, the risks to which you are exposed by our processing activities,
                the costs of the implementation of security measures and other relevant matters in the particular
                circumstances.
              </p>
              <p>
                Some of the measures we apply include access authorization control, information classification (and
                handling thereof), protection of integrity and confidentiality, data backup, firewalls, data encryption
                and other appropriate measures. We equip our staff with the appropriate knowledge and understanding of
                the importance and confidentiality of your personal data security.
              </p>
              <p>
                Whenever we save your personal information, it’s stored on servers and in facilities that only selected
                personnel and our contractors have access to. We encrypt all data that you submit through Platform
                during transmission using SSL in order to prevent unauthorized parties from viewing such information.
                Remember – all information you submit to us by email is not secure, so please do not send sensitive
                information in any email to KleverCargo. We never request that you submit sensitive or personal
                information over email, so please report any such requests to us by sending an email to
                privacy@klevercargo.com.
              </p>
              <p>
                We protect personal information you provide online in connection with registering an account via
                KleverCargo Platform. Access to your own personal information is available through a password selected
                by you. This password is encrypted while transmitted from your browser to our servers and while stored
                on our systems. To protect the security of your personal information, never share your password to
                anyone. Please notify us immediately if you believe your password has been compromised.
              </p>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.title} ref={privacyPolicy.personalDataSection}>
                6. WITH WHOM DO WE SHARE YOUR PERSONAL DATA?
              </p>
              <p>
                KleverCargo utilizes external processors and sub-processors for certain processing activities. We
                conduct information audits to identify, categorize and record all personal data that is processed
                outside our company, so that the information, processing activity, processor and legal basis are all
                recorded, reviewed and easily accessible. The list of our sub-processors is approved by the Client.
              </p>
              <p>
                We have strict due diligence procedures and measures in place and review, assess and background check
                all processors prior to forming a business relationship. We obtain company documents, certifications,
                references and ensure that the processor is adequate, appropriate, and effective for the task we are
                employing them for.
              </p>
              <p>
                We audit their processes and activities prior to contract and during the contract period to ensure
                compliance with the data protection regulations and review any codes of conduct that oblige them to
                confirm compliance.
              </p>
              <p>This is the list of processors and sub-processors with whom we share your personal data:</p>
              <div className={`${styles.table} ${isMobile ? styles.tableResponsive : ''}`}>
                <div className={styles.row}>
                  <div className={`${styles.fragment} ${styles.fragmentTitle}`}>
                    {' '}
                    <DataProcessorSvg />
                    DATA PROCESSOR
                  </div>
                  <div className={`${styles.fragment} ${styles.fragmentTitle}`}>
                    <MemberSvg />
                    ROLE
                  </div>
                  <div className={`${styles.fragment} ${styles.fragmentTitle}`}>
                    <SeatSvg />
                    SEAT
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.fragment}>
                    <p>Digital Ocean, Inc.</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>Providing servers</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>USA</p>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.fragment}>
                    <p>Twilio (SendGrid)</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>Email services provider</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>USA</p>
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.fragment}>
                    <p>Zoho</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>Chat support</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>The Netherlands</p>
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.fragment}>
                    <p>Stripe, Inc.</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>Payment processor</p>
                  </div>
                  {/* <div className={styles.fragment}>
                    <p>UK</p>
                  </div> */}
                </div>
              </div>
              <div className={`${styles.table} ${isMobile ? styles.tableResponsive : ''}`}>
                <div className={styles.row}>
                  <div className={`${styles.fragment} ${styles.fragmentTitle}`}>
                    <SubProcessorSvg />
                    SUB-PROCESSOR
                  </div>
                  <div className={`${styles.fragment} ${styles.fragmentTitle}`}>
                    {' '}
                    <MemberSvg />
                    ROLE
                  </div>
                  <div className={`${styles.fragment} ${styles.fragmentTitle}`}>
                    <SeatSvg />
                    SEAT
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.fragment}>
                    <p>Geoapify GmbH</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>Maps and location intelligence provider</p>
                  </div>
                  <div className={styles.fragment}>
                    <p>Germany</p>
                  </div>
                </div>
              </div>

              <p>We may also share your personal data with our outside accountants, legal counsels, and auditors.</p>
              <p>Moreover, we may disclose your personal information to third parties:</p>
              <ul>
                <li>
                  if we are under a duty to disclose or share your personal data in order to comply with any legal
                  obligation;
                </li>
                <li>to prevent and detect fraud or crime;</li>
                <li>in response to a subpoena, warrant, court order, or as otherwise required by law.</li>
              </ul>
              <p>
                Please note that personal information may be disclosed or transferred as part of, or during negotiations
                of, a merger, consolidation, sale of our assets, as well as equity financing, acquisition, strategic
                alliance or in any other situation where personal information may be transferred as one of the business
                assets of KleverCargo.
              </p>
              <p>
                We do not have a list of all third parties we share your data with. However, if you would like further
                information about who we have shared your data with, you can request this by contacting us at
                privacy@klevercargo.com.
              </p>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.title}>7. INTERNATIONAL TRANSFER OF YOUR PERSONAL DATA</p>
              <p>
                We may transfer your personal data to countries other than the one you reside in. In that case, we will
                also apply appropriate technical and organizational measures to ensure an adequate level of security in
                respect of all personal data we process. If the GDPR applies to you, we make sure that such transfer is
                made:
              </p>
              <ol>
                <li>to the countries within the EEA;</li>
                <li>⦁ to the countries which ensure an adequate level of protection;</li>
                <li>
                  to the countries which do not belong to those specified under item 1. and 2, but only by applying the
                  appropriate safeguard measures (such as Standard Contractual Clauses adopted by the European
                  Commission)
                </li>
              </ol>
              <p>
                If you would like to obtain more information about these protective measures, please contact us at
                privacy@klevercargo.com.
              </p>
              <p>Your personal data is stored on servers located in Frankfurt, Germany.</p>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.title}>8. HOW LONG DO WE KEEP YOUR DATA?</p>
              <p>
                The period for which we store your personal data depends on a particular purpose for the processing of
                personal data, as explained in detail in{' '}
                <span
                  onClick={() => scrollToSection(privacyPolicy.whatDataSection)}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Section 3
                </span>
                . We retain personal data for as long as we reasonably require it for legal or business purposes. In
                determining data retention periods, we take into consideration the applicable law (
                <span
                  onClick={() => closeAndSetSection(termsOfUse.governingLaw)}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  see Section 20 of the Terms of Use
                </span>
                ), contractual obligations, and the expectations and requirements of our Clients. When we no longer need
                personal information, or when you legitimately request us to delete your information, we will securely
                delete or destroy it.
              </p>
              <p>
                However, as an exception to the retention periods in{' '}
                <span
                  onClick={() => scrollToSection(privacyPolicy.whatDataSection)}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Section 3
                </span>{' '}
                the data may be processed to determine, pursue, or defend claims and counterclaims.
              </p>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.title} ref={privacyPolicy.yourRightsSection}>
                9. YOUR RIGHTS
              </p>
              <p>
                Given that fairness and transparency are our cornerstone principles, we wanted to remind you of the
                rights that you have as a Data Subject. These rights may be exercised by Data Subject when KleverCargo
                operates as a Data Controller.
              </p>
              <p>
                If your inquiry or exercise of any of the Data {"Subject's"} rights relates to the data processed by the
                Client as a Data Controller as explained in Section{' '}
                <span
                  onClick={() => scrollToSection(privacyPolicy.dataProcessorSection)}
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                >
                  3.2
                </span>{' '}
                of the Privacy Policy, please contact the Client (the company that you have linked your Account with by
                sending them a request).
              </p>
              <p>
                In the event KleverCargo receives a request for exercising any of these rights directly from a Data
                Subject, we are obliged to notify the Client before responding to such a request.
              </p>
              <div className={styles.titleSvgContainer}>
                <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
                  Right of Access
                </p>
                <RightOfAccessSvg />
              </div>
              <p>You can send us a request for a copy of the personal data we hold about you</p>
              <p>
                We have ensured that appropriate measures have been taken to provide such in a concise, transparent,
                intelligible, and easily accessible form, using clear and plain language. Such information is provided
                in writing free of charge. It may be provided by other means when authorized by the Data Subject and
                with prior verification as to the {"subject's"} identity
              </p>
              <p>
                Information is provided to the Data Subject at the earliest convenience, but at a maximum of 30 days
                from the date the request was received. Where the provision of information is particularly complex or is
                subject to a valid delay, the period may be extended by two further months where necessary.
              </p>
              <div className={styles.titleSvgContainer}>
                <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
                  Right to Object to Processing
                </p>
                <RightToObjProcessingSvg />
              </div>
              <p>
                You have the right to object to the processing of your personal data where that processing is being
                undertaken based on the Data Controller’s legitimate interest. In such a case the Data Controller is
                required to cease processing your data unless they can demonstrate adequate grounds that override your
                objection.
              </p>
              <div className={styles.titleSvgContainer}>
                <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
                  Right to Correction of Your Personal Data
                </p>
                <RightToCorrectionSvg />
              </div>
              <p>
                If your personal data processed by the Data Controller is incorrect, you have the right to request that
                we correct those data. Where notified of inaccurate data by the Data Subject, we will rectify the error
                within 30 days and inform any third party of the rectification if we have disclosed the personal data in
                question to them.
              </p>
              <div className={styles.titleSvgContainer}>
                <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
                  Right to Erasure
                </p>
                <RightToErasureSvg />
              </div>

              <p>You have the right to request that your personal data is deleted in certain circumstances, such as:</p>
              <ul>
                <li>The personal data are no longer needed for the purpose for which they were collected;</li>
                <li>You withdraw your consent (where the processing was based on consent);</li>
                <li>
                  You object to the processing and no overriding legitimate grounds are justifying processing the
                  personal data;
                </li>
                <li>The personal data have been unlawfully processed; or</li>
                <li>To comply with a legal obligation.</li>
              </ul>
              <p>However, this right does not apply where, for example, the processing is necessary:</p>
              <ul>
                <li>To comply with a legal obligation; or</li>
                <li>For the establishment, exercise or defense of legal claims.</li>
              </ul>
              <p>
                Each User can leave the company account and deactivate its User Account. Please note that some data will
                be kept for our internal business purposes, legal, financial, and accounting purposes in accordance with{' '}
                <span
                  onClick={() => closeAndSetSection(termsOfUse.termination)}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Section 15. of Terms of Use
                </span>
                .
              </p>
              <div className={styles.titleSvgContainer}>
                <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
                  Right to Restriction of Processing
                </p>
                <RightToRestrictionSvg />
              </div>
              <p>You can exercise your right to the restriction of processing in the following situations:</p>
              <ul>
                <li>if the accuracy of the personal data is contested,</li>
                <li>you consider the processing unlawful, but you do not want your personal data to be erased,</li>
                <li>
                  we no longer need the personal data, but you require it for the establishment, exercise or defense of
                  legal claims or you have objected to the processing and verification.
                </li>
              </ul>
              <div className={styles.titleSvgContainer}>
                <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
                  Right to Data Portability
                </p>
                <RightToDataPortSvg />
              </div>
              <p>
                Where you have provided personal data to us, you have the right to receive such personal data back in a
                structured, commonly used and machine-readable format, and to have those data transmitted to a
                third-party without hindrance, but in each case only where:
              </p>
              <ul>
                <li>The processing is carried out by automated means; and</li>
                <li>The processing is based on your consent or the performance of a contract with you.</li>
              </ul>
              <div className={styles.titleSvgContainer}>
                <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
                  Right to Withdraw the Consent
                </p>
                <RightToWithdrawSvg />
              </div>
              <p>
                If you have provided your consent to the collection, processing, and transfer of your personal data, you
                have the right to fully or partly withdraw your consent. Once we have received notification that you
                have withdrawn your consent, we will no longer process your information for the purpose(s) to which you
                originally consented unless there is another legal ground for the processing.
              </p>
              <div className={styles.titleSvgContainer}>
                <p className={styles.title} style={{ color: 'var(--primary)', marginTop: 20, marginBottom: 10 }}>
                  Right to Lodge a Complaint
                </p>
                <RightToLodgeSvg />
              </div>
              <p>
                If you have any concerns or requests in relation to your personal data, please contact us at
                privacy@klevercargo.com and we will respond as soon as possible but not later than 30 days.
              </p>
              <p>
                If you are unsatisfied with our response, you may also contact the competent supervisory authority at
                your country of residency or Autoriteit Persoonsgegevens, Postbus 93374, 2509 AJ DEN HAAG, telephone
                number: (+31) - (0)70 - 888 85 00,{' '}
                <span>
                  <a href='https://autoriteitpersoonsgegevens.nl/en' target='_'>
                    https://autoriteitpersoonsgegevens.nl/en
                  </a>
                </span>
                .
              </p>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.title}>10. CHANGES TO OUR PRIVACY POLICY</p>
              <p>
                Any changes we may make to our Privacy Policy will be posted on this page and where appropriate may be
                notified to you by email or advised to you on the next login to KleverCargo. If you continue with the
                use of the Platform after the changes were implemented, that will signify that you agree to any such
                changes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
