import * as React from 'react';
const OtherTypesSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} {...props}>
    <path
      fill='none'
      stroke='#0C2138'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M4 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM11 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM18 5a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM4 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM11 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM18 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM4 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM11 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM18 19a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z'
    />
  </svg>
);
export default OtherTypesSvg;
