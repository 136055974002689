import * as React from 'react';

const ConsentSvg = (props) => (
  <svg data-name='Layer 22' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <circle
      cx={16}
      cy={15}
      r={9.9}
      style={{
        strokeWidth: '.8px',
        fill: 'none',
        stroke: '#010101',
        strokeMiterlimit: 10
      }}
    />
    <circle
      cx={16}
      cy={15}
      r={11.75}
      style={{
        strokeWidth: '1.6px',
        fill: 'none',
        stroke: '#010101',
        strokeMiterlimit: 10
      }}
    />
    <path
      style={{
        fill: '#010101'
      }}
      d='m16 30 2.71-2.98h-5.42L16 30z'
    />
    <path
      style={{
        strokeLinecap: 'round',
        strokeWidth: '1.6px',
        fill: 'none',
        stroke: '#010101',
        strokeMiterlimit: 10
      }}
      d='m10.14 14.85 4.33 4.32 8.35-8.34'
    />
  </svg>
);

export default ConsentSvg;
