import { RELOAD_REDUX_STATE } from '../../constants/actions';

function reloadReduxStateAction() {
  return {
    type: RELOAD_REDUX_STATE
  };
}

export default function reloadReduxState() {
  return (dispatch) => {
    dispatch(reloadReduxStateAction());
  };
}
