import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './Tooltip.module.css';
import 'react-tooltip/dist/react-tooltip.css';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';

const Tooltip = ({ id, children, place, position = 'absolute', maxWidth }) => {
  const { isMobile } = useWindowDimensions();

  const mobileCustomMaxWidthStyle = {
    maxWidth: maxWidth || '200px'
  };

  const pcCustomMaxWidthStyle = {
    maxWidth: maxWidth || '280px'
  };

  return (
    <ReactTooltip
      style={isMobile ? mobileCustomMaxWidthStyle : pcCustomMaxWidthStyle}
      className={styles.tooltip}
      positionStrategy={position}
      place={place}
      anchorId={id}
      noArrow
    >
      {children}
    </ReactTooltip>
  );
};

export default Tooltip;
