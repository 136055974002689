import * as React from 'react';

const YouSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.st1,.st8{fill:none;stroke-miterlimit:10}.st1{display:inline;stroke:#f15b27;stroke-width:.1}.st8{stroke:#000;stroke-width:1.2;stroke-linejoin:round}'
      }
    </style>
    <g id='You'>
      <circle
        cx={16.04}
        cy={5.22}
        r={1.46}
        style={{
          fill: 'none',
          stroke: '#000',
          strokeWidth: 1.2,
          strokeMiterlimit: 10
        }}
      />
      <path
        className='st8'
        d='M17.72 18.2h-3.36c-1.1 0-2-.9-2-2V8.89h7.36v7.31c0 1.1-.9 2-2 2zM14.58 18.55h2.91v7.9h-2.91z'
      />
      <path
        d='M12.47 26.02c-3.73.2-6.36.69-6.36 1.26 0 .75 4.61 1.36 10.3 1.36s10.3-.61 10.3-1.36c0-.61-2.99-1.12-7.13-1.29'
        style={{
          fill: 'none',
          stroke: '#000',
          strokeMiterlimit: 10
        }}
      />
    </g>
  </svg>
);

export default YouSvg;
