import React, { useContext } from 'react';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import LogoSvg from '../../../assets/images/svg/LogoUpdateSvg';
import AppComponentContext from '../../../contexts/AppComponentContext';
import Button from '../../UI/Button/Button';

import StickyFooter, { FooterButtons } from '../../UI/StickyFooter/StickyFooter';
import InfoPanel from '../../UI/InfoPanel/InfoPanel';
import styles from './UpgradeInfo.module.css';

const UpgradeInfo = ({ version, hideNewVersionMessage, data }) => {
  const localization = useContext(AppComponentContext).strings;
  const { isPC } = useWindowDimensions();
  const infoPanelWrapper = `${styles.infoPanelWrapper} ${
    !isPC ? styles.infoPanelWrapperMobile : styles.infoPanelWrapperSticky
  }`;

  let containerClass = `${styles.container} ${!isPC ? styles.containerMobile : ''}`;
  const dataInfoWrapper = `${styles.dataInfoWrapper} ${isPC ? styles.dataInfoWrapperScroll : ''}`;

  const renderFeatures = (data) => {
    return data.map((item, index) => {
      return (
        <div key={index} className={styles.mainWrapper}>
          <div className={styles.featureTitle}>{item.title}</div>
          {item.image ? (
            <div className={styles.imageWrapper}>
              <img className={styles.image} src={item.image} alt='feature' />
            </div>
          ) : null}
          <div className={styles.textWrapper}>{item.text}</div>
        </div>
      );
    });
  };

  const renderData = (data) => {
    if (data?.data && data?.data.length > 0) {
      return (
        <div className={dataInfoWrapper}>
          {data?.data && data?.data.length > 0 ? renderFeatures(data?.data) : null}

          <StickyFooter>
            <FooterButtons>
              <Button buttonType='primaryOutlined' onClick={hideNewVersionMessage}>
                {localization.close}
              </Button>
            </FooterButtons>
          </StickyFooter>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className={containerClass}
      style={isPC && data?.data?.length > 0 ? { width: 'calc(var(--elementMinWidth) * 2)' } : {}}
    >
      <>
        <div className={infoPanelWrapper}>
          <InfoPanel
            Icon={LogoSvg}
            title={localization.projectHeaderTitle}
            text={data?.data?.length > 0 ? localization.latestReleaseNotes : ''}
            style={styles.InfoPanel}
            buttons={
              (!data || data?.data?.length === 0) && (
                <StickyFooter className={styles.wide}>
                  <FooterButtons>
                    <Button buttonType='primaryOutlined' onClick={hideNewVersionMessage}>
                      {localization.close}
                    </Button>
                  </FooterButtons>
                </StickyFooter>
              )
            }
          >
            <div className={styles.version}>
              <span>
                {localization.version} {version}
              </span>
            </div>
          </InfoPanel>
        </div>

        {renderData(data)}
      </>
    </div>
  );
};

export default UpgradeInfo;
