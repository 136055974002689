import * as React from 'react';

const DataProcessorSvg = (props) => (
  <svg id='Layer_25' data-name='Layer 25' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>
        {
          '.cls-1-dataprocessor,.cls-2-dataprocessor{stroke-width:.8px;stroke:#231f20;stroke-linejoin:round}.cls-2-dataprocessor{fill:none;stroke-width:1.6px}'
        }
      </style>
    </defs>
    <circle className='cls-2-dataprocessor' cx={21.68} cy={19.83} r={4.72} />
    <path
      className='cls-2-dataprocessor'
      d='M22.39 15.04h-1.44l.26-1.75h.93l.25 1.75zM18.8 15.94l-1.02 1.02-1.06-1.43.66-.65 1.42 1.06zM16.89 19.12v1.43l-1.76-.26v-.92l1.76-.25zM17.79 22.71l1.01 1.02-1.42 1.06-.66-.66 1.07-1.42zM20.96 24.62h1.44l-.26 1.75h-.93l-.25-1.75zM24.56 23.72l1.01-1.02 1.06 1.43-.66.66-1.41-1.07zM26.46 20.54v-1.43l1.76.26v.92l-1.76.25zM25.56 16.95l-1.01-1.02 1.42-1.05.66.65-1.07 1.42z'
    />
    <circle className='cls-2-dataprocessor' cx={10.44} cy={11.27} r={4} />
    <path
      className='cls-1-dataprocessor'
      d='M11.08 7.02H9.8l.23-1.56h.82l.23 1.56zM7.89 7.82l-.91.9-.94-1.27.59-.58 1.26.95zM6.19 10.64v1.27l-1.56-.23v-.82l1.56-.22zM6.99 13.83l.9.9-1.26.94-.59-.58.95-1.26zM9.81 15.52h1.28l-.24 1.56h-.82l-.22-1.56zM13 14.72l.9-.9.94 1.27-.58.58-1.26-.95zM14.69 11.9v-1.27l1.56.23v.82l-1.56.22zM13.9 8.71l-.91-.9 1.27-.94.58.58-.94 1.26z'
    />
  </svg>
);

export default DataProcessorSvg;
