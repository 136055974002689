import * as React from 'react';

const SeatSvg = (props) => (
  <svg data-name='Layer 40' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <circle
      cx={15.62}
      cy={10.39}
      r={3.72}
      style={{
        fill: 'none',
        stroke: '#231f20',
        strokeLinejoin: 'round',
        strokeWidth: '.8px'
      }}
    />
    <path
      d='M23.7 10.61c0 4.41-2.11 8.76-8.08 18.39-5.04-8.91-8.07-13.98-8.07-18.39s3.62-7.98 8.08-7.98 8.08 3.57 8.08 7.98Z'
      style={{
        strokeWidth: '1.6px',
        fill: 'none',
        stroke: '#231f20',
        strokeLinejoin: 'round'
      }}
    />
  </svg>
);

export default SeatSvg;
