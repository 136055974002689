import * as React from 'react';

const UserLegalSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <style>{'.st1{display:inline;fill:none;stroke:#f15b27;stroke-width:.1;stroke-miterlimit:10}'}</style>
    <g id='user'>
      <circle
        cx={16}
        cy={9.28}
        r={3.62}
        style={{
          fill: 'none',
          stroke: '#1a1a1a',
          strokeWidth: 1.6,
          strokeMiterlimit: 10
        }}
      />
      <path
        d='M23.9 27.1H7.94V22c0-3.31 2.69-6 6-6h3.96c3.31 0 6 2.69 6 6v5.1z'
        style={{
          fill: 'none',
          stroke: '#1a1a1a',
          strokeWidth: 1.6,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10
        }}
      />
    </g>
  </svg>
);

export default UserLegalSvg;
