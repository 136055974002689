import * as React from 'react';

function CookiePolicySvg(props) {
  return (
    <svg {...props} width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.9491 14.81C25.9491 15.61 25.8691 16.39 25.7091 17.14C24.0491 16.44 22.1891 16.69 20.9791 17.93C19.8991 19.04 19.6291 20.65 20.1091 22.16C18.4991 21.17 16.4791 21.29 15.2491 22.56C14.3391 23.49 14.0591 24.82 14.3991 26.1C8.27914 25.96 3.36914 20.96 3.36914 14.81C3.36914 8.66002 8.41914 3.52002 14.6591 3.52002C20.8991 3.52002 25.9491 8.57002 25.9491 14.81Z'
        stroke='#005B9E'
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M18.8605 27.53C19.4846 27.53 19.9905 27.0241 19.9905 26.4C19.9905 25.7759 19.4846 25.27 18.8605 25.27C18.2364 25.27 17.7305 25.7759 17.7305 26.4C17.7305 27.0241 18.2364 27.53 18.8605 27.53Z'
        fill='#005B9E'
      />
      <path
        d='M24.3795 26.4C25.1306 26.4 25.7395 25.7911 25.7395 25.04C25.7395 24.2889 25.1306 23.68 24.3795 23.68C23.6284 23.68 23.0195 24.2889 23.0195 25.04C23.0195 25.7911 23.6284 26.4 24.3795 26.4Z'
        fill='#005B9E'
      />
      <path
        d='M26.3005 23.37C26.6153 23.37 26.8705 23.1148 26.8705 22.8C26.8705 22.4852 26.6153 22.23 26.3005 22.23C25.9857 22.23 25.7305 22.4852 25.7305 22.8C25.7305 23.1148 25.9857 23.37 26.3005 23.37Z'
        fill='#005B9E'
      />
      <path
        d='M20.6208 24.74C20.9356 24.74 21.1908 24.4848 21.1908 24.17C21.1908 23.8552 20.9356 23.6 20.6208 23.6C20.306 23.6 20.0508 23.8552 20.0508 24.17C20.0508 24.4848 20.306 24.74 20.6208 24.74Z'
        fill='#005B9E'
      />
      <path
        d='M24.2195 21.59C24.7442 21.59 25.1695 21.1647 25.1695 20.64C25.1695 20.1153 24.7442 19.69 24.2195 19.69C23.6949 19.69 23.2695 20.1153 23.2695 20.64C23.2695 21.1647 23.6949 21.59 24.2195 21.59Z'
        fill='#005B9E'
      />
      <path
        d='M14.6592 19.21C16.9843 19.21 18.8692 17.3251 18.8692 15C18.8692 12.6749 16.9843 10.79 14.6592 10.79C12.3341 10.79 10.4492 12.6749 10.4492 15C10.4492 17.3251 12.3341 19.21 14.6592 19.21Z'
        stroke='#005B9E'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 6.26001L15.75 7.76001'
        stroke='#005B9E'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.26953 14.51L8.32953 15.11'
        stroke='#005B9E'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M19.6406 9.78998L20.3506 11.26' stroke='#005B9E' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.9296 21.47L10.3496 22.82' stroke='#005B9E' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.1809 8.5L9.88086 9.21' stroke='#005B9E' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}

export default CookiePolicySvg;
