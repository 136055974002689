import { API_LOGIN_SUCCESS, LOGOUT } from '../../../constants/actions';

const initialState = {
  loggedInStatus: false,
  userId: null,
  Ht: null,
  Et: null,
  Ct: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_LOGIN_SUCCESS:
      return {
        ...state,
        loggedInStatus: true,
        userId: action.response.data.userId,
        Ht: action.response.data.Ht,
        Et: action.response.data.Et,
        Ct: action.response.data.Ct
      };
    case LOGOUT:
      return {
        ...state,
        loggedInStatus: false,
        userId: null,
        Ht: null,
        Et: null,
        Ct: null
      };
    default:
      return state;
  }
}
