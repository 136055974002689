import * as React from 'react';

const OdsIconSvg = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.41146 2H14.4678L19.9987 7.128V20.961C19.999 21.0974 19.9717 21.2326 19.9183 21.3587C19.865 21.4848 19.7867 21.5994 19.688 21.6959C19.5892 21.7924 19.4719 21.869 19.3428 21.9212C19.2137 21.9733 19.0753 22.0001 18.9355 22H5.41146C5.13048 21.9984 4.86159 21.8882 4.66366 21.6934C4.46574 21.4987 4.35492 21.2353 4.35547 20.961V3.039C4.35492 2.76466 4.46574 2.50129 4.66366 2.30655C4.86159 2.11182 5.13048 2.00159 5.41146 2'
      fill='white'
    />
    <path
      d='M5.41249 22C5.13132 21.9987 4.86217 21.8886 4.66403 21.6938C4.46589 21.499 4.35493 21.2355 4.35547 20.961V3.038C4.35493 2.76358 4.46592 2.50016 4.66409 2.30556C4.86226 2.11095 5.13142 2.00106 5.41249 2H14.4688L19.9997 7.127V20.96C20 21.0966 19.9726 21.232 19.9192 21.3583C19.8658 21.4846 19.7873 21.5994 19.6883 21.696C19.5894 21.7926 19.4718 21.8692 19.3425 21.9214C19.2131 21.9735 19.0745 22.0003 18.9345 22H5.41249ZM5.28651 3.038V20.96C5.28637 20.9761 5.2895 20.9921 5.29572 21.007C5.30194 21.0219 5.31112 21.0355 5.32274 21.0469C5.33436 21.0583 5.34818 21.0674 5.36341 21.0736C5.37863 21.0798 5.39497 21.083 5.41146 21.083H18.9314C18.9646 21.083 18.9964 21.0701 19.0198 21.0473C19.0432 21.0244 19.0564 20.9934 19.0564 20.961L19.0615 8.089H14.053C13.9287 8.08847 13.8096 8.03988 13.7219 7.95386C13.6342 7.86783 13.5849 7.75139 13.5849 7.63V2.914H5.41249C5.37898 2.91426 5.34694 2.92744 5.32334 2.95067C5.29974 2.9739 5.2865 3.00529 5.28651 3.038V3.038ZM14.5241 7.171H18.6825L14.5241 3.313V7.171Z'
      fill='#8F99A2'
    />
    <path
      d='M3.31513 11.372H15.1502C15.3226 11.3716 15.4934 11.4044 15.6528 11.4685C15.8122 11.5325 15.9571 11.6267 16.0793 11.7454C16.2014 11.8642 16.2983 12.0053 16.3646 12.1607C16.4308 12.3161 16.4651 12.4827 16.4654 12.651V17.766C16.465 17.9342 16.4306 18.1007 16.3643 18.256C16.298 18.4113 16.201 18.5523 16.0789 18.6709C15.9568 18.7896 15.8119 18.8836 15.6526 18.9477C15.4932 19.0117 15.3225 19.0444 15.1502 19.044H3.31513C3.14282 19.0444 2.97213 19.0117 2.81279 18.9477C2.65345 18.8836 2.50858 18.7896 2.38646 18.6709C2.26434 18.5523 2.16736 18.4113 2.10105 18.256C2.03474 18.1007 2.0004 17.9342 2 17.766V12.651C2.00027 12.4827 2.03451 12.3161 2.10076 12.1607C2.167 12.0053 2.26397 11.8642 2.3861 11.7454C2.50824 11.6267 2.65315 11.5325 2.81255 11.4685C2.97196 11.4044 3.14274 11.3716 3.31513 11.372Z'
      fill='#209BE6'
    />
    <path
      d='M10.3225 15.214C10.3336 15.4165 10.2993 15.6188 10.222 15.8069C10.1446 15.995 10.026 16.1644 9.87462 16.3031C9.7232 16.4418 9.54255 16.5465 9.34533 16.6099C9.14811 16.6732 8.93909 16.6938 8.73289 16.67H7.7998V13.748H8.73289C8.93967 13.7257 9.14896 13.7475 9.34623 13.8121C9.5435 13.8766 9.72401 13.9823 9.87526 14.1218C10.0265 14.2613 10.1448 14.4312 10.2221 14.6198C10.2993 14.8084 10.3336 15.0112 10.3225 15.214M8.73289 16.358C8.89219 16.3768 9.05377 16.3602 9.20562 16.3096C9.35747 16.259 9.4957 16.1757 9.61004 16.0657C9.72438 15.9558 9.81189 15.8222 9.86608 15.6748C9.92027 15.5273 9.93974 15.3698 9.92306 15.214C9.9397 15.0576 9.92032 14.8995 9.86634 14.7514C9.81235 14.6032 9.72512 14.4687 9.61103 14.3577C9.49694 14.2467 9.35888 14.162 9.20694 14.1098C9.055 14.0576 8.89304 14.0392 8.73289 14.056H8.19106V16.356L8.73289 16.358Z'
      fill='white'
    />
    <path
      d='M12.8066 14.5151C12.7915 14.3806 12.729 14.2765 12.6191 14.2026C12.5093 14.1278 12.371 14.0904 12.2044 14.0904C12.085 14.0904 11.9818 14.1093 11.8947 14.1472C11.8076 14.1842 11.7399 14.2353 11.6916 14.3006C11.6442 14.365 11.6206 14.4384 11.6206 14.5208C11.6206 14.5899 11.6367 14.6496 11.6689 14.6998C11.702 14.75 11.7451 14.7921 11.7981 14.8262C11.8521 14.8593 11.9099 14.8873 11.9714 14.91C12.033 14.9318 12.0922 14.9498 12.149 14.964L12.4331 15.0378C12.5259 15.0606 12.621 15.0913 12.7186 15.1302C12.8161 15.169 12.9065 15.2201 12.9899 15.2836C13.0732 15.347 13.1404 15.4256 13.1916 15.5194C13.2437 15.6131 13.2697 15.7253 13.2697 15.856C13.2697 16.0208 13.2271 16.1671 13.1419 16.2949C13.0576 16.4228 12.935 16.5236 12.774 16.5975C12.6139 16.6714 12.4203 16.7083 12.193 16.7083C11.9752 16.7083 11.7868 16.6737 11.6277 16.6046C11.4686 16.5355 11.344 16.4375 11.2541 16.3106C11.1641 16.1827 11.1144 16.0312 11.1049 15.856H11.5453C11.5538 15.9611 11.5879 16.0487 11.6475 16.1188C11.7082 16.1879 11.7853 16.2395 11.8791 16.2736C11.9738 16.3068 12.0775 16.3234 12.1902 16.3234C12.3142 16.3234 12.4245 16.3039 12.5211 16.2651C12.6187 16.2253 12.6954 16.1704 12.7512 16.1003C12.8071 16.0293 12.835 15.9465 12.835 15.8518C12.835 15.7656 12.8104 15.695 12.7612 15.6401C12.7129 15.5852 12.6471 15.5397 12.5637 15.5038C12.4814 15.4678 12.3881 15.436 12.2839 15.4086L11.9402 15.3148C11.7072 15.2514 11.5225 15.1581 11.3862 15.035C11.2508 14.9119 11.1831 14.749 11.1831 14.5464C11.1831 14.3788 11.2285 14.2324 11.3194 14.1074C11.4103 13.9824 11.5334 13.8854 11.6887 13.8163C11.844 13.7462 12.0192 13.7111 12.2143 13.7111C12.4113 13.7111 12.585 13.7457 12.7356 13.8148C12.8871 13.884 13.0065 13.9791 13.0936 14.1003C13.1807 14.2206 13.2261 14.3589 13.2299 14.5151H12.8066Z'
      fill='white'
    />
    <path
      d='M5.55582 16.701C5.25214 16.706 4.95381 16.6226 4.69882 16.4615C4.44383 16.3004 4.24372 16.0688 4.12396 15.7963C4.00421 15.5238 3.97023 15.2226 4.02635 14.9312C4.08247 14.6397 4.22615 14.3712 4.4391 14.1598C4.65205 13.9483 4.92463 13.8035 5.22214 13.7438C5.51965 13.6841 5.82863 13.7122 6.10974 13.8245C6.39084 13.9368 6.63136 14.1283 6.80066 14.3745C6.96996 14.6207 7.06039 14.9105 7.06043 15.207C7.06757 15.403 7.03372 15.5983 6.96096 15.781C6.8882 15.9638 6.77806 16.1302 6.63726 16.27C6.49645 16.4098 6.32794 16.5201 6.142 16.5942C5.95607 16.6683 5.75662 16.7047 5.55582 16.701M5.55582 16.37C5.70894 16.372 5.86078 16.3426 6.00154 16.2837C6.14229 16.2248 6.26883 16.1378 6.37298 16.0282C6.47713 15.9186 6.55658 15.7889 6.60618 15.6474C6.65579 15.506 6.67446 15.3559 6.66098 15.207C6.67328 15.0581 6.65385 14.9083 6.60392 14.767C6.55398 14.6258 6.47463 14.4962 6.37085 14.3864C6.26708 14.2765 6.14113 14.1889 6.00094 14.129C5.86076 14.069 5.70938 14.0381 5.55633 14.0381C5.40329 14.0381 5.25191 14.069 5.11173 14.129C4.97154 14.1889 4.84559 14.2765 4.74182 14.3864C4.63804 14.4962 4.55869 14.6258 4.50875 14.767C4.45882 14.9083 4.43939 15.0581 4.45169 15.207C4.43806 15.3559 4.45659 15.5059 4.50607 15.6473C4.55556 15.7888 4.63491 15.9185 4.73898 16.0281C4.84305 16.1378 4.96953 16.2248 5.11024 16.2837C5.25094 16.3426 5.40274 16.372 5.55582 16.37'
      fill='white'
    />
  </svg>
);

export default OdsIconSvg;
