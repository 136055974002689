import { SET_INTERNET_CONNECTION_OFFLINE, SET_INTERNET_CONNECTION_ONLINE } from '../../../constants/actions';

export const connectionStatus = {
  OFFLINE: 'offline',
  ONLINE: 'online'
};

const initialState = {
  status: connectionStatus.ONLINE
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_INTERNET_CONNECTION_OFFLINE:
      return {
        status: connectionStatus.OFFLINE
      };
    case SET_INTERNET_CONNECTION_ONLINE:
      return {
        status: connectionStatus.ONLINE
      };
    default:
      return state;
  }
}
