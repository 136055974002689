import React from 'react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import Label from '../Label/Label';
import StrengthChecker from '../StrengthChecker/StrengthChecker';
import styles from './Input.module.css';

// Import functions
import ErrorMark from '../../ErrorMark/ErrorMark';

const MAX_NUMBER_LENGTH = 15;
const DEFAULT_MAX_DECIMAL_PLACES = 2;

export const classNameForClarity = 'clarity';

const Input = React.forwardRef(
  (
    {
      label,
      id,
      strengthChecker,
      htmlid,
      required,
      currentStrength,
      maxStrength,
      outerLeft,
      outerRight,
      isDisabled,
      left,
      name = '',
      type,
      step = 0.1,
      value,
      placeholder,
      onKeyDown,
      onChange,
      onBlur,
      readOnly,
      min = 0,
      maxLength,
      right,
      validationText,
      wrapperDivClassName,
      labelClassName,
      divClassName = '',
      fieldClassName,
      maxDecimalPlaces = null,
      maxNumber = null,
      bigInput = false,
      autocomplete = 'on',
      dataCy
    },
    ref
  ) => {
    const context = useFormContext() || { formState: {} };
    const { setValue } = useFormContext();
    const {
      register,
      formState: { errors }
    } = context;

    const errorMessage = get(errors, name)?.message;
    const registerProps = readOnly ? undefined : register(name);

    const limitDecimalPlaces = (e) => {
      let decPlaces = 1;

      decPlaces += maxDecimalPlaces !== null ? maxDecimalPlaces : DEFAULT_MAX_DECIMAL_PLACES;

      const t = e.target.value;

      if (t.indexOf('.') >= 0) {
        e.target.value = t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), decPlaces);
      }
    };

    const limitNumber = (e) => {
      const value = e.target.value;

      if (value.length > MAX_NUMBER_LENGTH) {
        e.target.value = value.slice(0, MAX_NUMBER_LENGTH);
      }

      if (maxNumber && Number(value) > Number(maxNumber)) {
        e.target.value = Number(maxNumber);
      }
    };

    return (
      <div
        className={wrapperDivClassName ? `${wrapperDivClassName} ${styles.inputWrapperDiv}` : styles.inputWrapperDiv}
      >
        <div className={styles.labelWrapper}>
          {label && (
            <Label
              className={labelClassName || styles.inputLabel}
              htmlFor={htmlid}
              label={label}
              required={required}
              bigLabel={bigInput}
            />
          )}

          {strengthChecker && <StrengthChecker currentStrength={currentStrength} maxStrength={maxStrength} />}
        </div>

        <div className={styles.inputRow}>
          {outerLeft && <div className={styles.leftProp}>{outerLeft}</div>}
          <div
            disabled={isDisabled}
            className={`${styles.inputDiv} ${divClassName} ${bigInput ? styles.bigInputDiv : ''} ${
              isDisabled ? styles.preventDefaultBrowserClass : ''
            }`}
          >
            {left}
            <input
              id={id || 'input_component_' + name}
              ref={ref}
              name={name}
              {...registerProps}
              className={
                fieldClassName
                  ? `${fieldClassName} ${styles.inputField} ${classNameForClarity} ${
                      bigInput ? styles.bigInputDiv : ''
                    }`
                  : `${styles.inputField} ${classNameForClarity} ${bigInput ? styles.bigInputDiv : ''}`
              }
              type={type}
              step={step}
              placeholder={placeholder}
              value={value}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (type === 'number') {
                  limitDecimalPlaces(e);
                  limitNumber(e);
                }
                registerProps?.onChange(e);
                onChange && onChange(e);
              }}
              onBlur={(e) => {
                registerProps?.onBlur(e);
                onBlur && onBlur(e);
                setValue(name, e.target.value.trim());
              }}
              onKeyDown={onKeyDown}
              disabled={isDisabled}
              readOnly={readOnly}
              min={min}
              maxLength={maxLength}
              autoComplete={autocomplete}
              data-cy={dataCy}
            />
            <div className={styles.rightWrapper}>{right}</div>
          </div>

          {validationText && <div className={styles.inputLabel}>{validationText}</div>}
          {outerRight && <div className={styles.rightProp}>{outerRight}</div>}
          <ErrorMark errorMessage={errorMessage} bigLabel={bigInput} />
        </div>
      </div>
    );
  }
);

Input.displayName = 'Input';

Input.defaultProps = {
  type: 'text',
  required: false,
  validationText: '',
  strengthChecker: false,
  currentStrength: 0,
  maxStrength: 0,
  error: {},
  onKeyDown: () => {},
  step: 1
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'number', 'password', 'file']),
  required: PropTypes.bool,
  strengthChecker: PropTypes.bool,
  currentStrength: PropTypes.number,
  maxStrength: PropTypes.number,
  validationText: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  value: PropTypes.any,
  children: PropTypes.node,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
  wrapperDivClassName: PropTypes.any,
  labelClassName: PropTypes.any,
  errorComponentClassName: PropTypes.any
};

export default Input;
