import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { formatPrice } from '../../../assets/helpers/common';
import { formatDateToYMD, parseDateDMY } from '../../../assets/helpers/dateTimeUtils';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import CircleAddSvg from '../../../assets/images/svg/CircleAddSvg';
import LogoSvg from '../../../assets/images/svg/LogoSvg';
import { parsePackagingMethod } from '../../../constants/packagingMethods';
import AppComponentContext from '../../../contexts/AppComponentContext';
import styles from './CmrGenerator.module.css';

const CmrGenerator = (props) => {
  const { postalCode, street, city, regionName, countryName, lat, lng } = props.pickupLatLng?.departure_field || {};
  const { date_created, transport_price } = props.agreementInfo || {};
  const { sender, receiver, pickup_date } = props.senderReceiverParams || {};
  const { sender_currency, sender_table } = sender || {};
  const { receiver_currency, receiver_table, receiver_observation } = receiver || {};

  const { getValues, setValue, register } = useFormContext() || {};
  const { isPC } = useWindowDimensions();
  const localization = useContext(AppComponentContext).strings;
  const hData = useSelector(({ userCompanies }) => {
    return {
      companyName: userCompanies?.selected?.name,
      companyPostalCode: userCompanies?.selected?.postal_code,
      companyCity: userCompanies?.selected?.city_name,
      companyCountry: userCompanies?.selected?.country_name
    };
  });

  const { companyName, companyPostalCode, companyCity, companyCountry } = hData || {};
  const [showAddButton, setShowAddButton] = useState(true);
  const [senderText, setSenderText] = useState('');
  const [consigneeText, setConsigneeText] = useState('');
  const [placeOfDeliveryText, setPlaceOfDeliveryText] = useState('');
  const [numberOfUnitsText, setNumberOfUnitsText] = useState('');
  const [natureOfGoodsText, setNatureOfGoodsText] = useState('');
  const [grossWeightText, setGrossWeightText] = useState('');
  const [cargoDescription, setCargoDescription] = useState('');
  const [pickupPoint, setPickupPoint] = useState('');
  const handleTheme = useCallback(
    (pixel) => {
      return `${pixel}px solid ${props.theme === 'blackCopy' ? 'black' : props.theme}`;
    },
    [props.theme]
  );

  const generatePickupPointAgreement = useMemo(() => {
    if (!sender) return;
    return `${sender.pickup_point?.split(/\r?\n/)[0]}\r\n${parseDateDMY(pickup_date, localization)}`;
  }, [localization, pickup_date, sender]);

  const onChangeHandler = useCallback(
    (el, name) => {
      el.addEventListener('change', (e) => {
        props.setDisableButton(false);
        setValue(`cmr_template.generator_packages.${name}`, e.target.value);
        el.defaultValue = e.target.value;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const setPackagingMethod = useMemo(() => {
    if (!props.view) {
      const width = getValues('packages.0.width');
      const length = getValues('packages.0.length');
      if (!props.initStateUpdate) {
        return getValues('cmr_template.generator_packages.0.packaging_method');
      } else
        setValue(
          'cmr_template.generator_packages.0.packaging_method',
          parsePackagingMethod(getValues('packages.0.packaging_method'), localization, { width, length })
        );
      return parsePackagingMethod(getValues('packages.0.packaging_method'), localization, { width, length });
    }
  }, [getValues, localization, props.initStateUpdate, props.view, setValue]);

  const setLiquid = useMemo(() => {
    if (props.view) return;
    if (!props.initStateUpdate) {
      return getValues('cmr_template.generator_packages.0.volume_l');
    } else {
      if (getValues('packages.0.volume_l')) {
        setValue('cmr_template.generator_packages.0.volume_l', getValues('packages.0.volume_l'));
        return getValues('packages.0.volume_l') / 1000;
      } else {
        setValue('cmr_template.generator_packages.0.volume_l', '');
      }
    }
  }, [getValues, props.initStateUpdate, props.view, setValue]);

  const handleHeadingNumber = useMemo(() => {
    switch (props.theme) {
      case 'black':
        return '1';
      case 'red':
        return '2';
      case 'blue':
        return '3';
      case 'green':
        return '4';
      case 'blackCopy':
        return '5';
    }
  }, [props.theme]);

  const getAndSetInitialValues = useCallback(
    (value, name) => {
      if (!props.initStateUpdate) {
        return setValue(name, getValues(name));
      }
      if (getValues(value)) {
        if (name === 'cmr_template.generator_packages.0.unit_weight') {
          setValue(name, getValues(value));
          return getValues(value);
        } else {
          setValue(name, getValues(value));
          return getValues(value);
        }
      } else {
        setValue(name, '');
      }
    },
    [getValues, props.initStateUpdate, setValue]
  );

  const generateSenderTextInput = useCallback(() => {
    if (props.pickupLatLng) {
      if (!props.initStateUpdate) {
        return setValue('cmr_template.company_name', getValues('cmr_template.company_name'));
      }
      const text = `${companyName ? companyName : ''}\r\n${postalCode}, ${street}, ${city}${
        regionName ? `,${regionName}` : ''
      }, ${countryName}\r\nLatitude:${lat}\r\nLongitude:${lng}`;
      setValue('cmr_template.company_name', text);
      return setSenderText(text);
    } else if (getValues('cmr_template.company_name')) {
      setValue('cmr_template.company_name', getValues('cmr_template.company_name'));
    } else {
      setValue('cmr_template.company_name', '');
    }
  }, [
    city,
    companyName,
    countryName,
    getValues,
    lat,
    lng,
    postalCode,
    props.initStateUpdate,
    props.pickupLatLng,
    regionName,
    setValue,
    street
  ]);

  const generateGrossWeightText = useCallback(() => {
    getAndSetInitialValues('packages.0.unit_weight', 'cmr_template.generator_packages.0.unit_weight');

    setGrossWeightText(Number(getValues('packages.0.unit_weight')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAndSetInitialValues, getValues, grossWeightText]);

  const generateConsigneeText = useCallback(() => {
    let text;
    if (!props.initStateUpdate) {
      getValues('cmr_template.sender_info');
    } else {
      text = `${companyName}\r\n${companyPostalCode}, ${companyCity}, ${companyCountry}`;
      setValue('cmr_template.sender_info', companyName ? text : '');
    }

    return setConsigneeText(text);
  }, [companyCity, companyCountry, companyName, companyPostalCode, getValues, props.initStateUpdate, setValue]);

  const generatePlaceOfDeliveryText = useCallback(() => {
    if (!props.initStateUpdate) {
      getValues('cmr_template.delivery_point');
    } else {
      getAndSetInitialValues('arrival_field', 'cmr_template.delivery_point');
    }
    setPlaceOfDeliveryText(getValues('cmr_template.delivery_point'));
  }, [getAndSetInitialValues, getValues, props.initStateUpdate]);

  const generateNumOfUnitsText = useCallback(() => {
    if (!props.initStateUpdate) {
      getValues('cmr_template.generator_packages.0.num_of_units');
    } else {
      getAndSetInitialValues('packages.0.num_of_units', 'cmr_template.generator_packages.0.num_of_units');
    }
    setNumberOfUnitsText(getValues('cmr_template.generator_packages.0.num_of_units'));
  }, [getAndSetInitialValues, getValues, props.initStateUpdate]);

  const generateNatureOfGoodsText = useCallback(() => {
    getAndSetInitialValues('packages.0.name', 'cmr_template.generator_packages.0.name');
    setNatureOfGoodsText(getValues('cmr_template.generator_packages.0.name'));
  }, [getAndSetInitialValues, getValues]);

  const generateUploadedDocuments = useCallback(async () => {
    if (!props.view) {
      if (!props.initStateUpdate) {
        return setValue('cmr_template.uploaded_documents', getValues('cmr_template.uploaded_documents'));
      } else {
        const invoice = await getValues('invoice_certificate_name');
        const packingList = await getValues('packing_list_certificate_name');
        const text = await `${invoice ? `Invoice\r\n` : ''}${packingList ? `Packing list` : ''}`;
        await setValue('cmr_template.uploaded_documents', text);
      }
    }
  }, [getValues, props.initStateUpdate, props.view, setValue]);

  const generateRequestedServices = useCallback(() => {
    const adr = getValues('adr');
    const frigo = getValues('frigo');
    const tailLift = getValues('tail_lift');
    const collectiveTransport = getValues('collective_transport');
    const customsClearing = getValues('customs_clearing');
    if (!frigo && !tailLift && !adr && !collectiveTransport && !customsClearing) {
      return '';
    } else {
      return `\r\nRequested services: ${frigo ? 'Frigo' + ', ' : ''}${tailLift ? 'Tail lift' + ', ' : ''}${
        adr ? 'ADR' + ', ' : ''
      }${collectiveTransport ? 'Collective transport' + ', ' : ''}${customsClearing ? 'Customs clearing' + ', ' : ''}`;
    }
  }, [getValues]);

  const generatePickupPoint = useCallback(() => {
    if (props.view || !props.initStateUpdate) return;
    const pickupPoint = getValues('departure_field');
    const dateFrom = parseDateDMY(formatDateToYMD(getValues('pickup_point.date_range.date_from')), localization);
    const dateTo = getValues('pickup_point.date_range.date_to');
    const dateToParsed =
      getValues('pickup_point.date_range.date_to') && parseDateDMY(formatDateToYMD(dateTo), localization);
    if (pickupPoint) {
      const text = `${pickupPoint}\r\n${dateFrom}${dateToParsed ? ' - ' + dateToParsed : ''}`;
      return setPickupPoint(text);
    }
  }, [getValues, localization, props.initStateUpdate, props.view]);

  //Parsing function
  const parseIncotermsEnum = useCallback((value) => {
    switch (Number(value)) {
      case 1:
        return 'EXW | Ex Works';
      case 2:
        return 'FCA | Free Carrier';
      case 3:
        return 'CPT | Carriage Paid To';
      case 4:
        return 'Pallet CIP | Carriage and Insurance Paid To';
      case 5:
        return 'DAP | Delivered at Place';
      case 6:
        return 'DPU | Delivered at Place Unloaded';
      case 7:
        return 'DDP | Delivered Duty Paid';
    }
  }, []);
  //**

  //Functions for DOM input configuration
  const configureFormValue = useCallback(
    (name, value, input) => {
      const inputName = `cmr_template.generator_packages.${name}`;
      input.setAttribute('name', inputName);
      if (!value) {
        return (input.placeholder = 'input text');
      } else {
        setValue(inputName, value);
        return (input.value = value);
      }
    },
    [setValue]
  );

  //**

  const getAllCloseButtons = useCallback(async () => {
    const element = await document.querySelectorAll(`.${styles.circleClose}`);
    element.forEach(async (el) => {
      el.style.display = 'none';
    });
  }, []);

  const addInputNameAndValue = useCallback(
    async (index, input, num) => {
      const width = getValues(`packages.${num}.width`);
      const length = getValues(`packages.${num}.length`);

      switch (index) {
        case 0:
          await onChangeHandler(input, `${num}.consolidation_name`);
          return configureFormValue(
            `${num}.consolidation_name`,
            getValues(`cmr_template.consolidation_name.${num}.consolidation_name`),
            input
          );
        case 1:
          await onChangeHandler(input, `${num}.num_of_units`);
          return configureFormValue(
            `${num}.num_of_units`,
            getValues(`${!props.initStateUpdate ? 'cmr_template.generator_packages' : 'packages'}.${num}.num_of_units`),
            input
          );
        case 2:
          await onChangeHandler(input, `${num}.packaging_method`);

          return configureFormValue(
            `${num}.packaging_method`,
            !props.initStateUpdate
              ? getValues(`cmr_template.generator_packages.${num}.packaging_method`)
              : parsePackagingMethod(getValues(`packages.${num}.packaging_method`, { width, length }), localization),
            input
          );
        case 3:
          await onChangeHandler(input, `${num}.name`);
          return configureFormValue(
            `${num}.name`,
            getValues(`${!props.initStateUpdate ? 'cmr_template.generator_packages' : 'packages'}.${num}.name`),
            input
          );
        case 4:
          await onChangeHandler(input, `${num}.statistical_nr`);
          return configureFormValue(
            `${num}.statistical_nr`,
            getValues(
              `${!props.initStateUpdate ? 'cmr_template.generator_packages' : 'packages'}.${num}.statistical_nr`
            ),
            input
          );
        case 5:
          await onChangeHandler(input, `${num}.unit_weight`);
          return configureFormValue(
            `${num}.unit_weight`,
            getValues(`${!props.initStateUpdate ? 'cmr_template.generator_packages' : 'packages'}.${num}.unit_weight`),
            input
          );
        case 6:
          await onChangeHandler(input, `${num}.volume_l`);
          return configureFormValue(
            `${num}.volume_l`,
            getValues(`${!props.initStateUpdate ? 'cmr_template.generator_packages' : 'packages'}.${num}.volume_l`),
            input
          );
      }
    },
    [configureFormValue, getValues, localization, onChangeHandler, props.initStateUpdate]
  );

  const printConsolidations = useCallback(
    (index, input, num) => {
      input.disabled = true;
      switch (index) {
        case 0:
          return (input.value = sender.generator_packages[num]?.consolidation_name
            ? sender.generator_packages[num].consolidation_name
            : '');
        case 1:
          return (input.value = sender.generator_packages[num]?.num_of_units
            ? sender.generator_packages[num].num_of_units
            : '');
        case 2:
          return (input.value = sender.generator_packages[num]?.packaging_method
            ? sender.generator_packages[num].packaging_method
            : '');
        case 3:
          return (input.value = sender.generator_packages[num]?.unit_weight
            ? sender.generator_packages[num].unit_weight
            : '');
        case 4:
          return (input.value = sender.generator_packages[num]?.statistical_nr
            ? sender.generator_packages[num].statistical_nr
            : '');
        case 5:
          return (input.value = sender.generator_packages[num]?.unit_weight
            ? sender.generator_packages[num].unit_weight
            : '');
        case 6:
          return (input.value = sender.generator_packages[num]?.volume_l
            ? sender.generator_packages[num].volume_l
            : '');
      }
    },
    [sender?.generator_packages]
  );

  const setIncoterms = useCallback(() => {
    if (!props.initStateUpdate) {
      return getValues('cmr_template.incoterms');
    } else {
      setValue('cmr_template.incoterms', parseIncotermsEnum(getValues('incoterms')));
    }
  }, [getValues, parseIncotermsEnum, props.initStateUpdate, setValue]);

  const nextAll = useCallback((element) => {
    const nextElements = [];
    let nextElement = element;

    while (nextElement.nextElementSibling) {
      nextElements.push(nextElement.nextElementSibling);
      nextElement = nextElement.nextElementSibling;
    }

    return nextElements;
  }, []);

  const addNewRow = useCallback(async () => {
    //Html elements
    const parentDiv = document.querySelectorAll('.parentClass');

    parentDiv.forEach((el, i) => {
      if (el.childNodes.length === 5) {
        setShowAddButton(false);
      }
      const closeButton = document.createElement('div');
      closeButton.classList.add(styles.circleClose);
      let newWrapper = document.createElement('div');
      newWrapper.classList.add(styles.newWrapper);
      let newInput = document.createElement('textarea');
      newInput.maxLength = 20;
      newInput.style.width = '88px';
      newInput.style.paddingLeft = 'unset';

      //First input is always "Consolidation" and is wrapped in different div with button
      if (i === 0 && !props.view) {
        newInput.classList.add(styles.inputSecondSectionMain);
        newInput.style.paddingLeft = '5px';
        if (!getValues(`cmr_template.generator_packages.${el.childNodes.length}.consolidation_name`)) {
          newInput.defaultValue = `Consolidation ${el.childNodes.length + 1}`;
          setValue(`cmr_template.generator_packages.${el.childNodes.length}.consolidation_name`, newInput.defaultValue);
        } else {
          newInput.defaultValue = getValues(
            `cmr_template.generator_packages.${el.childNodes.length}.consolidation_name`
          );
          setValue(
            `cmr_template.generator_packages.${el.childNodes.length}.consolidation_name`,
            getValues(`cmr_template.generator_packages.${el.childNodes.length}.consolidation_name`)
          );
        }
        !props.view && newWrapper.appendChild(closeButton);
      } else if (i === 4 || i === 5 || i === 6) {
        newInput.style.width = '68px';
        newInput.maxLength = 14;
        newInput.style.paddingLeft = '5px';
      } else {
        newInput.classList.add(styles.inputSecondSection);
      }

      if (!props.view) {
        //Add input name and values to created textarea fields if we are on create offer page
        if (!newInput.value) {
          newInput.placeholder = 'input text';
        }
        addInputNameAndValue(i, newInput, el.childNodes.length);
      } else {
        //Add input name and values to created textarea fields if we are on create offer page
        printConsolidations(i, newInput, el.childNodes.length);
      }
      closeButton.setAttribute('id', el.childNodes.length);

      newWrapper.appendChild(newInput);
      newWrapper.setAttribute('id', el.childNodes.length);
      el.appendChild(newWrapper);

      closeButton.addEventListener('click', async () => {
        setShowAddButton(true);
        const wrapper = document.querySelectorAll(`.${styles.newWrapper}`);
        wrapper.forEach((el) => {
          if (el.id === closeButton.id) {
            if (el.nextElementSibling) {
              const siblings = nextAll(el);
              siblings.forEach(async (e) => {
                if (e.childNodes.length > 1) {
                  const name = e.childNodes[1].name.split('.');
                  const currentValue = e.childNodes[1].value;
                  e.childNodes[0].id = (await Number(e.childNodes[0].id)) - 1;
                  e.childNodes[1].name = await `${name[0]}.${name[1]}.${name[2] - 1}.${name[3]}`;
                  await setValue(e.childNodes[1].name, currentValue);
                } else {
                  const name = e.childNodes[0].name.split('.');
                  const currentValue = e.childNodes[0].value;
                  e.childNodes[0].name = await `${name[0]}.${name[1]}.${name[2] - 1}.${name[3]}`;
                  await setValue(e.childNodes[0].name, currentValue);
                }
                e.id = (await Number(e.id)) - 1;
              });
            }
            props.remove(closeButton.id);
            props.remove(el.id);
            el.remove();
          }
        });
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addInputNameAndValue, printConsolidations, props.view, setValue, nextAll, getValues]);

  const initGenerateConsolidations = useCallback(async () => {
    if (!props.initStateUpdate && !props.view) {
      for (let i = 0; i < getValues('cmr_template.generator_packages').length > 0; i++) {
        await addNewRow();
      }
    }
    if (props.packages?.length > 0 && props.initStateUpdate) {
      for (let i = 0; i < props.packages.length && props.packages.length <= 6; i++) {
        await addNewRow();

        const textarea = await document.querySelector(`.${styles.inputInstructions}`);
        if (!getValues(`packages.${i}.cargo_remarks`)) {
          textarea.defaultValue = '';
        } else {
          textarea.defaultValue += await `Consolidation ${i + 1}: ${getValues(`packages.${i}.cargo_remarks`)}\r\n`;
        }
      }
      if (!props.initStateUpdate) {
        setCargoDescription(getValues('cmr_template.cargo_description'));
      } else {
        const textarea = await document.querySelector(`.${styles.inputInstructions}`);
        textarea.defaultValue += await generateRequestedServices();
        setCargoDescription(textarea.defaultValue);
      }
    } else if (props.view && sender) {
      for (let i = 0; i < sender.generator_packages.length; i++) {
        await addNewRow();
      }
    }
  }, [addNewRow, generateRequestedServices, getValues, props.initStateUpdate, props.packages, props.view, sender]);

  const initDeleteCloseButtons = useCallback(async () => {
    await getAllCloseButtons();
    await setShowAddButton(false);
    props.setIsDomRendered && (await props.setIsDomRendered(true));
  }, [getAllCloseButtons, props]);

  const returnAllCloseButtons = useCallback(async () => {
    const element = await document.querySelectorAll(`.${styles.circleClose}`);
    element.forEach(async (el) => {
      el.style.display = '';
    });
  }, []);

  const initReturnButtons = useCallback(async () => {
    await returnAllCloseButtons();
  }, [returnAllCloseButtons]);

  const handleHeadingTitle = useMemo(() => {
    switch (props.theme) {
      case 'black':
        return (
          <>
            <p>Examplar fur den Absender</p>
            <p>Copy for sender</p>
          </>
        );
      case 'red':
        return (
          <>
            <p>Exemplar fur den Empfanger</p>
            <p>Copy for consignee</p>
          </>
        );
      case 'blue':
        return (
          <>
            <p>Examplar fur den Frachtfuhrer</p>
            <p>Copy for carrier</p>
          </>
        );
      case 'green':
      case 'blackCopy':
        return (
          <>
            <p>Examplar fur</p>
            <p>Copy for</p>
          </>
        );
    }
  }, [props.theme]);

  const handleHeadingInfo = useMemo(() => {
    return (
      <div className={styles.headingInfo}>
        <div className={styles.number}>
          <p>{handleHeadingNumber}</p>
        </div>
        <div className={styles.cmrType}>{handleHeadingTitle}</div>
      </div>
    );
  }, [handleHeadingNumber, handleHeadingTitle]);

  useEffect(() => {
    initGenerateConsolidations();
  }, [initGenerateConsolidations]);

  useEffect(() => {
    if (props.printState || props.downloadState) {
      initDeleteCloseButtons();

      return () => {
        setShowAddButton(true);
        props.setIsDomRendered && props.setIsDomRendered(false);
        initReturnButtons();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.printState, props.downloadState, props.setIsDomRendered]);

  useEffect(() => {
    if (!props.view) {
      generateSenderTextInput();
      generateConsigneeText();
      generateGrossWeightText();
      generateUploadedDocuments();
      setIncoterms();
      generateUploadedDocuments();
      generatePickupPoint();
      generatePlaceOfDeliveryText();
      generateNumOfUnitsText();
      generateNatureOfGoodsText();
    }
  }, [
    generateGrossWeightText,
    generateNatureOfGoodsText,
    generateNumOfUnitsText,
    generatePickupPoint,
    generatePlaceOfDeliveryText,
    generateSenderTextInput,
    generateUploadedDocuments,
    generateConsigneeText,
    props.view,
    setIncoterms
  ]);

  useEffect(() => {
    if (props.view) return;
    if (cargoDescription) {
      setValue('cmr_template.cargo_description', cargoDescription);
    } else if (getValues('cmr_template.cargo_description')) {
      setValue('cmr_template.cargo_description', getValues('cmr_template.cargo_description'));
    } else {
      setValue('cmr_template.cargo_description', '');
    }
  }, [cargoDescription, getValues, props.view, setValue]);

  useEffect(() => {
    if (props.view) return;
    if (pickupPoint) {
      setValue('cmr_template.pickup_point', pickupPoint);
    } else if (getValues('cmr_template.pickup_point')) {
      setValue('cmr_template.pickup_point', getValues('cmr_template.pickup_point'));
    } else {
      setValue('cmr_template.pickup_point', '');
    }
  }, [generatePickupPoint, getValues, pickupPoint, props.view, setValue]);

  return (
    <>
      <div
        className={`${styles.mainContainer} ${!isPC && !props.downloadState ? styles.mainContainerResponsive : ''} ${
          props.customHeight && props.customHeight
        }`}
      >
        <div
          className={`${styles.heading} ${!isPC ? styles.headingResponsive : ''}`}
          style={{ backgroundColor: props.theme === 'blackCopy' ? 'black' : props.theme }}
        >
          {handleHeadingInfo}
        </div>
        <div className={styles.formContainerWrapper}>
          <div className={styles.asideContainer}>
            <div className={styles.asideLeft_1}>
              <p>{`Auszfullen auf Verantwortung des Absenders`}</p>
              <p>{`Auszfullen auf Verantwortung des Absenders`}</p>
              <p>{`To be completed on sender's responsibility`}</p>
            </div>
            <div className={styles.asideLeft_3}>
              <div className={styles.asideBig}>
                <div className={styles.asideNumber}>1-15</div>
                <div className={styles.asideNumberText}>
                  <p>einschlieslicht</p>
                  <p>including</p>
                </div>
              </div>
            </div>
            <div className={styles.asideLeft_2}>
              <div className={styles.asideBig}>
                <div className={styles.asideNumber}>19+20+21+22</div>
                <div className={styles.asideNumberText}>
                  <p>Die mit fett gedruckten Linien eingerahmten Rubriken mussen</p>
                  <p>The spaces framed with heavy lines must be filled</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.formContainer} style={{ borderRight: handleTheme(1), borderLeft: handleTheme(1) }}>
            <div className={styles.firstSectionWrapper} style={{ borderBottom: handleTheme(1) }}>
              <div>
                <div className={styles.columnHigh}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>1</p>
                    </div>
                    <div>
                      <p>{`Absender (Name, Adresse, Land)`} </p>
                      <p>{`Sender (name, address, country)`} </p>
                    </div>
                  </div>
                  {!props.view ? (
                    <>
                      <textarea
                        maxLength={180}
                        {...register('cmr_template.company_name')}
                        defaultValue={senderText ? senderText : ''}
                        className={styles.inputHigh}
                        placeholder='input text'
                      ></textarea>
                    </>
                  ) : (
                    <>
                      <textarea
                        className={styles.inputHigh}
                        placeholder={!props.view ? 'input text' : ''}
                        defaultValue={sender ? sender.company_name : ''}
                        disabled={props.view ? true : false}
                      ></textarea>
                    </>
                  )}
                </div>
                <div className={styles.columnHigh} style={{ borderBottom: handleTheme(1), borderTop: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>2</p>
                    </div>
                    <div>
                      <p>{`Empfanger (Name, Adresse, Land)`} </p>
                      <p>{`Consignee (name, address, country)`} </p>
                    </div>
                  </div>
                  {!props.view ? (
                    <>
                      <textarea
                        maxLength={180}
                        {...register('cmr_template.sender_info')}
                        defaultValue={consigneeText ? consigneeText : ''}
                        className={styles.inputHigh}
                        placeholder='input text'
                      ></textarea>
                    </>
                  ) : (
                    <>
                      <textarea
                        className={styles.inputHigh}
                        placeholder={!props.view ? 'input text' : ''}
                        disabled={props.view ? true : false}
                        defaultValue={sender ? sender.sender_info : ''}
                      ></textarea>
                    </>
                  )}
                </div>
                <div className={styles.columnLower}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>3</p>
                    </div>
                    <div>
                      <p>{`Auslieferot des Gutes (Ort, Land)`} </p>
                      <p>{`Place of delivery of the goods (place, country)`} </p>
                    </div>
                  </div>
                  {!props.view ? (
                    <>
                      <textarea
                        maxLength={180}
                        {...register('cmr_template.delivery_point')}
                        defaultValue={placeOfDeliveryText}
                        className={styles.inputLower}
                        placeholder='input text'
                      ></textarea>
                    </>
                  ) : (
                    <>
                      <textarea
                        className={styles.inputLower}
                        placeholder={!props.view ? 'input text' : ''}
                        disabled={props.view ? true : false}
                        defaultValue={sender ? sender.delivery_point : ''}
                      ></textarea>
                    </>
                  )}
                </div>
                <div className={styles.columnLower} style={{ borderBottom: handleTheme(1), borderTop: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>4</p>
                    </div>
                    <div>
                      <p>{`Ort und Datum der Ubernahme des Gutes (Ort, Land, Datum)`} </p>
                      <p>{`Place and date of taking over the goods (place, country, date)`} </p>
                    </div>
                  </div>
                  {!props.view ? (
                    <>
                      <textarea
                        maxLength={180}
                        {...register('cmr_template.pickup_point')}
                        defaultValue={!props.initStateUpdate ? getValues('cmr_template.pickup_point') : ''}
                        className={styles.inputLower}
                        placeholder='input text'
                      ></textarea>
                    </>
                  ) : (
                    <>
                      <textarea
                        className={styles.inputLower}
                        placeholder={!props.view ? 'input text' : ''}
                        disabled={props.view ? true : false}
                        defaultValue={sender ? generatePickupPointAgreement : ''}
                      ></textarea>
                    </>
                  )}
                </div>
                <div className={styles.columnLower}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>5</p>
                    </div>
                    <div>
                      <p>{`Belgefungte Dokumente`} </p>
                      <p>{`Documents attached`} </p>
                    </div>
                  </div>
                  {!props.view ? (
                    <>
                      <textarea
                        maxLength={180}
                        {...register('cmr_template.uploaded_documents')}
                        className={styles.inputLower}
                        placeholder='input text'
                      ></textarea>
                    </>
                  ) : (
                    <>
                      <textarea
                        className={styles.inputLower}
                        placeholder={!props.view ? 'input text' : ''}
                        disabled={props.view ? true : false}
                        defaultValue={sender ? sender.uploaded_documents : ''}
                      ></textarea>
                    </>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.columnShort} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.logoRowContainer}>
                    <div className={styles.logoColumnTitle}>
                      <p>INTERNATIONALER FRACHTBRIEF</p>
                      <p>INTERNATIONAL CONSIGNEE NOTE</p>
                    </div>
                    <div className={styles.logo}>
                      <p>№</p>
                      <div className={styles.cmrLogo}>CMR</div>
                    </div>
                  </div>
                  <div className={styles.logoRowContainer}>
                    <div className={styles.logoTextLeft}>
                      <p>{`Diese Beforderung unterliegt, unbeschadet andrea lautender Bestimmungen, dem Ubereinkommen uber der Vertag uber den internationalen Guterkrafverkehr(CMR)`}</p>
                    </div>
                    <div className={styles.logoTextLeft}>
                      <p>{`This carriage is subject, notwithstanding any clause to tje contray, to the Convention on the Contract for the international Carriage of goods by road(CMR)`}</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${!props.view ? styles.disabledColor : ''} ${
                    props.downloadState ? styles.downloadDisabledColor : ''
                  }`}
                >
                  <div
                    className={styles.columnShort}
                    style={{
                      borderBottom: handleTheme(1),
                      borderTop: handleTheme(2),
                      borderLeft: handleTheme(2),
                      borderRight: handleTheme(2)
                    }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>16</p>
                      </div>
                      <div>
                        <p>{`Frachtfuhrer (Name, Adresse, Land)`} </p>
                        <p>{`Carrier (name, address, country)`} </p>
                      </div>
                    </div>
                    {props.view ? (
                      <textarea
                        placeholder={!props.view ? 'input text' : ''}
                        disabled={props.view ? true : false}
                        defaultValue={receiver ? receiver.receiver_company_name : ''}
                      />
                    ) : null}
                  </div>
                  <div
                    className={styles.columnLowerShort}
                    style={{ borderLeft: handleTheme(2), borderRight: handleTheme(2) }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>17</p>
                      </div>
                      <div>
                        <p>{`Nachfolgender Frachtfuhrer (Name, Adresse, Land)`} </p>
                        <p>{`Successive carriers (name, address, country)`} </p>
                      </div>
                    </div>
                    {props.view ? (
                      <textarea
                        placeholder={!props.view ? 'input text' : ''}
                        disabled={props.view ? true : false}
                        defaultValue={receiver ? receiver.receiver_remarks : ''}
                      />
                    ) : null}
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(2),
                      borderRight: handleTheme(2),
                      borderBottom: handleTheme(2),
                      borderTop: handleTheme(1),
                      minHeight: 152,
                      maxHeight: 152
                    }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>18</p>
                      </div>
                      <div>
                        <p>{`Vorbehalte und Bemerkungen der Frachtfuhrer`} </p>
                        <p>{`Carrier's reservations and observations`} </p>
                      </div>
                    </div>
                    {props.view ? receiver_observation : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.secondSectionWrapper}>
              <LogoSvg className={`${styles.cargoLogo} ${props.downloadState ? styles.downloadLogoShow : ''}`} />
              <div className={styles.leftSideContainer}>
                <div className={styles.topBlock} style={{ borderBottom: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>6</p>
                    </div>
                    <div className={styles.secondSectionContainer}>
                      <p>{`Kennzeichen u. Nummern`} </p>
                      <p>{`Marks and Nos`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        {!props.view ? (
                          <textarea
                            maxLength={20}
                            style={{ width: '88px' }}
                            onChange={() => props.setDisableButton(true)}
                            {...register('cmr_template.generator_packages.0.consolidation_name')}
                            defaultValue={
                              getValues('cmr_template.packages.0.consolidation_name')
                                ? getValues('cmr_template.packages.0.consolidation_name')
                                : 'Consolidation 1'
                            }
                            className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                            placeholder={!props.view ? 'input text' : ''}
                          ></textarea>
                        ) : (
                          <textarea
                            style={{ width: '88px', paddingLeft: 'unset' }}
                            defaultValue={props.view ? sender.generator_packages[0].consolidation_name : ''}
                            className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                            placeholder={!props.view ? 'input text' : ''}
                            disabled={props.view ? true : false}
                          ></textarea>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>7</p>
                    </div>
                    <div>
                      <p>{`Anzahl der Pakete`} </p>
                      <p>{`Number of packages`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        {!props.view ? (
                          <>
                            <textarea
                              style={{ paddingLeft: 'unset', width: '88px' }}
                              maxLength={20}
                              {...register('cmr_template.generator_packages.0.num_of_units')}
                              defaultValue={numberOfUnitsText}
                              className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                              placeholder='input text'
                            ></textarea>
                          </>
                        ) : (
                          <>
                            <textarea
                              style={{ width: '88px', paddingLeft: 'unset' }}
                              className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                              placeholder={!props.view ? 'input text' : ''}
                              disabled={props.view ? true : false}
                              defaultValue={props.view ? sender.generator_packages[0].num_of_units : ''}
                            ></textarea>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>8</p>
                    </div>
                    <div>
                      <p>{`Art der Verpackung`} </p>
                      <p>{`Method of packing`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        {!props.view ? (
                          <>
                            <textarea
                              style={{ paddingLeft: 'unset', width: '88px' }}
                              maxLength={20}
                              {...register('cmr_template.generator_packages.0.packaging_method')}
                              className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                              onChange={(e) => {
                                props.setDisableButton(false);
                                setValue('cmr_template.generator_packages.0.packaging_method', e.target.value);
                              }}
                              defaultValue={setPackagingMethod}
                              placeholder='input text'
                            ></textarea>
                          </>
                        ) : (
                          <>
                            <textarea
                              style={{ width: '88px', paddingLeft: 'unset' }}
                              className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                              placeholder={!props.view ? 'input text' : ''}
                              disabled={props.view ? true : false}
                              defaultValue={props.view ? sender.generator_packages[0].packaging_method : ''}
                            ></textarea>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>9</p>
                    </div>
                    <div>
                      <p>{`Bezeichnung des Gutes`} </p>
                      <p>{`Nature of the goods`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        {!props.view ? (
                          <>
                            <textarea
                              style={{ paddingLeft: 'unset', width: '88px' }}
                              maxLength={20}
                              {...register('cmr_template.generator_packages.0.name')}
                              defaultValue={natureOfGoodsText}
                              onChange={(e) => setValue('cmr_template.generator_packages.0.name', e.target.value)}
                              className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                              placeholder='input text'
                            ></textarea>
                          </>
                        ) : (
                          <>
                            <textarea
                              style={{ width: '88px', paddingLeft: 'unset' }}
                              className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                              placeholder={!props.view ? 'input text' : ''}
                              disabled={props.view ? true : false}
                              defaultValue={props.view ? sender.generator_packages[0].name : ''}
                            ></textarea>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {!props.view ? (
                    <div
                      className={styles.addButton}
                      onClick={() => {
                        props.setDisableButton(false);
                        addNewRow();
                      }}
                    >
                      {showAddButton &&
                        (!props.view ? !getValues('packages.0.volume_l') ? <CircleAddSvg /> : null : <CircleAddSvg />)}
                    </div>
                  ) : null}
                </div>

                <div className={styles.bottomBlock}>
                  <div className={styles.row_1}>
                    <p>UN-Nr</p>
                    <p>Ben. s. Nr.9</p>
                    <p>Gefahrzettelmuster-Nr.</p>
                    <p>Verp.-Grp.</p>
                  </div>
                  <div className={styles.row_2}>
                    <p>name s. nr.9</p>
                    <p>Hazard label sample no.</p>
                    <p>Pack. group</p>
                  </div>
                </div>
              </div>
              <div className={styles.rightSideContainer}>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>10</p>
                    </div>
                    <div>
                      <p>{`Statistiknr`} </p>
                      <p>{`Statistical nr.`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                    {!props.view ? (
                      <>
                        <textarea
                          style={{ width: '68px' }}
                          maxLength={14}
                          {...register('cmr_template.generator_packages.0.statistical_nr')}
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                          placeholder='input text'
                        ></textarea>
                      </>
                    ) : (
                      <>
                        <textarea
                          style={{ width: '68px' }}
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                          placeholder={!props.view ? 'input text' : ''}
                          defaultValue={props.view ? sender.generator_packages[0].statistical_nr : ''}
                          disabled={props.view ? true : false}
                        ></textarea>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>11</p>
                    </div>
                    <div>
                      <p>{`Bruttogew. kg`} </p>
                      <p>{`Gross weight kg`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                    {!props.view ? (
                      <>
                        <textarea
                          style={{ width: '68px' }}
                          maxLength={14}
                          {...register('cmr_template.generator_packages.0.unit_weight')}
                          defaultValue={grossWeightText}
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                          placeholder='input text'
                        ></textarea>
                      </>
                    ) : (
                      <>
                        <textarea
                          style={{ width: '68px' }}
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                          placeholder={!props.view ? 'input text' : ''}
                          disabled={props.view ? true : false}
                          defaultValue={props.view ? sender.generator_packages[0].unit_weight : ''}
                        ></textarea>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>12</p>
                    </div>
                    <div>
                      <p>{`Volumen in m3`} </p>
                      <p>{`Volume in m3`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                    {!props.view ? (
                      <>
                        <textarea
                          style={{ width: '68px' }}
                          maxLength={14}
                          {...register('cmr_template.generator_packages.0.volume_l')}
                          defaultValue={setLiquid}
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                          placeholder='input text'
                        ></textarea>
                      </>
                    ) : (
                      <>
                        <textarea
                          style={{ width: '88px' }}
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                          placeholder={!props.view ? 'input text' : ''}
                          disabled={props.view ? true : false}
                          defaultValue={props.view ? sender.generator_packages[0].volume_l : ''}
                        ></textarea>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.thirdSectionWrapper}>
              <div
                className={styles.sendersInstructions}
                style={{ borderBottom: handleTheme(1), borderTop: handleTheme(1) }}
              >
                <div className={styles.sectionInfo}>
                  <div className={styles.smallNumber}>
                    <p>13</p>
                  </div>
                  <div>
                    <p>{`Anweisungen des Absenders (Zoll-, amtl. Behandungen, Sondervorschriften, etc)`} </p>
                    <p>{`Sender's instructions`} </p>
                  </div>
                </div>
                {!props.view ? (
                  <>
                    <textarea
                      maxLength={514}
                      {...register('cmr_template.cargo_description')}
                      className={styles.inputInstructions}
                      defaultValue={cargoDescription}
                      placeholder='input text'
                    ></textarea>
                  </>
                ) : (
                  <>
                    <textarea
                      className={styles.inputInstructions}
                      placeholder='input text'
                      disabled={props.view ? true : false}
                      defaultValue={sender ? sender.cargo_description : ''}
                    ></textarea>
                  </>
                )}
              </div>
              <div className={styles.rightSideTable}>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    borderLeft: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>19</p>
                    </div>
                    <div>
                      <p>{`Zu bezahlen von`} </p>
                      <p>{`To be paid by`} </p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(1),
                    borderTop: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '5px'
                    }}
                  >
                    <p>{`Absender`} </p>
                    <p>{`Sender`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '5px'
                    }}
                  >
                    <p>{`Wahrung`} </p>
                    <p>{`Currency`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    borderRight: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '5px'
                    }}
                  >
                    <p>{`Empfanger`} </p>
                    <p>{`Consignee`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(2)
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '2px',
                      lineHeight: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <p>{`Fracht`} </p>
                    <p>{`Carriage`} </p>
                    <p>{`Ermasgung`} </p>
                    <p>{`Reductions`} </p>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{
                    borderBottom: handleTheme(1)
                  }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  >
                    {props.view ? sender_table?.carriage : ''}
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  >
                    {props.view ? sender_table?.reductions : ''}
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div className={styles.verticalLine} style={{ borderLeft: handleTheme(3) }} />
                  <div
                    className={styles.verticalBlock}
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                  >
                    <textarea
                      disabled
                      defaultValue={props.view ? sender_currency?.carriage : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                    <textarea
                      disabled
                      defaultValue={props.view ? receiver_currency?.carriage : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div className={styles.verticalBlock} style={{ borderLeft: handleTheme(1) }}>
                    <textarea
                      disabled
                      defaultValue={props.view ? sender_currency?.reductions : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                    <textarea
                      disabled
                      defaultValue={props.view ? receiver_currency?.reductions : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    {props.view ? receiver_table?.carriage : ''}
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    {props.view ? receiver_table?.reductions : ''}
                  </div>
                </div>

                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(2)
                  }}
                >
                  <div style={{ paddingLeft: '2px', lineHeight: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <p>{`Zwischensumme`} </p>
                    <p>{`Balance`} </p>
                    <p>{`Zuschlage`} </p>
                    <p>{`Supplement charges`} </p>
                    <p>{`Nebengebuhren`} </p>
                    <p>{`Additional charges`} </p>
                    <p>{`Sonstiges`} </p>
                    <p>{`Miscellaneous`} </p>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  >
                    {props.view ? sender_table?.balance : ''}
                  </div>
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  >
                    {props.view ? sender_table?.supplement_charges : ''}
                  </div>
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  >
                    {props.view ? sender_table?.additional_charges : ''}
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  >
                    {props.view ? sender_table?.miscellaneous : ''}
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  >
                    <textarea
                      disabled
                      defaultValue={props.view ? sender_currency?.balance : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                    <textarea
                      disabled
                      defaultValue={props.view ? receiver_currency?.balance : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  >
                    <textarea
                      disabled
                      defaultValue={props.view ? sender_currency?.supplement_charges : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                    <textarea
                      disabled
                      defaultValue={props.view ? receiver_currency?.supplement_charges : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  >
                    <textarea
                      disabled
                      defaultValue={props.view ? sender_currency?.additional_charges : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                    <textarea
                      disabled
                      defaultValue={props.view ? receiver_currency?.additional_charges : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    className={styles.verticalBlock}
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  >
                    <textarea
                      disabled
                      defaultValue={props.view ? sender_currency?.miscellaneous : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                    <textarea
                      disabled
                      defaultValue={props.view ? receiver_currency?.miscellaneous : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{
                    borderBottom: handleTheme(1)
                  }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    {props.view ? receiver_table?.balance : ''}
                  </div>
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    {props.view ? receiver_table?.supplement_charges : ''}
                  </div>
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    {props.view ? receiver_table?.additional_charges : ''}
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    {props.view ? receiver_table?.miscellaneous : ''}
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(2),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div style={{ paddingLeft: '2px', lineHeight: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <p>{`Gesamtbetrag`} </p>
                    <p>{`To be paid`} </p>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${!props.view ? styles.disabledColor : ''} ${
                    props.downloadState ? styles.downloadDisabledColor : ''
                  }`}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(2)
                  }}
                >
                  {props.view ? sender_table?.to_be_paid : ''}
                </div>
                <div
                  className={`${styles.gridFragment} ${!props.view ? styles.disabledColor : ''} ${
                    props.downloadState ? styles.downloadDisabledColor : ''
                  }`}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div className={styles.verticalBlock}>
                    <textarea
                      disabled
                      defaultValue={props.view ? sender_currency?.to_be_paid : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                    <textarea
                      disabled
                      defaultValue={props.view ? receiver_currency?.to_be_paid : ''}
                      style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${!props.view ? styles.disabledColor : ''} ${
                    props.downloadState ? styles.downloadDisabledColor : ''
                  }`}
                  style={{
                    borderLeft: handleTheme(1),
                    borderRight: handleTheme(2),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '5px'
                    }}
                  >
                    {props.view ? receiver_table?.to_be_paid || formatPrice(transport_price) : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.fourthSectionWrapper}>
              <div className={styles.cashOnDelivery} style={{ borderBottom: handleTheme(1) }}>
                <div className={styles.sectionInfo}>
                  <div className={styles.smallNumber}>
                    <p>14</p>
                  </div>
                  <div>
                    <p>{`Ruckerstattung`} </p>
                    <p>{`Cash on delivery`} </p>
                  </div>
                  {!props.view ? (
                    <textarea
                      maxLength={22}
                      {...register('cmr_template.cash_on_delivery')}
                      placeholder={'input text'}
                      onChange={(e) => {
                        setValue('cmr_template.cash_on_delivery', e.target.value);
                      }}
                    />
                  ) : (
                    <textarea
                      name='cmr_template.cash_on_delivery'
                      placeholder={!props.view ? 'input text' : ''}
                      disabled={props.view ? true : false}
                      defaultValue={props.view ? sender.cash_on_delivery : ''}
                    />
                  )}
                </div>
              </div>
              <div className={styles.bottomWrapper}>
                <div className={styles.topSection}>
                  <div
                    className={styles.leftSideWrapper}
                    style={{ borderRight: handleTheme(1), borderBottom: handleTheme(1) }}
                  >
                    <div className={styles.topDiv} style={{ borderBottom: handleTheme(1) }}>
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>15</p>
                        </div>
                        <div>
                          <p>{`Frachtzahlungsanweisungen`} </p>
                          <p>{`Instruction as to payment carriage`} </p>
                          <p>Frei/Carriage paid</p>
                          <p>Unfrei/Carriage forward</p>
                        </div>
                        {!props.view ? (
                          <>
                            <textarea
                              maxLength={44}
                              {...register('cmr_template.incoterms')}
                              placeholder='input text'
                              style={{ alignSelf: 'center' }}
                            ></textarea>
                          </>
                        ) : (
                          <>
                            <textarea
                              placeholder={!props.view ? 'input text' : ''}
                              style={{ alignSelf: 'center' }}
                              disabled={props.view ? true : false}
                              defaultValue={props.view ? sender.incoterms : ''}
                            ></textarea>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${styles.bottomDiv} ${!props.view ? styles.disabledColor : ''} ${
                        props.downloadState ? styles.downloadDisabledColor : ''
                      }`}
                    >
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>21</p>
                        </div>
                        <div>
                          <p>{`Ausgefertigt`} </p>
                          <p>{`Established in`} </p>
                        </div>
                      </div>
                      <textarea
                        defaultValue={props.view ? parseDateDMY(formatDateToYMD(date_created), localization) : ''}
                        disabled
                      ></textarea>
                      <div className={styles.additional}>
                        <p>am</p>
                        <p>on</p>
                      </div>
                      <textarea disabled></textarea>
                    </div>
                  </div>
                  <div className={styles.rightSideWrapper} style={{ borderBottom: handleTheme(1) }}>
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>20</p>
                      </div>
                      <div>
                        <p>{`Besondere Vereinbarungen`} </p>
                        <p>{`Special agreements`} </p>
                      </div>
                    </div>
                    {!props.view ? (
                      <>
                        <textarea
                          maxLength={100}
                          {...register('cmr_template.remarks')}
                          defaultValue={
                            !props.emptySheet ? getAndSetInitialValues('remarks', 'cmr_template.remarks') : ''
                          }
                          className={styles.inputSpecial}
                          placeholder='input text'
                        ></textarea>
                      </>
                    ) : (
                      <>
                        <textarea
                          className={styles.inputSpecial}
                          placeholder={!props.view ? 'input text' : ''}
                          disabled={props.view ? true : false}
                          defaultValue={sender ? sender.remarks : ''}
                        ></textarea>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.bottomSection}>
                  <div className={styles.column}>
                    <div className={styles.smallNumber}>
                      <p>22</p>
                    </div>
                    <div className={styles.helperContainer}>
                      <p>Signatur und Stempel des Absenders</p>
                      <p>Signature and stamp of the sender</p>
                    </div>
                  </div>
                  <div
                    className={styles.column}
                    style={{
                      borderTop: handleTheme(3),
                      borderRight: handleTheme(3),
                      borderLeft: handleTheme(3)
                    }}
                  >
                    <div className={styles.smallNumber}>
                      <p>23</p>
                    </div>
                    <div className={styles.helperContainer}>
                      <p>Unterschrift und Stempel des Frachtfuhrers</p>
                      <p>Signature and stamp of the carrier</p>
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.columnContainer}>
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>24</p>
                        </div>
                        <div>
                          <p>{`Gut empfangen`} </p>
                          <p>{`Goods received`} </p>
                        </div>
                      </div>
                      <div className={styles.helperRow}>
                        <p>Ort</p>
                        <p>am</p>
                      </div>
                      <div className={styles.helperRowBottom}>
                        <p>Place</p>
                        <p>on</p>
                      </div>
                      <div className={styles.helperContainerPadding}>
                        <p>Unterschrift und Stempel des Empfangers</p>
                        <p>Signature and stamp of the consignee</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.asideContainerRight}>
            <div className={styles.asideRight_1}>
              <p>{`"Bei gefahrlichen Gutem sind neben der moglichen Zertifizierung in der letzten Zelie der Spalte die Angaben"`}</p>
              <p>{`"In case of dangerous goods mention, besides the possible certification, on the last line of the column the particulars of the class, the UN number and the packing group, if needed."`}</p>
            </div>
          </div>
        </div>
        <div
          className={`${styles.footer} ${!isPC ? styles.footerResponsive : ''}`}
          style={{ backgroundColor: props.theme === 'blackCopy' ? 'black' : props.theme }}
        >
          <p>
            Das CMR/RU/Polen-Modell von 1976 fur den internationalen Strasenverkehr entspricht den Regelungen der
            Internationalen Strasenverkehrsunion/IRU/.
          </p>
          <p>
            The 1976 CMR/IRU/Poland model for international road transport compiles with the rules of the international
            Road Transport Union/IRU/.
          </p>
        </div>
      </div>
    </>
  );
};

export default CmrGenerator;
