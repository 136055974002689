import * as React from 'react';

const OdtIconSvg = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.41146 2H14.4678L19.9987 7.128V20.961C19.999 21.0974 19.9717 21.2326 19.9183 21.3587C19.865 21.4848 19.7867 21.5994 19.688 21.6959C19.5892 21.7924 19.4719 21.869 19.3428 21.9212C19.2137 21.9733 19.0753 22.0001 18.9355 22H5.41146C5.13048 21.9984 4.86159 21.8882 4.66366 21.6934C4.46574 21.4987 4.35492 21.2353 4.35547 20.961V3.039C4.35492 2.76466 4.46574 2.50129 4.66366 2.30655C4.86159 2.11182 5.13048 2.00159 5.41146 2'
      fill='white'
    />
    <path
      d='M5.41249 22C5.13132 21.9987 4.86217 21.8886 4.66403 21.6938C4.46589 21.499 4.35493 21.2355 4.35547 20.961V3.038C4.35493 2.76358 4.46592 2.50016 4.66409 2.30556C4.86226 2.11095 5.13142 2.00106 5.41249 2H14.4688L19.9997 7.127V20.96C20 21.0966 19.9726 21.232 19.9192 21.3583C19.8658 21.4846 19.7873 21.5994 19.6883 21.696C19.5894 21.7926 19.4718 21.8692 19.3425 21.9214C19.2131 21.9735 19.0745 22.0003 18.9345 22H5.41249ZM5.28651 3.038V20.96C5.28637 20.9761 5.2895 20.9921 5.29572 21.007C5.30194 21.0219 5.31112 21.0355 5.32274 21.0469C5.33436 21.0583 5.34818 21.0674 5.36341 21.0736C5.37863 21.0798 5.39497 21.083 5.41146 21.083H18.9314C18.9646 21.083 18.9964 21.0701 19.0198 21.0473C19.0432 21.0244 19.0564 20.9934 19.0564 20.961L19.0615 8.089H14.053C13.9287 8.08847 13.8096 8.03988 13.7219 7.95386C13.6342 7.86783 13.5849 7.75139 13.5849 7.63V2.914H5.41249C5.37898 2.91426 5.34694 2.92744 5.32334 2.95067C5.29974 2.9739 5.2865 3.00529 5.28651 3.038V3.038ZM14.5241 7.171H18.6825L14.5241 3.313V7.171Z'
      fill='#8F99A2'
    />
    <path
      d='M3.31513 11.372H15.1502C15.3226 11.3716 15.4934 11.4044 15.6528 11.4685C15.8122 11.5325 15.9571 11.6267 16.0793 11.7454C16.2014 11.8642 16.2983 12.0053 16.3646 12.1607C16.4308 12.3161 16.4651 12.4827 16.4654 12.651V17.766C16.465 17.9342 16.4306 18.1007 16.3643 18.256C16.298 18.4113 16.201 18.5523 16.0789 18.6709C15.9568 18.7896 15.8119 18.8836 15.6526 18.9477C15.4932 19.0117 15.3225 19.0444 15.1502 19.044H3.31513C3.14282 19.0444 2.97213 19.0117 2.81279 18.9477C2.65345 18.8836 2.50858 18.7896 2.38646 18.6709C2.26434 18.5523 2.16736 18.4113 2.10105 18.256C2.03474 18.1007 2.0004 17.9342 2 17.766V12.651C2.00027 12.4827 2.03451 12.3161 2.10076 12.1607C2.167 12.0053 2.26397 11.8642 2.3861 11.7454C2.50824 11.6267 2.65315 11.5325 2.81255 11.4685C2.97196 11.4044 3.14274 11.3716 3.31513 11.372Z'
      fill='#209BE6'
    />
    <path
      d='M10.5227 15.214C10.5338 15.4165 10.4995 15.6188 10.4222 15.8069C10.3448 15.995 10.2262 16.1644 10.0748 16.3031C9.92339 16.4418 9.74275 16.5465 9.54553 16.6099C9.34831 16.6732 9.13928 16.6938 8.93308 16.67H8V13.748H8.93308C9.13987 13.7257 9.34916 13.7475 9.54642 13.8121C9.74369 13.8766 9.92421 13.9823 10.0755 14.1218C10.2267 14.2613 10.345 14.4312 10.4223 14.6198C10.4995 14.8084 10.5338 15.0112 10.5227 15.214M8.93308 16.358C9.09239 16.3768 9.25396 16.3602 9.40581 16.3096C9.55767 16.259 9.69589 16.1757 9.81023 16.0657C9.92457 15.9558 10.0121 15.8222 10.0663 15.6748C10.1205 15.5273 10.1399 15.3698 10.1233 15.214C10.1399 15.0576 10.1205 14.8995 10.0665 14.7514C10.0125 14.6032 9.92532 14.4687 9.81123 14.3577C9.69714 14.2467 9.55907 14.162 9.40714 14.1098C9.2552 14.0576 9.09323 14.0392 8.93308 14.056H8.39126V16.356L8.93308 16.358Z'
      fill='white'
    />
    <path d='M11.1761 14.1288V13.7509H13.4276V14.1288H12.5199V16.66H12.0824V14.1288H11.1761Z' fill='white' />
    <path
      d='M5.55582 16.701C5.25214 16.706 4.95381 16.6226 4.69882 16.4615C4.44383 16.3004 4.24372 16.0688 4.12396 15.7963C4.00421 15.5238 3.97023 15.2226 4.02635 14.9312C4.08247 14.6397 4.22615 14.3712 4.4391 14.1598C4.65205 13.9483 4.92463 13.8035 5.22214 13.7438C5.51965 13.6841 5.82863 13.7122 6.10974 13.8245C6.39084 13.9368 6.63136 14.1283 6.80066 14.3745C6.96996 14.6207 7.06039 14.9105 7.06043 15.207C7.06757 15.403 7.03372 15.5983 6.96096 15.781C6.8882 15.9638 6.77806 16.1302 6.63726 16.27C6.49645 16.4098 6.32794 16.5201 6.142 16.5942C5.95607 16.6683 5.75662 16.7047 5.55582 16.701M5.55582 16.37C5.70894 16.372 5.86078 16.3426 6.00154 16.2837C6.14229 16.2248 6.26883 16.1378 6.37298 16.0282C6.47713 15.9186 6.55658 15.7889 6.60618 15.6474C6.65579 15.506 6.67446 15.3559 6.66098 15.207C6.67328 15.0581 6.65385 14.9083 6.60392 14.767C6.55398 14.6258 6.47463 14.4962 6.37085 14.3864C6.26708 14.2765 6.14113 14.1889 6.00094 14.129C5.86076 14.069 5.70938 14.0381 5.55633 14.0381C5.40329 14.0381 5.25191 14.069 5.11173 14.129C4.97154 14.1889 4.84559 14.2765 4.74182 14.3864C4.63804 14.4962 4.55869 14.6258 4.50875 14.767C4.45882 14.9083 4.43939 15.0581 4.45169 15.207C4.43806 15.3559 4.45659 15.5059 4.50607 15.6473C4.55556 15.7888 4.63491 15.9185 4.73898 16.0281C4.84305 16.1378 4.96953 16.2248 5.11024 16.2837C5.25094 16.3426 5.40274 16.372 5.55582 16.37'
      fill='white'
    />
  </svg>
);

export default OdtIconSvg;
