import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { exceptionRoutes } from '../../constants/paths';

// Containers
import NotFoundPage from './NotFoundPage/NotFoundPage';
import { withRouter } from '../../assets/helpers/withRouter';

const ExceptionRoutesTopComponent = () => {
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
      <Routes>
        <Route path={exceptionRoutes.NOT_FOUND_PAGE} element={<NotFoundPage />} />

        {/* redirect to some page if no match found */}
        <Route path='*' element={<Navigate to={exceptionRoutes.NOT_FOUND_PAGE} />} />
      </Routes>
    </div>
  );
};

export default withRouter(ExceptionRoutesTopComponent);
