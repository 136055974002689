import {
  SET_WAREHOUSE_OFFERS_FILTER,
  CLEAR_WAREHOUSE_OFFERS_FILTER,
  CLEAR_WAREHOUSE_OFFERS_FILTER_LOCATION,
  CLEAR_WAREHOUSE_OFFERS_FILTER_STORAGE,
  CLEAR_WAREHOUSE_OFFERS_FILTER_SERVICES,
  CLEAR_WAREHOUSE_OFFERS_FILTER_REQUESTER,
  CLEAR_WAREHOUSE_OFFERS_FILTER_OFFERS
} from '../../../../constants/actions';

const initialState = {
  filters: []
};

export default function reducer(state = initialState, action = {}) {
  let filters = [];
  let selectedFilter = {};
  let selectedIndex = '';

  switch (action.type) {
    case SET_WAREHOUSE_OFFERS_FILTER:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        filters.push(action.filter);
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex] = action.filter;
      }

      return {
        ...state,
        filters: filters
      };

    case CLEAR_WAREHOUSE_OFFERS_FILTER:
      filters = [...state.filters];
      selectedFilter = filters.filter(
        (filter) => Number(filter.userId) === action.userId && Number(filter.companyId) === action.companyId
      )[0];

      return {
        ...state,
        filters: filters.filter((filter) => {
          return (
            Number(filter.userId) !== Number(selectedFilter?.userId) ||
            Number(filter.companyId) !== Number(selectedFilter?.companyId)
          );
        })
      };
    case CLEAR_WAREHOUSE_OFFERS_FILTER_REQUESTER:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.origin_country = '';
        filters[selectedIndex].data.main_filter.rating = null;
        filters[selectedIndex].data.main_filter.origin_country = '';
        filters[selectedIndex].data.main_filter.business_type = '';

        return {
          ...state,
          filters: filters
        };
      }

    case CLEAR_WAREHOUSE_OFFERS_FILTER_LOCATION:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.main_filter.available_from = {
          date_from: null
        };
        filters[selectedIndex].data.main_filter.location = [
          {
            location_field: null,
            location_country: '',
            location_city: '',
            location_region: '',
            postal_code_location: '',
            location_street: '',
            location_radius: false,
            location_radius_value: 10,
            location_lat: '',
            location_lon: ''
          }
        ];
        return {
          ...state,
          filters: filters
        };
      }

    case CLEAR_WAREHOUSE_OFFERS_FILTER_STORAGE:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.main_filter.warehouse_category = 0;
        filters[selectedIndex].data.main_filter.warehouse_storage_type = [];
        filters[selectedIndex].data.main_filter.warehouse_equipment = [];
        filters[selectedIndex].data.main_filter.storage_size = '';
        filters[selectedIndex].data.main_filter.storage_unit = 4;

        return {
          ...state,
          filters: [filters]
        };
      }

    case CLEAR_WAREHOUSE_OFFERS_FILTER_SERVICES:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.main_filter.hazardous_storage = '';
        filters[selectedIndex].data.main_filter.temp_control = [];
        filters[selectedIndex].data.main_filter.professional_staff = '';

        return {
          ...state,
          filters: [filters]
        };
      }

    case CLEAR_WAREHOUSE_OFFERS_FILTER_OFFERS:
      filters = [...state.filters];
      selectedIndex = filters.findIndex((el) => {
        return el.userId === action.filter.userId && el.companyId === action.filter.companyId;
      });

      if (!filters[selectedIndex]) {
        return {
          ...state,
          filters
        };
      } else {
        filters[selectedIndex].data.main_filter.show_my_offers = '0';
        filters[selectedIndex].data.main_filter.show_company_offers = '1';

        return {
          ...state,
          filters: filters
        };
      }

    default:
      return state;
  }
}
