import * as React from 'react';

const RightToErasureSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <circle
      cx={10.41}
      cy={13.54}
      r={2.96}
      style={{
        stroke: '#1a1a1a',
        strokeWidth: '1.6px',
        fill: 'none',
        strokeMiterlimit: 10
      }}
    />
    <path
      d='M9.81 19.04h1.07c3.31 0 6 2.69 6 6v3.09H3.81v-3.09c0-3.31 2.69-6 6-6Z'
      style={{
        strokeLinejoin: 'round',
        stroke: '#1a1a1a',
        strokeWidth: '1.6px',
        fill: 'none'
      }}
    />
    <text
      transform='translate(19.28 15.59)'
      style={{
        fill: '#231f20',
        fontFamily: 'Poppins-SemiBold,Poppins',
        fontSize: '13.14px',
        fontWeight: 600
      }}
    >
      <tspan x={0} y={0}>
        {'?'}
      </tspan>
    </text>
    <circle
      cx={22.35}
      cy={11.04}
      r={6.41}
      style={{
        fill: 'none',
        strokeMiterlimit: 10,
        stroke: '#231f20'
      }}
    />
  </svg>
);

export default RightToErasureSvg;
