import * as React from 'react';

function FreightForwarderSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} viewBox='0 0 24 24' {...props}>
      <path
        d='m 12.0001,5.77734 c -1.2307,0 -2.43369,0.36493 -3.45692,1.04864 C 7.51994,7.50968 6.72242,8.48146 6.25147,9.61842 5.78053,10.7554 5.65731,12.0065 5.89739,13.2135 c 0.24009,1.207 0.8327,2.3156 1.70289,3.1858 0.87019,0.8702 1.97889,1.4628 3.18592,1.7029 1.207,0.2401 2.458,0.1169 3.595,-0.354 1.137,-0.471 2.1087,-1.2685 2.7924,-2.2918 0.6838,-1.0232 1.0487,-2.2262 1.0487,-3.4568 0,-1.6503 -0.6556,-3.23292 -1.8225,-4.39981 C 15.2329,6.4329 13.6503,5.77734 12.0001,5.77734 Z m 4.7522,8.83166 h -1.4078 c -0.2662,0.8333 -0.6597,1.6204 -1.1667,2.3333 -0.3741,0.1631 -0.7655,0.2831 -1.1666,0.3578 0.7184,-0.7807 1.2714,-1.6987 1.6255,-2.6989 h -2.3022 v 2.7806 H 11.6773 V 14.609 H 9.36339 C 9.71705,15.6105 10.27,16.53 10.9889,17.3118 10.596,17.2379 10.2126,17.1205 9.84561,16.9618 9.33688,16.2429 8.94327,15.4491 8.67895,14.609 H 7.24783 C 7.1236,14.3824 7.01573,14.1471 6.92506,13.9051 H 8.48061 C 8.33485,13.2803 8.2592,12.6412 8.25506,11.9996 8.25527,11.4048 8.31784,10.8118 8.44172,10.2301 H 6.85117 C 6.93295,9.98881 7.03172,9.75359 7.14672,9.52623 H 8.62839 C 8.9064,8.6003 9.33755,7.72749 9.90395,6.94401 10.2631,6.79519 10.6373,6.68566 11.0201,6.61734 10.2464,7.44764 9.66257,8.43621 9.30895,9.51457 H 11.6773 V 6.55512 h 0.6572 v 2.97111 h 2.3683 c -0.353,-1.07662 -0.9354,-2.0638 -1.7072,-2.89333 0.3821,0.06339 0.7563,0.16769 1.1161,0.31111 0.5631,0.77782 0.9929,1.6439 1.2717,2.56278 h 1.4661 c 0.115,0.22736 0.2138,0.46258 0.2956,0.70391 h -1.5789 c 0.1265,0.5879 0.1904,1.1875 0.1905,1.7889 -0.002,0.6453 -0.0763,1.2884 -0.2216,1.9172 h 1.5555 c -0.0962,0.2385 -0.2093,0.4698 -0.3383,0.6922 z m -4.4217,-4.3785 v 3.6866 h 2.52 c 0.1645,-0.6261 0.252,-1.2699 0.2605,-1.9172 -0.0012,-0.5955 -0.0691,-1.189 -0.2022,-1.7694 z m -3.42227,1.7694 c 0.00218,0.6465 0.08314,1.2903 0.24111,1.9172 H 11.6694 V 10.2305 H 9.11444 C 8.98,10.8108 8.91087,11.4043 8.90833,11.9999 Z'
        style={{ fill: 'var(--secondary)' }}
      />
      <path
        d='m 18.666016,10.886719 2.222656,2.222656 0.353516,-0.353516 1.86914,-1.86914 z M 22,12 C 22,9.46775 21.0393,7.02979 19.3119,5.17824 17.5844,3.32668 15.2189,2.19943 12.6927,2.02402 10.1665,1.84861 7.66789,2.6381 5.70112,4.23314 3.73436,5.82817 2.44595,8.10996 2.09597,10.6179 c -0.34998,2.508 0.26453,5.0553 1.71949,7.1278 1.45497,2.0726 3.64202,3.5159 6.11971,4.0388 2.47773,0.5229 5.06143,0.0863 7.22983,-1.2216 2.1683,-1.3079 3.7597,-3.3897 4.453,-5.8252 l -1.8887,-0.5376 c -0.5571,1.9572 -1.836,3.6303 -3.5785,4.6813 -1.7426,1.0511 -3.819,1.402 -5.8102,0.9818 C 8.34948,19.443 6.59188,18.283 5.42262,16.6175 4.25337,14.9519 3.75952,12.9048 4.04078,10.8893 4.32204,8.87383 5.35744,7.04011 6.93801,5.75828 8.51857,4.47646 10.5266,3.84199 12.5567,3.98296 14.5868,4.12393 16.4878,5.02982 17.8761,6.5178 19.2643,8.00577 20.0363,9.96499 20.0363,12 Z'
        style={{ fill: 'var(--primary)' }}
      />
    </svg>
  );
}

export default FreightForwarderSvg;
