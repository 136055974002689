import { TOO_MANY_REQUESTS_MODAL_SHOW, TOO_MANY_REQUESTS_MODAL_HIDE } from '../../../constants/actions';

const initialState = {
  opened: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOO_MANY_REQUESTS_MODAL_SHOW:
      return {
        ...state,
        opened: state.opened + 1
      };
    case TOO_MANY_REQUESTS_MODAL_HIDE:
      return {
        ...state,
        opened: state.opened > 0 ? state.opened - state.opened : state.opened
      };
    default:
      return state;
  }
}
