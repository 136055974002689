import * as React from 'react';

const RightToObjProcessingSvg = (props) => (
  <svg id='Layer_31' data-name='Layer 31' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32.06 32' {...props}>
    <defs>
      <style>{'.cls-1{fill:none;stroke:#231f20;stroke-linecap:round;stroke-linejoin:round;stroke-width:.4px}'}</style>
    </defs>
    <path
      style={{
        fill: '#231f20'
      }}
      d='M14.58 23.86h2.24v6.03h-2.24z'
    />
    <path
      d='M23.59 5.3c-4.25-4.25-11.14-4.25-15.39 0s-4.25 11.14 0 15.39 11.14 4.24 15.39 0 4.25-11.14 0-15.39Zm-1.11 1.11a9.303 9.303 0 0 1 .53 12.58L9.9 5.88a9.303 9.303 0 0 1 12.58.53ZM9.32 19.57a9.293 9.293 0 0 1-.53-12.58L21.9 20.1a9.293 9.293 0 0 1-12.58-.53Z'
      style={{
        stroke: '#231f20',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '.4px'
      }}
    />
    <path
      className='cls-1'
      d='M8.79 6.99 21.9 20.1c-3.66 3.1-9.13 2.92-12.58-.53s-3.63-8.92-.53-12.58ZM23.01 18.99 9.9 5.88c3.66-3.1 9.14-2.91 12.58.53s3.63 8.92.53 12.58Z'
    />
    <path
      className='cls-1'
      d='M8.79 6.99 21.9 20.1c-3.66 3.1-9.13 2.92-12.58-.53s-3.63-8.92-.53-12.58ZM23.01 18.99 9.9 5.88c3.66-3.1 9.14-2.91 12.58.53s3.63 8.92.53 12.58Z'
    />
  </svg>
);

export default RightToObjProcessingSvg;
