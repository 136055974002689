import { useCallback } from 'react';
import logout from '../../store/actions/logout';
import setMiddlewareError from '../../store/actions/setMiddlewareError';
import setIsUserVerified from '../../store/actions/setIsUserVerified';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticatedRoutes, exceptionRoutes } from '../../constants/paths';
import { useDispatch } from 'react-redux';
import { getUserCompanies } from '../../store/actions/User/userCompanies';

const nonRedirect = [
  authenticatedRoutes.OFFERS_VEHICLE,
  authenticatedRoutes.OFFERS_CARGO,
  authenticatedRoutes.OFFERS_WAREHOUSE,
  exceptionRoutes.USER_VERIFICATION,
  exceptionRoutes.CONTACT_UNSUBSCRIBE
];

/**
 * Sets the global error
 *
 * @param {int} error
 * @param {object} navigate
 */

const useHandleGlobalErrors = () => {
  const location = useLocation();
  const parsedPathname = `/${location.pathname.split('/')[1]}`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleErrors = useCallback(
    (error) => {
      switch (error) {
        case 1000000:
          localStorage.setItem('__TIMED_OUT__', true); // eslint-disable-line
          dispatch(logout());
          dispatch(setMiddlewareError(null));
          break;
        case 1000001:
          dispatch(setMiddlewareError(null));
          dispatch(setIsUserVerified(false));
          if (!nonRedirect.includes(parsedPathname)) {
            navigate(authenticatedRoutes.DASHBOARD);
          }
          break;
        case 1000002:
          dispatch(getUserCompanies());
          navigate(authenticatedRoutes.MANAGE_COMPANIES);
          break;
        default:
          dispatch(setMiddlewareError(null));
          break;
      }
    },
    [dispatch, navigate, parsedPathname]
  );

  return handleErrors;
};

export default useHandleGlobalErrors;
