import * as React from 'react';

function ArrowDownSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={7.224} height={4.112} viewBox='0 0 7.224 4.112' {...props}>
      <path
        data-name='arrow down'
        d='M.708.707l2.9 2.9 2.9-2.9'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ArrowDownSvg;
