import {
  GET_USER_COMPANIES_REQUEST,
  GET_USER_COMPANIES_SUCCESS,
  GET_USER_COMPANIES_ERROR,
  SELECT_COMPANY,
  DESELECT_COMPANY
} from '../../../constants/actions';
import store from '../../store';
import SocketIO from '../../../assets/helpers/socket';
import api from '../../../assets/helpers/api';
import { userEndpoints } from '../../../constants/apiEndpoints';

function getUserCompaniesActionRequest() {
  return {
    type: GET_USER_COMPANIES_REQUEST
  };
}

function getUserCompaniesAction(data) {
  return {
    type: GET_USER_COMPANIES_SUCCESS,
    data: data.data
  };
}

function getUserCompaniesActionError(error) {
  return {
    type: GET_USER_COMPANIES_ERROR,
    error
  };
}

function selectCompanyAction(data) {
  return {
    type: SELECT_COMPANY,
    data: data
  };
}

function deselectCompanyAction() {
  return {
    type: DESELECT_COMPANY
  };
}

export function getUserCompanies() {
  const method = 'GET';
  const state = store.getState();
  const hData = {
    userId: state.session.userId,
    Ht: state.session.Ht,
    Et: state.session.Et,
    Ct: state.session.Ct,
    companyId: state.userCompanies?.selected?.id
  };

  return async (dispatch) => {
    dispatch(getUserCompaniesActionRequest());
    return api(userEndpoints.GET_USER_COMPANIES, null, method, hData)
      .then((response) => dispatch(getUserCompaniesAction(response)))
      .then((response) => {
        const activeCompanies = response.data.filter(
          (company) => company.company_status == '1' && company.status == '1'
        );
        const prevSelectedCompany = state.userCompanies?.selected;
        const newSelectedCompany =
          prevSelectedCompany && activeCompanies.find((company) => company.id == prevSelectedCompany?.id);
        if (newSelectedCompany) {
          dispatch(selectCompany(newSelectedCompany));
        } else {
          if (activeCompanies.length > 0) {
            dispatch(selectCompany(activeCompanies[0]));
          } else {
            dispatch(selectCompany(false));
          }
        }
      })
      .catch((err) => dispatch(getUserCompaniesActionError(err)));
  };
}

export function selectCompany(data) {
  return (dispatch) => {
    syncCompanyData(data);

    return dispatch(selectCompanyAction(data));
  };
}

export function deselectCompany() {
  return (dispatch) => {
    return dispatch(deselectCompanyAction());
  };
}

function syncCompanyData(data) {
  const state = store.getState();

  // change only if there is difference
  if (data.id != state.userCompanies?.selected?.id) {
    const socket = SocketIO.getInstance({ query: `Ht=${state.session.Ht}` });
    socket &&
      socket.emit('selected-company-changed', {
        notify_type: 'company_changed',
        notify_payload: {
          ht: state.session.Ht,
          data: data
        }
      });
  }
}
