import React, { useContext } from 'react';
import styles from './PasswordPreview.module.css';
import AppComponentContext from '../../../contexts/AppComponentContext';

const PasswordPreview = ({ show, ...restProps }) => {
  const localization = useContext(AppComponentContext).strings;
  return (
    <div className={styles.wrapper} {...restProps}>
      <span>{show ? localization.hide : localization.show}</span>
    </div>
  );
};

export default PasswordPreview;
