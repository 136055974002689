import { MESSAGE_RECEIVED } from '../../../constants/actions';

function setMessageReceived(message) {
  return {
    type: MESSAGE_RECEIVED,
    message: message
  };
}

export function messageReceived(message) {
  return (dispatch) => {
    return dispatch(setMessageReceived(message));
  };
}
