import {
  GET_NOTIFICATIONS_SUCCESS,
  LOAD_MORE_NOTIFICATIONS_SUCCESS,
  NOTIFICATION_MESSAGE_RECEIVED,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  NOTIFICATION_POPUP_DELETED,
  SET_NOTIFICATIONS_MENU_VISIBILITY,
  MARK_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  CHAT_MESSAGE_RECEIVED,
  NEW_CHAT_RECEIVED
} from '../../../constants/actions';

/**
 * notificationsMessages are for the notifications in the dropdown menu
 * popupNotifications are for notifications that get displayed on screen on receiving a new notification
 */
const initialState = {
  // popupCount keeps track of the index each time a new notification gets added
  // As multiple notifications can appear at once, each notification has to have a unique index which does not change
  popupCount: 0,
  totalCount: 0,
  remainingPageCount: 0,
  unreadCount: 0,
  notificationMessages: [],
  popupNotifications: [],
  menuIsVisible: false
};

export default function reducer(state = initialState, action = {}) {
  // Add index to notification if it's a popup message. You cannot create variables inside case
  let notification = {};
  let newPopupNotifications = [];
  let newNotifications = [];
  let currentRead = false;
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationMessages: [...action.response.notifications],
        remainingPageCount: Number(action.response.num_of_remaining_pages),
        unreadCount: Number(action.response.num_of_unread),
        popupCount: ++state.popupCount,
        totalCount: action.response.num_of_notifications
      };
    case LOAD_MORE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationMessages: [...state.notificationMessages, ...action.response.notifications],
        remainingPageCount: Number(action.response.num_of_remaining_pages),
        unreadCount: Number(action.response.num_of_unread),
        popupCount: ++state.popupCount,
        totalCount: action.response.num_of_notifications
      };
    case NOTIFICATION_MESSAGE_RECEIVED:
      notification = action && action.notification;
      notification.index = state.popupCount;

      // if menu is visible just add notification to menu, don't show popup
      newPopupNotifications = state.menuIsVisible
        ? state.popupNotifications
        : [...state.popupNotifications, notification];

      return {
        ...state,
        notificationMessages: [action.notification.notify_payload, ...state.notificationMessages],
        popupNotifications: newPopupNotifications,
        unreadCount: state.unreadCount + 1,
        popupCount: ++state.popupCount,
        totalCount: Number(state.totalCount) + 1
      };
    case DELETE_NOTIFICATION:
      notification = state.notificationMessages.find((notification) => notification.id == action.id);

      return {
        ...state,
        notificationMessages: state.notificationMessages.filter((notification) => notification.id != action.id),
        unreadCount: Number(notification.read) ? state.unreadCount : state.unreadCount - 1,
        totalCount: state.totalCount - 1
      };
    case NOTIFICATION_POPUP_DELETED:
      return {
        ...state,
        popupNotifications: state.popupNotifications.filter((notification) =>
          notification.chat_id
            ? notification.id != action.notificationIndex
            : notification.index != action.notificationIndex
        )
      };

    case MARK_NOTIFICATION_AS_READ:
      notification = state.notificationMessages.find((notification) => notification.id == action.id);
      currentRead = notification.read;

      notification.read = 1;

      newNotifications = [...state.notificationMessages];

      return {
        ...state,
        notificationMessages: newNotifications,
        unreadCount: Number(currentRead) ? state.unreadCount : state.unreadCount - 1
      };
    case MARK_ALL_NOTIFICATIONS_AS_READ:
      newNotifications = [...state.notificationMessages];

      for (const notification of newNotifications) {
        notification.read = 1;
      }

      return {
        ...state,
        notificationMessages: newNotifications,
        unreadCount: 0
      };
    case SET_NOTIFICATIONS_MENU_VISIBILITY:
      return {
        ...state,
        menuIsVisible: action.data
      };
    case DELETE_ALL_NOTIFICATIONS:
      return {
        ...initialState
      };
    case CHAT_MESSAGE_RECEIVED:
    case NEW_CHAT_RECEIVED:
      return {
        ...state,
        notificationMessages: [action.message.chat_id, ...state.notificationMessages],
        popupNotifications: [...state.popupNotifications, action.message],
        unreadCount: state.unreadCount,
        popupCount: ++state.popupCount,
        totalCount: Number(state.totalCount)
      };
    default:
      return state;
  }
}
