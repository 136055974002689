import { NEW_CHAT_RECEIVED } from '../../../constants/actions';

function setNewChatReceived(message) {
  return {
    type: NEW_CHAT_RECEIVED,
    message: message
  };
}

export function newChatReceived(message) {
  return (dispatch) => {
    return dispatch(setNewChatReceived(message));
  };
}
