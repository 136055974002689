import React from 'react';
import { connect } from 'react-redux';

import WholePageLoader from './WholePageLoader';

const WholePageLoaderWrapper = ({ wholePageLoaderVisible }) => {
  return wholePageLoaderVisible ? <WholePageLoader /> : null;
};

function mapStateToProps({ wholePageLoader }) {
  return {
    wholePageLoaderVisible: wholePageLoader.visible
  };
}

export default connect(mapStateToProps)(WholePageLoaderWrapper);
