import * as React from 'react';

const MemberSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.st1,.st7{fill:none;stroke-miterlimit:10}.st1{display:inline;stroke:#f15b27;stroke-width:.1}.st7{stroke:#000;stroke-width:1.6;stroke-linejoin:round}'
      }
    </style>
    <g id='Member'>
      <path className='st7' d='M20.32 21.71h-8.64v-2.33c0-2.21 1.79-4 4-4h.64c2.21 0 4 1.79 4 4v2.33z' />
      <circle className='st7' cx={16} cy={11.55} r={1.77} />
      <circle className='st7' cx={16} cy={16} r={13} />
    </g>
  </svg>
);

export default MemberSvg;
