import {
  SET_SIDE_BAR_STATE,
  SET_LOGIN_SIDE_BAR_STATE,
  SET_REGISTER_SIDE_BAR_STATE,
  SET_SIDE_BAR_STATE_REGULATED_BY_SCROLL
} from '../../../constants/actions';

const initialState = {
  opened: null,
  loginVisible: null,
  registerVisible: null,
  visibilityRegulatedByScroll: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SIDE_BAR_STATE:
      return {
        ...state,
        opened: action.state
      };
    case SET_LOGIN_SIDE_BAR_STATE:
      return {
        ...state,
        loginVisible: action.state
      };
    case SET_REGISTER_SIDE_BAR_STATE:
      return {
        ...state,
        registerVisible: action.state
      };
    case SET_SIDE_BAR_STATE_REGULATED_BY_SCROLL:
      return {
        ...state,
        visibilityRegulatedByScroll: action.state
      };
    default:
      return state;
  }
}
