import * as React from 'react';

function CrossSvg(props) {
  return (
    <svg {...props} width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.25202 16.5366C6.17214 16.6163 6.10875 16.7109 6.06551 16.8151C6.02226 16.9192 6 17.0309 6 17.1437C6 17.2565 6.02226 17.3682 6.06551 17.4724C6.10875 17.5766 6.17214 17.6712 6.25202 17.7508C6.41371 17.9105 6.63176 18 6.85895 18C7.08614 18 7.30418 17.9105 7.46588 17.7508L12 13.2154L16.5341 17.7508C16.6958 17.9105 16.9139 18 17.1411 18C17.3682 18 17.5863 17.9105 17.748 17.7508C17.8279 17.6712 17.8912 17.5766 17.9345 17.4724C17.9777 17.3682 18 17.2565 18 17.1437C18 17.0309 17.9777 16.9192 17.9345 16.8151C17.8912 16.7109 17.8279 16.6163 17.748 16.5366L13.2139 12.0012L17.748 7.46569C17.909 7.30468 17.9994 7.08629 17.9994 6.85858C17.9994 6.74583 17.9772 6.63419 17.934 6.53002C17.8909 6.42585 17.8277 6.3312 17.748 6.25147C17.6683 6.17175 17.5737 6.1085 17.4695 6.06536C17.3654 6.02221 17.2538 6 17.1411 6C16.9134 6 16.6951 6.09046 16.5341 6.25147L12 10.7869L7.46588 6.25147C7.30491 6.09046 7.08659 6 6.85895 6C6.6313 6 6.41298 6.09046 6.25202 6.25147C6.09105 6.41249 6.00062 6.63087 6.00062 6.85858C6.00062 7.08629 6.09105 7.30468 6.25202 7.46569L10.7861 12.0012L6.25202 16.5366Z' />
    </svg>
  );
}

export default CrossSvg;
