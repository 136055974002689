import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { utilsEndpoint } from '../constants/apiEndpoints';
import api from '../assets/helpers/api';
import { setOffline } from '../store/actions/internetConnection';
import { connectionStatus } from '../store/reducers/LogicalState/internetConnection';
import setMinVersion from '../store/actions/Socket/setMinVersion';

const CONNECTION_TEST_INTERVAL = 30000;

/**
 * @returns [isOffline, checkConnection]
 */
const useInternetConnection = () => {
  const dispatch = useDispatch();

  const status = useSelector(({ internetConnection }) => internetConnection.status);
  const isOffline = useMemo(() => status === connectionStatus.OFFLINE, [status]);

  const checkConnection = useCallback(
    async (data = {}) => {
      try {
        const versionData = await api(utilsEndpoint.GET_API_VERSION, data, 'GET', {}, 3000);

        const { min_front_version } = versionData?.data || {};

        if (min_front_version) dispatch(setMinVersion(min_front_version));
      } catch (error) {
        // console.error(error);
      }
    },

    [dispatch]
  );

  useEffect(() => {
    checkConnection();
  }, [checkConnection]);

  useEffect(() => {
    let interval = null;
    if (status === connectionStatus.OFFLINE) {
      interval = setInterval(() => {
        checkConnection();
      }, CONNECTION_TEST_INTERVAL);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [checkConnection, status]);

  useEffect(() => {
    window.addEventListener('offline', () => {
      dispatch(setOffline());
    });

    return () => {
      window.removeEventListener('offline', () => {
        dispatch(setOffline());
      });
    };
  }, [dispatch]);

  return [isOffline, checkConnection];
};

export default useInternetConnection;
