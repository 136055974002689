import {
  ADD_BACKEND_DATE_TIME_LISTENER,
  REMOVE_BACKEND_DATE_TIME_LISTENER,
  BACKEND_DATE_TIME_REQUEST,
  BACKEND_DATE_TIME_SUCCESS,
  BACKEND_DATE_TIME_FAILURE,
  UPDATE_BACKEND_DATE_TIME
} from '../../../constants/actions';

const initialState = {
  isFetching: null,
  error: null,
  timestamp: null,
  status: 'idle',
  numberOfListeners: 0,
  dateTime: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case BACKEND_DATE_TIME_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        timestamp: new Date().toUTCString(),
        status: 'fetching',
        dateTime: null
      };
    case BACKEND_DATE_TIME_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        timestamp: new Date().toUTCString(),
        status: 'success',
        dateTime: action.data
      };
    case BACKEND_DATE_TIME_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: new Date().toUTCString(),
        status: 'fail',
        dateTime: null
      };
    case ADD_BACKEND_DATE_TIME_LISTENER:
      return {
        ...state,
        numberOfListeners: state.numberOfListeners + 1
      };
    case REMOVE_BACKEND_DATE_TIME_LISTENER:
      return {
        ...state,
        numberOfListeners: state.numberOfListeners > 0 ? state.numberOfListeners - 1 : state.numberOfListeners
      };
    case UPDATE_BACKEND_DATE_TIME:
      return {
        ...state,
        dateTime: state.dateTime?.add(action.data, 'milliseconds')
      };
    default:
      return state;
  }
}
