import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const tabletWidth = 1024;
const mobileWidth = 430;

const screenResolutionBreakPoint = 1441; /* Also change in variables.css!!! */
const cardMinWidth = 320; /* Also change in variables.css!!! */
const bigColumnMinWidth = 360; /* Also change in variables.css!!! */
const smallColumnMinWidth = 320; /* Also change in variables.css!!! */
const navMenuMinWidthOpened = 210; /* Also change in variables.css!!! */
const navMenuMinWidthClosed = 52; /* Also change in variables.css!!! */
const cardsGridGap = 15; /* Also change in variables.css!!! */
const scrollWidth = 6; /* Also change in variables.css!!! */

export const useWindowDimensions = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isPC, setIsPC] = useState(true);
  const [columnCount, setColumnCount] = useState(4);
  const [maxColumnsOnScreen, setMaxColumnsOnScreen] = useState(4);
  const navBarOpened = useSelector(({ authNavBar }) => authNavBar.opened);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const elementMinWidth = window.innerWidth >= screenResolutionBreakPoint ? bigColumnMinWidth : smallColumnMinWidth;
      let navBarMinWidth = navBarOpened ? navMenuMinWidthOpened : navMenuMinWidthClosed;
      if (!isPC) navBarMinWidth = 0;

      setMaxColumnsOnScreen(
        Math.floor((width - navBarMinWidth - cardsGridGap - scrollWidth) / (cardMinWidth + cardsGridGap))
      );
      setColumnCount(Math.floor(width / elementMinWidth));

      if (width <= mobileWidth) {
        setIsMobile(true);
        setIsPC(false);
        setIsTablet(false);
      } else if (width <= tabletWidth) {
        setIsTablet(true);
        setIsPC(false);
        setIsMobile(false);
      } else {
        setIsPC(true);
        setIsTablet(false);
        setIsMobile(false);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [navBarOpened, isPC]);

  return { isPC, isTablet, isMobile, columnCount, maxColumnsOnScreen };
};
