import { SET_MIDDLEWARE_ERROR } from '../../constants/actions';

function setError(error) {
  return {
    type: SET_MIDDLEWARE_ERROR,
    error
  };
}

export default function setMiddlewareError(error) {
  return (dispatch) => {
    return dispatch(setError(error));
  };
}
