import * as React from 'react';

function SuccessFullCircleSvg(props) {
  return (
    <>
      <svg width='80' height='80' viewBox='0 0 80 80' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
        <rect width='80' height='80' rx='8' fill='#67B164' />
        <path
          d='M22.5 42L35.8672 53.3195C36.3041 53.6895 36.9619 53.6178 37.3089 53.1623L58.0002 26'
          stroke='white'
          fill='none'
          strokeWidth='6'
          strokeLinecap='round'
        />
      </svg>
    </>
  );
}

export default SuccessFullCircleSvg;
