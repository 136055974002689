import * as React from 'react';

const SubProcessorSvg = (props) => (
  <svg id='Layer_29' data-name='Layer 29' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>
        {
          '.cls-2,.cls-3{stroke-width:.8px}.cls-2,.cls-4{fill:none}.cls-2{stroke-linejoin:round;stroke:#231f20}.cls-3,.cls-4{stroke:#231f20;stroke-miterlimit:10}.cls-3{fill:#010101}.cls-4{stroke-width:.5px}.cls-6{fill:#231f20}'
        }
      </style>
    </defs>
    <path
      d='M26.15 13.76c-.29.08-.58.12-.85.12H14.58c-1.75 0-3.25-1.35-3.39-3.13a3.401 3.401 0 0 1 5.1-3.23 3.952 3.952 0 0 1 2.79-2.83c2.11-.57 4.28.68 4.85 2.78.01.03.01.06.02.09a3.314 3.314 0 0 1 4.53 2.15c.47 1.77-.57 3.58-2.33 4.05Z'
      style={{
        strokeLinejoin: 'round',
        fill: 'none',
        strokeWidth: '.8px',
        stroke: '#000'
      }}
    />
    <path className='cls-3' d='M15.72 13.93v2.35' />
    <circle className='cls-4' cx={15.72} cy={17.13} r={0.79} />
    <path className='cls-3' d='M19.6 14.11v3.51' />
    <circle className='cls-4' cx={19.6} cy={18.46} r={0.79} />
    <path className='cls-2' d='M23.58 13.89v3.99h2.35' />
    <circle
      cx={26.77}
      cy={17.88}
      r={0.79}
      style={{
        strokeWidth: '.5px',
        stroke: '#231f20',
        strokeLinejoin: 'round',
        fill: 'none'
      }}
    />
    <path className='cls-2' d='M2.45 24.13H17.2v3.65H2.45zM2.45 19.59H17.2v3.65H2.45z' />
    <circle className='cls-6' cx={4.78} cy={21.42} r={0.78} />
    <circle className='cls-6' cx={6.68} cy={21.42} r={0.78} />
    <circle className='cls-6' cx={8.58} cy={21.42} r={0.78} />
    <circle className='cls-6' cx={4.78} cy={26.15} r={0.78} />
    <circle className='cls-6' cx={6.68} cy={26.15} r={0.78} />
    <circle className='cls-6' cx={8.58} cy={26.15} r={0.78} />
  </svg>
);

export default SubProcessorSvg;
