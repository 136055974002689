import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as duration from 'dayjs/plugin/duration';
import * as utc from 'dayjs/plugin/utc';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addBackendDateTimeListener, removeBackendDateTimeListener } from '../store/actions/backendDateTime';
import AppComponentContext from '../contexts/AppComponentContext';

const START_COUNTDOWN_ANIMATION_AT = 300; // seconds
const START_COUNTDOWN_AT = 3600; // seconds

/**
 * @returns [text, countdownPercentageLeft, countdownStarted]
 */
const useCountdown = ({ expiresAt = '2023-10-03 12:01:09' }) => {
  const localization = useContext(AppComponentContext).strings;
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', { relativeTime: localization.relativeTime });
  dayjs.extend(utc);
  dayjs.extend(duration);
  const dispatch = useDispatch();
  const backendDateTime = useSelector(({ backendDateTime }) => backendDateTime.dateTime);
  const expiresAtUTC = dayjs.utc(expiresAt);

  const addedListener = useRef(false);

  const removeListener = useCallback(() => {
    if (addedListener.current) {
      addedListener.current = false;
      dispatch(removeBackendDateTimeListener());
    }
  }, [dispatch]);

  const [timeLeft, setTimeLeft] = useState(null);

  const endText = dayjs.duration({ minutes: Math.floor(timeLeft / 60), seconds: timeLeft % 60 }).format('mm:ss');

  const countdownStarted = timeLeft <= START_COUNTDOWN_AT;
  const animCountdownStarted = timeLeft <= START_COUNTDOWN_ANIMATION_AT;
  const animCountdownPercentageLeft = animCountdownStarted
    ? Math.floor((timeLeft / START_COUNTDOWN_ANIMATION_AT) * 100)
    : 0;
  const animCountdownEnded = timeLeft == 0;

  const text = useMemo(() => {
    if (timeLeft == null) return '. . .';

    return countdownStarted ? endText : expiresAtUTC.from(backendDateTime, true);
  }, [backendDateTime, countdownStarted, endText, expiresAtUTC, timeLeft]);

  useEffect(() => {
    addedListener.current = true;

    dispatch(addBackendDateTimeListener());

    return () => {
      removeListener();
    };
  }, [dispatch, removeListener]);

  useEffect(() => {
    if (backendDateTime) {
      const diff = expiresAtUTC.diff(backendDateTime, 'milliseconds');

      // -1000 (1sec) for better sync, tick rate is not exactly 1 sec, so we need to leave some space
      if (diff > -1000) {
        setTimeLeft(Math.ceil(diff / 1000));
      } else {
        removeListener();
      }
    } else {
      setTimeLeft(0);
    }
  }, [backendDateTime, expiresAtUTC, removeListener]);

  return [text, animCountdownStarted, animCountdownPercentageLeft, animCountdownEnded];
};

export default useCountdown;
