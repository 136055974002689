import * as React from 'react';
const PaymentRemainderSecondary = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      stroke='#EBB70E'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      fill='none'
      d='M15 19a3 3 0 0 1-6 0m3.72-13.997L11.256 5c-3.344-.008-6.247 2.709-6.27 6v3.79c0 .79-.1 1.561-.531 2.218l-.287.438C3.73 18.11 4.2 19 4.985 19h14.03c.785 0 1.254-.89.818-1.554l-.287-.438c-.43-.657-.531-1.429-.531-2.219v-3.788c-.04-3.292-2.95-5.99-6.294-5.998Z'
    />
    <path
      stroke='#EBB70E'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      fill='none'
      d='M12 2a2 2 0 0 1 2 2v1h-4V4a2 2 0 0 1 2-2Z'
    />
  </svg>
);
export default PaymentRemainderSecondary;
