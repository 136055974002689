import React from 'react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import styles from './Textarea.module.css';
import Label from '../../../UI/FormElements/Label/Label';
import ErrorMark from '../../ErrorMark/ErrorMark';

const Textarea = React.forwardRef(
  (
    { name, textareaDivClass, labelText, textareaClass, placeholder, onFocus, disabled, readOnly, onKeyDown, value },
    ref
  ) => {
    const {
      register,
      formState: { errors }
    } = useFormContext();
    const errorMessage = get(errors, name)?.message;
    const registerProps = readOnly ? {} : register(name);

    return (
      <div className={textareaDivClass ? [textareaDivClass, styles.textareaDiv].join(' ') : styles.textareaDiv}>
        {labelText && labelText.length > 0 && <Label className={styles.textareaLabel} label={labelText} />}
        <div className={styles.row}>
          <textarea
            ref={ref}
            name={name}
            {...registerProps}
            className={textareaClass ? [textareaClass, styles.textareaField].join(' ') : styles.textareaField}
            placeholder={placeholder}
            onFocus={onFocus}
            disabled={disabled}
            readOnly={readOnly}
            onKeyDown={onKeyDown}
            value={value}
            maxLength='250'
          />
          <ErrorMark errorMessage={errorMessage} />
        </div>
      </div>
    );
  }
);

Textarea.displayName = 'Textarea';

Textarea.defaultProps = {
  required: false,
  disabled: false,
  readOnly: false,
  onKeyDown: () => {}
};

Textarea.propTypes = {
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string
};

export default Textarea;
