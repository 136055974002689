import * as React from 'react';

function ErrorFullCircleSvg(props) {
  return (
    <svg width='96' height='96' {...props} viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='96' height='96' rx='6' fill='#F78079' />
      <path d='M23 21C30.5414 29.3199 48.028 48.5283 48.028 48.5283L23 75.5504' fill='#F78079' />
      <path
        d='M23 21C30.5414 29.3199 48.028 48.5283 48.028 48.5283L23 75.5504'
        stroke='white'
        fill='none'
        strokeWidth='10.2162'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M73.0278 21C65.4864 29.3199 47.9998 48.5283 47.9998 48.5283L73.0278 75.5504' fill='#F78079' />
      <path
        d='M73.0278 21C65.4864 29.3199 47.9998 48.5283 47.9998 48.5283L73.0278 75.5504'
        stroke='white'
        fill='none'
        strokeWidth='10.2162'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ErrorFullCircleSvg;
