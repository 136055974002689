import * as React from 'react';

const RightToLodgeSvg = (props) => (
  <svg id='Layer_37' data-name='Layer 37' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>{'.cls-4{fill:none;stroke:#010101;stroke-linejoin:round;stroke-linecap:round;stroke-width:1.5px}'}</style>
    </defs>
    <g id='Terms_of_use-2' data-name='Terms of use'>
      <path
        style={{
          strokeLinejoin: 'round',
          fill: 'none',
          stroke: '#010101',
          strokeWidth: '.8px'
        }}
        d='M8.33 16.77h5.2v5.06h-5.2z'
      />
      <path
        style={{
          strokeLinecap: 'round',
          strokeWidth: '1.6px',
          strokeLinejoin: 'round',
          fill: 'none',
          stroke: '#010101'
        }}
        d='M13.82 27.17H5.1V2.83h17.96v12.43'
      />
      <path
        style={{
          strokeMiterlimit: 10,
          strokeWidth: '.5px',
          fill: 'none',
          stroke: '#010101'
        }}
        d='m9.31 19.3 1.04 1.09 2.2-2.18'
      />
      <path className='cls-4' d='M8.95 6.43h10.94M8.95 8.85h10.94M8.95 11.26h10.94' />
    </g>
    <path
      style={{
        strokeWidth: '1.6px',
        strokeLinejoin: 'round',
        fill: 'none',
        stroke: '#010101'
      }}
      d='m22 17.61 3 5.19 3 5.2H16l3-5.2 3-5.19z'
    />
    <circle
      cx={22}
      cy={26.1}
      r={0.6}
      style={{
        fill: '#010101'
      }}
    />
    <path
      style={{
        fill: '#010101',
        stroke: '#010202',
        strokeWidth: '.25px',
        strokeLinejoin: 'round'
      }}
      d='M22.3 24.56h-.6l-.17-3.51h.94l-.17 3.51z'
    />
  </svg>
);

export default RightToLodgeSvg;
