import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styles from './WholePageLoader.module.css';
import LogoAnimatedSvg from '../../../assets/images/svg/LogoAnimatedSvg';

const WholePageLoader = () => {
  const loaderRoot = useMemo(() => document.getElementById('root'), []);
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    loaderRoot.appendChild(el);
    return () => {
      loaderRoot.removeChild(el);
    };
  }, [el, loaderRoot]);

  return createPortal(
    <div className={styles.ldsRing}>
      <LogoAnimatedSvg className={styles.logo} />
    </div>,
    el
  );
};

export const CardLoader = () => {
  return (
    <div className={styles.ldsRingCard}>
      <LogoAnimatedSvg className={styles.logoCard} />
    </div>
  );
};

export const ComponentLoader = () => {
  return (
    <div className={`${styles.ldsRingCard} ${styles.componentLoader}`}>
      <LogoAnimatedSvg className={styles.logoCard} />
    </div>
  );
};

export default WholePageLoader;
