import { USER_TYPING_IN_CHAT } from '../../../constants/actions';

function setTypingChatMessage(message) {
  return {
    type: USER_TYPING_IN_CHAT,
    message: message
  };
}

export function typingChatMessage(message) {
  return (dispatch) => {
    return dispatch(setTypingChatMessage(message));
  };
}
