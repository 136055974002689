import { SET_IS_USER_VERIFIED } from '../../constants/actions';

function setIsVerified(isVerified) {
  return {
    type: SET_IS_USER_VERIFIED,
    isVerified
  };
}

export default function setIsUserVerified(isVerified) {
  return (dispatch) => {
    return dispatch(setIsVerified(isVerified));
  };
}
