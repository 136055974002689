import { SET_NEW_USERS_TUNNEL } from '../../../constants/actions';

const initialState = {
  opened: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NEW_USERS_TUNNEL:
      return {
        ...state,
        opened: action.opened
      };
    default:
      return state;
  }
}
