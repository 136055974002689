import React, { useContext, useState } from 'react';
import AppComponentContext from '../../../contexts/AppComponentContext';
import styles from './CookiePolicy.module.css';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import CrossSvg from '../../../assets/images/svg/CrossSvg';
import LogoSvg from '../../../assets/images/svg/LogoSvg';
import CookieCollapsible from '../CookieCollapsible/CookieCollapsible';
import Button from '../Button/Button';

const CookieSettings = ({
  handleCloseModal,
  isModal = false,
  setShowCookiePolicy = () => {},
  setShowPrivacy = () => {}
}) => {
  const localization = useContext(AppComponentContext).strings;
  const { isPC, isMobile } = useWindowDimensions();
  const { statistic, marketing, unclassified, chat } = JSON.parse(localStorage?.getItem('cookies')) || {};
  const [toggleState, setToggleState] = useState({
    statistic: false,
    unclassified: false,
    necessary: false,
    marketing: false,
    chat: false
  });

  const necessaryCookiesUsed = [
    'CONSENT',
    'PHPSESSID',
    'CookieConsent',
    'user',
    'stripe.csrf',
    'site_sid',
    'site-auth',
    'recent-views',
    'private_machine_identifier',
    'merchant',
    'machine_identifier',
    'lang',
    'expanded-topics',
    'ev',
    'double_cmd_f_uses',
    'docs.prefs',
    'cookie-perms',
    'cid',
    '__stripe_sid',
    '__stripe_orig_props',
    '__stripe_mid'
  ];
  const statisticCookiesUsed = ['_ga_*', 'c', '_gcl_au', 'CONSENT', '_ga', '_ga_#', '_gat', '_gid', 'collect'];
  const marketingCookiesUsed = [
    'TESTCOOKIESENABLED',
    'nextld',
    'LAST_RESULT_ENTRY_KEY',
    'remote_sid',
    'VISITOR_INFO1_LIVE',
    'YSC',
    'LogsDatabaseV2:V921167ec||#L',
    'YtIdbMeta#databases',
    't.innertube::nextId',
    'requests',
    'yt.innertube::nextId',
    'yt-remote-cast-available',
    'yt-remote-cast-installed',
    'yt-remote-connected-devices',
    'yt-remote-device-id',
    'yt-remote-fast-check-period',
    'yt-remote-session-app',
    'yt-remote-session-name',
    '_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx'
  ];
  const unclassifiedCookiesUsed = [
    'LogsDatabaseV2:Vfbc728e9||#LogsRequestsStore',
    'nextld',
    'requests',
    '"ZLD"+ <@lsid@ >+"tabowner"',
    '"ZLDPERSONALIZE"',
    '"ZSIQ"+<@widgetcode@>+"blocked"',
    '"ZLD"+<@lsid@>+"avuid"',
    '<@avuid@>+"_accesstime”',
    // '<@chatId@>+"_sound”',
    // '<@chatId@>+"_translate”',
    '"ZLDTRIGGER"+<@lsid@>',
    '"ZLD"+<lsid@>+"WTIME"”',
    '"ZLD"+<@lsid@>+"WAITING”',
    '"ZLD"+<@lsid@>',
    'klevercargo_cookie_policy',
    'ServiceWorkerLogsDatabase#SWHealthLog',
    'TESTCOOKIESENABLED',
    'YtIdbMeta#databases',
    'klevercargo_cookie_policy',
    'isiframeenabled'
  ];

  const customerSupportCookies = [
    '<@chatId@>+"_sound”',
    '<@chatId@>+"_translate”',
    'LS_CSRF_TOKEN',
    '_zcsr_tmp',
    'uesign',
    'b3e783bb62'
  ];

  return (
    <>
      {isModal ? (
        <div
          className={[
            styles.settingsModal,
            !isPC && !isMobile ? styles.modalResponsiveTablet : isMobile ? styles.modalResponsive : ''
          ].join(' ')}
        >
          <div className={[styles.header, !isPC ? styles.headerRes : ''].join(' ')}>
            <span className={styles.modalHeader}>{localization.cookie}</span>
            <CrossSvg className={styles.svg} onClick={handleCloseModal} />
          </div>
          <div className={[styles.contentWrapper, isMobile ? styles.contentWrapperResponsive : ''].join(' ')}>
            <div>
              <LogoSvg className={styles.logo} />
            </div>
            <div>
              {localization.formatString(localization.cookieText, {
                cookiePolicy: (
                  <label className={styles.link} onClick={() => setShowCookiePolicy(true)}>
                    {localization.cookiePolicy}
                  </label>
                )
              })}
            </div>
            <div className={styles.formHeading}>
              <span className={styles.formHeader}>{localization.privacyPreferenceCenter}</span>
              <div>
                <label className={styles.headingLink} onClick={() => setShowPrivacy(true)}>
                  {localization.privacyPolicy}
                </label>
              </div>
            </div>
            <div className={styles.formInputs}>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.necessaryCookies}
                toggleName={'necessary'}
                setToggleState={setToggleState}
                toggleState={toggleState.necessary}
                alwaysActive
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.necesseryCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{necessaryCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.statisticCookies}
                toggleName={'statistic'}
                defaultValue={statistic}
                setToggleState={setToggleState}
                toggleState={toggleState.statistic}
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.statisticCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{statisticCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.marketingCookies}
                toggleName={'marketing'}
                defaultValue={marketing}
                setToggleState={setToggleState}
                toggleState={toggleState.marketing}
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.marketingCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{marketingCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.unclassifiedCookies}
                toggleName={'unclassified'}
                defaultValue={unclassified}
                setToggleState={setToggleState}
                toggleState={toggleState.unclassified}
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.unclassifiedCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{unclassifiedCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.chatCookies}
                toggleName={'chat'}
                defaultValue={chat}
                setToggleState={setToggleState}
                toggleState={toggleState.chat}
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.chatCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{unclassifiedCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
            </div>
            <div className={styles.modalButtons}>
              <Button buttonClassName={styles.button} name='button' buttonType='primary'>
                {localization.saveSettings}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={[styles.cookiePolicyWrapper, isMobile ? styles.cookiePolicyWrapperRes : ''].join(' ')}>
          <div className={[styles.contentWrapperDrawer, isMobile ? styles.contentWrapperResponsive : ''].join(' ')}>
            <div>
              <LogoSvg className={styles.logo} />
            </div>
            <div>
              {localization.formatString(localization.cookieText, {
                cookiePolicy: (
                  <label className={styles.link} onClick={() => setShowCookiePolicy(true)}>
                    {localization.cookiePolicy}
                  </label>
                )
              })}
            </div>
            <div className={styles.formHeading}>
              <span className={styles.formHeader}>{localization.privacyPreferenceCenter}</span>
              <div>
                <label className={styles.headingLink} onClick={() => setShowPrivacy(true)}>
                  {localization.privacyPolicy}
                </label>
              </div>
            </div>
            <div className={styles.formInputs}>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.necessaryCookies}
                toggleName={'necessary'}
                setToggleState={setToggleState}
                toggleState={toggleState.necessary}
                alwaysActive
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.necesseryCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{necessaryCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.statisticCookies}
                toggleName={'statistic'}
                defaultValue={statistic}
                setToggleState={setToggleState}
                toggleState={toggleState.statistic}
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.statisticCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{statisticCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.marketingCookies}
                toggleName={'marketing'}
                defaultValue={marketing}
                setToggleState={setToggleState}
                toggleState={toggleState.marketing}
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.marketingCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{marketingCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.unclassifiedCookies}
                toggleName={'unclassified'}
                defaultValue={unclassified}
                setToggleState={setToggleState}
                toggleState={toggleState.unclassified}
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.unclassifiedCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{unclassifiedCookiesUsed.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
              <CookieCollapsible
                className={styles.collapsibleWrapper}
                title={localization.chatCookies}
                toggleName={'chat'}
                defaultValue={chat}
                setToggleState={setToggleState}
                toggleState={toggleState.chat}
              >
                <div className={styles.subInfoContainer}>
                  <span>{localization.chatCookieText}</span>
                  <div className={styles.cookiesUsedWrapper}>
                    <span className={styles.usedCookiesHeader}>{localization.cookiesUsed}</span>
                    <div className={styles.cookiesArray}>{customerSupportCookies.join(', ')}</div>
                  </div>
                </div>
              </CookieCollapsible>
            </div>
            <div className={styles.modalButtons}>
              <Button buttonClassName={styles.button} name='button' buttonType='primary'>
                {localization.saveSettings}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieSettings;
