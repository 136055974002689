import React, { useContext, useEffect, useState } from 'react';
import styles from './FatalErrorView.module.css';
import { errorHandlingEndpoint } from '../../../constants/apiEndpoints';
import useAPI, { apiStatus } from '../../../hooks/useAPI';
import Textarea from '../FormElements/Textarea/Textarea';
import AppComponentContext from '../../../contexts/AppComponentContext';
import ActionInfo from '../ActionInfo/ActionInfo';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';

const defaultValues = {
  id: '',
  additional_info: ''
};

const FatalErrorView = ({ error }) => {
  const localization = useContext(AppComponentContext).strings;
  const [copiedText, setCopiedText] = useState(false);
  const navigate = useNavigate();

  const { isMobile } = useWindowDimensions();

  const [sendFrontError, frontError] = useAPI({
    url: errorHandlingEndpoint.SEND_FRONT_ERROR,
    method: 'POST'
  });

  const [postSendReport, sendMessageReport, sendMessageStatus] = useAPI({
    url: errorHandlingEndpoint.SEND_FRONT_ERROR_REPORT,
    method: 'POST'
  });

  const { id } = frontError || {};

  const isFetching = sendMessageStatus == apiStatus.FETCHING;

  const formMethods = useForm({
    defaultValues: defaultValues
  });

  const { handleSubmit, setValue, getValues } = formMethods;

  useEffect(() => {
    setValue('id', id);
  }, [id, setValue]);

  const onSubmit = (data) => {
    const preparedData = {
      id: data.id,
      additional_info: data.additional_info
    };
    postSendReport(preparedData);
  };

  const copyText = () => {
    const copiedValue = getValues('id');
    navigator.clipboard.writeText(copiedValue);
    if (copiedText !== '') {
      setCopiedText(true);
      setTimeout(() => {
        setCopiedText(false);
      }, 1000);
    }
  };

  useEffect(() => {
    sendFrontError({
      stack: error.stack,
      message: error.message,
      url: location?.href
    });
  }, [error.message, error.stack, sendFrontError]);

  return (
    <>
      <FormProvider {...formMethods}>
        <div className={styles.mainDiv}></div>
        <div className={styles.modal}>
          {sendMessageStatus !== apiStatus.IDLE && sendMessageStatus !== apiStatus.FETCHING ? (
            <>
              <div className={`${styles.modalContainer} ${isMobile ? styles.modalContainerMobile : ''}`}>
                <ActionInfo
                  message={localization.messageSent}
                  response={sendMessageReport}
                  apiStatus={sendMessageStatus}
                  btnText={localization.ok}
                  navigateBack
                  onClose={() => {
                    navigate('/dashboard');
                    window.location.reload();
                  }}
                />
              </div>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h1 className={styles.title}>Something went Wrong!</h1>
              <span className={styles.errId}>Error id </span>
              <div className={styles.report}>
                <div className={styles.fieldDiv} onClick={copyText}>
                  <span className={styles.idInput}>{id}</span>
                  {copiedText ? (
                    <div className={styles.copiedText}>
                      <p>Copied!</p>
                    </div>
                  ) : null}
                </div>
                <Textarea
                  name='additional_info'
                  placeholder={localization.explainIssue}
                  labelText={localization.sendReport}
                  textareaClass={styles.textarea}
                />
                <Button buttonType={'tertiary'} isFetching={isFetching} buttonClassName={styles.sendReport}>
                  <span>{localization.sendReport}</span>
                </Button>
                <Button
                  buttonType='secondary'
                  type='button'
                  onClick={() => {
                    navigate('/dashboard');
                    window.location.reload();
                  }}
                >
                  Go to dashboard
                </Button>
              </div>
            </form>
          )}
        </div>
      </FormProvider>
    </>
  );
};

export default FatalErrorView;
