import { SET_MIN_VERSION } from '../../../constants/actions';

const setMinVersionAction = (data) => {
  return {
    type: SET_MIN_VERSION,
    data: data
  };
};

export default function setMinVersion(data) {
  return (dispatch) => {
    return dispatch(setMinVersionAction(data));
  };
}
