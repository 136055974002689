import * as React from 'react';

function WarehouseCompTypeSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} stroke='none' viewBox='0 0 24 24' {...props}>
      <path
        d='M 12 3.1269531 C 11.682453 3.1269531 11.364457 3.227175 11.099609 3.4257812 L 3.5996094 9.0507812 C 3.2226931 9.3334834 3 9.7787499 3 10.25 L 3 21 L 5 21 L 5 10.5 L 12 5.25 L 19 10.5 L 19 21 L 21 21 L 21 10.25 C 21 9.7787499 20.777307 9.3334834 20.400391 9.0507812 L 12.900391 3.4257812 C 12.635543 3.227175 12.317547 3.1269531 12 3.1269531 z '
        style={{ fill: 'var(--secondary)' }}
        stroke='none'
      />
      <path
        d='m 7.5,13 h 9 c 0.277,0 0.5,0.223 0.5,0.5 v 1 c 0,0.277 -0.223,0.5 -0.5,0.5 h -9 C 7.223,15 7,14.777 7,14.5 v -1 C 7,13.223 7.223,13 7.5,13 Z m 0,3 h 9 c 0.277,0 0.5,0.223 0.5,0.5 v 1 c 0,0.277 -0.223,0.5 -0.5,0.5 h -9 C 7.223,18 7,17.777 7,17.5 v -1 C 7,16.223 7.223,16 7.5,16 Z m 0,3 h 9 c 0.277,0 0.5,0.223 0.5,0.5 v 1 c 0,0.277 -0.223,0.5 -0.5,0.5 h -9 C 7.223,21 7,20.777 7,20.5 v -1 C 7,19.223 7.223,19 7.5,19 Z'
        style={{ fill: 'var(--primary)' }}
        stroke='none'
      />
    </svg>
  );
}

export default WarehouseCompTypeSvg;
