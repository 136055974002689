import React, { useContext, useEffect, useState } from 'react';
import styles from './GlobalErrorModal.module.css';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../Button/Button';
import Input from '../FormElements/Input/Input';
import Textarea from '../FormElements/Textarea/Textarea';
import AppComponentContext from '../../../contexts/AppComponentContext';
import { FormProvider, useForm } from 'react-hook-form';
import ActionInfo from '../ActionInfo/ActionInfo';
import useAPI, { apiStatus } from '../../../hooks/useAPI';
import { errorHandlingEndpoint } from '../../../constants/apiEndpoints';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';

const defaultValues = {
  hash: '',
  additional_info: ''
};

Modal.setAppElement('body');

const GlobalErrorModal = React.forwardRef(
  (
    {
      isOpen = false,
      title,
      message,
      reverseButtons,
      isFetching,
      closeModal,
      redButtonText,
      blueButtonText,
      style = 'primary',
      error,
      deactivatedCompany = false,
      setDeactivatedCompModal
    },
    ref
  ) => {
    const localization = useContext(AppComponentContext).strings;
    const [copiedText, setCopiedText] = useState(false);
    const [postSendReport, sendMessageStatus, sendMessageReport] = useAPI({
      url: errorHandlingEndpoint.SEND_REPORT,
      method: 'POST'
    });

    const { isMobile } = useWindowDimensions();

    const getStyleClassName = () => {
      switch (style) {
        case 'primary':
          return styles.primary;
        case 'success':
          return styles.success;
        case 'danger':
          return styles.danger;
        default:
          return styles.primary;
      }
    };
    const formMethods = useForm({
      defaultValues: defaultValues
    });
    const { handleSubmit, setValue, getValues } = formMethods;
    useEffect(() => {
      if (error) {
        setValue('hash', error?.hash);
      }
    }, [error, setValue]);
    const styleClassName = getStyleClassName();

    const onSubmit = (data) => {
      const preparedData = {
        hash: data.hash,
        additional_info: data.additional_info
      };
      postSendReport(preparedData);
    };

    const copyText = () => {
      const copiedValue = getValues('hash');
      navigator.clipboard.writeText(copiedValue);
      if (copiedText !== '') {
        setCopiedText(true);
        setTimeout(() => {
          setCopiedText(false);
        }, 1000);
      }
    };

    return (
      <>
        {isOpen ? (
          <FormProvider {...formMethods}>
            <Modal
              ref={ref}
              className={`${styles.modal} ${isMobile ? styles.modalResponsive : ''}`}
              isOpen={isOpen}
              overlayClassName={styles.overlay}
            >
              {sendMessageReport !== apiStatus.IDLE && sendMessageReport !== apiStatus.FETCHING ? (
                <>
                  {deactivatedCompany ? null : (
                    <div className={`${styles.modalContainer} ${isMobile ? styles.modalContainerMobile : ''}`}>
                      <ActionInfo
                        message={localization.messageSent}
                        response={sendMessageReport}
                        apiStatus={sendMessageStatus}
                        btnText={localization.ok}
                        navigateBack
                        onClose={() => closeModal()}
                      />
                    </div>
                  )}
                </>
              ) : (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={`${styles.modalContainer} ${isMobile ? styles.modalContainerMobile : ''}`}
                >
                  <div className={`${styles.modalTitle} ${isMobile ? styles.modalTitleMobile : ''} ${styleClassName}`}>
                    <span>{title}</span>
                  </div>
                  <div className={styles.modalMessage}>
                    <span>{message}</span>
                  </div>
                  <div className={styles.formFields}>
                    {deactivatedCompany ? null : (
                      <>
                        <div onClick={() => copyText()}>
                          <Input
                            name='hash'
                            wrapperDivClassName={styles.fieldDiv}
                            label={localization.hash}
                            placeholder={localization.hash}
                            isDisabled={true}
                          />
                          {copiedText ? (
                            <div className={styles.copiedText}>
                              <p>Copied!</p>
                            </div>
                          ) : null}
                        </div>
                        <Textarea
                          name='additional_info'
                          placeholder={localization.explainIssue}
                          labelText={localization.sendReport}
                          textareaClass={styles.textarea}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className={`${isFetching || reverseButtons ? styles.modalButtons : styles.modalButtonsReversed} ${
                      isMobile ? styles.modalControlsMobile : ''
                    }`}
                  >
                    {deactivatedCompany ? (
                      <>
                        <Button
                          buttonType={`${style}Outlined`}
                          isFetching={isFetching}
                          buttonClassName={styles.modalButtonRed}
                          onClick={() => {
                            closeModal();
                            setDeactivatedCompModal(false);
                          }}
                          disableModalButton
                          disableModal
                        >
                          <span>{redButtonText}</span>
                        </Button>
                      </>
                    ) : (
                      <>
                        {!isFetching && (
                          <Button
                            buttonType={style}
                            isFetching={isFetching}
                            buttonClassName={`${styles.modalButtonBlue} ${styleClassName}`}
                            onClick={() => handleSubmit()}
                            disableModal
                          >
                            <span>{blueButtonText}</span>
                          </Button>
                        )}
                        <Button
                          buttonType={`${style}Outlined`}
                          isFetching={isFetching}
                          buttonClassName={styles.modalButtonRed}
                          onClick={() => closeModal()}
                          disableModalButton
                          disableModal
                        >
                          <span>{redButtonText}</span>
                        </Button>
                      </>
                    )}
                  </div>
                </form>
              )}
            </Modal>
          </FormProvider>
        ) : null}
      </>
    );
  }
);

GlobalErrorModal.defaultProps = {
  isOpen: false
};

GlobalErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  modalText: PropTypes.string,
  modalInput: PropTypes.bool,
  children: PropTypes.any,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  blueButtonText: PropTypes.string,
  redButtonText: PropTypes.string,
  reverseButtons: PropTypes.bool
};

GlobalErrorModal.displayName = 'GlobalErrorModal';

export default GlobalErrorModal;
