import {
  API_GET_WAREHOUSE_TYPES_REQUEST,
  API_GET_WAREHOUSE_TYPES_SUCCESS,
  API_GET_WAREHOUSE_TYPES_FAILURE
} from '../../../../constants/actions';

const initialState = {
  isFetching: null,
  error: null,
  timestamp: null,
  response: {},
  status: 'idle'
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_GET_WAREHOUSE_TYPES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        response: {},
        timestamp: new Date().toUTCString(),
        status: 'fetching'
      };
    case API_GET_WAREHOUSE_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        response: action.response,
        timestamp: new Date().toUTCString(),
        status: 'success'
      };
    case API_GET_WAREHOUSE_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        response: {},
        timestamp: new Date().toUTCString(),
        status: 'fail'
      };
    default:
      return state;
  }
}
