import * as React from 'react';

function CookiePolicyProviderSvg(props) {
  return (
    <svg {...props} width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29.1603 4.77002H2.82031V27.17H29.1603V4.77002Z'
        stroke='#005B9E'
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M6.01055 6.97001C6.25908 6.97001 6.46055 6.76854 6.46055 6.52001C6.46055 6.27148 6.25908 6.07001 6.01055 6.07001C5.76202 6.07001 5.56055 6.27148 5.56055 6.52001C5.56055 6.76854 5.76202 6.97001 6.01055 6.97001Z'
        fill='#005B9E'
      />
      <path
        d='M7.02031 6.97001C7.26884 6.97001 7.47031 6.76854 7.47031 6.52001C7.47031 6.27148 7.26884 6.07001 7.02031 6.07001C6.77178 6.07001 6.57031 6.27148 6.57031 6.52001C6.57031 6.76854 6.77178 6.97001 7.02031 6.97001Z'
        fill='#005B9E'
      />
      <path
        d='M5.01055 6.97001C5.25908 6.97001 5.46055 6.76854 5.46055 6.52001C5.46055 6.27148 5.25908 6.07001 5.01055 6.07001C4.76202 6.07001 4.56055 6.27148 4.56055 6.52001C4.56055 6.76854 4.76202 6.97001 5.01055 6.97001Z'
        fill='#005B9E'
      />
      <path d='M3.33984 7.98999H28.5698' stroke='#005B9E' strokeWidth='0.8' strokeMiterlimit='10' />
      <path
        d='M23.3398 12.06L22.4298 15.48L21.5098 18.89L19.5198 16.91L19.4798 15.92H18.5398L16.5098 13.89L19.9198 12.97L23.3398 12.06Z'
        stroke='#005B9E'
        strokeWidth='0.8'
        strokeMiterlimit='10'
      />
      <path
        d='M5.7793 18.64C5.7793 18.64 5.9693 18.99 6.3193 19.48'
        stroke='#005B9E'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M6.96875 20.3C8.09875 21.56 9.95875 22.94 12.2888 22.15C13.6788 21.68 15.8488 19.75 17.1488 18.52'
        stroke='#005B9E'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M17.5391 18.15C17.9791 17.72 18.2491 17.44 18.2491 17.44'
        stroke='#005B9E'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  );
}

export default CookiePolicyProviderSvg;
