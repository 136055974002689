import * as React from 'react';

const PdfIconSvg = (props) => (
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fill='#8F99A2'
      stroke='none'
      d='M5.41023 22C5.12901 21.9987 4.85981 21.8886 4.66164 21.6938C4.46346 21.499 4.35248 21.2355 4.35303 20.961V3.038C4.35248 2.76358 4.4635 2.50016 4.6617 2.30556C4.85991 2.11095 5.12911 2.00106 5.41023 2H14.4681L19.9999 7.127V20.96C20.0002 21.0963 19.973 21.2313 19.9198 21.3574C19.8666 21.4834 19.7885 21.598 19.6899 21.6946C19.5914 21.7911 19.4743 21.8678 19.3454 21.9202C19.2165 21.9726 19.0783 21.9997 18.9387 22H5.41023ZM5.28422 3.038V20.96C5.28409 20.976 5.28719 20.9919 5.29334 21.0068C5.2995 21.0216 5.30859 21.0351 5.3201 21.0466C5.33161 21.058 5.34531 21.0671 5.36043 21.0733C5.37554 21.0796 5.39176 21.0829 5.40818 21.083H18.9305C18.9636 21.083 18.9954 21.0701 19.0188 21.0473C19.0423 21.0244 19.0554 20.9934 19.0554 20.961L19.0606 8.089H14.0512C13.9268 8.08847 13.8077 8.03988 13.72 7.95386C13.6323 7.86784 13.583 7.75139 13.583 7.63V2.914H5.41023C5.37671 2.91426 5.34467 2.92744 5.32107 2.95067C5.29746 2.9739 5.28422 3.00529 5.28422 3.038ZM14.5234 7.171H18.6815L14.5224 3.313L14.5234 7.171Z'
    />
    <path
      fill='#f78079'
      stroke='none'
      d='M3.31535 11.375H15.1494C15.4973 11.3745 15.8313 11.5088 16.0779 11.7484C16.3245 11.988 16.4637 12.3133 16.4647 12.653V17.768C16.4637 18.1077 16.3245 18.433 16.0779 18.6726C15.8313 18.9123 15.4973 19.0465 15.1494 19.046H3.31535C2.96739 19.0465 2.63344 18.9123 2.38683 18.6726C2.14021 18.433 2.00109 18.1077 2 17.768V12.654C2.00081 12.3142 2.13982 11.9886 2.38647 11.7487C2.63311 11.5089 2.96722 11.3745 3.31535 11.375Z'
    />
    <path
      fill='white'
      stroke='none'
      d='m 11.2383,13.6484 h 1.7753 v 0.326 H 11.648 v 1.032 h 1.1085 v 0.326 H 11.648 v 1.381 h -0.4097 z m -0.536,1.5403 c 0.0115,0.2125 -0.0247,0.4248 -0.1061,0.6222 -0.0813,0.1974 -0.2059,0.375 -0.365,0.5205 -0.159,0.1454 -0.34872,0.2552 -0.55579,0.3216 -0.20707,0.0664 -0.42651,0.0878 -0.64294,0.0627 H 8.05518 v -3.066 h 0.97729 c 0.2172,-0.0237 0.43708,-9e-4 0.64436,0.0668 0.20727,0.0676 0.39697,0.1785 0.55587,0.325 0.1589,0.1465 0.2832,0.325 0.3643,0.5231 0.0811,0.1981 0.117,0.4111 0.1053,0.6241 z m -1.66778,1.2 c 0.1671,0.0197 0.3366,0.0024 0.49589,-0.0507 0.1593,-0.0531 0.30428,-0.1405 0.42418,-0.2558 0.11991,-0.1153 0.21161,-0.2555 0.26831,-0.4102 0.0568,-0.1547 0.077,-0.3199 0.0594,-0.4833 0.0175,-0.1641 -0.0028,-0.33 -0.0596,-0.4854 C 10.166,14.5479 10.0744,14.4068 9.95451,14.2905 9.83464,14.1741 9.68961,14.0855 9.53006,14.031 9.3705,13.9765 9.20048,13.9576 9.03247,13.9757 H 8.46494 v 2.409 z M 6.46139,15.4344 H 5.85801 v 1.279 H 5.44824 v -3.065 h 1.01315 c 0.73963,0 1.0869,0.395 1.0869,0.9 0,0.466 -0.30732,0.889 -1.0869,0.889 m 0,-0.331 c 0.46816,0 0.66689,-0.214 0.66689,-0.558 0,-0.344 -0.19873,-0.568 -0.66689,-0.568 H 5.85801 v 1.126 z'
    />
  </svg>
);

export default PdfIconSvg;
