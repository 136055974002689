import React, { useContext } from 'react';
import { connect } from 'react-redux';
import styles from './UserEmailNotVerified.module.css';
import AppComponentContext from '../../../contexts/AppComponentContext';
import config from '../../../constants/config';
import Button from '../Button/Button';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';

const UserEmailNotVerified = ({ setNotVerifiedUserEmail, name }) => {
  const localization = useContext(AppComponentContext)?.strings;
  const { isPC } = useWindowDimensions();
  return (
    <div className={[styles.mainWrapper, !isPC ? styles.wrapperResponsive : ''].join(' ')}>
      <div className={!isPC ? styles.resWrapper : ''}>
        <div>
          <img src={config.CDN_URL + 'public/email/VerifyMailIcon.png'} />
        </div>

        <div className={styles.info}>
          <p>
            {localization.dear} {name},
          </p>
          <p>{localization.thanksForGettingStarted}</p>
          <p>{localization.needMoreInfo}</p>
          <p>{localization.clickOnMailToVerify}</p>
        </div>
      </div>
      <Button buttonType={'primaryOutlined'} onClick={() => setNotVerifiedUserEmail(false)}>
        {localization.close}
      </Button>
    </div>
  );
};

const mapStateToProps = ({ userPersonalInfo }) => {
  return {
    name: userPersonalInfo.response.Fn
  };
};

export default connect(mapStateToProps)(UserEmailNotVerified);
