import React from 'react';
import styles from './ModalComponent.module.css';
import Modal from 'react-modal';
import Button from '../Button/Button';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import CloseSvg from '../../../assets/images/svg/CloseSvg';

Modal.setAppElement('body');

const ModalComponent = React.forwardRef(
  (
    {
      className,
      overlayClassName,
      isOpen = false,
      title,
      message,
      Message,
      reverseButtons,
      isFetching,
      isFetchingBlue = false,
      redFunction,
      redButtonText,
      blueFunction,
      blueButtonText,
      onlyBlueButton = false,
      style = 'primary',
      messageClassName = '',
      buttonsColumn = false,
      hasX = false,
      firstButtonType = false,
      additionalContent = false,
      topContent = false
    },
    ref
  ) => {
    const { isMobile } = useWindowDimensions();

    const getStyleClassName = () => {
      switch (style) {
        case 'primary':
          return styles.primary;
        case 'success':
          return styles.success;
        case 'danger':
          return styles.danger;
        case 'tertiary':
          return styles.tertiary;
        default:
          return styles.primary;
      }
    };

    const styleClassName = getStyleClassName();

    return (
      <>
        {isOpen ? (
          <Modal
            ref={ref}
            className={`${[styles.modal].join(' ')} ${isMobile ? styles.modalResponsive : ''}`}
            isOpen={isOpen}
            overlayClassName={`${styles.overlay} ${overlayClassName ? overlayClassName : null}`}
          >
            <div
              className={`${[styles.modalContainer, className ? className : ''].join(' ')} ${
                isMobile ? styles.modalContainerMobile : ''
              }`}
            >
              <div className={`${styles.modalTitle} ${styleClassName}`}>
                {title}
                {hasX ? <CloseSvg onClick={hasX} className={styles.closeSvg} /> : null}
              </div>
              {topContent ? <div className={styles.topContent}>{topContent}</div> : null}
              {message ? <div className={`${styles.modalMessage} ${messageClassName}`}>{message}</div> : null}
              {Message ? (
                <div className={`${styles.modalMessage} ${styles.secondModalMessage} ${messageClassName}`}>
                  {Message}
                </div>
              ) : null}
              <div
                className={`${isFetching || reverseButtons ? styles.modalButtons : styles.modalButtonsReversed} ${
                  isMobile ? styles.buttonControlsMobile : ''
                } ${buttonsColumn ? styles.buttonsColumnClass : ''}`}
              >
                {!onlyBlueButton ? (
                  <Button
                    buttonType={`${style}Outlined`}
                    isFetching={isFetching}
                    buttonClassName={styles.modalButtonRed}
                    onClick={() => redFunction()}
                    disableModalButton
                    disableModal
                    disabled={isFetchingBlue}
                  >
                    <span>{redButtonText}</span>
                  </Button>
                ) : null}
                <Button
                  buttonType={firstButtonType ? firstButtonType : style}
                  isFetching={isFetching || isFetchingBlue}
                  buttonClassName={`${styles.modalButtonBlue}`}
                  onClick={() => blueFunction()}
                  disableModal
                >
                  <span>{blueButtonText}</span>
                </Button>
              </div>
              {additionalContent ? <div className={styles.additionalContent}>{additionalContent}</div> : null}
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
);

ModalComponent.defaultProps = {
  isOpen: false
};

ModalComponent.displayName = 'ModalComponent';

export default ModalComponent;
