import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatPrice, removeUnderscoresAndCapitalize } from '../../../assets/helpers/common';
import { formatDateToYMD, parseDateDMY } from '../../../assets/helpers/dateTimeUtils';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import CircleAddSvg from '../../../assets/images/svg/CircleAddSvg';
import LogoSvg from '../../../assets/images/svg/LogoSvg';
import AppComponentContext from '../../../contexts/AppComponentContext';
import styles from './CmrGenerator.module.css';
import { useEffect } from 'react';

const CmrGeneratorAgreement = ({ downloadState, theme, agreementData, fields, remove, append, type }) => {
  const { setValue, register } = useFormContext() || {};
  const { isPC } = useWindowDimensions();
  const localization = useContext(AppComponentContext).strings;

  const generator_packages = useMemo(() => {
    return {
      name: '',
      consolidation_name: '',
      num_of_units: '',
      unit_weight: '',
      packaging_method: '',
      incoterms: ''
    };
  }, []);

  const [senderText, setSenderText] = useState('');
  const [consigneeText, setConsigneeText] = useState('');
  const [carrierText, setCarrierText] = useState('');

  const handleTheme = useCallback(
    (pixel) => {
      return `${pixel}px solid ${theme === 'blackCopy' ? 'black' : theme}`;
    },
    [theme]
  );

  const generatePickupPointAgreement = useMemo(() => {
    const { street_from, departure_date_to } = agreementData || {};
    return street_from ? `${street_from.split(/\r?\n/)[0]}\r\n${parseDateDMY(departure_date_to, localization)}` : '';
  }, [localization, agreementData]);

  const handleHeadingNumber = useMemo(() => {
    switch (theme) {
      case 'black':
        return '1';
      case 'red':
        return '2';
      case 'blue':
        return '3';
      case 'green':
        return '4';
      case 'blackCopy':
        return '5';
    }
  }, [theme]);

  const generateSenderTextInputCargo = useCallback(() => {
    const { c1_name, c1_postal_code, c1_address, c1_city, c1_region_name, c1_country, lat_from, lng_from } =
      agreementData || {};
    const text = `${c1_name}\r\n${c1_postal_code}, ${c1_address}, ${c1_city}${
      c1_region_name ? `,${c1_region_name}` : ''
    }, ${c1_country}\r\nLatitude:${lat_from}\r\nLongitude:${lng_from}`;
    setValue('cmr_template.company_name', text);
    return setSenderText(text);
  }, [agreementData, setValue]);

  const generateSenderTextInputVehicle = useCallback(() => {
    const { c2_name, c2_postal_code, c2_address, c2_city, c2_region_name, c2_country, lat_to, lng_to } =
      agreementData || {};
    const text = `${c2_name}\r\n${c2_postal_code}, ${c2_address}, ${c2_city}${
      c2_region_name ? `,${c2_region_name}` : ''
    }, ${c2_country}\r\nLatitude:${lat_to}\r\nLongitude:${lng_to}`;
    setValue('cmr_template.company_name', text);
    return setSenderText(text);
  }, [agreementData, setValue]);

  const generateConsigneeTextCargo = useCallback(() => {
    const { c1_name, c1_postal_code, c1_city, c1_country } = agreementData || {};
    const text = `${c1_name}\r\n${c1_postal_code}, ${c1_city}, ${c1_country}`;
    setValue('cmr_template.sender_info', text);

    return setConsigneeText(text);
  }, [agreementData, setValue]);

  const generateConsigneeTextVehicle = useCallback(() => {
    const { c2_name, c2_postal_code, c2_city, c2_country } = agreementData || {};
    const text = `${c2_name}\r\n${c2_postal_code}, ${c2_city}, ${c2_country}`;
    setValue('cmr_template.sender_info', text);

    return setConsigneeText(text);
  }, [agreementData, setValue]);

  const generateCarrierTextCargo = useCallback(() => {
    const { c2_name, c2_postal_code, c2_city, c2_country } = agreementData || {};
    const text = `${c2_name}\r\n${c2_postal_code}, ${c2_city}, ${c2_country}`;
    setValue('cmr_template.receiver_info', text);

    return setCarrierText(text);
  }, [agreementData, setValue]);

  const generateCarrierTextVehicle = useCallback(() => {
    const { c1_name, c1_postal_code, c1_city, c1_country } = agreementData || {};
    const text = `${c1_name}\r\n${c1_postal_code}, ${c1_city}, ${c1_country}`;
    setValue('cmr_template.receiver_info', text);

    return setCarrierText(text);
  }, [agreementData, setValue]);

  const addNewRow = useCallback(async () => {
    append({ ...generator_packages, consolidation_name: `Consolidation ${fields.length + 1}` });
  }, [generator_packages, append, fields.length]);

  const renderConsolidationNameFields = useMemo(() => {
    return fields.map((el, i) => (
      <div key={i} className={styles.consolidationField}>
        {i !== 0 && !downloadState ? <div className={styles.circleClose} onClick={() => remove(i)} /> : null}
        <textarea
          maxLength={20}
          placeholder='input text'
          className={styles.consolidationField}
          {...register(`cmr_template.generator_packages.${i}.consolidation_name`)}
          defaultValue={el.consolidation_name ? el.consolidation_name : `Consolidation ${i + 1}`}
        ></textarea>
      </div>
    ));
  }, [downloadState, fields, register, remove]);

  const renderNumberOfPackagesFields = useMemo(() => {
    return fields.map((el, i) => (
      <div key={i} className={styles.consolidationField}>
        <textarea
          maxLength={20}
          placeholder='input text'
          {...register(`cmr_template.generator_packages.${i}.num_of_units`)}
          defaultValue={el.num_of_units}
        ></textarea>
      </div>
    ));
  }, [fields, register]);

  const renderMethodOfPackagingFields = useMemo(() => {
    return fields.map((el, i) => (
      <div key={i} className={styles.consolidationField}>
        <textarea
          maxLength={20}
          placeholder='input text'
          {...register(`cmr_template.generator_packages.${i}.packaging_method`)}
          defaultValue={el.packaging_method}
        ></textarea>
      </div>
    ));
  }, [fields, register]);

  const renderNatureOfGoodsFields = useMemo(() => {
    return fields.map((el, i) => (
      <div key={i} className={styles.consolidationField}>
        <textarea
          maxLength={20}
          placeholder='input text'
          {...register(`cmr_template.generator_packages.${i}.name`)}
          defaultValue={el.name}
        ></textarea>
      </div>
    ));
  }, [fields, register]);

  const renderUnintWeightFields = useMemo(() => {
    return fields.map((el, i) => (
      <div key={i} className={styles.consolidationField}>
        <textarea
          maxLength={14}
          placeholder='input text'
          {...register(`cmr_template.generator_packages.${i}.unit_weight`)}
          defaultValue={el.unit_weight}
        ></textarea>
      </div>
    ));
  }, [fields, register]);

  const renderStatisticalNr = useMemo(() => {
    return fields.map((el, i) => (
      <div key={i} className={styles.consolidationField}>
        <textarea
          maxLength={14}
          placeholder='input text'
          {...register(`cmr_template.generator_packages.${i}.statistical_nr`)}
          defaultValue={el.statistical_nr}
        ></textarea>
      </div>
    ));
  }, [fields, register]);

  const renderVolumeInM3 = useMemo(() => {
    return fields.map((el, i) => (
      <div key={i} className={styles.consolidationField}>
        <textarea
          maxLength={14}
          placeholder='input text'
          {...register(`cmr_template.generator_packages.${i}.volume_l`)}
          defaultValue={el.volume_m3}
        ></textarea>
      </div>
    ));
  }, [fields, register]);

  const handleHeadingTitle = useMemo(() => {
    switch (theme) {
      case 'black':
        return (
          <>
            <p>Examplar fur den Absender</p>
            <p>Copy for sender</p>
          </>
        );
      case 'red':
        return (
          <>
            <p>Exemplar fur den Empfanger</p>
            <p>Copy for consignee</p>
          </>
        );
      case 'blue':
        return (
          <>
            <p>Examplar fur den Frachtfuhrer</p>
            <p>Copy for carrier</p>
          </>
        );
      case 'green':
      case 'blackCopy':
        return (
          <>
            <p>Examplar fur</p>
            <p>Copy for</p>
          </>
        );
    }
  }, [theme]);

  const handleHeadingInfo = useMemo(() => {
    return (
      <div className={styles.headingInfo}>
        <div className={styles.number}>
          <p>{handleHeadingNumber}</p>
        </div>
        <div className={styles.cmrType}>{handleHeadingTitle}</div>
      </div>
    );
  }, [handleHeadingNumber, handleHeadingTitle]);

  const handleUploadedDocs = useMemo(() => {
    let typeOfData;
    if (type == 2) {
      typeOfData = agreementData.offer_documents;
    } else {
      typeOfData = agreementData.bid_documents;
    }
    return typeOfData?.map(
      (el, i) => `${i + 1 < typeOfData.length && i !== 0 ? ',' : ''} ${removeUnderscoresAndCapitalize(el.type)}`
    );
  }, [agreementData.bid_documents, agreementData.offer_documents, type]);

  const handleSendersInstructions = useMemo(() => {
    const { adr, collective_transport, customs_clearing } = agreementData;
    return `Requested services: ${adr ? 'ADR' : ''}${adr ? ', ' : ''} ${
      collective_transport ? 'Collective transport' : ''
    }${adr || collective_transport ? ', ' : ''} ${(adr || collective_transport) && customs_clearing ? ', ' : ''} ${
      customs_clearing ? 'Customs clearing' : ''
    }`;
  }, [agreementData]);

  useEffect(() => {
    type == 2 ? generateSenderTextInputCargo() : generateSenderTextInputVehicle();
    type == 2 ? generateConsigneeTextCargo() : generateConsigneeTextVehicle();
    type == 2 ? generateCarrierTextCargo() : generateCarrierTextVehicle();
  }, [
    generateCarrierTextCargo,
    generateCarrierTextVehicle,
    generateConsigneeTextCargo,
    generateConsigneeTextVehicle,
    generateSenderTextInputCargo,
    generateSenderTextInputVehicle,
    type
  ]);

  return (
    <>
      <div className={`${styles.mainContainer} ${!isPC && !downloadState ? styles.mainContainerResponsive : ''}`}>
        <div
          className={`${styles.heading} ${!isPC ? styles.headingResponsive : ''}`}
          style={{ backgroundColor: theme === 'blackCopy' ? 'black' : theme }}
        >
          {handleHeadingInfo}
        </div>
        <div className={styles.formContainerWrapper}>
          <div className={styles.asideContainer}>
            <div className={styles.asideLeft_1}>
              <p>{`Auszfullen auf Verantwortung des Absenders`}</p>
              <p>{`Auszfullen auf Verantwortung des Absenders`}</p>
              <p>{`To be completed on sender's responsibility`}</p>
            </div>
            <div className={styles.asideLeft_3}>
              <div className={styles.asideBig}>
                <div className={styles.asideNumber}>1-15</div>
                <div className={styles.asideNumberText}>
                  <p>einschlieslicht</p>
                  <p>including</p>
                </div>
              </div>
            </div>
            <div className={styles.asideLeft_2}>
              <div className={styles.asideBig}>
                <div className={styles.asideNumber}>19+20+21+22</div>
                <div className={styles.asideNumberText}>
                  <p>Die mit fett gedruckten Linien eingerahmten Rubriken mussen</p>
                  <p>The spaces framed with heavy lines must be filled</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.formContainer} style={{ borderRight: handleTheme(1), borderLeft: handleTheme(1) }}>
            <div className={styles.firstSectionWrapper} style={{ borderBottom: handleTheme(1) }}>
              <div>
                <div className={styles.columnHigh}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>1</p>
                    </div>
                    <div>
                      <p>{`Absender (Name, Adresse, Land)`} </p>
                      <p>{`Sender (name, address, country)`} </p>
                    </div>
                  </div>
                  <textarea
                    maxLength={180}
                    {...register('cmr_template.company_name')}
                    defaultValue={senderText ? senderText : ''}
                    className={styles.inputHigh}
                    placeholder='input text'
                  ></textarea>
                </div>
                <div className={styles.columnHigh} style={{ borderBottom: handleTheme(1), borderTop: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>2</p>
                    </div>
                    <div>
                      <p>{`Empfanger (Name, Adresse, Land)`} </p>
                      <p>{`Consignee (name, address, country)`} </p>
                    </div>
                  </div>

                  <textarea
                    maxLength={180}
                    {...register('cmr_template.sender_info')}
                    defaultValue={consigneeText ? consigneeText : ''}
                    className={styles.inputHigh}
                    placeholder='input text'
                  ></textarea>
                </div>
                <div className={styles.columnLower}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>3</p>
                    </div>
                    <div>
                      <p>{`Auslieferot des Gutes (Ort, Land)`} </p>
                      <p>{`Place of delivery of the goods (place, country)`} </p>
                    </div>
                  </div>
                  <textarea
                    maxLength={180}
                    {...register('cmr_template.delivery_point')}
                    defaultValue={agreementData.street_to || agreementData.city_to}
                    className={styles.inputLower}
                    placeholder='input text'
                  ></textarea>
                </div>
                <div className={styles.columnLower} style={{ borderBottom: handleTheme(1), borderTop: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>4</p>
                    </div>
                    <div>
                      <p>{`Ort und Datum der Ubernahme des Gutes (Ort, Land, Datum)`} </p>
                      <p>{`Place and date of taking over the goods (place, country, date)`} </p>
                    </div>
                  </div>

                  <textarea
                    maxLength={180}
                    {...register('cmr_template.pickup_point')}
                    defaultValue={generatePickupPointAgreement}
                    className={styles.inputLower}
                    placeholder='input text'
                  ></textarea>
                </div>
                <div className={styles.columnLower}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>5</p>
                    </div>
                    <div>
                      <p>{`Belgefungte Dokumente`} </p>
                      <p>{`Documents attached`} </p>
                    </div>
                  </div>
                  <textarea
                    maxLength={180}
                    {...register('cmr_template.uploaded_documents')}
                    className={styles.inputLower}
                    placeholder='input text'
                    defaultValue={handleUploadedDocs}
                  ></textarea>
                </div>
              </div>
              <div>
                <div className={styles.columnShort} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.logoRowContainer}>
                    <div className={styles.logoColumnTitle}>
                      <p>INTERNATIONALER FRACHTBRIEF</p>
                      <p>INTERNATIONAL CONSIGNEE NOTE</p>
                    </div>
                    <div className={styles.logo}>
                      <p>№</p>
                      <div className={styles.cmrLogo}>CMR</div>
                    </div>
                  </div>
                  <div className={styles.logoRowContainer}>
                    <div className={styles.logoTextLeft}>
                      <p>{`Diese Beforderung unterliegt, unbeschadet andrea lautender Bestimmungen, dem Ubereinkommen uber der Vertag uber den internationalen Guterkrafverkehr(CMR)`}</p>
                    </div>
                    <div className={styles.logoTextLeft}>
                      <p>{`This carriage is subject, notwithstanding any clause to tje contray, to the Convention on the Contract for the international Carriage of goods by road(CMR)`}</p>
                    </div>
                  </div>
                </div>
                <div className={`${downloadState ? styles.downloadDisabledColor : ''}`}>
                  <div
                    className={styles.columnShort}
                    style={{
                      borderBottom: handleTheme(1),
                      borderTop: handleTheme(2),
                      borderLeft: handleTheme(2),
                      borderRight: handleTheme(2)
                    }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>16</p>
                      </div>
                      <div>
                        <p>{`Frachtfuhrer (Name, Adresse, Land)`} </p>
                        <p>{`Carrier (name, address, country)`} </p>
                      </div>
                    </div>
                    <textarea
                      {...register('cmr_template.receiver_info')}
                      placeholder={'input text'}
                      defaultValue={carrierText}
                    />
                  </div>
                  <div
                    className={styles.columnLowerShort}
                    style={{ borderLeft: handleTheme(2), borderRight: handleTheme(2) }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>17</p>
                      </div>
                      <div>
                        <p>{`Nachfolgender Frachtfuhrer (Name, Adresse, Land)`} </p>
                        <p>{`Successive carriers (name, address, country)`} </p>
                      </div>
                    </div>
                    <textarea
                      {...register('cmr_template.receiver_remarks')}
                      placeholder={'input text'}
                      defaultValue={agreementData?.vehicle_remarks}
                      maxLength={180}
                      className={styles.inputLower}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(2),
                      borderRight: handleTheme(2),
                      borderBottom: handleTheme(2),
                      borderTop: handleTheme(1),
                      minHeight: 152,
                      maxHeight: 152
                    }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>18</p>
                      </div>
                      <div>
                        <p>{`Vorbehalte und Bemerkungen der Frachtfuhrer`} </p>
                        <p>{`Carrier's reservations and observations`} </p>
                      </div>
                    </div>
                    <textarea
                      maxLength={180}
                      className={styles.inputLower}
                      placeholder='input text'
                      {...register('cmr_template.receiver_observation')}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.secondSectionWrapper}>
              <LogoSvg className={`${styles.cargoLogo} ${downloadState ? styles.downloadLogoShow : ''}`} />
              <div className={styles.leftSideContainer}>
                <div className={styles.topBlock} style={{ borderBottom: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>6</p>
                    </div>
                    <div className={styles.secondSectionContainer}>
                      <p>{`Kennzeichen u. Nummern`} </p>
                      <p>{`Marks and Nos`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        {renderConsolidationNameFields}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>7</p>
                    </div>
                    <div>
                      <p>{`Anzahl der Pakete`} </p>
                      <p>{`Number of packages`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        {renderNumberOfPackagesFields}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>8</p>
                    </div>
                    <div>
                      <p>{`Art der Verpackung`} </p>
                      <p>{`Method of packing`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        {renderMethodOfPackagingFields}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>9</p>
                    </div>
                    <div>
                      <p>{`Bezeichnung des Gutes`} </p>
                      <p>{`Nature of the goods`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        {renderNatureOfGoodsFields}
                      </div>
                    </div>
                  </div>
                  {!downloadState && fields.length < 6 ? (
                    <div className={styles.addButton} onClick={addNewRow}>
                      <CircleAddSvg />
                    </div>
                  ) : null}
                </div>

                <div className={styles.bottomBlock}>
                  <div className={styles.row_1}>
                    <p>UN-Nr</p>
                    <p>Ben. s. Nr.9</p>
                    <p>Gefahrzettelmuster-Nr.</p>
                    <p>Verp.-Grp.</p>
                  </div>
                  <div className={styles.row_2}>
                    <p>name s. nr.9</p>
                    <p>Hazard label sample no.</p>
                    <p>Pack. group</p>
                  </div>
                </div>
              </div>
              <div className={styles.rightSideContainer}>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>10</p>
                    </div>
                    <div>
                      <p>{`Statistiknr`} </p>
                      <p>{`Statistical nr.`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                    {renderStatisticalNr}
                  </div>
                </div>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>11</p>
                    </div>
                    <div>
                      <p>{`Bruttogew. kg`} </p>
                      <p>{`Gross weight kg`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                    {renderUnintWeightFields}
                  </div>
                </div>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>12</p>
                    </div>
                    <div>
                      <p>{`Volumen in m3`} </p>
                      <p>{`Volume in m3`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>{renderVolumeInM3}</div>
                </div>
              </div>
            </div>
            <div className={styles.thirdSectionWrapper}>
              <div
                className={styles.sendersInstructions}
                style={{ borderBottom: handleTheme(1), borderTop: handleTheme(1) }}
              >
                <div className={styles.sectionInfo}>
                  <div className={styles.smallNumber}>
                    <p>13</p>
                  </div>
                  <div>
                    <p>{`Anweisungen des Absenders (Zoll-, amtl. Behandungen, Sondervorschriften, etc)`} </p>
                    <p>{`Sender's instructions`} </p>
                  </div>
                </div>
                <textarea
                  maxLength={514}
                  {...register('cmr_template.cargo_description')}
                  className={styles.inputInstructions}
                  defaultValue={handleSendersInstructions}
                  placeholder='input text'
                ></textarea>
              </div>
              <div className={styles.rightSideTable}>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    borderLeft: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>19</p>
                    </div>
                    <div>
                      <p>{`Zu bezahlen von`} </p>
                      <p>{`To be paid by`} </p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(1),
                    borderTop: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div style={{ paddingLeft: '5px' }}>
                    <p>{`Absender`} </p>
                    <p>{`Sender`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div style={{ paddingLeft: '5px' }}>
                    <p>{`Wahrung`} </p>
                    <p>{`Currency`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    borderRight: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div style={{ paddingLeft: '5px' }}>
                    <p>{`Empfanger`} </p>
                    <p>{`Consignee`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(2)
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '2px',
                      lineHeight: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <p>{`Fracht`} </p>
                    <p>{`Carriage`} </p>
                    <p>{`Ermasgung`} </p>
                    <p>{`Reductions`} </p>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo}`}
                  style={{
                    borderBottom: handleTheme(1)
                  }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.sender.carriage')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.sender.reductions')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div className={styles.verticalLine} style={{ borderLeft: handleTheme(3) }} />
                  <div
                    className={styles.verticalBlock}
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                  >
                    <>
                      <textarea
                        {...register('cmr_template.currency.sender.carriage')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                      <textarea
                        {...register('cmr_template.currency.receiver.carriage')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                    </>
                  </div>
                  <div className={styles.verticalBlock}>
                    <div
                      style={{
                        borderLeft: handleTheme(1)
                      }}
                    >
                      <textarea
                        {...register('cmr_template.currency.sender.reductions')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                      <textarea
                        {...register('cmr_template.currency.receiver.reductions')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo}`}
                  style={{ borderBottom: handleTheme(1), borderRight: handleTheme(2) }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.receiver.carriage')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.receiver.reductions')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(2)
                  }}
                >
                  <div style={{ paddingLeft: '2px', lineHeight: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <p>{`Zwischensumme`} </p>
                    <p>{`Balance`} </p>
                    <p>{`Zuschlage`} </p>
                    <p>{`Supplement charges`} </p>
                    <p>{`Nebengebuhren`} </p>
                    <p>{`Additional charges`} </p>
                    <p>{`Sonstiges`} </p>
                    <p>{`Miscellaneous`} </p>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.sender.balance')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.sender.supplement_charges')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.sender.additional_charges')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.sender.miscellaneous')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  >
                    <>
                      <textarea
                        {...register('cmr_template.currency.sender.balance')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                      <textarea
                        {...register('cmr_template.currency.receiver.balance')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                    </>
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  >
                    <>
                      <textarea
                        {...register('cmr_template.currency.sender.supplement_charges')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                      <textarea
                        {...register('cmr_template.currency.receiver.supplement_charges')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                    </>
                  </div>
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  >
                    <>
                      <textarea
                        {...register('cmr_template.currency.sender.additional_charges')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                      <textarea
                        {...register('cmr_template.currency.receiver.additional_charges')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                    </>
                  </div>
                  <div
                    className={styles.verticalBlock}
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  >
                    <>
                      <textarea
                        {...register('cmr_template.currency.sender.miscellaneous')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                      <textarea
                        {...register('cmr_template.currency.receiver.miscellaneous')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                    </>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree}`}
                  style={{
                    borderBottom: handleTheme(1),
                    borderRight: handleTheme(2)
                  }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.receiver.balance')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.receiver.supplement_charges')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2),
                      borderBottom: handleTheme(1)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.receiver.additional_charges')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  >
                    <textarea
                      {...register('cmr_template.receiver.miscellaneous')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(2),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div style={{ paddingLeft: '2px', lineHeight: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <p>{`Gesamtbetrag`} </p>
                    <p>{`To be paid`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div>
                    <textarea
                      {...register('cmr_template.sender.to_be_paid')}
                      maxLength={9}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div className={styles.verticalBlock}>
                    <>
                      <textarea
                        {...register('cmr_template.currency.sender.to_be_paid')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                      <textarea
                        {...register('cmr_template.currency.receiver.to_be_paid')}
                        maxLength={4}
                        placeholder='Text'
                        style={{ width: '35px', fontSize: '9px', overflow: 'hidden' }}
                      />
                    </>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderRight: handleTheme(2),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div>
                    <textarea
                      {...register('cmr_template.receiver.to_be_paid')}
                      maxLength={9}
                      defaultValue={formatPrice(agreementData?.transport_price)}
                      placeholder='Text'
                      style={{ width: '70px', fontSize: '9px', overflow: 'hidden' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.fourthSectionWrapper}>
              <div className={styles.cashOnDelivery} style={{ borderBottom: handleTheme(1) }}>
                <div className={styles.sectionInfo}>
                  <div className={styles.smallNumber}>
                    <p>14</p>
                  </div>
                  <div>
                    <p>{`Ruckerstattung`} </p>
                    <p>{`Cash on delivery`} </p>
                  </div>
                  <textarea
                    maxLength={22}
                    {...register('cmr_template.cash_on_delivery')}
                    placeholder={'input text'}
                    onChange={(e) => {
                      setValue('cmr_template.cash_on_delivery', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.bottomWrapper}>
                <div className={styles.topSection}>
                  <div
                    className={styles.leftSideWrapper}
                    style={{ borderRight: handleTheme(1), borderBottom: handleTheme(1) }}
                  >
                    <div className={styles.topDiv} style={{ borderBottom: handleTheme(1) }}>
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>15</p>
                        </div>
                        <div>
                          <p>{`Frachtzahlungsanweisungen`} </p>
                          <p>{`Instruction as to payment carriage`} </p>
                          <p>Frei/Carriage paid</p>
                          <p>Unfrei/Carriage forward</p>
                        </div>
                        <textarea
                          maxLength={44}
                          {...register('cmr_template.incoterms')}
                          defaultValue={agreementData.incoterms}
                          placeholder='input text'
                          style={{ alignSelf: 'center' }}
                        ></textarea>
                      </div>
                    </div>
                    <div className={`${styles.bottomDiv} ${downloadState ? styles.downloadDisabledColor : ''}`}>
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>21</p>
                        </div>
                        <div>
                          <p>{`Ausgefertigt`} </p>
                          <p>{`Established in`} </p>
                        </div>
                      </div>
                      <textarea
                        defaultValue={parseDateDMY(
                          formatDateToYMD(agreementData.departure_date_from || agreementData.bid_departure_date_from),
                          localization
                        )}
                      ></textarea>
                      <div className={styles.additional}>
                        <p>am</p>
                        <p>on</p>
                      </div>
                      <textarea disabled></textarea>
                    </div>
                  </div>
                  <div className={styles.rightSideWrapper} style={{ borderBottom: handleTheme(1) }}>
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>20</p>
                      </div>
                      <div>
                        <p>{`Besondere Vereinbarungen`} </p>
                        <p>{`Special agreements`} </p>
                      </div>
                    </div>
                    <textarea
                      maxLength={100}
                      {...register('cmr_template.remarks')}
                      defaultValue={agreementData.cargo_remarks}
                      className={styles.inputSpecial}
                      placeholder='input text'
                    ></textarea>
                  </div>
                </div>
                <div className={styles.bottomSection}>
                  <div className={styles.column}>
                    <div className={styles.smallNumber}>
                      <p>22</p>
                    </div>
                    <div className={styles.helperContainer}>
                      <p>Signatur und Stempel des Absenders</p>
                      <p>Signature and stamp of the sender</p>
                    </div>
                  </div>
                  <div
                    className={styles.column}
                    style={{
                      borderTop: handleTheme(3),
                      borderRight: handleTheme(3),
                      borderLeft: handleTheme(3)
                    }}
                  >
                    <div className={styles.smallNumber}>
                      <p>23</p>
                    </div>
                    <div className={styles.helperContainer}>
                      <p>Unterschrift und Stempel des Frachtfuhrers</p>
                      <p>Signature and stamp of the carrier</p>
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.columnContainer}>
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>24</p>
                        </div>
                        <div>
                          <p>{`Gut empfangen`} </p>
                          <p>{`Goods received`} </p>
                        </div>
                      </div>
                      <div className={styles.helperRow}>
                        <p>Ort</p>
                        <p>am</p>
                      </div>
                      <div className={styles.helperRowBottom}>
                        <p>Place</p>
                        <p>on</p>
                      </div>
                      <div className={styles.helperContainerPadding}>
                        <p>Unterschrift und Stempel des Empfangers</p>
                        <p>Signature and stamp of the consignee</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.asideContainerRight}>
            <div className={styles.asideRight_1}>
              <p>{`"Bei gefahrlichen Gutem sind neben der moglichen Zertifizierung in der letzten Zelie der Spalte die Angaben"`}</p>
              <p>{`"In case of dangerous goods mention, besides the possible certification, on the last line of the column the particulars of the class, the UN number and the packing group, if needed."`}</p>
            </div>
          </div>
        </div>
        <div
          className={`${styles.footer} ${!isPC ? styles.footerResponsive : ''}`}
          style={{ backgroundColor: theme === 'blackCopy' ? 'black' : theme }}
        >
          <p>
            Das CMR/RU/Polen-Modell von 1976 fur den internationalen Strasenverkehr entspricht den Regelungen der
            Internationalen Strasenverkehrsunion/IRU/.
          </p>
          <p>
            The 1976 CMR/IRU/Poland model for international road transport compiles with the rules of the international
            Road Transport Union/IRU/.
          </p>
        </div>
      </div>
    </>
  );
};

export default CmrGeneratorAgreement;
