import * as React from 'react';

const ProcessingSvg = (props) => (
  <svg id='Layer_28' data-name='Layer 28' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>{'.cls-1-processing{stroke-width:1.6px;fill:none;stroke:#000;stroke-linejoin:round}'}</style>
    </defs>
    <circle className='cls-1-processing' cx={21.61} cy={21.26} r={3.91} />
    <path
      className='cls-1-processing'
      d='M22.2 17.3h-1.19l.21-1.46h.77l.21 1.46zM19.22 18.04l-.84.84-.88-1.18.54-.54 1.18.88zM17.64 20.67v1.19l-1.45-.21v-.77l1.45-.21zM18.38 23.65l.85.84-1.19.88-.54-.55.88-1.17zM21.02 25.23h1.19l-.22 1.45h-.77l-.2-1.45zM23.99 24.48l.85-.84.87 1.18-.54.55-1.18-.89zM25.57 21.85v-1.19l1.46.22v.77l-1.46.2zM24.83 18.88l-.84-.85 1.18-.87.54.54-.88 1.18z'
    />
    <path
      d='M10.53 6.32a4.943 4.943 0 0 1 6.99 0'
      style={{
        strokeLinecap: 'round',
        strokeWidth: '.8px',
        stroke: '#000',
        strokeLinejoin: 'round',
        fill: 'none'
      }}
    />
    <circle className='cls-1-processing' cx={14.03} cy={9.82} r={6.57} />
    <path
      transform='rotate(45 8.534 14.935)'
      style={{
        strokeWidth: '.8px',
        stroke: '#000',
        strokeLinejoin: 'round'
      }}
      d='M6.33 14.54h4.4v.79h-4.4z'
    />
    <path
      transform='rotate(45 5.822 17.571)'
      style={{
        strokeWidth: '.8px',
        stroke: '#000',
        strokeLinejoin: 'round',
        fill: 'none'
      }}
      d='M4.78 14.54h2.07v6.08H4.78z'
    />
  </svg>
);

export default ProcessingSvg;
