import api from '../../assets/helpers/api';
import { API_LOGIN_REQUEST, API_LOGIN_SUCCESS, API_LOGIN_FAILURE } from '../../constants/actions';
import { saveReduxStateToStorage } from '../../assets/helpers/utils';
import store from '../store';
import { userEndpoints } from '../../constants/apiEndpoints';

function loginRequest() {
  return { type: API_LOGIN_REQUEST };
}

function loginSuccess(response) {
  const state = store.getState();
  const stateToSave = {};
  const session = {
    loggedInStatus: true,
    userId: response.data.userId,
    Ht: response.data.Ht,
    Et: response.data.Et,
    Ct: response.data.Ct
  };
  stateToSave.session = session;
  stateToSave.authNavBar = state.authNavBar;
  stateToSave.language = state.language;
  // stateToSave.userCompanies = state.userCompanies;
  stateToSave.vehicleOffersFilter = state.vehicleOffersFilter;
  stateToSave.cargoOffersFilter = state.cargoOffersFilter;

  saveReduxStateToStorage(stateToSave);
  return {
    type: API_LOGIN_SUCCESS,
    response
  };
}

function loginError(error) {
  return {
    type: API_LOGIN_FAILURE,
    error
  };
}

export function setLoginSuccess(data) {
  return async (dispatch) => {
    return dispatch(loginSuccess(data));
  };
}

export function login(data) {
  const method = 'POST';
  const headerData = {};
  return async (dispatch) => {
    dispatch(loginRequest());
    try {
      const response = await api(userEndpoints.LOGIN, data, method, headerData);
      return dispatch(loginSuccess(response));
    } catch (error) {
      return dispatch(loginError(error));
    }
  };
}
