import { MESSAGE_RECEIVED } from '../../../constants/actions';

// This was only for testing
const initialState = {
  messageReceived: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MESSAGE_RECEIVED:
      return {
        ...state,
        messageReceived: action.message
      };
    default:
      return state;
  }
}
