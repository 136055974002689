import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorComponent.module.css';

const ErrorComponent = forwardRef((props, ref) => {
  const receivedErrors =
    props.errors &&
    props.errors.length > 0 &&
    props.errors
      .filter((value) => value.length > 0)
      .map((value, index) => {
        return (
          <div key={index} className={props.errorItemClassName ? props.errorItemClassName : styles.errorItem}>
            <div className={styles.errorExclamationMark}>!</div>
            <div className={styles.errorText}>{value}</div>
          </div>
        );
      });

  return (
    <>
      {props.errors && props.errors.length > 0 && !props.apiErrors ? (
        <div ref={ref} className={props.errorContainerClassName ? props.errorContainerClassName : styles.container}>
          {receivedErrors}
        </div>
      ) : null}
      {props.errors && props.errors.length > 0 && props.apiErrors ? (
        <div
          ref={ref}
          className={
            props.errorContainerClassName
              ? [props.errorContainerClassName, styles.globalErrorsContainer].join(' ')
              : styles.globalErrorsContainer
          }
        >
          {receivedErrors}
        </div>
      ) : null}
    </>
  );
});

ErrorComponent.propTypes = {
  errors: PropTypes.any,
  errorContainerClassName: PropTypes.any,
  errorItemClassName: PropTypes.any,
  apiErrors: PropTypes.any
};

ErrorComponent.defaultProps = {
  apiErrors: false
};

ErrorComponent.displayName = 'ErrorComponent';

export default ErrorComponent;
