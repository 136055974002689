import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import styles from './CustomLink.module.css';
import useCountdown from '../../../hooks/useCountdown';
import ClockSvg from '../../../assets/images/svg/ClockSvg';
import CircleLoader from '../Loader/CircleLoader';

export const linkTypes = {
  ROUTE: 1,
  CUSTOM_URL: 2,
  ROUTE_WITH_COUNTDOWN: 3
};

export const linkStyles = {
  PRIMARY: 1,
  SECONDARY: 2,
  TERTIARY: 3,
  SUCCESS: 4,
  DANGER: 5,
  TEXT: 6,
  TERTIARY_OUTLINED: 7,
  INACTIVE: 8
};

const CustomLink = (props) => {
  const { children, to, type, style, state = null, onClick = () => {}, className = '', isDisabled = false } = props;

  const getLinkClassNameByStyle = () => {
    switch (style) {
      case linkStyles.PRIMARY:
        return `${styles.link} ${styles.primary}`;
      case linkStyles.SECONDARY:
        return `${styles.link} ${styles.secondary}`;
      case linkStyles.TERTIARY:
        return `${styles.link} ${styles.tertiary}`;
      case linkStyles.TERTIARY_OUTLINED:
        return `${styles.link} ${styles.tertiaryOutlined}`;
      case linkStyles.SUCCESS:
        return `${styles.link} ${styles.success}`;
      case linkStyles.DANGER:
        return `${styles.link} ${styles.danger}`;
      case linkStyles.INACTIVE:
        return `${styles.link} ${styles.inactive}`;
      case linkStyles.TEXT:
        return styles.textLink;
      default:
        return `${styles.link} ${styles.primary}`;
    }
  };

  const linkClassName = `${getLinkClassNameByStyle()} ${className} ${
    type === linkTypes.ROUTE_WITH_COUNTDOWN ? styles.routeWithCountdown : ''
  }`;

  const link = useMemo(() => {
    switch (type) {
      case linkTypes.ROUTE:
        return (
          <Link
            to={!isDisabled ? to : ''}
            onClick={onClick}
            state={state}
            className={isDisabled ? styles.disabled : linkClassName}
          >
            {children}
          </Link>
        );
      case linkTypes.CUSTOM_URL:
        return (
          <a
            href={!isDisabled ? to : ''}
            onClick={onClick}
            className={isDisabled ? `${styles.disabled}` : linkClassName}
            target='_blank'
            rel='no noreferrer'
          >
            {children}
          </a>
        );
      case linkTypes.ROUTE_WITH_COUNTDOWN:
        return (
          <RouteWithCountdown {...props} linkClassName={linkClassName}>
            {children}
          </RouteWithCountdown>
        );
      default:
        return <></>;
    }
  }, [children, isDisabled, linkClassName, onClick, props, state, to, type]);

  return <>{link}</>;
};

const RouteWithCountdown = ({ to, onClick, state, linkClassName, isDisabled, countdownTo, children }) => {
  const [countdownText, animCountdownStarted, leftCountdownPercentage, animCountdownEnded] = useCountdown({
    expiresAt: countdownTo
  });

  return (
    <>
      {animCountdownEnded ? (
        <Link to={''} onClick={onClick} state={state} className={isDisabled ? styles.disabled : linkClassName}>
          <CircleLoader width={25} height={25} />
          <span className={styles.countdownTime}>{countdownText}</span>
        </Link>
      ) : (
        <Link
          to={!isDisabled ? to : ''}
          onClick={onClick}
          state={state}
          className={isDisabled ? styles.disabled : linkClassName}
        >
          {animCountdownStarted ? (
            <div
              className={styles.countdownAnimation}
              style={{
                width: `${100 - leftCountdownPercentage}%`
              }}
            ></div>
          ) : null}

          <div className={styles.routeWithCountdownContainer}>
            <ClockSvg className={styles.clockSvg} /> <span>{children}</span>
            <span className={styles.countdownTime}>{countdownText}</span>
          </div>
        </Link>
      )}
    </>
  );
};

export default CustomLink;
