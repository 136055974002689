import * as React from 'react';

const GdprSvg = (props) => (
  <svg id='Layer_26' data-name='Layer 26' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>
        {'.cls-2-gdpr{fill:none;stroke:#010101;stroke-linejoin:round;stroke-width:1.6px}.cls-3-gdpr{fill:#010101}'}
      </style>
    </defs>
    <path
      className='cls-2-gdpr'
      d='M7.44 11.26h17.13v12.55c0 1.66-1.34 3-3 3H10.44c-1.66 0-3-1.34-3-3V11.26ZM21.26 11.26V9.62c0-2.97-2.41-5.38-5.38-5.38S10.5 6.65 10.5 9.62v1.64'
    />
    <path
      className='cls-3-gdpr'
      d='m11.82 21.69.38.76.84.12-.61.59.14.84-.75-.4-.74.4.14-.84-.61-.59.84-.12.37-.76zM14.69 21.69l.37.76.84.12-.61.59.15.84-.75-.4-.75.4.14-.84-.6-.59.83-.12.38-.76zM17.48 21.69l.37.76.84.12-.61.59.15.84-.75-.4-.75.4.14-.84-.6-.59.83-.12.38-.76zM20.18 21.69l.37.76.84.12-.61.59.14.84-.74-.4-.75.4.14-.84-.61-.59.84-.12.38-.76z'
    />
    <text
      transform='translate(9.19 18.11)'
      style={{
        fill: '#231f20',
        fontFamily: 'Poppins-SemiBold,Poppins',
        fontSize: 5,
        fontWeight: 600
      }}
    >
      <tspan x={0} y={0}>
        {'GDPR'}
      </tspan>
    </text>
  </svg>
);

export default GdprSvg;
