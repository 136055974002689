// export const nameRegex = /^[A-Za-z0-9\\/&'"()]{3,200}$/;
export const nameRegex = /^[\p{L}0-9!#$%&()*+,\-.:;<=>?@[\]^_`{|}~ ]{3,200}$/u;

export const emailRegex =
  // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// Password
export const passwordRegex = /^[\w!#$%&()*+,\-.:;<=>?@[\]^_`{|}~]{8,32}$/;

// phone number
// export const phoneRegex = /^[\d]{3,10}$/;
export const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const alphanumeric = /^[a-zA-Z0-9_.\-' ]*$/;

export const tin = /^[\p{L}0-9/()-_]{3,200}$/u;

export const isAlphanumeric = (inputString) => {
  return nameRegex.test(inputString);
};
