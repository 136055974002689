import * as React from 'react';

const RightToRestrictionSvg = (props) => (
  <svg id='Layer_34' data-name='Layer 34' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>
        {
          '.cls-1-restriction,.cls-2-restriction,.cls-3-restriction{fill:none;stroke:#010101;stroke-linejoin:round}.cls-1-restriction,.cls-3-restriction{stroke-linecap:round}.cls-2-restriction{stroke-width:1.6px}.cls-3-restriction{stroke-width:.8px}'
        }
      </style>
    </defs>
    <path className='cls-2-restriction' d='M6.04 3.81h6.94v6.94H6.04zM18.74 3.81h6.94v6.94h-6.94z' />
    <path className='cls-3-restriction' d='m7.51 7.42 1.24 1.24 2.76-2.76M24.05 5.44l-3.68 3.68M20.37 5.44l3.68 3.68' />
    <path className='cls-1-restriction' d='M19.91 29.47h-5.34l-4.18-6.55 1.2-.78 2.05 2.05v-8.93h2.09v5.6' />
    <path
      className='cls-1-restriction'
      d='M17.61 21.11V19.3h-1.88M19.56 21.53v-1.48h-1.95M19.91 29.47l1.7-4.96v-3.72h-2.05'
    />
  </svg>
);

export default RightToRestrictionSvg;
