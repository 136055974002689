import React from 'react';
import styles from './CircleLoader.module.css';

const CircleLoader = (props) => {
  const borderWidth = props.borderWidth || 2;
  return (
    <div style={{ height: props.height, width: props.width }} className={styles.ldsRing}>
      <div style={{ height: props.height, width: props.width, borderWidth: borderWidth }} />
    </div>
  );
};

export default CircleLoader;
