import React from 'react';
import styles from './ErrorMark.module.css';

const ErrorMark = ({ errorMessage, className, messageStyle, inlineText, bigLabel = false }) => {
  return (
    <>
      {errorMessage ? (
        <div className={className ? className : ''}>
          <div className={`${styles.errorExclamationMark} ${bigLabel ? styles.bigExclamationMark : ''}`}>!</div>
          <div
            className={[styles.errorMessage, messageStyle ? messageStyle : '', bigLabel ? styles.bigLabel : ''].join(
              ' '
            )}
          >
            {errorMessage}
          </div>
        </div>
      ) : null}
      {inlineText ? (
        <div className={styles.inlineContainer}>
          <div className={`${styles.errorExclamationMark} ${bigLabel ? styles.bigExclamationMark : ''}`}>!</div>
          <p>{inlineText}</p>
        </div>
      ) : null}
    </>
  );
};

export default ErrorMark;
