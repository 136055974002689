import * as React from 'react';

function TransportSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} viewBox='0 0 24 24' {...props}>
      <rect x='10' y='6' width='12' height='9' rx='1' style={{ fill: 'var(--primary)' }} />
      <path
        d='M 8.5,9 C 8.7761,9 9,9.2217586 9,9.4941406 v 6.4101564 c 0,0.272343 -0.2239,0.49414 -0.5,0.494141 h -6 c -0.2761,0 -0.5,-0.221798 -0.5,-0.494141 v -2.888672 c 0,-0.04666 0.0062,-0.09379 0.01953,-0.138672 L 3.0625,9.3554688 C 3.1247,9.145042 3.318716,9 3.541016,9 Z M 7.498047,9.9824219 H 4.345703 c -0.216095,0 -0.408235,0.1376261 -0.476562,0.3417971 l -0.669922,2 c -0.108359,0.32376 0.133895,0.658203 0.476562,0.658203 h 3.822266 c 0.277264,0 0.501953,-0.22386 0.501953,-0.5 v -2 C 8,10.20628 7.775311,9.9824219 7.498047,9.9824219 Z M 21,16 v 2 h -2 a 2,2 0 0 1 -2,2 2,2 0 0 1 -2,-2 H 8 C 8,19.098647 7.098647,20 6,20 5.26685,20 4.629458,19.593551 4.28125,19 H 2.574219 C 2.257558,19 2,18.5523 2,18 v -1 -1 z m -4,1 c -0.55228,0 -1,0.4477 -1,1 0,0.5523 0.44772,1 1,1 0.55228,0 1,-0.4477 1,-1 0,-0.5523 -0.44772,-1 -1,-1 z M 6,17 c -0.5523,0 -1,0.4477 -1,1 0,0.138075 0.02752,0.269033 0.07813,0.388672 0.100368,0.242604 0.290599,0.432835 0.533203,0.533203 C 5.730967,18.972477 5.861925,19 6,19 6.138075,19 6.269033,18.972477 6.388672,18.921875 6.631276,18.821507 6.821507,18.631276 6.921875,18.388672 6.972477,18.269033 7,18.138075 7,18 7,17.4477 6.5523,17 6,17 Z'
        style={{ fill: 'var(--secondary)' }}
      />
    </svg>
  );
}

export default TransportSvg;
