import { SET_INTERNET_CONNECTION_OFFLINE, SET_INTERNET_CONNECTION_ONLINE } from '../../constants/actions';

function setOfflineAction() {
  return {
    type: SET_INTERNET_CONNECTION_OFFLINE
  };
}

function setOnlineAction() {
  return {
    type: SET_INTERNET_CONNECTION_ONLINE
  };
}

export function setOffline() {
  return (dispatch) => {
    return dispatch(setOfflineAction());
  };
}

export function setOnline() {
  return (dispatch) => {
    return dispatch(setOnlineAction());
  };
}
