import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import moment from 'moment';

export function getChatDateFormat(date, localization) {
  return localizeChatTimes(
    moment(date + '+0000').calendar(null, {
      sameDay: 'h:mm A',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday]',
      lastWeek: 'dddd',
      sameElse: 'MM/DD/YY'
    }),
    localization
  );
}
export function localizeChatTimes(string, localization) {
  switch (string) {
    case 'Yesterday': {
      return localization.yesterday;
    }
    case 'Monday': {
      return localization.monday;
    }
    case 'Tuesday': {
      return localization.tuesday;
    }
    case 'Wednesday': {
      return localization.wednesday;
    }
    case 'Thursday': {
      return localization.thursday;
    }
    case 'Friday': {
      return localization.friday;
    }
    case 'Saturday': {
      return localization.saturday;
    }
    case 'Sunday': {
      return localization.sunday;
    }
    default: {
      return string;
    }
  }
}
// const SERVER_TIME_ZONE = 'Etc/UTC';

// export function getBrowserTime(time) {
//   // const newTime = time.replace(/-/g, '/').replace('.000000', '');
//   const serverTime = new Date(`${time} UTC`);
//   const serverTimeISOString = serverTime.toISOString();
//   const serverTimeString = serverTimeISOString.toLocaleString('en-US', { timeZone: SERVER_TIME_ZONE });
//   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const browserTime = new Intl.DateTimeFormat('en-US', {
//     timeZone: timeZone,
//     year: 'numeric',
//     month: 'numeric',
//     day: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     second: 'numeric',
//     hour12: false
//   }).format(new Date(serverTimeString));
//   const result = getTime(browserTime);
//   return result;
// }

export function getMaxDate(date1, date2) {
  if (!date1 || date1 === null) return date2;
  if (!date2 || date2 === null) return date1;

  return date1 > date2 ? date1 : date2;
}

export function formatDateToYMD(dateParam) {
  const date = new Date(dateParam);
  // TO CHECK : we use moment library and import to this file but formating doing in plain JS???
  // const formattedDate = moment(date).format('YYYY-MM-DD');
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), month, day].join('-');
}

export function formatTime(stringTime, hideType = false) {
  const splitTime = stringTime.split(':');
  return `${splitTime[0]}:${splitTime[1]}${hideType ? '' : 'h'}`;
}

export function getFormattedDate(stringDate) {
  return new Date(dayjs(stringDate).format());
}

export function getMonthDayYear(date) {
  dayjs.extend(utc);
  return dayjs.utc(date).format('DD. MMM YYYY. ');
}

export function getTime(date, fullTime = false) {
  return fullTime ? dayjs(date).format('hh:mm:ss') : dayjs(date).format('HH:mm');
}

export function getMonthString(month, strings) {
  switch (String(month)) {
    case '01':
      return strings.januaryShort;
    case '02':
      return strings.februaryShort;
    case '03':
      return strings.marchShort;
    case '04':
      return strings.aprilShort;
    case '05':
      return strings.mayShort;
    case '06':
      return strings.juneShort;
    case '07':
      return strings.julyShort;
    case '08':
      return strings.augustShort;
    case '09':
      return strings.septemberShort;
    case '10':
      return strings.octoberShort;
    case '11':
      return strings.novemberShort;
    case '12':
      return strings.decemberShort;
    default:
      break;
  }
}

export function parseYearFromDate(date) {
  return date.split(' ')[0].split('-')[0];
}

export function parseDayFromDate(date) {
  return date.split(' ')[0].split('-')[2];
}

export function parseMonthFromDate(date, strings) {
  const month = date.split('-')[1];
  return getMonthString(month, strings);
}

export function parseDateDMY(date, strings) {
  if (!date) return '';
  const day = parseDayFromDate(date);
  const month = parseMonthFromDate(date, strings);
  const year = parseYearFromDate(date);
  return `${day}. ${month} ${year}.`;
}

export function parseDateObj(date, strings) {
  if (!date) return '';

  let dateObj = date;
  if (typeof dateObj === 'string' || dateObj instanceof String) {
    dateObj = new Date(Date.parse(dateObj));
  }

  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const monthString = getMonthString(('0' + month).slice(-2), strings);

  const year = dateObj.getFullYear();
  return `${day}. ${monthString} ${year}.`;
}

export const addDays = (date, days) => {
  const newDate = new Date(date); // Create a copy of the provided date
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const removeTimeFromDate = (date) => {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};
