import * as React from 'react';

function TradeSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} viewBox='0 0 24 24' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.48984 5.6L8.21841 9.4H15.7817L15.5103 5.6H8.48984ZM7.93117 4C7.40653 4 6.97109 4.40545 6.93371 4.92875L6.57657 9.92875C6.53522 10.5076 6.99368 11 7.57403 11H16.4261C17.0064 11 17.4649 10.5076 17.4235 9.92875L17.0664 4.92875C17.029 4.40545 16.5936 4 16.0689 4H7.93117Z'
        style={{ fill: 'var(--secondary)' }}
      />
      <path
        d='M 3.1113281 8 C 2.4976781 8 2 8.44772 2 9 L 2 19 C 2 19.5523 2.4976781 20 3.1113281 20 L 20.888672 20 C 21.502272 20 22 19.5523 22 19 L 22 9 C 22 8.44772 21.502272 8 20.888672 8 L 3.1113281 8 z M 11 12 L 13 12 L 13 14 L 11 14 L 11 12 z '
        style={{ fill: 'var(--primary)' }}
      />
    </svg>
  );
}

export default TradeSvg;
