import { COMPANY_SUSPENDED_MODAL_SHOW, COMPANY_SUSPENDED_MODAL_HIDE } from '../../constants/actions';

export function companySuspendedModalShow(state) {
  return (dispatch) => {
    return dispatch({
      state,
      type: COMPANY_SUSPENDED_MODAL_SHOW
    });
  };
}

export function companySuspendedModalHide(state) {
  return (dispatch) => {
    return dispatch({
      state,
      type: COMPANY_SUSPENDED_MODAL_HIDE
    });
  };
}
