import * as React from 'react';

function CookiepolicyTypeSvg(props) {
  return (
    <svg {...props} width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M28.2199 28.26H3.66992V14.11H8.62992L9.80992 16H28.2199V28.26Z'
        stroke='#005B9E'
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path d='M6.44922 13.7V3.41998H26.3992V15.82' stroke='#005B9E' strokeWidth='0.8' strokeLinejoin='round' />
      <path
        d='M8.68945 5.96002H24.5495'
        stroke='#005B9E'
        strokeWidth='0.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.68945 8.5H24.5495' stroke='#005B9E' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.68945 11.04H24.5495'
        stroke='#005B9E'
        strokeWidth='0.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default CookiepolicyTypeSvg;
