import { HANDLE_ERRORS } from '../../../constants/actions';

const initialState = {
  error: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case HANDLE_ERRORS:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
