import React from 'react';
import styles from './Label.module.css';
import EllipsisText from '../../EllipsisText/EllipsisText';

const Label = ({ style, htmlFor, className, onClick, label, required, bigLabel = false }) => {
  return (
    <label
      style={style}
      htmlFor={htmlFor}
      className={`${className} ${bigLabel ? styles.bigLabel : ''}`}
      onClick={onClick}
    >
      <EllipsisText>{label}</EllipsisText>
      {required && <span className={styles.asteriks}> *</span>}
    </label>
  );
};

export default Label;
