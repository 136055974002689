import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import styles from './SessionExpiredForm.module.css';
import AppComponentContext from '../../../contexts/AppComponentContext';
import { emailRegex } from '../../../assets/helpers/regex';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../FormElements/Input/Input';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import { nonAuthenticatedRoutes as paths } from '../../../constants/paths';
import LogoSvg from '../../../assets/images/svg/LogoSvg';
import StickyFooter from '../StickyFooter/StickyFooter';
import { browserName, fullBrowserVersion, isMobileOnly, isTablet, osName, osVersion } from 'react-device-detect';
import { apiStatus } from '../../../hooks/useAPI';
import PasswordPreview from '../PasswordPreview/PasswordPreview';
import { useDispatch, useSelector } from 'react-redux';
import CloseIconSmallGraySvg from '../../../assets/images/svg/CloseIconSmallGraySvg';
import { login } from '../../../store/actions/login';
import logout from '../../../store/actions/logout';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import { prepareApiErrorCodes } from '../../../assets/helpers/common';

Modal.setAppElement('body');

const SessionExpiredForm = ({ isOpen, loginApiResponse }) => {
  const [loginAttempted, setLoginAttempted] = useState(false);

  const { isPC } = useWindowDimensions();
  const dispatch = useDispatch();
  const response = useSelector(({ login }) => login.status);
  const FORM_ERROR_CODES = [1101, 1201, 1202];
  const localization = useContext(AppComponentContext)?.strings;

  const [isOpened, setIsOpened] = useState(false);
  const [preview, setPreview] = useState('password');

  const defaultValues = {
    email: '',
    password: ''
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required(localization.requiredInput).matches(emailRegex, localization.invalidEmail),
    password: yup.string().required(localization.requiredInput)
  });

  const formMethods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    shouldFocusError: true,
    criteriaMode: 'all'
  });
  const { handleSubmit } = formMethods;

  const previewPassword = useCallback(() => {
    preview === 'password' && setPreview('text');
    preview === 'text' && setPreview('password');
  }, [preview]);

  const onSubmitLogin = useCallback(
    (data) => {
      let platform;
      if (isMobileOnly) {
        platform = 'MOBILE';
      } else if (isTablet) {
        platform = 'TABLET';
      } else {
        platform = 'PC';
      }

      const os = `${osName} ${osVersion}`;
      const browser = `${browserName} ${fullBrowserVersion}`;
      const loginData = { ...data, platform, os, browser };

      dispatch(login(loginData));
      setLoginAttempted(true);
    },
    [dispatch]
  );

  const logoutHandler = useCallback(() => {
    dispatch(logout());
    setIsOpened(false);
    window.location.reload();
  }, [dispatch]);

  useEffect(() => {
    if (response.toUpperCase() === apiStatus.SUCCESS) {
      setIsOpened(false);
      window.location.reload();
    }
  }, [response]);

  useEffect(() => {
    if (isOpen) {
      setIsOpened(true);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpened}
      className={[styles.modal, !isPC ? styles.modalResponsive : ''].join(' ')}
      overlayClassName={styles.overlay}
    >
      <FormProvider {...formMethods}>
        <form
          className={[styles.wrapper, !isPC ? styles.wrapperResponsive : ''].join(' ')}
          onSubmit={handleSubmit(onSubmitLogin)}
        >
          <div>
            <CloseIconSmallGraySvg className={styles.close} onClick={logoutHandler} />
          </div>
          <div className={isPC ? styles.formContentWrapper : styles.formContentWrapperMobile}>
            <div className={styles.logoAndDescription}>
              <Link to={paths.HOME} className={styles.headerLogoLink} onClick={logoutHandler}>
                <LogoSvg className={styles.logo} />
              </Link>
              <div className={isPC ? styles.modalDescription : styles.modalDescriptionMobile}>
                <span className={styles.title}>{localization.timedOut}</span>
                <div className={styles.messageAndDescription}>
                  <span className={styles.description}>
                    {localization.timedOutDesc}
                    <br />
                    {localization.logInAgain}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.form}>
              <Input
                name='email'
                label={localization.email}
                placeholder={localization.inputEmailPlaceholder}
                bigInput
              ></Input>
              <Input
                type={preview}
                right={<PasswordPreview show={preview === 'text'} onClick={previewPassword} />}
                name='password'
                label={localization.password}
                placeholder={localization.inputPassPlaceholder}
                bigInput
              />
              <div className={styles.forgotPassLink} onClick={logoutHandler}>
                <Link to={paths.FORGOT_YOUR_PASSWORD} className={styles.registerLink}>
                  {localization.forgotYourPassword}
                </Link>
              </div>
            </div>
          </div>
          <StickyFooter className={isPC ? styles.expired : styles.expiredMobile}>
            <Button name='button' buttonType='primary' buttonClassName={styles.logInButtonColor}>
              {localization.signIn}
            </Button>
          </StickyFooter>
          {loginAttempted ? (
            <ErrorComponent
              apiErrors
              errors={prepareApiErrorCodes(
                FORM_ERROR_CODES,
                loginApiResponse.error && loginApiResponse.error.errors,
                localization
              )}
            />
          ) : null}
        </form>
      </FormProvider>
    </Modal>
  );
};

function mapStateToProps({ login }) {
  return {
    loginApiResponse: login
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpiredForm);
