import * as React from 'react';
const HazardousSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} {...props}>
    <path
      fill='none'
      stroke='#0C2138'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12 9v4m0 3h.01M10.363 3.59 2.257 17.126a1.914 1.914 0 0 0 1.636 2.87h16.214a1.913 1.913 0 0 0 1.636-2.87L13.637 3.59a1.914 1.914 0 0 0-3.274 0v.001Z'
    />
  </svg>
);
export default HazardousSvg;
