import * as React from 'react';
const CreditCardSuccessNotify = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} fill='none' {...props}>
    <path
      fill='#67B164'
      fillRule='evenodd'
      d='M2 4.5h18a.5.5 0 0 1 .5.5v2h-19V5a.5.5 0 0 1 .5-.5ZM1.5 9v8a.5.5 0 0 0 .5.5h18a.5.5 0 0 0 .5-.5V9h-19ZM0 5a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5Z'
      clipRule='evenodd'
    />
    <path stroke='#67B164' strokeLinecap='round' d='M3.5 11.5h5' />
  </svg>
);
export default CreditCardSuccessNotify;
