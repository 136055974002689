import { RESET_APP } from '../../constants/actions';

function resetAppAction() {
  return {
    type: RESET_APP
  };
}

export default function resetApp() {
  return (dispatch) => {
    dispatch(resetAppAction());
  };
}
