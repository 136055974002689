import {
  GET_USER_PERSONAL_INFO_REQUEST,
  GET_USER_PERSONAL_INFO_SUCCESS,
  GET_USER_PERSONAL_INFO_ERROR
} from '../../../../constants/actions';

const initialState = {
  isFetching: null,
  error: null,
  timestamp: null,
  response: {
    Em: null,
    Fn: null,
    Ln: null
  },
  selected: false,
  status: 'IDLE',
  initialFetching: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        timestamp: new Date().toUTCString(),
        status: 'FETCHING'
      };
    case GET_USER_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        response: { Fn: action.data.data.first_name, Ln: action.data.data.last_name, Em: action.data.data.email },
        timestamp: new Date().toUTCString(),
        status: 'SUCCESS'
      };
    case GET_USER_PERSONAL_INFO_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: new Date().toUTCString(),
        status: 'FAIL'
      };
    default:
      return state;
  }
}
