import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './store/store';
import { BrowserRouter } from 'react-router-dom';
import { saveReduxStateToStorage } from './assets/helpers/utils';

// react-helmet-async is a library used for changing title and meta tags for different pages of the app
import { HelmetProvider } from 'react-helmet-async';

// Polyfill includes - just in case some browser needs it
import cssVars from 'css-vars-ponyfill';

// sentry
import * as Sentry from '@sentry/react';

import App from './App';
import reportWebVitals from './reportWebVitals';

//new stuff
import { ErrorBoundary } from 'react-error-boundary';
import FatalErrorView from './components/UI/FatalErrorView/FatalErrorView';
const Language = lazy(() => import('./Language' /* webpackChunkName: "Language" */));

if (
  process.env.REACT_APP_ENV !== 'prod' &&
  process.env.REACT_APP_ENV !== 'loc' &&
  process.env.REACT_APP_ENV !== 'locBuild'
) {
  const tracesSampleRate = { stage: 0.1, dev: 0.1, prod: 0.1 };

  Sentry.init({
    dsn: 'https://d172a800c4cd406588af30d9d17a9f54@o1323795.ingest.sentry.io/6584893',
    release: process.env.REACT_APP_VERSION,
    tracesSampleRate: tracesSampleRate[process.env.REACT_APP_ENV],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: tracesSampleRate[process.env.REACT_APP_ENV],
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.Replay()]
  });
}

cssVars({});

const app = (
  <Provider store={store}>
    <HelmetProvider>
      <Language>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={FatalErrorView}>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </Language>
    </HelmetProvider>
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(app);

window.addEventListener('unload', function () {
  const state = store.getState();
  if (state.session.loggedInStatus) {
    const stateToSave = {};
    stateToSave.language = state.language;
    stateToSave.session = {
      Ct: state.session.Ct,
      Et: state.session.Et,
      Ht: state.session.Ht,
      userId: state.session.userId,
      loggedInStatus: state.session.loggedInStatus
    };
    stateToSave.authNavBar = state.authNavBar;
    // stateToSave.userCompanies = state.userCompanies;
    stateToSave.vehicleOffersFilter = state.vehicleOffersFilter;
    stateToSave.cargoOffersFilter = state.cargoOffersFilter;
    stateToSave.warehouseOffersFilter = state.warehouseOffersFilter;
    saveReduxStateToStorage(stateToSave);
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
