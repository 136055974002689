import {
  // NOTIFICATION_NOTIFY_RECEIVED,
  // NOTIFICATION_NOTIFY_DELETED,
  DELETE_ALL_NOTIFICATIONS
} from '../../../constants/actions';

// Reducer for ping notifications
const initialState = {
  vehicleCount: 0, // Menu item counter
  cargoCount: 0, // Menu item counter
  warehouseCount: 0, // Menu item counter
  location: [], // Keeps track of which location has notification dots. If the route (for example: '/vehicleOffers') is inside the array , it will draw the counter in the navbar
  pathname: {}, // Keeps track of which offers or bids have notifications. If the route (for example: '/vehicleOffer/1') is inside the array, it will remove a dot(s) upon visiting the page
  counter: {
    // Counters for submenu items
    '/vehicleMyOffers': 0,
    '/vehicleMyBids': 0,
    '/cargoMyOffers': 0,
    '/cargoMyBids': 0,
    '/warehouseMyOffers': 0,
    '/warehouseMyBids': 0
  }
};

export default function reducer(state = initialState, action = {}) {
  // let pathnameExists;
  // let pathname;
  // let deleteCount;
  // let newLocation;

  switch (action.type) {
    // case NOTIFICATION_NOTIFY_RECEIVED:
    // switch (action.notification.notify_subtype) {
    //     case '/vehicleMyOffers':
    //       pathnameExists = `/myVehicleOffer/${action.notification.notify_payload.data.id}` in state.pathname;
    //       pathnameExists
    //         ? (pathname = {
    //             ...state.pathname,
    //             [`/myVehicleOffer/${action.notification.notify_payload.data.id}`]: ++state.pathname[
    //               `/myVehicleOffer/${action.notification.notify_payload.data.id}`
    //             ]
    //           })
    //         : (pathname = { ...state.pathname, [`/myVehicleOffer/${action.notification.notify_payload.data.id}`]: 1 });
    //       return {
    //         ...state,
    //         vehicleCount: ++state.vehicleCount,
    //         counter: {
    //           ...state.counter,
    //           '/vehicleMyOffers': ++state.counter['/vehicleMyOffers']
    //         },
    //         location: [...state.location, action.notification.notify_subtype],
    //         pathname
    //       };
    //     case '/vehicleMyBids':
    //       pathnameExists = `/vehicleOffer/${action.notification.notify_payload.data.id}` in state.pathname;
    //       pathnameExists
    //         ? (pathname = {
    //             ...state.pathname,
    //             [`/vehicleOffer/${action.notification.notify_payload.data.id}`]: ++state.pathname[
    //               `/vehicleOffer/${action.notification.notify_payload.data.id}`
    //             ]
    //           })
    //         : (pathname = { ...state.pathname, [`/vehicleOffer/${action.notification.notify_payload.data.id}`]: 1 });
    //       return {
    //         ...state,
    //         vehicleCount: ++state.vehicleCount,
    //         counter: {
    //           ...state.counter,
    //           '/vehicleMyBids': ++state.counter['/vehicleMyBids']
    //         },
    //         location: [...state.location, action.notification.notify_subtype],
    //         pathname
    //       };
    //     case '/cargoMyOffers':
    //       pathnameExists = `/myCargoOffer/${action.notification.notify_payload.data.id}` in state.pathname;
    //       pathnameExists
    //         ? (pathname = {
    //             ...state.pathname,
    //             [`/myCargoOffer/${action.notification.notify_payload.data.id}`]: ++state.pathname[
    //               `/myCargoOffer/${action.notification.notify_payload.data.id}`
    //             ]
    //           })
    //         : (pathname = { ...state.pathname, [`/myCargoOffer/${action.notification.notify_payload.data.id}`]: 1 });
    //       return {
    //         ...state,
    //         cargoCount: ++state.cargoCount,
    //         counter: {
    //           ...state.counter,
    //           '/cargoMyOffers': ++state.counter['/cargoMyOffers']
    //         },
    //         location: [...state.location, action.notification.notify_subtype],
    //         pathname
    //       };
    //     case '/cargoMyBids':
    //       pathnameExists = `/cargoOffer/${action.notification.notify_payload.data.id}` in state.pathname;
    //       pathnameExists
    //         ? (pathname = {
    //             ...state.pathname,
    //             [`/cargoOffer/${action.notification.notify_payload.data.id}`]: ++state.pathname[
    //               `/cargoOffer/${action.notification.notify_payload.data.id}`
    //             ]
    //           })
    //         : (pathname = { ...state.pathname, [`/cargoOffer/${action.notification.notify_payload.data.id}`]: 1 });
    //       return {
    //         ...state,
    //         cargoCount: ++state.cargoCount,
    //         counter: {
    //           ...state.counter,
    //           '/cargoMyBids': ++state.counter['/cargoMyBids']
    //         },
    //         location: [...state.location, action.notification.notify_subtype],
    //         pathname
    //       };
    //     case '/warehouseMyOffers':
    //       pathnameExists = `/myWarehouseOffer/${action.notification.notify_payload.data.id}` in state.pathname;
    //       pathnameExists
    //         ? (pathname = {
    //             ...state.pathname,
    //             [`/myWarehouseOffer/${action.notification.notify_payload.data.id}`]: ++state.pathname[
    //               `/myWarehouseOffer/${action.notification.notify_payload.data.id}`
    //             ]
    //           })
    //         : (pathname = {
    //             ...state.pathname,
    //             [`/myWarehouseOffer/${action.notification.notify_payload.data.id}`]: 1
    //           });
    //       return {
    //         ...state,
    //         warehouseCount: ++state.warehouseCount,
    //         counter: {
    //           ...state.counter,
    //           '/warehouseMyOffers': ++state.counter['/warehouseMyOffers']
    //         },
    //         location: [...state.location, action.notification.notify_subtype],
    //         pathname
    //       };
    //     case '/warehouseMyBids':
    //       pathnameExists = `/warehouseOffer/${action.notification.notify_payload.data.id}` in state.pathname;
    //       pathnameExists
    //         ? (pathname = {
    //             ...state.pathname,
    //             [`/warehouseOffer/${action.notification.notify_payload.data.id}`]: ++state.pathname[
    //               `/warehouseOffer/${action.notification.notify_payload.data.id}`
    //             ]
    //           })
    //         : (pathname = { ...state.pathname, [`/warehouseOffer/${action.notification.notify_payload.data.id}`]: 1 });
    //       return {
    //         ...state,
    //         warehouseCount: ++state.warehouseCount,
    //         counter: {
    //           ...state.counter,
    //           '/warehouseMyBids': ++state.counter['/warehouseMyBids']
    //         },
    //         location: [...state.location, action.notification.notify_subtype],
    //         pathname
    //       };
    //     default:
    //       return state;
    //   }
    // case NOTIFICATION_NOTIFY_DELETED:
    //   switch (action.subtype) {
    //     case '/vehicleMyOffers':
    //       pathnameExists = `/myVehicleOffer/${action.id}` in state.pathname;
    //       if (pathnameExists) {
    //         deleteCount = state.pathname[`/myVehicleOffer/${action.id}`];
    //         delete state.pathname[`/myVehicleOffer/${action.id}`];
    //       }
    //       state.counter['/vehicleMyOffers'] - deleteCount === 0
    //         ? (newLocation = state.location.filter((location) => location !== action.subtype))
    //         : (newLocation = state.location);
    //       return {
    //         ...state,
    //         vehicleCount: state.vehicleCount - deleteCount,
    //         counter: {
    //           ...state.counter,
    //           '/vehicleMyOffers': state.counter['/vehicleMyOffers'] - deleteCount
    //         },
    //         location: newLocation
    //       };
    //     case '/vehicleMyBids':
    //       pathnameExists = `/vehicleOffer/${action.id}` in state.pathname;
    //       if (pathnameExists) {
    //         deleteCount = state.pathname[`/vehicleOffer/${action.id}`];
    //         delete state.pathname[`/vehicleOffer/${action.id}`];
    //       }
    //       state.counter['/vehicleMyBids'] - deleteCount === 0
    //         ? (newLocation = state.location.filter((location) => location !== action.subtype))
    //         : (newLocation = state.location);
    //       return {
    //         ...state,
    //         vehicleCount: state.vehicleCount - deleteCount,
    //         counter: {
    //           ...state.counter,
    //           '/vehicleMyBids': state.counter['/vehicleMyBids'] - deleteCount
    //         },
    //         location: newLocation
    //       };
    //     case '/cargoMyOffers':
    //       pathnameExists = `/myCargoOffer/${action.id}` in state.pathname;
    //       if (pathnameExists) {
    //         deleteCount = state.pathname[`/myCargoOffer/${action.id}`];
    //         delete state.pathname[`/myCargoOffer/${action.id}`];
    //       }
    //       state.counter['/cargoMyOffers'] - deleteCount === 0
    //         ? (newLocation = state.location.filter((location) => location !== action.subtype))
    //         : (newLocation = state.location);
    //       return {
    //         ...state,
    //         cargoCount: state.cargoCount - deleteCount,
    //         counter: {
    //           ...state.counter,
    //           '/cargoMyOffers': state.counter['/cargoMyOffers'] - deleteCount
    //         },
    //         location: newLocation
    //       };
    //     case '/cargoMyBids':
    //       pathnameExists = `/cargoOffer/${action.id}` in state.pathname;
    //       if (pathnameExists) {
    //         deleteCount = state.pathname[`/cargoOffer/${action.id}`];
    //         delete state.pathname[`/cargoOffer/${action.id}`];
    //       }
    //       state.counter['/cargoMyBids'] - deleteCount === 0
    //         ? (newLocation = state.location.filter((location) => location !== action.subtype))
    //         : (newLocation = state.location);
    //       return {
    //         ...state,
    //         cargoCount: state.cargoCount - deleteCount,
    //         counter: {
    //           ...state.counter,
    //           '/cargoMyBids': state.counter['/cargoMyBids'] - deleteCount
    //         },
    //         location: newLocation
    //       };
    //     case '/warehouseMyOffers':
    //       pathnameExists = `/myWarehouseOffer/${action.id}` in state.pathname;
    //       if (pathnameExists) {
    //         deleteCount = state.pathname[`/myWarehouseOffer/${action.id}`];
    //         delete state.pathname[`/myWarehouseOffer/${action.id}`];
    //       }
    //       state.counter['/warehouseMyOffers'] - deleteCount === 0
    //         ? (newLocation = state.location.filter((location) => location !== action.subtype))
    //         : (newLocation = state.location);
    //       return {
    //         ...state,
    //         warehouseCount: state.warehouseCount - deleteCount,
    //         counter: {
    //           ...state.counter,
    //           '/warehouseMyOffers': state.counter['/warehouseMyOffers'] - deleteCount
    //         },
    //         location: newLocation
    //       };
    //     case '/warehouseMyBids':
    //       pathnameExists = `/warehouseOffer/${action.id}` in state.pathname;
    //       if (pathnameExists) {
    //         deleteCount = state.pathname[`/warehouseOffer/${action.id}`];
    //         delete state.pathname[`/warehouseOffer/${action.id}`];
    //       }
    //       state.counter['/warehouseMyBids'] - deleteCount === 0
    //         ? (newLocation = state.location.filter((location) => location !== action.subtype))
    //         : (newLocation = state.location);
    //       return {
    //         ...state,
    //         warehouseCount: state.warehouseCount - deleteCount,
    //         counter: {
    //           ...state.counter,
    //           '/warehouseMyBids': state.counter['/warehouseMyBids'] - deleteCount
    //         },
    //         location: newLocation
    //       };
    //     default:
    //       return state;
    //   }
    case DELETE_ALL_NOTIFICATIONS:
      return {
        ...state,
        vehicleCount: 0,
        cargoCount: 0,
        warehouseCount: 0,
        location: [],
        pathname: {},
        counter: {
          '/vehicleMyOffers': 0,
          '/vehicleMyBids': 0,
          '/cargoMyOffers': 0,
          '/cargoMyBids': 0,
          '/warehouseMyOffers': 0,
          '/warehouseMyBids': 0
        }
      };
    default:
      return state;
  }
}
