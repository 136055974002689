import axios from './axios/axios';
import store from '../../store/store';
import setMiddlewareError from '../../store/actions/setMiddlewareError';
import setHandleError from '../../store/actions/setHandleError';
import { tooManyRequestsModalShow } from '../../store/actions/tooManyRequestsModal';

const globalErrors = [1000000, 1000001, 2000000];

export default async function api(url, data, method, headerData = null, timeout = null) {
  if (method === 'GET') {
    process.env.NODE_ENV !== 'production' && console.log('GET sent: ', url, data, method, headerData);

    const result = await axios.get(url, headerData, data, timeout).catch((error) => {
      const receivedError = error && error.errors && error.errors[0] && error.errors[0].error_code;
      if (globalErrors.includes(receivedError)) {
        store.dispatch(setMiddlewareError(receivedError));
        // error?.code == 500 || error?.code == 400 | we removed 400 because we need to fix error handling on backend
      } else if (error?.error?.status_code == 429) {
        store.dispatch(tooManyRequestsModalShow());
      } else if (error?.code == 500) {
        // redux
        store.dispatch(setHandleError(error));
      } else {
        throw error;
      }
    });
    process.env.NODE_ENV !== 'production' && console.log('GET received: ', result);
    if (result.data.status === 'success') {
      result.data.requestDuration = result.duration;

      return result.data;
    }
  }

  if (method === 'POST') {
    process.env.NODE_ENV !== 'production' && console.log('POST sent: ', url, data, method, headerData);
    const result = await axios.post(url, data, headerData).catch((error) => {
      const receivedError = error && error.errors && error.errors[0] && error.errors[0].error_code;
      if (globalErrors.includes(receivedError)) {
        store.dispatch(setMiddlewareError(receivedError));
      } else if (error?.error?.status_code == 429) {
        store.dispatch(tooManyRequestsModalShow());
      } else if (error?.code == 500) {
        // redux
        store.dispatch(setHandleError(error));
      } else {
        throw error;
      }
    });
    process.env.NODE_ENV !== 'production' && console.log('POST received: ', result);
    if (result.data.status === 'success') {
      result.data.requestDuration = result.duration;

      return result.data;
    }
  }

  if (method === 'PUT') {
    process.env.NODE_ENV !== 'production' && console.log('PUT sent: ', url, data, method, headerData);
    const result = await axios.put(url, data, headerData).catch((error) => {
      const receivedError = error && error.errors && error.errors[0] && error.errors[0].error_code;
      if (globalErrors.includes(receivedError)) {
        store.dispatch(setMiddlewareError(receivedError));
      } else if (error?.error?.status_code == 429) {
        store.dispatch(tooManyRequestsModalShow());
      } else if (error?.code == 500) {
        // redux
        store.dispatch(setHandleError(error));
      } else {
        throw error;
      }
    });
    process.env.NODE_ENV !== 'production' && console.log('PUT received: ', result);
    if (result?.data.status === 'success') {
      result.data.requestDuration = result.duration;

      return result.data;
    }
  }

  // This doesn't work.
  if (method === 'DELETE') {
    process.env.NODE_ENV !== 'production' && console.log('DELETE sent: ', url, data, method, headerData);
    const result = await axios.delete(url, headerData).catch((error) => {
      const receivedError = error && error.errors && error.errors[0] && error.errors[0].error_code;
      if (globalErrors.includes(receivedError)) {
        store.dispatch(setMiddlewareError(receivedError));
      } else if (error?.error?.status_code == 429) {
        store.dispatch(tooManyRequestsModalShow());
      } else if (error?.code == 500) {
        // redux
        store.dispatch(setHandleError(error));
      } else {
        throw error;
      }
    });
    process.env.NODE_ENV !== 'production' && console.log('DELETE received: ', result);
    if (result.data.status === 'success') {
      result.data.requestDuration = result.duration;

      return result.data;
    }
  }
}
