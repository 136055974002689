import { CHAT_MESSAGE_RECEIVED } from '../../../constants/actions';

function setChatMessageReceived(message) {
  return {
    type: CHAT_MESSAGE_RECEIVED,
    message: message
  };
}

export function chatMessageReceived(message) {
  return (dispatch) => {
    return dispatch(setChatMessageReceived(message));
  };
}
