import React, { useState, useRef, useEffect, useContext } from 'react';
import AppComponentContext from '../../../contexts/AppComponentContext';
import styles from './CookieCollapsible.module.css';
import ArrowDownSvg from '../../../assets/images/svg/ArrowDownSvg';
import Toggle from '../Toggle/Toggle';

const CookieCollapsible = ({
  children,
  title,
  className,
  toggleName,
  toggleChecked,
  readOnly,
  setToggleState,
  toggleState,
  alwaysActive = false,
  defaultValue = false
}) => {
  const localization = useContext(AppComponentContext).strings;
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (toggleState) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [toggleState]);

  const handleOppening = () => {
    switch (toggleName) {
      case 'necessary':
        setToggleState({
          marketing: false,
          statistic: false,
          necessary: !toggleState,
          unclassified: false,
          chat: false
        });
        break;
      case 'statistic':
        setToggleState({
          marketing: false,
          statistic: !toggleState,
          necessary: false,
          unclassified: false,
          chat: false
        });
        break;
      case 'marketing':
        setToggleState({
          marketing: !toggleState,
          statistic: false,
          necessary: false,
          unclassified: false,
          chat: false
        });
        break;
      case 'unclassified':
        setToggleState({
          marketing: false,
          statistic: false,
          necessary: false,
          unclassified: !toggleState,
          chat: false
        });
        break;
      case 'chat':
        setToggleState({
          marketing: false,
          statistic: false,
          necessary: false,
          unclassified: false,
          chat: !toggleState
        });
        break;
    }
  };

  return (
    <div className={styles.container}>
      <div className={[styles.header, className ? className : ''].join(' ')}>
        <div className={styles.headerWrapper}>
          {alwaysActive ? (
            <>
              <div className={styles.activeHeader} onClick={handleOppening}>
                <div className={styles.contentWrapper}>
                  <ArrowDownSvg className={toggleState ? styles.arrowUp : styles.arrowDown} />
                  <div>{title}</div>
                </div>
                <span className={styles.activeText}>{localization.alwaysActive}</span>
              </div>
            </>
          ) : (
            <>
              <div className={styles.titleWrapper} onClick={handleOppening}>
                <ArrowDownSvg className={toggleState ? styles.arrowUp : styles.arrowDown} />
                <div>{title}</div>
              </div>
              <div className={styles.toggleWrapper}>
                {alwaysActive ? (
                  <span className={styles.activeText}>{localization.alwaysActive}</span>
                ) : (
                  <Toggle name={toggleName} checked={toggleChecked} readOnly={readOnly} defaultValue={defaultValue} />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.content} style={{ height }}>
        <div ref={ref}>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CookieCollapsible;
