import * as React from 'react';

function LogoArrowSvgBig(props) {
  return (
    <svg width='96' {...props} height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='96' height='96' rx='6' fill='#2586C5' />
      <path d='M21 60.0281C29.3199 52.4867 48.5283 35.0001 48.5283 35.0001L75.5504 60.0281' fill='#2586C5' />
      <path
        d='M21 60.0281C29.3199 52.4867 48.5283 35.0001 48.5283 35.0001L75.5504 60.0281'
        stroke='white'
        strokeWidth='10.2162'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default LogoArrowSvgBig;
