import { WHOLE_PAGE_LOADER_SHOW, WHOLE_PAGE_LOADER_HIDE } from '../../../constants/actions';

const initialState = {
  visible: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case WHOLE_PAGE_LOADER_SHOW:
      return {
        ...state,
        visible: state.visible + 1
      };
    case WHOLE_PAGE_LOADER_HIDE:
      return {
        ...state,
        visible: state.visible > 0 ? state.visible - 1 : state.visible
      };
    default:
      return state;
  }
}
