import * as React from 'react';

const PersonalDataSvg = (props) => (
  <svg id='Layer_27' data-name='Layer 27' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>{'.cls-2-personaldata{fill:#010101}'}</style>
    </defs>
    <circle className='cls-2-personaldata' cx={15.94} cy={13.9} r={2.05} />
    <path
      className='cls-2-personaldata'
      d='M15.04 16.63h1.81c1.07 0 1.94.87 1.94 1.94v3.75H13.1v-3.75c0-1.07.87-1.94 1.94-1.94Z'
    />
    <path
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.6px'
      }}
      d='M29.25 27.18H2.83V4.81h6.11l.77 2.64h19.54v19.73z'
    />
  </svg>
);

export default PersonalDataSvg;
