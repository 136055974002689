import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './StrengthChecker.module.css';
import AppComponentContext from '../../../../contexts/AppComponentContext';

const StrengthChecker = (props) => {
  const lowLevel = props.maxStrength / 3;

  const context = useContext(AppComponentContext);
  const strings = context.strings;

  return (
    <div className={props.currentStrength === 0 ? `${styles.wrapper} ${styles.hideChecker}` : `${styles.wrapper}`}>
      <div
        className={
          props.currentStrength === props.maxStrength
            ? `${styles.dot} ${styles.strongDot}`
            : props.currentStrength >= lowLevel
              ? `${styles.dot} ${styles.mediumDot}`
              : styles.dot
        }
      />
      {props.currentStrength < lowLevel ? <span>{strings.weak}</span> : null}
      {props.currentStrength >= lowLevel && props.currentStrength < props.maxStrength ? (
        <span>{strings.medium}</span>
      ) : null}
      {props.currentStrength === props.maxStrength ? <span>{strings.strong}</span> : null}
    </div>
  );
};

StrengthChecker.propTypes = {
  currentStrength: PropTypes.number,
  maxStrength: PropTypes.number
};

export default StrengthChecker;
