/* ignore FormData */
import axios from 'axios';
import config from '../../../constants/config';
import { setOffline, setOnline } from '../../../store/actions/internetConnection';
import { connectionStatus } from '../../../store/reducers/LogicalState/internetConnection';
import store from '../../../store/store';
import setMinVersion from '../../../store/actions/Socket/setMinVersion';

const MIN_VERSION_CODE = 11000;

class AxiosService {
  constructor() {
    this.apiUrl = config.API_URL;
    this.baseHeadersOption = { 'X-Client-Version': config.VERSION };

    const service = axios.create({
      timeout: config.API_TIMEOUT,
      crossdomain: true
    });

    service.interceptors.request.use(
      function (config) {
        config.metadata = { startTime: new Date() };
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    const state = store.getState();
    if (state.internetConnection.status === connectionStatus.OFFLINE) store.dispatch(setOnline());

    response.config.metadata.endTime = new Date();
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime;

    return response;
  }

  handleError(error) {
    if (error?.response?.data?.code === MIN_VERSION_CODE) {
      store.dispatch(setMinVersion(error.response.data.errors.min_version));
      // window.localStorage.setItem('sw_show_update_for_v', error?.response?.data?.errors?.min_version);
      //window.location.reload(true);
      return { data: { success: true }, status: error.response.status, headers: error.response.headers };
    } else if (error.code === 'ECONNABORTED') {
      console.log(`A timeout happend on url ${error.config.url}`);
      store.dispatch(setOffline());
      return Promise.reject(error.config);
    } else if (error.toJSON().message === 'Network Error') {
      store.dispatch(setOffline());
      return Promise.reject(error.config);
    }
    return Promise.reject(error.response.data);
  }

  recurseAttribute = (data) => {
    if (typeof data === 'object' && data !== null) {
      const newData = {};
      for (const key in data) {
        if (typeof data === 'object' && data !== null) {
          this.recurseAttribute(data[key]);
        } else if (data[key] !== undefined && data[key] !== null && data[key] !== '') {
          newData.append(key, data[key]);
        }
      }
      return newData;
    }
    return data;
  };

  prepareFormData = (data) => {
    // eslint-disable-next-line
    const formData = new FormData();

    for (const key in data) {
      if (data[key] !== undefined && data[key] !== null && data[key] !== '') {
        if (data[key] instanceof File) {
          formData.append(key, data[key]);
        } else formData.append(key, this.recurseAttribute(data[key]));
      }
    }
    return formData;
  };

  async post(path, data, headerData = null) {
    const formData = this.prepareFormData(data);
    const request = await this.service.request({
      url: path,
      method: 'POST',
      baseURL: this.apiUrl,
      responseType: 'json',
      data: formData,
      headers: {
        ...this.baseHeadersOption,
        userId: headerData.userId,
        Em: headerData.Em,
        Ht: headerData.Ht,
        Ct: headerData.Ct,
        Et: headerData.Et,
        companyId: headerData.companyId,
        'Content-Type': 'multipart/form-data'
      }
    });
    return request;
  }

  async put(path, data, headerData = null) {
    const request = await this.service.request({
      method: 'put',
      url: path,
      baseURL: this.apiUrl,
      responseType: 'json',
      data,
      headers: {
        ...this.baseHeadersOption,
        userId: headerData.userId,
        Em: headerData.Em,
        Ht: headerData.Ht,
        Ct: headerData.Ct,
        Et: headerData.Et,
        companyId: headerData.companyId,
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    });
    return request;
  }

  async get(path, headerData = null, data, timeout = null) {
    const request = await this.service.request({
      method: 'GET',
      url: path,
      baseURL: this.apiUrl,
      responseType: 'json',
      headers: {
        ...this.baseHeadersOption,
        userId: headerData.userId,
        Em: headerData.Em,
        Ht: headerData.Ht,
        Ct: headerData.Ct,
        Et: headerData.Et,
        companyId: headerData.companyId
      },
      params: {
        ...data
      },
      ...(timeout && { timeout })
    });
    return request;
  }

  async delete(path, headerData = null) {
    const request = await this.service.request({
      method: 'DELETE',
      url: path,
      baseURL: this.apiUrl,
      responseType: 'json',
      headers: {
        ...this.baseHeadersOption,
        userId: headerData.userId,
        Em: headerData.Em,
        Ht: headerData.Ht,
        Ct: headerData.Ct,
        Et: headerData.Et,
        companyId: headerData.companyId,
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    });
    return request;
  }
}

export default new AxiosService();
