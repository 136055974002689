import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import styles from './ActionInfo.module.css';
import SuccessFullCircleSvg from '../../../assets/images/svg/SuccessFullCircleSvg';
import ErrorFullCircleSvg from '../../../assets/images/svg/ErrorFullCircleSvg';

import Button from '../Button/Button';
import AppComponentContext from '../../../contexts/AppComponentContext';
import { useNavigate } from 'react-router-dom';
import LogoArrowSvgBig from '../../../assets/images/svg/LogoArrowSvgBig';
import { drawerContext } from '../FormDrawer/FormDrawer';
import { apiStatus as statusEnum } from '../../../hooks/useAPI';
import config from '../../../constants/config';

// const API_SUCCESS_CODES = ['200', '204'];
const API_ERROR_CODES = [20200];

const ActionInfo = ({
  messageHeader,
  message,
  btnText,
  secondBtnText,
  btnType,
  secondBtnType,
  onClose,
  secondOnClose,
  response,
  navigateBack = false,
  secondNavigateBack = false,
  type = 'success',
  twoButtons = false,
  noButtons = false,
  btnFuction,
  secondBtnFuction,
  wrapperClassName,
  apiStatus = false,
  forceError = false,
  buttonsWrapperClass,
  footerText = false,
  image = false,
  Svg = false,
  customMessageStyle = false
}) => {
  const localization = useContext(AppComponentContext).strings;
  const setDrawerProps = useContext(drawerContext)?.setDrawerProps;
  const setApiStatus = useContext(drawerContext)?.setApiStatus;
  const [errorCodes, setErrorCodes] = useState([]);
  const navigate = useNavigate();
  const renderColors = useMemo(() => {
    let colorsObj = { firstButton: '', secondButton: '' };
    switch (apiStatus) {
      case 'SUCCESS':
        if (type === 'temporary') {
          colorsObj = { firstButton: 'primary', secondButton: 'dangerOutlined', text: styles.successMessage };
        } else if (response && response[0]?.error_code) {
          colorsObj = {
            firstButton: 'dangerOutlined',
            text: styles.errorMessage
          };
        } else if (type === 'tertiary') {
          colorsObj = { firstButton: 'tertiaryOutlined' };
        } else {
          colorsObj = {
            firstButton: 'successOutlined',
            secondButton: 'successOutlined',
            text: styles.successMessage
          };
        }
        break;
      case 'FAIL':
        colorsObj = twoButtons
          ? { firstButton: 'danger', secondButton: 'dangerOutlined', text: styles.errorMessage }
          : { firstButton: 'dangerOutlined', text: styles.errorMessage };
        break;
      default:
        if (type === 'success') {
          colorsObj = { firstButton: 'successOutlined', secondButton: 'successOutlined', text: styles.successMessage };
        } else if (type === 'danger') {
          colorsObj = { firstButton: 'danger', secondButton: 'dangerOutlined', text: styles.errorMessage };
        } else if (type === 'register') {
          colorsObj = { firstButton: 'primary', secondButton: 'primaryOutlined', text: styles.successMessage };
        } else if (type === 'verification') {
          if (response?.errors?.length && response?.errors[0]?.error_code === 1601) {
            colorsObj = { firstButton: 'primary', secondButton: 'primaryOutlined', text: styles.errorMessage };
          } else {
            colorsObj = { firstButton: 'primary', secondButton: 'primaryOutlined', text: styles.successMessage };
          }
        } else {
          colorsObj = {
            firstButton: 'primary',
            secondButton: 'primaryOutlined',
            text: customMessageStyle ? customMessageStyle : styles.infoMessage
          };
        }
        break;
    }
    return colorsObj;
  }, [apiStatus, customMessageStyle, response, twoButtons, type]);

  const getErrorMessages = useCallback(() => {
    if (!forceError && errorCodes.length > 0) {
      return errorCodes?.map((errorCode) => <p key={errorCode}>{localization[errorCode]}</p>);
    } else {
      return <p>{message}</p>;
    }
  }, [errorCodes, forceError, localization, message]);

  useEffect(() => {
    if (apiStatus) {
      switch (apiStatus) {
        case 'SUCCESS':
          setDrawerProps && setDrawerProps({ style: type === 'tertiary' ? 'tertiary' : 'success' });
          setApiStatus && setApiStatus({ status: 'SUCCESS' });
          break;
        case 'ERROR':
        case 'FAIL':
          setDrawerProps && setDrawerProps({ style: 'danger' });
          break;
      }
    } else if (type) {
      switch (type) {
        case 'success':
          setDrawerProps && setDrawerProps({ style: 'success' });
          break;
        case 'danger':
          setDrawerProps && setDrawerProps({ style: 'danger' });
          break;
      }
    }
  }, [apiStatus, setApiStatus, setDrawerProps, type]);

  const renderContent = useCallback(() => {
    switch (type) {
      case 'info':
        return (
          <div className={styles.contentWrapper}>
            <div className={styles.logoSuccessContainer}>
              <LogoArrowSvgBig className={styles.logoSuccess} />
            </div>
            <div className={styles.sectionWrappSuccess}>
              {messageHeader ? (
                <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: message }} className={renderColors.text} />
            </div>
          </div>
        );
      case 'verification':
        return (
          <>
            {(response?.errors?.length && response?.errors[0].invalid_link) || apiStatus === statusEnum.FAIL ? (
              <div className={styles.contentWrapper}>
                <div className={styles.logoSuccessContainer}>
                  <ErrorFullCircleSvg className={styles.logoSuccess} />
                </div>
                <div className={styles.sectionWrappSuccess}>
                  {messageHeader ? (
                    <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
                  ) : null}
                  <div dangerouslySetInnerHTML={{ __html: message }} className={renderColors.text} />
                </div>
              </div>
            ) : (
              <div className={styles.contentWrapper}>
                <div className={styles.logoSuccessContainer}>
                  <SuccessFullCircleSvg className={styles.logoSuccess} />
                </div>
                <div className={styles.sectionWrappSuccess}>
                  {messageHeader ? (
                    <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
                  ) : null}
                  <div dangerouslySetInnerHTML={{ __html: message }} className={renderColors.text} />
                </div>
              </div>
            )}
          </>
        );
      case 'passwordReset':
        return (
          <>
            {apiStatus === 'FAIL' ? (
              <div className={styles.contentWrapper}>
                <div className={styles.logoSuccessContainer}>
                  <ErrorFullCircleSvg className={styles.logoSuccess} />
                </div>
                <div className={styles.sectionWrappSuccess}>
                  {messageHeader ? (
                    <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
                  ) : null}
                  <div dangerouslySetInnerHTML={{ __html: message }} className={renderColors.text} />
                </div>
              </div>
            ) : (
              <div className={styles.contentWrapper}>
                <div className={styles.logoSuccessContainer}>
                  <SuccessFullCircleSvg className={styles.logoSuccess} />
                </div>
                <div className={styles.sectionWrappSuccess}>
                  {messageHeader ? (
                    <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
                  ) : null}
                  <div dangerouslySetInnerHTML={{ __html: message }} className={renderColors.text} />
                </div>
              </div>
            )}
          </>
        );
      case 'danger':
        return (
          <div className={styles.contentWrapper}>
            <div className={styles.logoSuccessContainer}>
              <ErrorFullCircleSvg className={styles.logoSuccess} />
            </div>
            <div className={styles.sectionWrappSuccess}>
              {messageHeader ? (
                <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: message }} className={renderColors.text} />
            </div>
          </div>
        );
      case 'register':
        return (
          <div className={styles.contentWrapper}>
            <div className={styles.logoSuccessContainer}>
              <SuccessFullCircleSvg className={styles.logoSuccess} />
            </div>
            <div className={styles.sectionWrappSuccess}>
              {messageHeader ? (
                <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: message }} className={renderColors.text} />
            </div>
          </div>
        );
      case 'temporary':
        return (
          <>
            {errorCodes.length || forceError || apiStatus === statusEnum.FAIL ? (
              <div className={styles.contentWrapper}>
                <div className={styles.logoSuccessContainer}>
                  <ErrorFullCircleSvg className={styles.logoSuccess} />
                </div>
                <div className={styles.sectionWrappSuccess}>
                  <span className={renderColors.text}>{getErrorMessages()}</span>
                </div>
              </div>
            ) : (
              <div className={styles.imagecontentWrapper}>
                <div className={styles.imageContainer}>
                  <img src={`${config.CDN_URL}public/common/temporaryOffer.png`} alt='temporary' />
                </div>
                <div className={styles.sectionWrappSuccess}>
                  {messageHeader ? (
                    <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
                  ) : null}
                  <div
                    dangerouslySetInnerHTML={{ __html: message }}
                    className={[renderColors.text, styles.textWidth].join(' ')}
                  />
                </div>
              </div>
            )}
          </>
        );
      default:
        return (
          <>
            {errorCodes.length || forceError || apiStatus === statusEnum.FAIL ? (
              <div className={styles.contentWrapper}>
                <div className={[styles.logoSuccessContainer, image ? styles.imageClass : ''].join(' ')}>
                  {!image ? <ErrorFullCircleSvg className={styles.logoSuccess} /> : <img src={image} />}
                </div>
                <div className={styles.sectionWrappSuccess}>
                  <span className={renderColors.text}>{getErrorMessages()}</span>
                </div>
              </div>
            ) : (
              <div className={styles.contentWrapper}>
                <div className={[styles.logoSuccessContainer, image ? styles.imageClass : ''].join(' ')}>
                  {!Svg ? (
                    !image ? (
                      <SuccessFullCircleSvg className={styles.logoSuccess} />
                    ) : (
                      <img src={image} />
                    )
                  ) : (
                    <Svg />
                  )}
                </div>
                <div className={styles.sectionWrappSuccess}>
                  {messageHeader ? (
                    <span className={[styles.title, renderColors.text].join(' ')}>{messageHeader}</span>
                  ) : null}
                  <div dangerouslySetInnerHTML={{ __html: message }} className={renderColors.text} />
                </div>
              </div>
            )}
          </>
        );
    }
  }, [
    type,
    messageHeader,
    renderColors.text,
    message,
    response,
    apiStatus,
    errorCodes.length,
    forceError,
    getErrorMessages,
    image,
    Svg
  ]);

  const renderButtons = useCallback(() => {
    return (
      <>
        {footerText ? (
          <span className={[styles.footerText, buttonsWrapperClass ? buttonsWrapperClass : ''].join(' ')}>
            {footerText}
          </span>
        ) : null}
        {!noButtons ? (
          <div className={[styles.controls, buttonsWrapperClass ? buttonsWrapperClass : ''].join(' ')}>
            {twoButtons ? (
              <>
                <Button
                  type='button'
                  buttonType={btnType ? btnType : renderColors.firstButton}
                  onClick={() => {
                    btnFuction && btnFuction();
                    navigateBack && navigate('..');
                    onClose && onClose();
                  }}
                  buttonClassName={[styles.button, styles.buttonFilled].join(' ')}
                >
                  {btnText}
                </Button>
                <Button
                  type='button'
                  buttonType={secondBtnType ? secondBtnType : renderColors.secondButton}
                  onClick={() => {
                    secondBtnFuction && secondBtnFuction();
                    secondNavigateBack && navigate('..');
                    secondOnClose && secondOnClose();
                  }}
                  buttonClassName={styles.button}
                >
                  {secondBtnText}
                </Button>
              </>
            ) : (
              <Button
                type='button'
                onClick={() => {
                  btnFuction && btnFuction();
                  navigateBack && navigate('..');
                  onClose && onClose();
                }}
                buttonClassName={styles.button}
                buttonType={btnType ? btnType : renderColors.firstButton}
              >
                {btnText}
              </Button>
            )}
          </div>
        ) : null}
      </>
    );
  }, [
    btnFuction,
    btnText,
    btnType,
    buttonsWrapperClass,
    footerText,
    navigate,
    navigateBack,
    noButtons,
    onClose,
    renderColors.firstButton,
    renderColors.secondButton,
    secondBtnFuction,
    secondBtnText,
    secondBtnType,
    secondNavigateBack,
    secondOnClose,
    twoButtons
  ]);

  useEffect(() => {
    if (response) {
      const isErrorCode = API_ERROR_CODES.includes(response?.code);
      if (response?.errors) {
        if (response?.errors.length) {
          const errorCodes = response.errors.map((error) => error.error_code);
          setErrorCodes(errorCodes);
        } else {
          setErrorCodes([response?.errors.error_code]);
        }
      } else if (response?.error?.errors) {
        const errorCodes = response.error.errors.map((error) => error.error_code);

        setErrorCodes(errorCodes);
      } else if (isErrorCode) {
        setErrorCodes([response.code]);
      } else if (response[0]?.error_code) {
        setErrorCodes([response[0]?.error_code]);
        setDrawerProps && setDrawerProps({ style: 'danger' });
      }
    }
  }, [localization, response, setDrawerProps]);

  return (
    <div className={`${wrapperClassName ? wrapperClassName : styles.wrapper} ${styles.background}`}>
      <div className={styles.content}>{renderContent()}</div>
      {renderButtons()}
    </div>
  );
};

export default ActionInfo;
