import { SET_MIN_VERSION } from '../../../constants/actions';

const initialState = {
  version: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MIN_VERSION:
      return {
        ...state,
        version: action.data
      };
    default:
      return state;
  }
}
