export function getReduxStateFromStorage() {
  try {
    const serializedState = window.localStorage.getItem('__REDUX_STATE__');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export function saveReduxStateToStorage(state) {
  try {
    window.localStorage.setItem('__REDUX_STATE__', JSON.stringify(state));
  } catch (e) {
    console.log('CANNOT SAVE REDUX TO LOCAL STORAGE', e);
  }
}

export function deleteReduxStateFromStorage() {
  window.localStorage.clear();
}
