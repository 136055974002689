import { SET_FILTER_NAME_VEHICLE, SET_FILTER_NAME_CARGO, SET_FILTER_NAME_WAREHOUSE } from '../../../constants/actions';

const initialState = {
  vehicle_filter_name: '',
  cargoe_filter_name: '',
  warehousee_filter_name: ''
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FILTER_NAME_VEHICLE:
      return {
        ...state,
        vehicle_filter_name: action.state
      };
    case SET_FILTER_NAME_CARGO:
      return {
        ...state,
        cargoe_filter_name: action.state
      };
    case SET_FILTER_NAME_WAREHOUSE:
      return {
        ...state,
        warehousee_filter_name: action.state
      };
    default:
      return state;
  }
}
