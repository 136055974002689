import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../Button/Button';
import ArrowDownSvg from '../../../assets/images/svg/ArrowDownSvg';
import styles from './ButtonSelect.module.css';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';

const ButtonSelect = ({ children, onClick, title, dropdown, setDropdown, buttonType, disabled = false }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const { isPC } = useWindowDimensions();

  const handleDropdownMenu = useCallback(() => {
    setDropdown(!dropdown);
  }, [dropdown, setDropdown]);

  useEffect(() => {
    if (dropdown) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [dropdown]);

  return (
    <>
      <div className={styles.buttonWrapper}>
        <div className={styles.arrowContainer} onClick={handleDropdownMenu}>
          <ArrowDownSvg
            className={
              !dropdown
                ? isPC
                  ? styles.arrowDown
                  : styles.arrowDownResponsive
                : isPC
                  ? styles.arrowUp
                  : styles.arrowUpResponsive
            }
          />
        </div>
        <Button
          buttonClassName={styles.buttonStyle}
          buttonType={buttonType ? buttonType : 'primary'}
          onClick={onClick}
          disabled={disabled}
        >
          {title}
        </Button>
        {dropdown ? (
          <div
            className={`${isPC ? styles.dropdownMenu : styles.dropdownMenuResponsive}`}
            style={{ height, top: isPC ? -height : null }}
          >
            <div ref={ref}>{children}</div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ButtonSelect;
