import * as React from 'react';

function ClockSvg(props) {
  return (
    <svg {...props} width={12} height={12} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 16.2C12.96 16.2 16.2 12.96 16.2 9C16.2 5.04 12.96 1.8 9 1.8C5.04 1.8 1.8 5.04 1.8 9C1.8 12.96 5.04 16.2 9 16.2ZM9 0C13.95 0 18 4.05 18 9C18 13.95 13.95 18 9 18C4.05 18 0 13.95 0 9C0 4.05 4.05 0 9 0ZM13.5 8.55V9.9H8.1V4.5H9.45V8.55H13.5Z'
        // fill={fill}
      />
    </svg>
  );
}

export default ClockSvg;
