import configureStore from './configureStore';
import { getReduxStateFromStorage } from '../assets/helpers/utils';
// import throttle from 'lodash/throttle';

const initalState = getReduxStateFromStorage();
const store = configureStore(initalState);
// deleteReduxStateFromStorage();

// store.subscribe(throttle(() => {
//     saveReduxStateToStorage(store.getState());
// }, 1000));

export default store;
