import { LOGOUT } from '../../constants/actions';
import resetApp from './resetApp';
import { saveReduxStateToStorage } from '../../assets/helpers/utils';
import store from '../store';
import SocketIO from '../../assets/helpers/socket';

function logoutRequest() {
  const state = store.getState();

  // close socket on logout on all instances with same Hash
  SocketIO.logout(state.session.Ht);

  const stateToSave = {};
  const session = {
    loggedInStatus: false,
    id: null,
    Ht: null,
    Et: null,
    Ct: null
  };
  stateToSave.session = session;
  stateToSave.authNavBar = state.authNavBar;
  stateToSave.language = state.language;
  // stateToSave.userCompanies = state.userCompanies;
  stateToSave.vehicleOffersFilter = state.vehicleOffersFilter;
  stateToSave.cargoOffersFilter = state.cargoOffersFilter;

  saveReduxStateToStorage(stateToSave);

  return {
    type: LOGOUT
  };
}

export default function logout() {
  return (dispatch) => {
    dispatch(logoutRequest());
    dispatch(resetApp());
  };
}
