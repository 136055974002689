import * as React from 'react';

function LogoUpdateAnimateSvg(props) {
  return (
    <svg
      {...props}
      id='eniUZtyLDvN1'
      viewBox='0 0 128 128'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
    >
      <rect width='128' height='128' rx='6' ry='6' fill='#005b9e' strokeWidth='0' />
      <path
        id='eniUZtyLDvN3'
        d='M46.647993,27.549281c10.570762,11.146498,35.08169,36.880955,35.08169,36.880955L46.647992,100.632891'
        transform='matrix(.951228 0 0 0.995214 3.298238 0.215654)'
        fill='none'
        stroke='#fff'
        strokeWidth='10.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='eniUZtyLDvN4'
        d='M46.647993,27.549281c10.570762,11.146498,35.08169,36.880955,35.08169,36.880955L46.647992,100.632891'
        transform='matrix(.951228 0 0 0.995214 2.941781 0.215654)'
        fill='none'
        stroke='#fff'
        strokeWidth='10.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default LogoUpdateAnimateSvg;
