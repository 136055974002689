import {
  authenticatedRoutes as paths,
  nestedlVehicleRoutes,
  nestedCargoRoutes,
  nestedWarehouseRoutes,
  nestedCompanyRoutes,
  nestedPaymentRoutes
} from './paths';

import CompanySvg from '../assets/images/svg/CompanySvg';
import creditCardSuccessSvg from '../assets/images/svg/CreditCardSuccessNotify';
import creditCardSDangerSvg from '../assets/images/svg/CreditCardDangerNotify';
import PaymentRemainderPrimary from '../assets/images/svg/PaymentRemainderPrimary';
import PaymentRemainderSecondary from '../assets/images/svg/PaymentRemainderSecondary';
import SmartFilterActive from '../assets/images/svg/SmartFilterActiveSvg';
import NotificationChatSvg from '../assets/images/svg/NotificationChatSvg';

export const TYPE_SUCCESS = 1;
export const TYPE_INFO = 2;
export const TYPE_PENDING = 3;
export const TYPE_ERROR = 4;

export const NotificationMessageType = {
  USER: 1,
  COMPANY: 2,
  VEHICLE: 3,
  CARGO: 4,
  WAREHOUSE: 5,
  PAYMENT: 6,
  PAYMENT_INFO_PRIMARY: 7,
  PAYMENT_INFO_SECONDARY: 8,
  SMART_FILTER: 9
};

// Company subtypes
export const companySubtypes = {
  1: { name: 'COMPANY_INVITE_USER', path: `${paths.MANAGE_COMPANIES}/${nestedCompanyRoutes.VIEW_COMPANY}` },
  2: { name: 'COMPANY_REMOVE_USER', path: `${paths.MANAGE_COMPANIES}/${nestedCompanyRoutes.VIEW_COMPANY}` },
  3: { name: 'COMPANY_ACTIVATE_USER', path: `${paths.MANAGE_COMPANIES}/${nestedCompanyRoutes.VIEW_COMPANY}` },
  4: { name: 'COMPANY_DEACTIVATE_USER', path: `${paths.MANAGE_COMPANIES}/${nestedCompanyRoutes.VIEW_COMPANY}` },
  5: { name: 'USER_ACCEPT_INVITE', path: `${paths.MANAGE_COMPANY_USERS}/${nestedCompanyRoutes.VIEW_COMPANY_USER}` },
  6: { name: 'USER_DECLINE_INVITE', path: `${paths.MANAGE_COMPANY_USERS}/${nestedCompanyRoutes.VIEW_COMPANY_USER}` },
  25: { name: 'USER_JOIN_REQUEST', path: `${paths.MANAGE_COMPANY_USERS}/${nestedCompanyRoutes.VIEW_COMPANY_USER}` },
  26: { name: 'COMPANY_DECLINE_JOIN_REQUEST', path: `${paths.MANAGE_COMPANIES}/${nestedCompanyRoutes.VIEW_COMPANY}` },
  COMPANY_DECLINE_JOIN_REQUEST: 26,
  27: { name: 'COMPANY_ACCEPT_JOIN_REQUEST', path: `${paths.MANAGE_COMPANIES}/${nestedCompanyRoutes.VIEW_COMPANY}` },
  COMPANY_ACCEPT_JOIN_REQUEST: 27,
  28: { name: 'USER_LEFT_COMPANY', path: `${paths.MANAGE_COMPANY_USERS}/${nestedCompanyRoutes.VIEW_COMPANY_USER}` },
  40: {
    name: 'VERIFIED_COMPANY',
    header: 'VERIFIED_COMPANY_HEADER',
    icon: CompanySvg
  },
  VERIFIED_COMPANY: 40,
  71: {
    name: 'REJECTED_COMPANY',
    header: 'REJECTED_COMPANY_HEADER',
    path: `${paths.MANAGE_COMPANIES}/${nestedCompanyRoutes.VIEW_COMPANY}`,
    icon: CompanySvg
  },
  REJECTED_COMPANY: 71
};

// User codes
export const userSubtypes = {
  41: {
    name: 'USER_ROLE_UPDATE',
    path: `${paths.MANAGE_COMPANY_USERS}/${nestedCompanyRoutes.VIEW_COMPANY_USER}`,
    icon: CompanySvg
  },
  42: {
    name: 'FIRST_USER_LOGIN',
    header: 'FIRST_USER_LOGIN_HEADER',
    path: paths.DASHBOARD
  }
};

// Vehicle codes
export const vehicleSubtypes = {
  7: {
    name: 'BID_ON_VEHICLE_OFFER',
    path: `${paths.OFFERS_MY_OFFER}/:offerId/${nestedlVehicleRoutes.OFFER_RECEIVED_BID}`
  },
  8: { name: 'VEHICLE_BID_SEND_PRICE', path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}` },
  VEHICLE_BID_SEND_PRICE: 8,
  9: { name: 'VEHICLE_BID_DECLINE_BID', path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}` },
  VEHICLE_BID_DECLINE_BID: 9,
  10: {
    name: 'VEHICLE_BID_ACCEPT_PRICE',
    path: `${paths.COMPANY_AGREEMENTS}/${nestedCompanyRoutes.VIEW_VEHICLE_AGREEMENT}`
  },
  VEHICLE_BID_ACCEPT_PRICE: 10,
  11: {
    name: 'VEHICLE_BID_DECLINE_PRICE',
    path: `${paths.OFFERS_MY_OFFER}/:offerId/${nestedlVehicleRoutes.OFFER_RECEIVED_BID}`
  },
  VEHICLE_BID_DECLINE_PRICE: 11,
  12: {
    name: 'VEHICLE_BID_WITHDRAW_BID',
    path: `${paths.OFFERS_MY_OFFER}/:offerId/${nestedlVehicleRoutes.OFFER_RECEIVED_BID}`
  },
  VEHICLE_BID_WITHDRAW_BID: 12,
  13: { name: 'VEHICLE_OFFER_WITHDRAW_PRICE', path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}` },
  VEHICLE_OFFER_WITHDRAW_PRICE: 13,
  29: {
    name: 'VEHICLE_AGREEMENT_RATE',
    path: `${paths.COMPANY_AGREEMENTS}/${nestedCompanyRoutes.VIEW_VEHICLE_AGREEMENT}/${nestedCompanyRoutes.RATING_AGREEMENT}`
  },
  32: {
    name: 'VEHICLE_OFFER_EXPIRED',
    path: `${paths.OFFERS_MY_OFFER}/:offerId`
  },
  33: {
    name: 'VEHICLE_BID_OFFER_EXPIRED',
    path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}`
  },
  VEHICLE_BID_OFFER_EXPIRED: 33,
  37: {
    name: 'VEHICLE_BID_OFFER_CLOSED',
    path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}`
  },
  VEHICLE_BID_OFFER_CLOSED: 37,
  44: {
    name: 'VEHICLE_BID_OFFER_CLOSED_WHEN_ACCEPTED',
    path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}`
  },
  VEHICLE_BID_OFFER_CLOSED_WHEN_ACCEPTED: 44,
  50: {
    name: 'VEHICLE_OFFER_EDITED',
    path: `${paths.OFFERS_VEHICLE}/${nestedlVehicleRoutes.BID_ON_OFFER}`
  },
  57: {
    name: 'VEHICLE_BID_DECLINE_FINAL',
    path: `${paths.OFFERS_MY_OFFER}/:offerId/${nestedlVehicleRoutes.OFFER_RECEIVED_BID}`
  },
  VEHICLE_BID_DECLINE_FINAL: 57,
  60: {
    name: 'VEHICLE_BID_EXPIRED',
    path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}`
  },
  VEHICLE_BID_EXPIRED: 60,
  63: {
    name: 'TIME_LIMIT_BID_ON_VEHICLE_OFFER',
    path: `${paths.OFFERS_MY_OFFER}/:offerId/${nestedlVehicleRoutes.OFFER_RECEIVED_BID}`
  },
  TIME_LIMIT_BID_ON_VEHICLE_OFFER: 63,
  66: {
    name: 'TIME_LIMIT_VEHICLE_BID_SEND_PRICE',
    path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}`
  },
  TIME_LIMIT_VEHICLE_BID_SEND_PRICE: 66,
  69: {
    name: 'VEHICLE_BID_EXPIRED_ON_YOUR_OFFER',
    path: `${paths.OFFERS_VEHICLE_BIDS}/${nestedlVehicleRoutes.VIEW_BID}`
  },
  VEHICLE_BID_EXPIRED_ON_YOUR_OFFER: 69
};

//Cargo codes
export const cargoSubtypes = {
  14: {
    name: 'BID_ON_CARGO_OFFER',
    path: `${paths.OFFERS_CARGO_MY_OFFER}/:offerId/${nestedCargoRoutes.OFFER_RECEIVED_BID}`
  },
  15: { name: 'CARGO_BID_ACCEPT', path: `${paths.COMPANY_AGREEMENTS}/${nestedCompanyRoutes.VIEW_CARGO_AGREEMENT}` },
  CARGO_BID_ACCEPT: 15,
  16: { name: 'CARGO_BID_DECLINE', path: `${paths.OFFERS_CARGO_BIDS}/${nestedCargoRoutes.VIEW_BID}` },
  CARGO_BID_DECLINE: 16,
  17: {
    name: 'CARGO_BID_WITHDRAW',
    path: `${paths.OFFERS_CARGO_MY_OFFER}/:offerId/${nestedCargoRoutes.OFFER_RECEIVED_BID}`
  },
  CARGO_BID_WITHDRAW: 17,
  30: {
    name: 'CARGO_AGREEMENT_RATE',
    path: `${paths.COMPANY_AGREEMENTS}/${nestedCompanyRoutes.VIEW_CARGO_AGREEMENT}/${nestedCompanyRoutes.RATING_AGREEMENT}`
  },
  34: {
    name: 'CARGO_OFFER_EXPIRED',
    path: `${paths.OFFERS_CARGO_MY_OFFER}/:offerId`
  },
  35: {
    name: 'CARGO_BID_OFFER_EXPIRED',
    path: `${paths.OFFERS_CARGO_BIDS}/${nestedCargoRoutes.VIEW_BID}`
  },
  CARGO_BID_OFFER_EXPIRED: 35,
  36: {
    name: 'CARGO_BID_SEND_PRICE',
    path: `${paths.OFFERS_CARGO_MY_OFFER}/:offerId/${nestedCargoRoutes.OFFER_RECEIVED_BID}`
  },
  CARGO_BID_SEND_PRICE: 36,
  38: {
    name: 'CARGO_BID_OFFER_CLOSED',
    path: `${paths.OFFERS_CARGO_BIDS}/${nestedCargoRoutes.VIEW_BID}`
  },
  CARGO_BID_OFFER_CLOSED: 38,
  43: {
    name: 'CARGO_BID_OFFER_CLOSED_WHEN_ACCEPTED',
    path: `${paths.OFFERS_CARGO_BIDS}/${nestedCargoRoutes.VIEW_BID}`
  },
  CARGO_BID_OFFER_CLOSED_WHEN_ACCEPTED: 43,
  49: {
    name: 'CARGO_OFFER_EDITED',
    path: `${paths.OFFERS_CARGO}/${nestedCargoRoutes.BID_ON_OFFER}`
  },
  56: { name: 'CARGO_BID_DECLINE_FINAL', path: `${paths.OFFERS_CARGO_BIDS}/${nestedCargoRoutes.VIEW_BID}` },
  CARGO_BID_DECLINE_FINAL: 56,
  59: { name: 'CARGO_BID_EXPIRED', path: `${paths.OFFERS_CARGO_BIDS}/${nestedCargoRoutes.VIEW_BID}` },
  CARGO_BID_EXPIRED: 59,
  62: {
    name: 'TIME_LIMIT_BID_ON_CARGO_OFFER',
    path: `${paths.OFFERS_CARGO_MY_OFFER}/:offerId/${nestedCargoRoutes.OFFER_RECEIVED_BID}`
  },
  TIME_LIMIT_BID_ON_CARGO_OFFER: 62,
  65: {
    name: 'TIME_LIMIT_CARGO_BID_SEND_PRICE',
    path: `${paths.OFFERS_CARGO_MY_OFFER}/:offerId/${nestedCargoRoutes.OFFER_RECEIVED_BID}`
  },
  TIME_LIMIT_CARGO_BID_SEND_PRICE: 65,
  68: {
    name: 'CARGO_BID_EXPIRED_ON_YOUR_OFFER',
    path: `${paths.OFFERS_CARGO_MY_OFFER}/:offerId/${nestedCargoRoutes.OFFER_RECEIVED_BID}`
  },
  CARGO_BID_EXPIRED_ON_YOUR_OFFER: 68,
  72: {
    name: 'CARGO_PRIVATE_OFFER_CONTACT',
    path: `${paths.OFFERS_CARGO}/${nestedCargoRoutes.BID_ON_OFFER}`
  },
  CARGO_PRIVATE_OFFER_CONTACT: 72
};

// Warehouse codes
export const warehouseSubtypes = {
  18: {
    name: 'BID_ON_WAREHOUSE_OFFER',
    path: `${paths.OFFERS_WAREHOUSE_MY_OFFER}/:offerId/${nestedWarehouseRoutes.OFFER_RECEIVED_BID}`
  },
  19: {
    name: 'WAREHOUSE_BID_SEND_PRICE',
    path: `${paths.OFFERS_WAREHOUSE_BIDS}/${nestedWarehouseRoutes.VIEW_BID}`
  },
  WAREHOUSE_BID_SEND_PRICE: 19,
  20: {
    name: 'WAREHOUSE_BID_DECLINE_BID',
    path: `${paths.OFFERS_WAREHOUSE_BIDS}/${nestedWarehouseRoutes.VIEW_BID}`
  },
  WAREHOUSE_BID_DECLINE_BID: 20,
  21: {
    name: 'WAREHOUSE_BID_ACCEPT_PRICE',
    path: `${paths.COMPANY_AGREEMENTS}/${nestedCompanyRoutes.VIEW_WAREHOUSE_AGREEMENT}`
  },
  WAREHOUSE_BID_ACCEPT_PRICE: 21,
  22: {
    name: 'WAREHOUSE_BID_DECLINE_PRICE',
    path: `${paths.OFFERS_WAREHOUSE_MY_OFFER}/:offerId/${nestedWarehouseRoutes.OFFER_RECEIVED_BID}`
  },
  WAREHOUSE_BID_DECLINE_PRICE: 22,
  23: {
    name: 'WAREHOUSE_BID_WITHDRAW_BID',
    path: `${paths.OFFERS_WAREHOUSE_MY_OFFER}/:offerId/${nestedWarehouseRoutes.OFFER_RECEIVED_BID}`
  },
  WAREHOUSE_BID_WITHDRAW_BID: 23,
  24: {
    name: 'WAREHOUSE_OFFER_WITHDRAW_PRICE',
    path: `${paths.OFFERS_WAREHOUSE_BIDS}/${nestedWarehouseRoutes.VIEW_BID}`
  },
  WAREHOUSE_OFFER_WITHDRAW_PRICE: 24,
  31: {
    name: 'WAREHOUSE_AGREEMENT_RATE',
    path: `${paths.COMPANY_AGREEMENTS}/${nestedCompanyRoutes.VIEW_WAREHOUSE_AGREEMENT}/${nestedCompanyRoutes.RATING_AGREEMENT}`
  },
  39: {
    name: 'WAREHOUSE_BID_OFFER_CLOSED',
    path: `${paths.OFFERS_WAREHOUSE_BIDS}/${nestedWarehouseRoutes.VIEW_BID}`
  },
  WAREHOUSE_BID_OFFER_CLOSED: 39,
  45: {
    name: 'WAREHOUSE_BID_OFFER_CLOSED_WHEN_ACCEPTED',
    path: `${paths.OFFERS_WAREHOUSE_BIDS}/${nestedWarehouseRoutes.VIEW_BID}`
  },
  WAREHOUSE_BID_OFFER_CLOSED_WHEN_ACCEPTED: 45,
  48: {
    name: 'WAREHOUSE_OFFER_EDITED',
    path: `${paths.OFFERS_WAREHOUSE}/${nestedWarehouseRoutes.BID_ON_OFFER}`
  },
  58: {
    name: 'WAREHOUSE_BID_DECLINE_FINAL',
    path: `${paths.OFFERS_WAREHOUSE_MY_OFFER}/:offerId/${nestedWarehouseRoutes.OFFER_RECEIVED_BID}`
  },
  WAREHOUSE_BID_DECLINE_FINAL: 58,
  61: {
    name: 'WAREHOUSE_BID_EXPIRED',
    path: `${paths.OFFERS_WAREHOUSE_BIDS}/${nestedWarehouseRoutes.VIEW_BID}`
  },
  WAREHOUSE_BID_EXPIRED: 61,
  64: {
    name: 'TIME_LIMIT_BID_ON_WAREHOUSE_OFFER',
    path: `${paths.OFFERS_WAREHOUSE_MY_OFFER}/:offerId/${nestedWarehouseRoutes.OFFER_RECEIVED_BID}`
  },
  TIME_LIMIT_BID_ON_WAREHOUSE_OFFER: 64,
  67: {
    name: 'TIME_LIMIT_WAREHOUSE_BID_SEND_PRICE',
    path: `${paths.OFFERS_WAREHOUSE_BIDS}/${nestedWarehouseRoutes.VIEW_BID}`
  },
  TIME_LIMIT_WAREHOUSE_BID_SEND_PRICE: 67,
  70: {
    name: 'WAREHOUSE_BID_EXPIRED_ON_YOUR_OFFER',
    path: `${paths.OFFERS_WAREHOUSE_BIDS}/${nestedWarehouseRoutes.VIEW_BID}`
  },
  WAREHOUSE_BID_EXPIRED_ON_YOUR_OFFER: 70
};

export const paymentSubtypes = {
  46: {
    name: 'PAYMENT_SUCCESSFUL',
    path: `${paths.BILLING}/${nestedPaymentRoutes.INVOICE}/:id`,
    icon: creditCardSuccessSvg
  },

  47: {
    name: 'PAYMENT_FAIL',
    path: paths.BILLING,
    icon: creditCardSDangerSvg
  },
  51: {
    name: 'PAYMENT_REMINDER_FIRST',
    path: paths.BILLING,
    icon: PaymentRemainderPrimary
  },
  52: {
    name: 'PAYMENT_REMINDER_SECOND',
    path: paths.BILLING,
    icon: PaymentRemainderSecondary
  },
  53: {
    name: 'PAYMENT_REMINDER_THIRD',
    path: paths.BILLING,
    icon: PaymentRemainderSecondary
  },
  54: {
    name: 'PAYMENT_REMINDER_FOURTH',
    path: paths.BILLING,
    icon: PaymentRemainderSecondary
  }
};

const filterSubtypes = {
  55: {
    name: 'NEW_FILTER_OFFERS',
    path: paths.SAVED_FILTERS,
    icon: SmartFilterActive
  }
};

const chatNotifications = {
  56: {
    name: 'CHAT_MESSAGE_RECEIVED',
    icon: NotificationChatSvg
  }
};

export const allSubtypes = {
  ...companySubtypes,
  ...userSubtypes,
  ...vehicleSubtypes,
  ...cargoSubtypes,
  ...warehouseSubtypes,
  ...paymentSubtypes,
  ...filterSubtypes,
  ...chatNotifications
};
