import React, { useCallback, useContext, useState } from 'react';
import styles from './UpdateModalComponent.module.css';
import AppComponentContext from '../../../contexts/AppComponentContext';
import Modal from 'react-modal';
import Button from '../Button/Button';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import LogoSvg from '../../../assets/images/svg/LogoSvg';
import useServiceWorker from '../../../hooks/useServiceWorker';
import LogoUpdateSvg from '../../../assets/images/svg/LogoUpdateSvg';
import CircleLoader from '../Loader/CircleLoader';
import logout from '../../../store/actions/logout';

Modal.setAppElement('body');

const UpdateModalComponent = React.forwardRef(({ reverseButtons, isFetching, style = 'primary' }, ref) => {
  const { isMobile } = useWindowDimensions();
  const localization = useContext(AppComponentContext).strings;
  const [updateClicked, setUpdatedClicked] = useState(false);
  const [isUpdateAvailable, updateCache, , isNewPatch, isUpdating, isUpdateReady] = useServiceWorker();

  const getStyleClassName = () => {
    switch (style) {
      case 'primary':
        return styles.primary;
      case 'success':
        return styles.success;
      case 'danger':
        return styles.danger;
      case 'tertiary':
        return styles.tertiary;
      default:
        return styles.primary;
    }
  };

  const styleClassName = getStyleClassName();

  const handleReload = () => {
    localStorage.clear();
    logout();
    window.location.reload(true);
  };

  const handleAnimateAndUpdate = useCallback(() => {
    setUpdatedClicked(true);
    setTimeout(() => {
      setUpdatedClicked(false);
      updateCache();
    }, 3000);
  }, [updateCache]);

  return (
    <>
      {isUpdateAvailable ? (
        <Modal
          ref={ref}
          className={`${styles.modal} ${isMobile ? styles.modalResponsive : ''}`}
          isOpen={isUpdateAvailable}
          overlayClassName={styles.overlay}
        >
          <div className={`${styles.modalContainer} ${isMobile ? styles.modalContainerMobile : ''}`}>
            <div className={`${styles.modalTitle} ${isMobile ? styles.modalTitleMobile : ''} ${styleClassName}`}>
              <span>{localization.updateAvailable}</span>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.message}>
                <p>{localization.newVersionOf}</p>
              </div>
              <div>
                {updateClicked ? (
                  <LogoUpdateSvg className={styles.logoAnimated} />
                ) : (
                  <LogoSvg className={styles.logo} />
                )}
              </div>
              <div className={styles.message}>
                <p>{localization.applicationIsAvailable}</p>
              </div>
            </div>
            <div
              className={`${isFetching || reverseButtons ? styles.modalButtons : styles.modalButtonsReversed} ${
                isMobile ? styles.buttonControlsMobile : ''
              }`}
            >
              <div className={styles.buttons}>
                {isNewPatch ? (
                  <Button
                    buttonType={style}
                    isFetching={isFetching || isUpdating}
                    buttonClassName={`${styles.modalButtonBlue} ${styleClassName}`}
                    onClick={handleReload}
                    disableModal
                  >
                    {localization.updateApplicationNow}
                  </Button>
                ) : isUpdateReady ? (
                  <Button
                    buttonType={style}
                    isFetching={isFetching || isUpdating}
                    buttonClassName={`${styles.modalButtonBlue} ${styleClassName}`}
                    onClick={handleAnimateAndUpdate}
                    disableModal
                  >
                    {localization.updateApplicationNow}
                  </Button>
                ) : (
                  <CircleLoader width={35} height={35} />
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
});

UpdateModalComponent.displayName = 'UpdateModalComponent';

export default UpdateModalComponent;
