import React, { useMemo, useContext, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { parseDateDMY } from '../../../assets/helpers/dateTimeUtils';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import LogoSvg from '../../../assets/images/svg/LogoSvg';
import AppComponentContext from '../../../contexts/AppComponentContext';
import styles from './CmrGenerator.module.css';

const CmrTheme = (props) => {
  const localization = useContext(AppComponentContext).strings;
  const { isPC } = useWindowDimensions();
  const { getValues } = useFormContext() || {};
  const { sender, receiver, pickup_date } = props.agreementInfo || {};
  const isAgreement = sender && receiver ? true : false;
  const {
    cargo_description,
    cash_on_delivery,
    company_name,
    delivery_point,
    generator_packages,
    incoterms,
    pickup_point,
    remarks,
    sender_info,
    uploaded_documents
  } = sender || {};
  const { receiver_company_name, receiver_remarks } = receiver || {};

  const handleTheme = useCallback(
    (pixel) => {
      return `${pixel}px solid ${props.theme == 'blackCopy' ? 'black' : props.theme}`;
    },
    [props.theme]
  );

  const handleHeadingNumber = useMemo(() => {
    switch (props.theme) {
      case 'black':
        return '1';
      case 'red':
        return '2';
      case 'blue':
        return '3';
      case 'green':
        return '4';
      case 'blackCopy':
        return '5';
    }
  }, [props.theme]);

  const handleHeadingTitle = useMemo(() => {
    switch (props.theme) {
      case 'black':
        return (
          <>
            <p>Examplar fur den Absender</p>
            <p>Copy for sender</p>
          </>
        );
      case 'red':
        return (
          <>
            <p>Exemplar fur den Empfanger</p>
            <p>Copy for consignee</p>
          </>
        );
      case 'blue':
        return (
          <>
            <p>Examplar fur den Frachtfuhrer</p>
            <p>Copy for carrier</p>
          </>
        );
      case 'green':
      case 'blackCopy':
        return (
          <>
            <p>Examplar fur</p>
            <p>Copy for</p>
          </>
        );
    }
  }, [props.theme]);

  const handleHeadingInfo = useMemo(() => {
    return (
      <div className={styles.headingInfo}>
        <div className={styles.number}>
          <p>{handleHeadingNumber}</p>
        </div>
        <div className={styles.cmrType}>{handleHeadingTitle}</div>
      </div>
    );
  }, [handleHeadingNumber, handleHeadingTitle]);

  return (
    <>
      <div
        className={`${styles.mainContainer} ${!isPC && !props.downloadState ? styles.mainContainerResponsive : ''} ${
          props.customHeight && props.customHeight
        }`}
      >
        <div
          className={`${styles.heading} ${!isPC ? styles.headingResponsive : ''}`}
          style={{ backgroundColor: props.theme }}
        >
          {handleHeadingInfo}
        </div>
        <div className={styles.formContainerWrapper}>
          <div className={styles.asideContainer}>
            <div className={styles.asideLeft_1}>
              <p>{`Auszfullen auf Verantwortung des Absenders`}</p>
              <p>{`Auszfullen auf Verantwortung des Absenders`}</p>
              <p>{`To be completed on sender's responsibility`}</p>
            </div>
            <div className={styles.asideLeft_3}>
              <div className={styles.asideBig}>
                <div className={styles.asideNumber}>1-15</div>
                <div className={styles.asideNumberText}>
                  <p>einschlieslicht</p>
                  <p>including</p>
                </div>
              </div>
            </div>
            <div className={styles.asideLeft_2}>
              <div className={styles.asideBig}>
                <div className={styles.asideNumber}>19+20+21+22</div>
                <div className={styles.asideNumberText}>
                  <p>Die mit fett gedruckten Linien eingerahmten Rubriken mussen</p>
                  <p>The spaces framed with heavy lines must be filled</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.formContainer} style={{ borderLeft: handleTheme(1), borderRight: handleTheme(1) }}>
            <div className={styles.firstSectionWrapper} style={{ borderBottom: handleTheme(1) }}>
              <div>
                <div className={styles.columnHigh} style={{ borderBottom: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>1</p>
                    </div>
                    <div>
                      <p>{`Absender (Name, Adresse, Land)`} </p>
                      <p>{`Sender (name, address, country)`} </p>
                    </div>
                  </div>

                  <>
                    <textarea
                      className={styles.inputHigh}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.company_name')
                            ? getValues('cmr_template.company_name')
                            : null
                          : company_name
                      }
                    />
                  </>
                </div>
                <div className={styles.columnHigh} style={{ borderBottom: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>2</p>
                    </div>
                    <div>
                      <p>{`Empfanger (Name, Adresse, Land)`} </p>
                      <p>{`Consignee (name, address, country)`} </p>
                    </div>
                  </div>

                  <>
                    <textarea
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.sender_info')
                            ? getValues('cmr_template.sender_info')
                            : null
                          : sender_info
                      }
                      className={styles.inputHigh}
                      placeholder='input text'
                    ></textarea>
                  </>
                </div>
                <div className={styles.columnLower} style={{ borderBottom: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>3</p>
                    </div>
                    <div>
                      <p>{`Auslieferot des Gutes (Ort, Land)`} </p>
                      <p>{`Place of delivery of the goods (place, country)`} </p>
                    </div>
                  </div>

                  <>
                    <textarea
                      maxLength={180}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.delivery_point')
                            ? getValues('cmr_template.delivery_point')
                            : null
                          : delivery_point
                      }
                      className={styles.inputLower}
                    ></textarea>
                  </>
                </div>
                <div className={styles.columnLower} style={{ borderBottom: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>4</p>
                    </div>
                    <div>
                      <p>{`Ort und Datum der Ubernahme des Gutes (Ort, Land, Datum)`} </p>
                      <p>{`Place and date of taking over the goods (place, country, date)`} </p>
                    </div>
                  </div>

                  <>
                    <textarea
                      maxLength={180}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.pickup_point')
                            ? getValues('cmr_template.pickup_point')
                            : null
                          : pickup_point
                      }
                      className={styles.inputLower}
                    ></textarea>
                  </>
                </div>
                <div className={styles.columnLower}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>5</p>
                    </div>
                    <div>
                      <p>{`Belgefungte Dokumente`} </p>
                      <p>{`Documents attached`} </p>
                    </div>
                  </div>

                  <>
                    <textarea
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.uploaded_documents')
                            ? getValues('cmr_template.uploaded_documents')
                            : null
                          : uploaded_documents
                      }
                      className={styles.inputLower}
                    ></textarea>
                  </>
                </div>
              </div>
              <div>
                <div className={styles.columnShort} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.logoRowContainer}>
                    <div className={styles.logoColumnTitle}>
                      <p>INTERNATIONALER FRACHTBRIEF</p>
                      <p>INTERNATIONAL CONSIGNEE NOTE</p>
                    </div>
                    <div className={styles.logo}>
                      <p>№</p>
                      <div className={styles.cmrLogo}>CMR</div>
                    </div>
                  </div>
                  <div className={styles.logoRowContainer}>
                    <div className={styles.logoTextLeft}>
                      <p>{`Diese Beforderung unterliegt, unbeschadet andrea lautender Bestimmungen, dem Ubereinkommen uber der Vertag uber den internationalen Guterkrafverkehr(CMR)`}</p>
                    </div>
                    <div className={styles.logoTextLeft}>
                      <p>{`This carriage is subject, notwithstanding any clause to tje contray, to the Convention on the Contract for the international Carriage of goods by road(CMR)`}</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${!props.view ? styles.disabledColor : ''} ${
                    props.downloadState ? styles.downloadDisabledColor : ''
                  }`}
                >
                  <div
                    className={styles.columnShort}
                    style={{
                      borderBottom: handleTheme(1),
                      borderTop: handleTheme(2),
                      borderLeft: handleTheme(2),
                      borderRight: handleTheme(2)
                    }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>16</p>
                      </div>
                      <div>
                        <p>{`Frachtfuhrer (Name, Adresse, Land)`} </p>
                        <p>{`Carrier (name, address, country)`} </p>
                      </div>
                    </div>
                    <textarea defaultValue={isAgreement ? receiver_company_name : ''} />
                  </div>
                  <div
                    className={styles.columnLowerShort}
                    style={{ borderLeft: handleTheme(2), borderRight: handleTheme(2), borderBottom: handleTheme(1) }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>17</p>
                      </div>
                      <div>
                        <p>{`Nachfolgender Frachtfuhrer (Name, Adresse, Land)`} </p>
                        <p>{`Successive carriers (name, address, country)`} </p>
                      </div>
                    </div>

                    <textarea defaultValue={!isAgreement ? receiver_remarks : ''} />
                  </div>
                  <div
                    style={{
                      borderLeft: handleTheme(2),
                      borderRight: handleTheme(2),
                      borderBottom: handleTheme(2),
                      minHeight: 152,
                      maxHeight: 152
                    }}
                  >
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>18</p>
                      </div>
                      <div>
                        <p>{`Vorbehalte und Bemerkungen der Frachtfuhrer`} </p>
                        <p>{`Carrier's reservations and observations`} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.secondSectionWrapper}>
              <LogoSvg className={styles.cargoLogo} />
              <div className={styles.leftSideContainer}>
                <div className={styles.topBlock} style={{ borderBottom: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>6</p>
                    </div>
                    <div className={styles.secondSectionContainer}>
                      <p>{`Kennzeichen u. Nummern`} </p>
                      <p>{`Marks and Nos`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.0.consolidation_name')
                                ? getValues('cmr_template.generator_packages.0.consolidation_name')
                                : null
                              : generator_packages[0]?.consolidation_name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.1.consolidation_name')
                                ? getValues('cmr_template.generator_packages.1.consolidation_name')
                                : null
                              : generator_packages[1]?.consolidation_name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.2.consolidation_name')
                                ? getValues('cmr_template.generator_packages.2.consolidation_name')
                                : null
                              : generator_packages[2]?.consolidation_name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.3.consolidation_name')
                                ? getValues('cmr_template.generator_packages.3.consolidation_name')
                                : null
                              : generator_packages[3]?.consolidation_name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.4.consolidation_name')
                                ? getValues('cmr_template.generator_packages.4.consolidation_name')
                                : null
                              : generator_packages[4]?.consolidation_name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.5.consolidation_name')
                                ? getValues('cmr_template.generator_packages.5.consolidation_name')
                                : null
                              : generator_packages[5]?.consolidation_name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>7</p>
                    </div>
                    <div>
                      <p>{`Anzahl der Pakete`} </p>
                      <p>{`Number of packages`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.0.num_of_units')
                                ? getValues('cmr_template.generator_packages.0.num_of_units')
                                : null
                              : generator_packages[0]?.num_of_units
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.1.num_of_units')
                                ? getValues('cmr_template.generator_packages.1.num_of_units')
                                : null
                              : generator_packages[1]?.num_of_units
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.2.num_of_units')
                                ? getValues('cmr_template.generator_packages.2.num_of_units')
                                : null
                              : generator_packages[2]?.num_of_units
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.3.num_of_units')
                                ? getValues('cmr_template.generator_packages.3.num_of_units')
                                : null
                              : generator_packages[3]?.num_of_units
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.4.num_of_units')
                                ? getValues('cmr_template.generator_packages.4.num_of_units')
                                : null
                              : generator_packages[4]?.num_of_units
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.5.num_of_units')
                                ? getValues('cmr_template.generator_packages.5.num_of_units')
                                : null
                              : generator_packages[5]?.num_of_units
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>8</p>
                    </div>
                    <div>
                      <p>{`Art der Verpackung`} </p>
                      <p>{`Method of packing`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.0.packaging_method')
                                ? getValues('cmr_template.generator_packages.0.packaging_method')
                                : null
                              : generator_packages[0]?.packaging_method
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.1.packaging_method')
                                ? getValues('cmr_template.generator_packages.1.packaging_method')
                                : null
                              : generator_packages[1]?.packaging_method
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.2.packaging_method')
                                ? getValues('cmr_template.generator_packages.2.packaging_method')
                                : null
                              : generator_packages[2]?.packaging_method
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.3.packaging_method')
                                ? getValues('cmr_template.generator_packages.3.packaging_method')
                                : null
                              : generator_packages[3]?.packaging_method
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.4.packaging_method')
                                ? getValues('cmr_template.generator_packages.4.packaging_method')
                                : null
                              : generator_packages[4]?.packaging_method
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.5.packaging_method')
                                ? getValues('cmr_template.generator_packages.5.packaging_method')
                                : null
                              : generator_packages[5]?.packaging_method
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>9</p>
                    </div>
                    <div>
                      <p>{`Bezeichnung des Gutes`} </p>
                      <p>{`Nature of the goods`} </p>
                      <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.0.name')
                                ? getValues('cmr_template.generator_packages.0.name')
                                : null
                              : generator_packages[0]?.name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.1.name')
                                ? getValues('cmr_template.generator_packages.1.name')
                                : null
                              : generator_packages[1]?.name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.2.name')
                                ? getValues('cmr_template.generator_packages.2.name')
                                : null
                              : generator_packages[2]?.name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.3.name')
                                ? getValues('cmr_template.generator_packages.3.name')
                                : null
                              : generator_packages[3]?.name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.4.name')
                                ? getValues('cmr_template.generator_packages.4.name')
                                : null
                              : generator_packages[4]?.name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />{' '}
                        <textarea
                          style={{ paddingLeft: 'unset', width: '88px' }}
                          maxLength={20}
                          defaultValue={
                            !isAgreement
                              ? getValues('cmr_template.generator_packages.5.name')
                                ? getValues('cmr_template.generator_packages.5.name')
                                : null
                              : generator_packages[5]?.name
                          }
                          className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.bottomBlock}>
                  <div className={styles.row_1}>
                    <p>UN-Nr</p>
                    <p>Ben. s. Nr.9</p>
                    <p>Gefahrzettelmuster-Nr.</p>
                    <p>Verp.-Grp.</p>
                  </div>
                  <div className={styles.row_2}>
                    <p>name s. nr.9</p>
                    <p>Hazard label sample no.</p>
                    <p>Pack. group</p>
                  </div>
                </div>
              </div>
              <div className={styles.rightSideContainer}>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>10</p>
                    </div>
                    <div>
                      <p>{`Statistiknr`} </p>
                      <p>{`Statistical nr.`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.0.statistical_nr')
                            ? getValues('cmr_template.generator_packages.0.statistical_nr')
                            : null
                          : generator_packages[0]?.statistical_nr
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.1.statistical_nr')
                            ? getValues('cmr_template.generator_packages.1.statistical_nr')
                            : null
                          : generator_packages[1]?.statistical_nr
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.2.statistical_nr')
                            ? getValues('cmr_template.generator_packages.2.statistical_nr')
                            : null
                          : generator_packages[2]?.statistical_nr
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.3.statistical_nr')
                            ? getValues('cmr_template.generator_packages.3.statistical_nr')
                            : null
                          : generator_packages[3]?.statistical_nr
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.4.statistical_nr')
                            ? getValues('cmr_template.generator_packages.4.statistical_nr')
                            : null
                          : generator_packages[4]?.statistical_nr
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.5.statistical_nr')
                            ? getValues('cmr_template.generator_packages.5.statistical_nr')
                            : null
                          : generator_packages[5]?.statistical_nr
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />
                  </div>
                </div>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>11</p>
                    </div>
                    <div>
                      <p>{`Bruttogew. kg`} </p>
                      <p>{`Gross weight kg`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.0.unit_weight')
                            ? getValues('cmr_template.generator_packages.0.unit_weight')
                            : null
                          : generator_packages[0]?.unit_weight
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.1.unit_weight')
                            ? getValues('cmr_template.generator_packages.1.unit_weight')
                            : null
                          : generator_packages[0]?.unit_weight
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.2.unit_weight')
                            ? getValues('cmr_template.generator_packages.2.unit_weight')
                            : null
                          : generator_packages[0]?.unit_weight
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.3.unit_weight')
                            ? getValues('cmr_template.generator_packages.3.unit_weight')
                            : null
                          : generator_packages[3]?.unit_weight
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.4.unit_weight')
                            ? getValues('cmr_template.generator_packages.4.unit_weight')
                            : null
                          : generator_packages[4]?.unit_weight
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.5.unit_weight')
                            ? getValues('cmr_template.generator_packages.5.unit_weight')
                            : null
                          : generator_packages[5]?.unit_weight
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />
                  </div>
                </div>
                <div className={styles.row} style={{ borderLeft: handleTheme(1) }}>
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>12</p>
                    </div>
                    <div>
                      <p>{`Volumen in m3`} </p>
                      <p>{`Volume in m3`} </p>
                    </div>
                  </div>
                  <div className={[styles.secondSectionInputParent, 'parentClass'].join(' ')}>
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.0.volume_l')
                            ? getValues('cmr_template.generator_packages.0.volume_l')
                            : null
                          : generator_packages[0]?.volume_l
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.1.volume_l')
                            ? getValues('cmr_template.generator_packages.1.volume_l')
                            : null
                          : generator_packages[1]?.volume_l
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.2.volume_l')
                            ? getValues('cmr_template.generator_packages.2.volume_l')
                            : null
                          : generator_packages[2]?.volume_l
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.3.volume_l')
                            ? getValues('cmr_template.generator_packages.3.volume_l')
                            : null
                          : generator_packages[3]?.volume_l
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />{' '}
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.4.volume_l')
                            ? getValues('cmr_template.generator_packages.4.volume_l')
                            : null
                          : generator_packages[4]?.volume_l
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />
                    <textarea
                      style={{ width: '68px' }}
                      maxLength={14}
                      defaultValue={
                        !isAgreement
                          ? getValues('cmr_template.generator_packages.5.volume_l')
                            ? getValues('cmr_template.generator_packages.5.volume_l')
                            : null
                          : generator_packages[5]?.volume_l
                      }
                      className={[styles.inputSecondSection, 'textAreaClass'].join(' ')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.thirdSectionWrapper}>
              <div
                className={styles.sendersInstructions}
                style={{ borderBottom: handleTheme(1), borderTop: handleTheme(1) }}
              >
                <div className={styles.sectionInfo}>
                  <div className={styles.smallNumber}>
                    <p>13</p>
                  </div>
                  <div>
                    <p>{`Anweisungen des Absenders (Zoll-, amtl. Behandungen, Sondervorschriften, etc)`} </p>
                    <p>{`Sender's instructions`} </p>
                  </div>
                </div>

                <>
                  <textarea
                    maxLength={514}
                    defaultValue={
                      !isAgreement
                        ? getValues('cmr_template.cargo_description')
                          ? getValues('cmr_template.cargo_description')
                          : null
                        : cargo_description
                    }
                    className={styles.inputInstructions}
                  ></textarea>
                </>
              </div>
              <div className={styles.rightSideTable}>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    borderLeft: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div className={styles.sectionInfo}>
                    <div className={styles.smallNumber}>
                      <p>19</p>
                    </div>
                    <div>
                      <p>{`Zu bezahlen von`} </p>
                      <p>{`To be paid by`} </p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(1),
                    borderTop: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '5px'
                    }}
                  >
                    <p>{`Absender`} </p>
                    <p>{`Sender`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '5px'
                    }}
                  >
                    <p>{`Wahrung`} </p>
                    <p>{`Currency`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(1),
                    borderTop: handleTheme(2),
                    borderRight: handleTheme(2),
                    lineHeight: 1.2
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '5px'
                    }}
                  >
                    <p>{`Empfanger`} </p>
                    <p>{`Consignee`} </p>
                  </div>
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(2)
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '2px',
                      lineHeight: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <p>{`Fracht`} </p>
                    <p>{`Carriage`} </p>
                    <p>{`Ermasgung`} </p>
                    <p>{`Reductions`} </p>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{
                    borderBottom: handleTheme(1)
                  }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  />
                  <div
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  />
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div className={styles.verticalLine} style={{ borderLeft: handleTheme(3) }} />
                  <div
                    className={styles.verticalBlock}
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                  />
                  <div className={styles.verticalBlock} style={{ borderLeft: handleTheme(1) }} />
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldTwo} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  />
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  />
                </div>

                <div
                  className={styles.gridFragment}
                  style={{
                    borderBottom: handleTheme(1),
                    borderLeft: handleTheme(2)
                  }}
                >
                  <div style={{ paddingLeft: '2px', lineHeight: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <p>{`Zwischensumme`} </p>
                    <p>{`Balance`} </p>
                    <p>{`Zuschlage`} </p>
                    <p>{`Supplement charges`} </p>
                    <p>{`Nebengebuhren`} </p>
                    <p>{`Additional charges`} </p>
                    <p>{`Sonstiges`} </p>
                    <p>{`Miscellaneous`} </p>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  />
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  />
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                  ></div>
                  <div
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  ></div>
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{ borderBottom: handleTheme(1) }}
                >
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  />
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderBottom: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  />
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1)
                    }}
                    className={styles.verticalBlock}
                  />
                  <div
                    className={styles.verticalBlock}
                    style={{
                      borderLeft: handleTheme(1)
                    }}
                  />
                </div>
                <div
                  className={`${styles.gridFragment} ${styles.gridFieldThree} ${
                    !props.view ? styles.disabledColor : ''
                  } ${props.downloadState ? styles.downloadDisabledColor : ''}`}
                  style={{
                    borderBottom: handleTheme(1)
                  }}
                >
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  />
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  />
                  <div
                    style={{
                      borderBottom: handleTheme(1),
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  />
                  <div
                    style={{
                      borderLeft: handleTheme(1),
                      borderRight: handleTheme(2)
                    }}
                  />
                </div>
                <div
                  className={styles.gridFragment}
                  style={{
                    borderLeft: handleTheme(2),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div style={{ paddingLeft: '2px', lineHeight: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <p>{`Gesamtbetrag`} </p>
                    <p>{`To be paid`} </p>
                  </div>
                </div>
                <div
                  className={`${styles.gridFragment} ${!props.view ? styles.disabledColor : ''} ${
                    props.downloadState ? styles.downloadDisabledColor : ''
                  }`}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(2)
                  }}
                />
                <div
                  className={`${styles.gridFragment} ${!props.view ? styles.disabledColor : ''} ${
                    props.downloadState ? styles.downloadDisabledColor : ''
                  }`}
                  style={{
                    borderLeft: handleTheme(1),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div className={styles.verticalBlock}></div>
                </div>
                <div
                  className={`${styles.gridFragment} ${!props.view ? styles.disabledColor : ''} ${
                    props.downloadState ? styles.downloadDisabledColor : ''
                  }`}
                  style={{
                    borderLeft: handleTheme(1),
                    borderRight: handleTheme(2),
                    borderBottom: handleTheme(2)
                  }}
                >
                  <div
                    style={{
                      paddingLeft: '5px'
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className={styles.fourthSectionWrapper}>
              <div className={styles.cashOnDelivery} style={{ borderBottom: handleTheme(1) }}>
                <div className={styles.sectionInfo}>
                  <div className={styles.smallNumber}>
                    <p>14</p>
                  </div>
                  <div>
                    <p>{`Ruckerstattung`} </p>
                    <p>{`Cash on delivery`} </p>
                  </div>

                  <textarea
                    maxLength={22}
                    defaultValue={
                      !isAgreement
                        ? getValues('cmr_template.cash_on_delivery')
                          ? getValues('cmr_template.cash_on_delivery')
                          : null
                        : cash_on_delivery
                    }
                  />
                </div>
              </div>
              <div className={styles.bottomWrapper}>
                <div className={styles.topSection}>
                  <div
                    className={styles.leftSideWrapper}
                    style={{ borderRight: handleTheme(1), borderBottom: handleTheme(1) }}
                  >
                    <div className={styles.topDiv} style={{ borderBottom: handleTheme(1) }}>
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>15</p>
                        </div>
                        <div>
                          <p>{`Frachtzahlungsanweisungen`} </p>
                          <p>{`Instruction as to payment carriage`} </p>
                          <p>Frei/Carriage paid</p>
                          <p>Unfrei/Carriage forward</p>
                        </div>

                        <>
                          <textarea
                            maxLength={44}
                            defaultValue={
                              !isAgreement
                                ? getValues('cmr_template.incoterms')
                                  ? getValues('cmr_template.incoterms')
                                  : null
                                : incoterms
                            }
                            placeholder='input text'
                            style={{ alignSelf: 'center' }}
                          ></textarea>
                        </>
                      </div>
                    </div>
                    <div
                      className={`${styles.bottomDiv} ${!props.view ? styles.disabledColor : ''} ${
                        props.downloadState ? styles.downloadDisabledColor : ''
                      }`}
                    >
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>21</p>
                        </div>
                        <div>
                          <p>{`Ausgefertigt`} </p>
                          <p>{`Established in`} </p>
                        </div>
                      </div>
                      <textarea defaultValue={isAgreement ? parseDateDMY(pickup_date, localization) : ''} />
                      <div className={styles.additional}>
                        <p>am</p>
                        <p>on</p>
                      </div>
                      <textarea disabled></textarea>
                    </div>
                  </div>
                  <div className={styles.rightSideWrapper} style={{ borderBottom: handleTheme(1) }}>
                    <div className={styles.sectionInfo}>
                      <div className={styles.smallNumber}>
                        <p>20</p>
                      </div>
                      <div>
                        <p>{`Besondere Vereinbarungen`} </p>
                        <p>{`Special agreements`} </p>
                      </div>
                    </div>
                    <>
                      <textarea
                        maxLength={100}
                        defaultValue={
                          !isAgreement
                            ? getValues('cmr_template.remarks')
                              ? getValues('cmr_template.remarks')
                              : null
                            : remarks
                        }
                        className={styles.inputSpecial}
                      ></textarea>
                    </>
                  </div>
                </div>
                <div className={styles.bottomSection}>
                  <div className={styles.column}>
                    <div className={styles.smallNumber}>
                      <p>22</p>
                    </div>
                    <div className={styles.helperContainer}>
                      <p>Signatur und Stempel des Absenders</p>
                      <p>Signature and stamp of the sender</p>
                    </div>
                  </div>
                  <div
                    className={styles.column}
                    style={{
                      borderTop: handleTheme(3),
                      borderRight: handleTheme(3),
                      borderLeft: handleTheme(3)
                    }}
                  >
                    <div className={styles.smallNumber}>
                      <p>23</p>
                    </div>
                    <div className={styles.helperContainer}>
                      <p>Unterschrift und Stempel des Frachtfuhrers</p>
                      <p>Signature and stamp of the carrier</p>
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.columnContainer}>
                      <div className={styles.sectionInfo}>
                        <div className={styles.smallNumber}>
                          <p>24</p>
                        </div>
                        <div>
                          <p>{`Gut empfangen`} </p>
                          <p>{`Goods received`} </p>
                        </div>
                      </div>
                      <div className={styles.helperRow}>
                        <p>Ort</p>
                        <p>am</p>
                      </div>
                      <div className={styles.helperRowBottom}>
                        <p>Place</p>
                        <p>on</p>
                      </div>
                      <div className={styles.helperContainerPadding}>
                        <p>Unterschrift und Stempel des Empfangers</p>
                        <p>Signature and stamp of the consignee</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.asideContainerRight}>
            <div className={styles.asideRight_1}>
              <p>{`"Bei gefahrlichen Gutem sind neben der moglichen Zertifizierung in der letzten Zelie der Spalte die Angaben"`}</p>
              <p>{`"In case of dangerous goods mention, besides the possible certification, on the last line of the column the particulars of the class, the UN number and the packing group, if needed."`}</p>
            </div>
          </div>
        </div>
        <div
          className={`${styles.footer} ${!isPC ? styles.footerResponsive : ''}`}
          style={{ backgroundColor: props.theme }}
        >
          <p>
            Das CMR/RU/Polen-Modell von 1976 fur den internationalen Strasenverkehr entspricht den Regelungen der
            Internationalen Strasenverkehrsunion/IRU/.
          </p>
          <p>
            The 1976 CMR/IRU/Poland model for international road transport compiles with the rules of the international
            Road Transport Union/IRU/.
          </p>
        </div>
      </div>
    </>
  );
};

export default CmrTheme;
