import * as React from 'react';

function NotFoundSvg(props) {
  return (
    <svg viewBox='0 0 626 312' {...props}>
      <g transform='translate(-3063.381 -1765.774)'>
        <ellipse
          cx={118.902}
          cy={8.655}
          rx={118.902}
          ry={8.655}
          transform='translate(3417.257 2018.056)'
          fill='#2d2d85'
        />
        <text
          transform='translate(3063.381 2019.774)'
          fill='#66c'
          fontSize={267}
          fontFamily='Quicksand-Light, Quicksand'
          fontWeight={300}
          opacity={0.1}
        >
          <tspan x={0} y={0}>
            {'404'}
          </tspan>
        </text>
        <path fill='#fff' stroke='#2d2d85' strokeMiterlimit={10} d='M3424.558 1908.914h147.894v44.473h-147.894z' />
        <path fill='#2d2d85' d='M3427.577 2023.878h74.042v3.674h-74.042z' />
        <path
          fill='#fff'
          stroke='#2d2d85'
          strokeMiterlimit={10}
          strokeWidth={1.42}
          d='M3427.399 1953.822h4.276v72.988h-4.276z'
        />
        <path
          d='M3427.191 1959.92l4.386 2.066v-8.584l-4.456.424zM3564.268 2023.878h74.042v3.674h-74.042z'
          fill='#2d2d85'
        />
        <path
          fill='#fff'
          stroke='#2d2d85'
          strokeMiterlimit={10}
          strokeWidth={1.42}
          d='M3564.09 1953.822h4.276v72.988h-4.276z'
        />
        <path d='M3563.882 1959.92l4.386 2.066v-8.584l-4.456.424z' fill='#2d2d85' />
        <g>
          <path
            d='M3306.829 2035.964h-41.783l11.8-61.048c.786-4.583 4.1-5.147 8.751-5.147h0c4.788 0 8.119.762 8.746 5.509z'
            fill='#fff'
            stroke='#2d2d85'
            strokeMiterlimit={10}
            strokeWidth={1.42}
          />
          <path
            fill='#fff'
            stroke='#2d2d85'
            strokeMiterlimit={10}
            strokeWidth={1.42}
            d='M3260.569 2035.962h50.14v2.492h-50.14z'
          />
          <path fill='#fff' stroke='#2d2d85' strokeMiterlimit={10} d='M3272.806 1993.881h25.667' />
          <path fill='none' stroke='#2d2d85' strokeMiterlimit={10} d='M3299.666 2001.043h-28.054' />
          <path fill='#fff' stroke='#2d2d85' strokeMiterlimit={10} d='M3269.224 2013.578h32.83' />
          <path fill='none' stroke='#2d2d85' strokeMiterlimit={10} d='M3268.03 2020.741h35.814' />
          <path
            d='M3260.717 2038.604l67.423 2.779a1.316 1.316 0 001.371-1.316 1.317 1.317 0 00-1.314-1.317zM3271.463 2000.745l28.74 2.338-.855-2.338zM3275.641 2021.04l28.739 2.223-.854-2.223z'
            fill='#2d2d85'
          />
          <path
            d='M3285.599 1969.917s-5.432-.46-7.223 8.444-8.258 59.392-8.258 59.392h-5.072l11.872-62.407s2.752-6.536 8.681-5.429z'
            fill='#2d2d85'
            opacity={0.07}
          />
        </g>
        <g>
          <path fill='#2d2d85' d='M3394.748 2023.045h74.042v3.674h-74.042z' />
          <path
            fill='#fff'
            stroke='#2d2d85'
            strokeMiterlimit={10}
            strokeWidth={1.42}
            d='M3394.57 1915.62h4.276v110.356h-4.276z'
          />
          <path d='M3394.362 1921.718l4.386 2.066v-8.584l-4.456.424z' fill='#2d2d85' />
          <path
            d='M3376.442 1915.687l-20.266-35.217 20.266-35.217h40.532l20.266 35.217-20.266 35.217z'
            fill='#fff'
            stroke='#2d2d85'
            strokeMiterlimit={10}
          />
          <text
            transform='translate(3397.425 1888.232)'
            fill='#2d2d85'
            fontSize={14}
            fontFamily='Poppins-Medium, Poppins'
            fontWeight={500}
          >
            <tspan x={-21.693} y={0}>
              {'ERROR'}
            </tspan>
          </text>
        </g>
        <text
          transform='translate(3500.109 1937.163)'
          fill='#2d2d85'
          fontSize={14}
          fontFamily='Poppins-Medium, Poppins'
          fontWeight={500}
        >
          <tspan x={-35.721} y={0}>
            {'Not Found'}
          </tspan>
        </text>
        <g>
          <path
            d='M3451.001 2042.933h-41.783l11.8-61.048c.786-4.583 4.1-5.147 8.751-5.147h0c4.788 0 8.119.762 8.746 5.509z'
            fill='#fff'
            stroke='#2d2d85'
            strokeMiterlimit={10}
            strokeWidth={1.42}
          />
          <path
            fill='#fff'
            stroke='#2d2d85'
            strokeMiterlimit={10}
            strokeWidth={1.42}
            d='M3404.741 2042.931h50.14v2.492h-50.14z'
          />
          <path fill='#fff' stroke='#2d2d85' strokeMiterlimit={10} d='M3416.978 2000.85h25.667' />
          <path fill='none' stroke='#2d2d85' strokeMiterlimit={10} d='M3443.838 2008.012h-28.054' />
          <path fill='#fff' stroke='#2d2d85' strokeMiterlimit={10} d='M3413.396 2020.547h32.83' />
          <path fill='none' stroke='#2d2d85' strokeMiterlimit={10} d='M3412.202 2027.71h35.814' />
          <path
            d='M3404.889 2045.573l67.423 2.779a1.316 1.316 0 001.371-1.316 1.317 1.317 0 00-1.314-1.317zM3415.635 2007.714l28.74 2.338-.855-2.338zM3419.813 2028.009l28.74 2.223-.854-2.223z'
            fill='#2d2d85'
          />
          <path
            d='M3429.771 1976.886s-5.432-.46-7.223 8.443-8.258 59.392-8.258 59.392h-5.072l11.872-62.407s2.752-6.535 8.681-5.428z'
            fill='#2d2d85'
            opacity={0.07}
          />
        </g>
      </g>
      {/* <text
        transform='translate(534 160)'
        fill='#2d2d85'
        fontSize={14}
        fontFamily='OpenSans-SemiBold, Open Sans'
        fontWeight={600}
        letterSpacing='.01em'
      >
        <tspan x={0} y={0}>
          {'Something&apos;s '}
        </tspan>
        <tspan x={0} y={19}>
          {'missing'}
        </tspan>
      </text> */}
    </svg>
  );
}

export default NotFoundSvg;
