import * as React from 'react';

function LogoSvg({ className }) {
  return (
    <svg className={className ? className : ''} viewBox='0 0 363.4 148'>
      <g transform='translate(-1332.998 -661)'>
        <g>
          <path
            fill='#005B9E'
            d='M1455,799h-116c-3.3,0-6-2.7-6-6V677c0-3.3,2.7-6,6-6h116c3.3,0,6,2.7,6,6v116
			C1461,796.3,1458.3,799,1455,799z M1380.5,693.5c-2.8,0-5,2.2-5,5c0,1.2,0.5,2.4,1.3,3.3l30.3,33.6l-30.3,32.8
			c-1.9,2-1.7,5.2,0.3,7.1c0.9,0.9,2.1,1.3,3.4,1.3c1.4,0,2.7-0.6,3.7-1.6l33.4-36.2c1.8-1.9,1.8-4.8,0-6.7l-33.4-37
			C1383.3,694.1,1381.9,693.5,1380.5,693.5z'
          />
        </g>
        <g>
          <path
            fill='#005B9E'
            d='M1498.9,711.5V733h-7.9v-48h7.9v21.9l18.6-21.9h9.9l-20.8,24l21.2,23.9h-10.3L1498.9,711.5z'
          />
          <path fill='#005B9E' d='M1534.7,681.9h7.9V733h-7.9V681.9z' />
          <path
            fill='#005B9E'
            d='M1569.3,733.6c-10.9,0-19-7.7-19-19.7c0-11.9,7.7-19.6,19-19.6c10.8,0,18.5,7.5,18.5,18.7
			c0,1.3-0.1,2.6-0.3,3.9h-29c0.6,6.3,5,10.1,10.8,10.1c4.8,0,7.5-2.3,9-5.3h8.5C1584.6,728.3,1578.6,733.6,1569.3,733.6z
			 M1558.5,710.6h21c-0.1-5.9-4.8-9.6-10.6-9.6C1563.6,701,1559.4,704.6,1558.5,710.6z'
          />
          <path fill='#005B9E' d='M1599.4,695l10.8,31l10.8-31h8.3l-14.5,38h-9.4l-14.4-38H1599.4z' />
          <path
            fill='#005B9E'
            d='M1651.6,733.6c-10.9,0-19-7.7-19-19.7c0-11.9,7.7-19.6,19-19.6c10.8,0,18.5,7.5,18.5,18.7
			c0,1.3-0.1,2.6-0.3,3.9h-29c0.6,6.3,5,10.1,10.8,10.1c4.8,0,7.5-2.3,9-5.3h8.5C1666.9,728.3,1660.9,733.6,1651.6,733.6z
			 M1640.8,710.6h21c-0.1-5.9-4.8-9.6-10.6-9.6C1646,701,1641.7,704.6,1640.8,710.6z'
          />
          <path
            fill='#005B9E'
            d='M1685.7,733h-7.9v-38h7.9v5.5c2.2-3.8,6-6.1,11.4-6.1v8.1h-2c-5.8,0-9.4,2.4-9.4,10.5V733z'
          />
        </g>
        <g>
          <path
            fill='#0C2138'
            d='M1507.4,744.6c9.7,0,16.2,5,18.3,13.7h-10.6c-1.1-3.3-3.7-5.4-7.8-5.4c-5.5,0-9.1,4.1-9.1,11.7
			c0,7.6,3.6,11.6,9.1,11.6c4.1,0,6.6-1.8,7.8-5.3h10.6c-2.1,8.3-8.6,13.7-18.3,13.7c-11.2,0-19.2-7.8-19.2-20
			C1488.2,752.4,1496.2,744.6,1507.4,744.6z'
          />
          <path
            fill='#0C2138'
            d='M1548,744.6c6.2,0,10.5,2.9,12.8,6.2v-5.5h9.9V784h-9.9v-5.7c-2.3,3.4-6.7,6.3-12.9,6.3
			c-9.8,0-17.6-8.1-17.6-20.2S1538.2,744.6,1548,744.6z M1550.6,753.2c-5.2,0-10.2,3.9-10.2,11.3s5,11.6,10.2,11.6
			c5.4,0,10.3-4.1,10.3-11.4C1560.8,757.3,1555.9,753.2,1550.6,753.2z'
          />
          <path
            fill='#0C2138'
            d='M1590.1,784h-9.8v-38.8h9.8v6c2.5-4,6.5-6.6,11.9-6.6v10.3h-2.6c-5.8,0-9.3,2.2-9.3,9.7V784z'
          />
          <path
            fill='#0C2138'
            d='M1623.8,744.6c6.1,0,10.5,2.8,12.8,6.2v-5.5h9.9v39.1c0,10.5-6.4,18.8-19.2,18.8c-10.9,0-18.6-5.5-19.5-14.4
			h9.7c1,3.5,4.6,5.8,9.5,5.8c5.4,0,9.6-3.1,9.6-10.3v-6c-2.3,3.4-6.7,6.4-12.8,6.4c-9.9,0-17.7-8.1-17.7-20.2
			S1613.9,744.6,1623.8,744.6z M1626.3,753.2c-5.2,0-10.2,3.9-10.2,11.3s5,11.6,10.2,11.6c5.4,0,10.3-4.1,10.3-11.4
			C1636.6,757.3,1631.7,753.2,1626.3,753.2z'
          />
          <path
            fill='#0C2138'
            d='M1673.3,784.6c-11.2,0-19.7-7.8-19.7-20c0-12.3,8.8-20,20-20s20,7.8,20,20
			C1693.5,776.8,1684.5,784.6,1673.3,784.6z M1673.3,776.1c5.2,0,10.2-3.8,10.2-11.5c0-7.8-4.8-11.5-10-11.5s-9.9,3.7-9.9,11.5
			C1663.5,772.3,1668,776.1,1673.3,776.1z'
          />
        </g>
      </g>
    </svg>
  );
}

export default LogoSvg;
