import React from 'react';
import styles from './CookiePolicyDocument.module.css';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import LogoSvg from '../../../assets/images/svg/LogoSvg';
import CookiePolicySvg from '../../../assets/images/svg/CookiePolicySvg';
import PurposeSvg from '../../../assets/images/svg/PurposeSvg';
import CookiePolicyProviderSvg from '../../../assets/images/svg/CookiePolicyProviderSvg';
import ExpirySvg from '../../../assets/images/svg/ExpirySvg';
import CookiepolicyTypeSvg from '../../../assets/images/svg/CookiepolicyTypeSvg';

const CookiePolicyDocument = ({ showPrivacy, showTou }) => {
  const { isPC, isMobile } = useWindowDimensions();

  return (
    <>
      <div className={[styles.wrapper, !isPC ? styles.responsiveWidth : ''].join(' ')}>
        <div className={styles.container}>
          <div className={styles.header}>
            <LogoSvg className={styles.logo} />
          </div>
          <div className={styles.body}>
            <p className={styles.headingData}>COOKIE POLICY</p>
            <p>
              This Cookie Policy is an integral part of KleverCargo’s{' '}
              <span
                onClick={() => showPrivacy(true)}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Privacy Policy
              </span>{' '}
              and{' '}
              <span
                onClick={() => showTou(true)}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Terms of Use
              </span>{' '}
              for the Platform, i.e.{' '}
              <span
                onClick={() => showPrivacy(true)}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Privacy Policy
              </span>{' '}
              and{' '}
              <a
                href='https://klevercargo.com/terms-and-conditions'
                target='_blank'
                rel='noreferrer'
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Terms and Conditions
              </a>{' '}
              for the Website.
            </p>
            <p>
              Below you can read more about cookies and their purpose, how we use them on the Website, as well as how
              you can control those cookies.
            </p>
            <p>
              All terms starting with a capital letter have the same meaning as defined in our{' '}
              <span
                onClick={() => showPrivacy(true)}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Privacy Policy
              </span>{' '}
              or{' '}
              <span
                onClick={() => showTou(true)}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Terms of Use for the Platform
              </span>{' '}
              or{' '}
              <span
                onClick={() => showPrivacy(true)}
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Privacy Policy
              </span>{' '}
              and{' '}
              <a
                href='https://klevercargo.com/terms-and-conditions'
                target='_blank'
                rel='noreferrer'
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Terms and Conditions for the Website
              </a>
              .
            </p>

            <div className={styles.sectionWrapper}>
              <p className={styles.title}>1. What are the cookies?</p>
              <p>
                Cookies are text files (pieces of code) that contain small amounts of information, and they are stored
                on your computer or mobile device. Cookies are being sent to your browser by a website you visit, and
                they may be sent back to the originating website during future visits, or to another website that
                recognizes them. Cookies are useful because they allow a website to remember a user&lsquo;s device and
                offer the best possible configuration.
              </p>
              <p>
                On the Platform, i.e. Website, we use cookies to make our Service more user-friendly, efficient, and
                secure. Also, some types of cookies enable us to collect information about the Platform&lsquo;s and
                Website&lsquo;s use and provide you with relevant content.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.title}>2. Types of cookies</p>
              <p>
                Cookies can be classified in three different ways: based on their duration, i.e., how long they last,
                where they originate from, and what is their purpose.
              </p>
            </div>
            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>(a)Duration of cookies</p>
              <p>
                In general, cookies may last a certain period of time (such as 179 days, 1 year, 2 years, etc.), or only
                while the website visit session lasts.
              </p>
              <p>
                So, session cookies are temporary cookies that expire once you close your browser (or once your session
                ends).
              </p>
              <p>
                Persistent cookies are cookies that remain on your hard drive until you erase them, or your browser
                does, depending on the cookie&lsquo;s expiration date. All persistent cookies have an expiration date
                written into their code, but their duration can vary.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>(b)Provenance (origin)</p>
              <p>
                First-party cookies - cookies that are put on your device directly by us, i.e., the website that you
                have visited.
              </p>
              <p>
                Third-party cookies - cookies placed on your device, not by us, but by a third party. Third-party
                cookies are usually related to an advertiser or an analytic system.
              </p>
              <p>
                Whether a certain cookie falls into the category of first-party or third-party ones, you can check in
                the category &lsquo;provider&lsquo; in the table below.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>(c)Purpose</p>
              <p>
                The Platform and the Website entail several types of cookies, which all have different purposes - the
                necessary ones, statistical, and marketing cookies. In addition, from time-to-time unclassified cookies
                may appear.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={[styles.subTitle, styles.textDecoration].join(' ')}>Cookies used on the Platform</p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Necessary cookies</p>
              <p>
                Necessary cookies make our Platform usable by enabling basic functions such as page navigation and
                access to secure areas of the Platform. In other words, Platform cannot function properly without these
                cookies.
              </p>
            </div>

            <div className={[styles.table, isMobile ? styles.tableResponsive : ''].join(' ')}>
              {!isMobile ? (
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>COOKIE NAME</p>
                    <CookiePolicySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PURPOSE</p>
                    <PurposeSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PROVIDER</p>
                    <CookiePolicyProviderSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>EXPIRY</p>
                    <ExpirySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>TYPE</p>
                    <CookiepolicyTypeSvg className={styles.svg} />
                  </div>
                </div>
              ) : null}

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>CONSENT</p>
                </div>
                <div className={styles.meaning}>
                  <p>Used to detect if the visitor has accepted the marketing category in the cookie banner.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>2 years</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>PHPSESSID</p>
                </div>
                <div className={styles.meaning}>
                  <p>Preserves user session state across page requests.</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>CookieConsent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the users cookie consent state for the current domain</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 year</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={[styles.subTitle, styles.textDecoration].join(' ')}>Cookies used on the Website</p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Necessary cookies</p>
              <p>
                Necessary cookies help us to make a website usable by enabling key functions like page navigation and
                access to secure areas of the website. These cookies are necessary for the website to function properly.
              </p>
            </div>

            <div className={[styles.table, isMobile ? styles.tableResponsive : ''].join(' ')}>
              {!isMobile ? (
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>COOKIE NAME</p>
                    <CookiePolicySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PURPOSE</p>
                    <PurposeSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PROVIDER</p>
                    <CookiePolicyProviderSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>EXPIRY</p>
                    <ExpirySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>TYPE</p>
                    <CookiepolicyTypeSvg className={styles.svg} />
                  </div>
                </div>
              ) : null}

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>CONSENT</p>
                </div>
                <div className={styles.meaning}>
                  <p>Used to detect if the visitor has accepted the marketing category in the cookie banner.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>2 years</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>LS_CSRF_TOKEN</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Cloudflare sets this cookie to track users activities across multiple websites. It expires once the
                    browser is closed.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>salesiq.zoho.eu</p>
                </div>
                <div className={styles.meaning}>
                  <p>session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>PHPSESSID</p>
                </div>
                <div className={styles.meaning}>
                  <p>Preserves user session state across page requests.</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>CookieConsent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the users cookie consent state for the current domain</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 year</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Functional cookies</p>
            </div>

            <div className={[styles.table, isMobile ? styles.tableResponsive : ''].join(' ')}>
              {!isMobile ? (
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>COOKIE NAME</p>
                    <CookiePolicySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PURPOSE</p>
                    <PurposeSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PROVIDER</p>
                    <CookiePolicyProviderSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>EXPIRY</p>
                    <ExpirySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>TYPE</p>
                    <CookiepolicyTypeSvg className={styles.svg} />
                  </div>
                </div>
              ) : null}

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>_zcsr_tmp</p>
                </div>
                <div className={styles.meaning}>
                  <p>Zoho sets this cookie for the login function on the website.</p>
                </div>
                <div className={styles.meaning}>
                  <p>salesiq.zoho.eu</p>
                </div>
                <div className={styles.meaning}>
                  <p>session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Functional</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>uesign</p>
                </div>
                <div className={styles.meaning}>
                  <p>Zoho sets this cookie for the Visitor Live Chat.</p>
                </div>
                <div className={styles.meaning}>
                  <p>salesiq.zoho.eu</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 month</p>
                </div>
                <div className={styles.meaning}>
                  <p>Functional</p>
                </div>
              </div>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Statistic cookies</p>
              <p>
                Statistic cookies enable us to understand how visitors interact with Website because they collect and
                report traffic information. Thanks to these cookies, we can measure and improve the performance of the
                Website.
              </p>
              <p>
                This way, we know which pages are the most and the least popular with our users, and we can also have an
                insight into how our visitors move around the Website.
              </p>
              <p>
                All information collected by these cookies is collected anonymously and cannot be reasonably used to
                identify any particular individual.
              </p>
            </div>

            <div className={[styles.table, isMobile ? styles.tableResponsive : ''].join(' ')}>
              {!isMobile ? (
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>COOKIE NAME</p>
                    <CookiePolicySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PURPOSE</p>
                    <PurposeSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PROVIDER</p>
                    <CookiePolicyProviderSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>EXPIRY</p>
                    <ExpirySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>TYPE</p>
                    <CookiepolicyTypeSvg className={styles.svg} />
                  </div>
                </div>
              ) : null}

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>_ga_*</p>
                </div>
                <div className={styles.meaning}>
                  <p>Google Analytics sets this cookie to store and count page views.</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 year 1 month 4 days</p>
                </div>
                <div className={styles.meaning}>
                  <p>Analytics</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>c</p>
                </div>
                <div className={styles.meaning}>
                  <p>Google Analytics sets this cookie to store a unique user ID.</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 minute</p>
                </div>
                <div className={styles.meaning}>
                  <p>Analytics</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>_gcl_au</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Provided by Google Tag Manager to experiment advertisement efficiency of websites using their
                    services.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>3 months</p>
                </div>
                <div className={styles.meaning}>
                  <p>Analytics</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>CONSENT</p>
                </div>
                <div className={styles.meaning}>
                  <p>YouTube sets this cookie via embedded youtube-videos and registers anonymous statistical data.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>2 years</p>
                </div>
                <div className={styles.meaning}>
                  <p>Analytics</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>_ga</p>
                </div>
                <div className={styles.meaning}>
                  <p>Registers a unique ID that is used to generate </p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>2 years</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>_ga_#</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Used by Google Analytics to collect data on the number of times a user has visited the website as
                    well as dates for the first and most recent visit.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>2 years</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>_gat</p>
                </div>
                <div className={styles.meaning}>
                  <p>Used by Google Analytics to throttle request rate.</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 day</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>_gid</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Registers a unique ID that is used to generate statistical data on how the visitor uses the website.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 day</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>collect</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Used to send data to Google Analytics about the visitor’s device and behaviour. Tracks the visitor
                    across devices and marketing channels.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>google-analytics.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Marketing cookies</p>
              <p>
                We have implemented marketing cookies in order to improve our Website and its performance. So, marketing
                cookies help us provide you with a good user experience and tailored content.
              </p>
              <p>
                These cookies are set up through the Website by our advertising partners, who may use marketing cookies
                to create a profile of your interests and show you relevant advertisements in other locations.
              </p>
              <p>
                Please note that marketing cookies uniquely identify your browser and device. If you do not allow these
                cookies, you will not experience our targeted ads on other websites.
              </p>
            </div>

            <div className={[styles.table, isMobile ? styles.tableResponsive : ''].join(' ')}>
              {!isMobile ? (
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>COOKIE NAME</p>
                    <CookiePolicySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PURPOSE</p>
                    <PurposeSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PROVIDER</p>
                    <CookiePolicyProviderSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>EXPIRY</p>
                    <ExpirySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>TYPE</p>
                    <CookiepolicyTypeSvg className={styles.svg} />
                  </div>
                </div>
              ) : null}

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>TESTCOOKIESENABLED</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 day</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>b3e783bb62</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    This cookie is set by the provider Zoho. This cookie is used for collecting information on user
                    interaction with the web-campaign content. This cookie helps the website owners to promote products
                    and events on the CRM-campaign-platform.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>salesiq.zoho.eu</p>
                </div>
                <div className={styles.meaning}>
                  <p>session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>nextld</p>
                </div>
                <div className={styles.meaning}>
                  <p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Persistent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>LAST_RESULT_ENTRY_KEY</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the users video player preferences using an embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>remote_sid</p>
                </div>
                <div className={styles.meaning}>
                  <p>Necessary for the implementation and functionality of YouTube video-consent on the website. </p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>VISITOR_INFO1_LIVE</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Tries to estimate the users’ bandwidth on pages with integrated YouTube videos.A cookie set by
                    YouTube to measure bandwidth that determines whether the user gets the new or old player interface.{' '}
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>5 months 27 days,Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>YSC</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Registers a unique ID to keep statistics of what videos from YouTube the user has seen.YSC cookie is
                    set by Youtube and is used to track the views of embedded videos on Youtube pages.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>LogsDatabaseV2:V921167ec||#L</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the users video player preferences using embedded YouTube video. </p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Persistent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>YtIdbMeta#databases</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the users video player preferences using embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Persistent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>t.innertube::nextId</p>
                </div>
                <div className={styles.meaning}>
                  <p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>requests</p>
                </div>
                <div className={styles.meaning}>
                  <p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Persistent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>yt.innertube::nextId</p>
                </div>
                <div className={styles.meaning}>
                  <p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Persistent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>yt-remote-cast-available</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the user’s video player preferences using embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>yt-remote-cast-installed</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the user’s video player preferences using embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>yt-remote-connected-devices</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the user’s video player preferences using embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>never</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>yt-remote-device-id</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the user’s video player preferences using embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>never</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>yt-remote-fast-check-period</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the user’s video player preferences using embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>yt-remote-session-app</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the user’s video player preferences using embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>yt-remote-session-name</p>
                </div>
                <div className={styles.meaning}>
                  <p>Stores the user’s video player preferences using embedded YouTube video.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Marketing</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Used for market and audience segmentation and to make our advertising seen on other sites more
                    relevant.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>A few seconds</p>
                </div>
                <div className={styles.meaning}>
                  <p>First-Party Cookies</p>
                </div>
              </div>

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>e- envelope</p>
                </div>
                <div className={styles.meaning}>
                  <p>Contains metadata information about the payload and page.</p>
                </div>
                <div className={styles.meaning}>
                  <p>clarity.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>13 months</p>
                </div>
                <div className={styles.meaning}>
                  <p>Analytics </p>
                </div>
              </div>

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>a-analytics</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Contains information about user interactions on the site. Used for analytics and playback of
                    interactions. Clarity collects the following type of events. click, scroll, mouse move, window
                    resize, selection, input, and so on. Diagnostic events: script and image errors, logs, performance
                    events, and so on. Page events: document sizes, page visibility, page unload, metrics, and
                    dimensions about the page. Custom events: Custom variables/events set by the website based on some
                    event.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>clarity.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>30 days</p>
                </div>
                <div className={styles.meaning}>
                  <p>Analytics</p>
                </div>
              </div>

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>p-playback</p>
                </div>
                <div className={styles.meaning}>
                  <p>
                    Contains information about the DOM and mutation events. Used for session playback. Data includes
                    information about each DOM node such as: Positional information: parent, position, previous node,
                    and so on. Layout details: Attributes, height, and width of the node used for display. Content:
                    Content within the node. For privacy-sensitive fields, this will be masked content not the actual
                    text.
                  </p>
                </div>
                <div className={styles.meaning}>
                  <p>clarity.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>30 days</p>
                </div>
                <div className={styles.meaning}>
                  <p>Analytics</p>
                </div>
              </div>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Unclassified cookies</p>
              <p>
                Unclassified cookies are cookies that we are in the process of classifying, together with the providers
                of individual cookies.
              </p>
            </div>

            <div className={[styles.table, isMobile ? styles.tableResponsive : ''].join(' ')}>
              {!isMobile ? (
                <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>COOKIE NAME</p>
                    <CookiePolicySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PURPOSE</p>
                    <PurposeSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>PROVIDER</p>
                    <CookiePolicyProviderSvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>EXPIRY</p>
                    <ExpirySvg className={styles.svg} />
                  </div>
                  <div className={[styles.term, styles.termTitle].join(' ')}>
                    <p>TYPE</p>
                    <CookiepolicyTypeSvg className={styles.svg} />
                  </div>
                </div>
              ) : null}

              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>klevercargo-_zldp</p>
                </div>
                <div className={styles.meaning}>
                  <p>No description</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 year 1 month 4 days</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>klevercargo-_zldt</p>
                </div>
                <div className={styles.meaning}>
                  <p>No description</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 day</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>LogsDatabaseV2:Vfbc728e9||#LogsRequestsStore</p>
                </div>
                <div className={styles.meaning}>
                  <p>Purpose unknown.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Persistent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>nextld</p>
                </div>
                <div className={styles.meaning}>
                  <p>Purpose unknown.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>requests</p>
                </div>
                <div className={styles.meaning}>
                  <p>Purpose unknown.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Session</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&quot;ZLD&quot;+ &lt;@lsid@ &gt;+&quot;tabowner&quot;</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores the wms session id</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>180 seconds</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&quot;ZLDPERSONALIZE&quot;</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores the attenderid</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>180 seconds</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&quot;ZSIQ&quot;+&lt;@widgetcode@&gt;+&quot;blocked&quot;</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores information if the IP is blocked or not</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>30 days</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&quot;ZLD&quot;+&lt;@lsid@&gt;+&quot;avuid&quot;</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores the avuid unique id to denote a visitor</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>310</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&lt;@avuid@&gt;+&quot;_accesstime”</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores the last fetched conversations end time</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 day</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&lt;@chatId@&gt;+&quot;_sound”</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores if the sound in the Live Chat is muted or not</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>180 seconds</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&lt;@chatId@&gt;+&quot;_translate”</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores if the chat translation is enabled or not</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>180 seconds</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&quot;ZLDTRIGGER&quot;+&lt;@lsid@&gt;</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores the trigger details when the visitor is contacted</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>310 seconds</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&quot;ZLD&quot;+&lt;lsid@&gt;+&quot;WTIME&quot;”</p>
                </div>
                <div className={styles.meaning}>
                  <p>The cookie stores the remaining waiting time details for a chat</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>120 seconds</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&quot;ZLD&quot;+&lt;@lsid@&gt;+&quot;WAITING”</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores the waiting time of the chat time details for a chat</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>Embed waiting time</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>&quot;ZLD&quot;+&lt;@lsid@&gt;</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie stores the Live Chat attender details and visitor details</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>310 seconds</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>klevercargo_cookie_policy</p>
                </div>
                <div className={styles.meaning}>
                  <p>No description</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.rs</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 hour</p>
                </div>
                <div className={styles.meaning}>
                  <p>Other</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>ServiceWorkerLogsDatabase#SWHealthLog</p>
                </div>
                <div className={styles.meaning}>
                  <p>Purpose unknown.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Persistent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>TESTCOOKIESENABLED</p>
                </div>
                <div className={styles.meaning}>
                  <p>Purpose unknown.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 day</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>YtIdbMeta#databases</p>
                </div>
                <div className={styles.meaning}>
                  <p>Purpose unknown.</p>
                </div>
                <div className={styles.meaning}>
                  <p>youtube.com</p>
                </div>
                <div className={styles.meaning}>
                  <p>Persistent</p>
                </div>
                <div className={styles.meaning}>
                  <p>Third-Party Cookies</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>klevercargo_cookie_policy</p>
                </div>
                <div className={styles.meaning}>
                  <p>/</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.rs</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 day</p>
                </div>
                <div className={styles.meaning}>
                  <p>Unclassified</p>
                </div>
              </div>
              <div className={[styles.row, isMobile ? styles.rowResponsive : ''].join(' ')}>
                <div className={styles.meaning}>
                  <p>isiframeenabled</p>
                </div>
                <div className={styles.meaning}>
                  <p>This cookie is set when the Live Chat feature is disabled by proactive chats/trigger/JSAPI</p>
                </div>
                <div className={styles.meaning}>
                  <p>klevercargo.app</p>
                </div>
                <div className={styles.meaning}>
                  <p>1 day</p>
                </div>
                <div className={styles.meaning}>
                  <p>Unclassified</p>
                </div>
              </div>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.title}>3. Use of external tools on the Website</p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Google Analytics</p>
              <p>At the Website, we have also integrated a special analysis service - Google Analytics.</p>
              <p>
                Website analysis refers to the collection, recording, and analysis of data regarding the behaviour of
                the Website visitors. In other words, Google Analytics uses cookies to remember a visitor’s behaviour
                and provide us with reports on the Website visits and the visitors’ activity.
              </p>
              <p>
                The operator of the Google Analytics tool is Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA
                94043-1351, USA. Google Analytics uses cookies, as described in the table above. The information about
                your use of our Website generated by the Google Analytics cookies is usually transmitted to a Google
                server in the USA and stored there. Google might disclose the personal data collected via the technical
                procedure to third parties.
              </p>
              <p>
                We have activated IP anonymization, which means that Google shortens the IP addresses of our visitors
                within the Member States of the European Union or in other countries that are parties to the European
                Marketing Area Treaty. Only in exceptional cases is the full IP address transmitted to a Google server
                in the USA and shortened there. Google uses this information to analyze your use of the Website to
                compile a report about your Website activities and provide us with other services associated with the
                Website and Service.
              </p>
              <p>
                In addition to the instructions in Section 5. of this Cookie Policy, you may prevent the disclosure of
                the data generated by the cookie which refers to the use of the Website (including your IP address) to
                Google, as well as the processing of these data by Google, by downloading and installing the browser
                plug-in available under the following link.
              </p>
              <p>
                This browser add-on notifies Google Analytics via JavaScript that no data or information about visitors
                may be transmitted to Google Analytics. Apart from that, a cookie left behind by Google Analytics may be
                erased at any time via the Internet browser or other software programs.
              </p>
              <p>
                Google Analytics is explained in more detail here, while information on how Google processes data you
                may find on this link.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Google Tag Manager</p>
              <p>
                Google Tag Manager is a tag management system that has the same functionality as the Google tag and
                enables us to optimize tag deployment, reduce tag configuration errors, and configure and instantly
                deploy tags on our website from an easy-to-use web-based interface. In addition, Tag Manager features
                support for tag organization and version control, third-party and community-developed tag templates, and
                enterprise collaboration and security features.
              </p>
              <p>
                Google Tag Manager allows us to track events or user behavior such as link clicks, button clicks, form
                submissions, file downloads, video views, etc.
              </p>
              <p>
                We use server-side tagging in order to ensure granular data privacy. Namely, server-side tagging gives
                us full control over the data that is distributed to third parties. Within the server container, we can
                remove any personally identifiable information (PII) before passing the data on to marketing partners.
                We can ensure that we are only collecting and sharing data that is necessary and compliant with data
                privacy regulations.
              </p>
              <p>
                In order to monitor and provide diagnostics about system stability, performance, and installation
                quality, Google Tag Manager may collect some aggregated data about tag firing. This data does not
                include user IP addresses or any measurement identifiers associated with a particular individual. Other
                than data in standard HTTP request logs, all of which is deleted within 14 days of being received, and
                diagnostics data noted above, Google Tag Manager does not collect, retain, or share any information
                about visitors to Website properties, including page URLs visited.
              </p>
              <p>
                This tool works by using a single JavaScript code snippet that we add to our site as a container for all
                the tags we want to manage. When a user triggers a tag (e.g., by clicking a link or loading a page),
                Google Tag Manager retrieves it and injects it into our website code, and executes the tag. In other
                words, Google Tag Manager only injects and executes a tag when a user triggers one. This reduces the
                overall amount of code on a website and minimizes the risk of implementation errors.
              </p>
              <p>Please see instructions in Section 5. of this Cookie Policy on how to disable this tool.</p>
              <p>
                Google Tag Manager is explained in more detail here, while information on how Google processes data you
                may find on this link.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Google Search Console</p>
              <p>
                Google Search Console is a service offered by Google that helps us monitor, maintain, and troubleshoot
                our site’s presence in Google Search results. Essentially, Google Search Console helps us understand and
                improve how Google sees our site.
              </p>
              <p>
                We use Search Console’s tools and reports for the following actions: confirming that Google can find and
                crawl our site, receiving alerts when Google encounters issues on site, view of how often our site
                appears in Google Search, fixing indexing problems, and requesting re-indexing of new or updated
                content, etc.
              </p>
              <p>Google Search Console is explained in more detail here.</p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Google AdWords</p>
              <p>
                Google AdWords is an advertising system Google developed that helps us to reach online target markets
                through its search engine platform and partner sites. After a user searches for keywords and phrases
                related to a business and its products or services, these partner sites host a text or image ad that
                appears on the page.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Meta Business Manager</p>
              <p>
                Meta Business Manager is a tool that we use to manage the permissions of our Meta Business Account. Our
                Meta Business Account is a place that holds our Facebook Pages, Instagram profiles, Ad accounts, and
                other business assets, and helps us manage the permissions of users on them. This helps us maintain
                control, security, and privacy of all our business assets within the Meta ecosystem.
              </p>
              <p>Meta Business Manager is operated by Meta, 1 Hacker Way, Menlo Park, California, U.S.</p>
              <p>
                Meta Business Manager account allows us to mitigate risks and security concerns and streamline our Meta
                marketing processes at the same time.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.subTitle}>Ahrefs</p>
              <p>
                Ahrefs is an all-in-one, analysis toolset for search engine optimization that we use for growing search
                traffic and optimizing a website. To do that, Ahrefs crawls the web, stores tons of data, and makes it
                accessible via a simple user interface.
              </p>
              <p>
                Ahrefs is operated by Ahrefs Pte. Ltd. a Singapore registered company located at 16 Raffles Quay #33-03,
                Singapore 048581.
              </p>
              <p>
                By using Ahrefs, we can collect information on inbound links, also known as backlinks, and at the same
                time measure the parameters and quality of individual links.
              </p>
              <p>
                Ahrefs acquires data by scanning the internet 24/7 for information on links, keywords, traffic, etc.
                This data is then integrated and analyzed against other information from Google servers and other tools.
                Ahrefs rank tracker and content explorer tool use crawlers and data from independent databases. They are
                not connected to any search engines or similar services, so Ahrefs can provide legit data on which we
                can build our visibility in organic search results.
              </p>
              <p>Information on how Ahrefs processes data can be found here.</p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.title}>4. Legal basis for the use of cookies</p>
              <p>
                We use cookies only if we have obtained your consent for that (through the cookie settings). In general,
                the legal basis for the use of cookies is the consent of the data subject, given in accordance with the
                Privacy Policies. The only exception is necessary cookies because the legal basis for their
                implementation is the performance of the contract concluded with the data subject (you).
              </p>
              <p>
                Please note that it is assumed that the device you use to access the Website, i.e. Platform is yours. If
                you access the Website, i.e. Platform from a device that you do not own, it will still be assumed that
                you have the authority to consent to the use of cookies on that device, in accordance with the Cookie
                Policy.
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.title}>5. How to block cookies?</p>
              <p>You can disable any type of cookies, except for the necessary ones.</p>
              <p>To block the cookies, you can:</p>
              <p className={styles.indent}>
                1) Refuse cookies via the cookie banner and general cookie settings, or <br /> 2) Adjust your browser
                settings.
              </p>
              <p>
                When disabling the cookies via the cookie banner, please choose the option “Decline”. Also, please have
                in mind that you can change your cookie preferences at any time by clicking on the “Cookie Settings”
                icon. Then, you can change the available sliders/checkboxes to “On” or “Off” and confirm the choice by
                clicking on “Save and close”.
              </p>
              <p>You may need to refresh your page for your settings to take effect.</p>

              <p>
                If you would like to disable cookies via browser, please note that most internet browsers accept cookies
                by their default setup. However, if you want to refuse or delete cookies, please refer to the help and
                support area on your browser for further instructions (e.g., Google Chrome, Microsoft Edge, Mozilla
                Firefox, Safari (Desktop), Safari (Mobile), Android Browser, Opera, Opera Mobile).
              </p>
              <p>
                Browsers may have different procedures for managing and configuring cookies. But, if you decide to use
                your browser settings to block all cookies (including necessary cookies), please keep in mind that you
                may not be able to access all or some parts of our Website.
              </p>
              <p>
                For more information on cookies, i.e., instructions on how to delete or block them, please visit{' '}
                <a href='http://www.aboutcookies.org/' target='_blank' rel='noreferrer'>
                  http://www.aboutcookies.org/.
                </a>
              </p>
            </div>

            <div className={styles.sectionWrapper}>
              <p className={styles.title}>6. Changes to our Cookie Policy</p>
              <p>
                Due to legal and technological requirements, we will update this Cookie Policy occasionally. In such a
                case, you may be notified through a pop-up on the Website in a reasonable period prior to and following
                the change.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePolicyDocument;
