import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './NotFoundPage.module.css';
import { nonAuthenticatedRoutes, authenticatedRoutes } from '../../../constants/paths';
import AppComponentContext from '../../../contexts/AppComponentContext';

// Import reusable components
import Button from '../../UI/Button/Button';
// Import SVGs
import NotFoundSvg from '../../../assets/images/svg/NotFoundSvg';

const NotFoundPage = ({ session }) => {
  const context = useContext(AppComponentContext);
  const strings = context.strings;
  const navigate = useNavigate();
  // created as a field so it can access context and props

  function redirect() {
    session && !session.loggedInStatus
      ? navigate(nonAuthenticatedRoutes.HOME)
      : navigate(authenticatedRoutes.DASHBOARD);
  }

  return (
    <>
      <div className={session && !session.loggedInStatus ? styles.unauthenticatedContainer : styles.container}>
        <div className={styles.buttonContainer}>
          <Button onClick={() => redirect()} buttonType='primary'>
            {session && session.loggedInStatus ? strings.goToDashboard : strings.goToHome}
          </Button>
        </div>
        <div className={styles.notFoundContainer}>
          <NotFoundSvg className={styles.notFound} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ session }) => {
  return {
    session: session
  };
};

export default connect(mapStateToProps)(NotFoundPage);
