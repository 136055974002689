import { HANDLE_ERRORS } from '../../constants/actions';

function setHandleError(error) {
  return {
    type: HANDLE_ERRORS,
    error
  };
}

export default function setHandleErrors(error) {
  return (dispatch) => {
    return dispatch(setHandleError(error));
  };
}
