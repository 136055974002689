import * as React from 'react';

const ManagerSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.st1{display:inline;fill:none;stroke:#f15b27;stroke-width:.1;stroke-miterlimit:10}.st7{stroke-width:1.6}.st14,.st7{fill:none;stroke:#000;stroke-linejoin:round;stroke-miterlimit:10}'
      }
    </style>
    <g id='Manager'>
      <path className='st7' d='M20.32 14.69h-8.64v-2.33c0-2.21 1.79-4 4-4h.64c2.21 0 4 1.79 4 4v2.33z' />
      <circle className='st7' cx={16} cy={4.53} r={1.77} />
      <path className='st14' d='M10.57 28.46H3.63v-1.61c0-1.92 1.55-3.47 3.47-3.47 1.92 0 3.47 1.55 3.47 3.47v1.61z' />
      <circle className='st14' cx={7.1} cy={20.65} r={1.42} />
      <path className='st14' d='M19.47 28.46h-6.94v-1.61c0-1.92 1.55-3.47 3.47-3.47 1.92 0 3.47 1.55 3.47 3.47v1.61z' />
      <circle className='st14' cx={16} cy={20.65} r={1.42} />
      <path className='st14' d='M28.32 28.46h-6.94v-1.61c0-1.92 1.55-3.47 3.47-3.47 1.92 0 3.47 1.55 3.47 3.47v1.61z' />
      <circle className='st14' cx={24.85} cy={20.65} r={1.42} />
    </g>
  </svg>
);

export default ManagerSvg;
