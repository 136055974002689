import {
  SET_RELOAD_PLANNER_DASHBOARD_BY_DATE,
  SET_RELOAD_EVENT_BY_ID,
  SET_RELOAD_PLANNER_DASHBOARD,
  SET_ACTIVE_HOVER_EVENT_DETAILS
} from '../../../constants/actions';

const initialState = {
  reloadPlannerDashboard: false,
  reloadPlannerDashboardByDate: { date: false, data: {} },
  reloadEventById: { type: false, data: {} },
  activeHoverEventDetails: undefined
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RELOAD_PLANNER_DASHBOARD_BY_DATE:
      return {
        ...state,
        reloadPlannerDashboardByDate: action.payload
      };
    case SET_RELOAD_EVENT_BY_ID:
      return {
        ...state,
        reloadEventById: action.payload
      };
    case SET_RELOAD_PLANNER_DASHBOARD:
      return {
        ...state,
        reloadPlannerDashboard: action.payload
      };
    case SET_ACTIVE_HOVER_EVENT_DETAILS:
      console.log('DATA IN REDUX: ', action.payload);
      return {
        ...state,
        activeHoverEventDetails: action.payload
      };
    default:
      return state;
  }
};

export default eventReducer;
