import { SAVE_REGISTER_STATE } from '../../../constants/actions';

const initialState = {
  vatin: '',
  cin: '',
  companyName: '',
  companyAddress: '',
  companyEmailAddress: '',
  companyType: '',
  companyCountry: '',
  companyCity: '',
  companyPostalCode: '',
  firstName: '',
  lastName: '',
  personalEmailAddress: '',
  phoneNumber: '',
  phoneType: '',
  phoneCode: '',
  username: ''
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_REGISTER_STATE:
      for (const [key, value] of Object.entries(action.stateObject)) {
        state[key] = value;
      }
      return { ...state };
    default:
      return state;
  }
}
