import { SET_AUTH_NAV_BAR, TOGGLE_AUTH_NAV_BAR } from '../../../constants/actions';

const initialState = {
  opened: true
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AUTH_NAV_BAR:
      return {
        ...state,
        opened: action.opened
      };
    case TOGGLE_AUTH_NAV_BAR:
      return {
        ...state,
        opened: !state.opened
      };
    default:
      return state;
  }
}
