import * as React from 'react';

const CookiesSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>
        {
          '.cls-1-cookie{stroke-linecap:round}.cls-1-cookie,.cls-2-cookie,.cls-3-cookie{fill:#fff;stroke:#010101}.cls-1-cookie-cookie,.cls-3-cookie{stroke-linejoin:round}.cls-2-cookie{stroke-miterlimit:10;stroke-width:1.6px}'
        }
      </style>
    </defs>
    <g id='Layer_17' data-name='Layer 17'>
      <path
        className='cls-2-cookie'
        d='M10.74 20.38a7.839 7.839 0 0 1 1.1-15.6c1.78 0 3.43.6 4.75 1.6'
        id='Coockie_policy'
        data-name='Coockie policy'
      />
    </g>
    <g id='Layer_23' data-name='Layer 23'>
      <circle className='cls-2-cookie' cx={10.28} cy={15.65} r={0.54} />
      <circle className='cls-2-cookie' cx={13.8} cy={11.81} r={0.54} />
      <circle className='cls-2-cookie' cx={9.48} cy={9.76} r={0.81} />
      <path className='cls-3-cookie' d='M20 21.43h5.5c2.21 0 4 1.79 4 4v1.85H16v-1.85c0-2.21 1.79-4 4-4Z' />
      <path className='cls-1-cookie' d='m18.96 24.9 1.85-1.24M22.46 24.52l1.52.3M27.45 25.46l-1.06-1.58' />
      <path className='cls-3-cookie' d='M20 14.61h5.5c2.21 0 4 1.79 4 4v1.85H16v-1.85c0-2.21 1.79-4 4-4Z' />
      <path className='cls-1-cookie' d='m18.96 18.07 1.85-1.23M22.46 17.69l1.52.31M27.45 18.64l-1.06-1.59' />
      <path className='cls-3-cookie' d='M20 7.78h5.5c2.21 0 4 1.79 4 4v1.85H16v-1.85c0-2.21 1.79-4 4-4Z' />
      <path className='cls-1-cookie' d='m18.96 11.25 1.85-1.24M22.46 10.87l1.52.3M27.45 11.81l-1.06-1.58' />
    </g>
  </svg>
);

export default CookiesSvg;
