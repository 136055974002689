import * as React from 'react';

function PurposeSvg(props) {
  return (
    <svg {...props} width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.8799 25.76C22.2091 25.76 27.3399 20.6292 27.3399 14.3C27.3399 7.97084 22.2091 2.84003 15.8799 2.84003C9.55074 2.84003 4.41992 7.97084 4.41992 14.3C4.41992 20.6292 9.55074 25.76 15.8799 25.76Z'
        stroke='#005B9E'
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M15.8798 21.59C19.906 21.59 23.1698 18.3262 23.1698 14.3C23.1698 10.2739 19.906 7.01001 15.8798 7.01001C11.8537 7.01001 8.58984 10.2739 8.58984 14.3C8.58984 18.3262 11.8537 21.59 15.8798 21.59Z'
        stroke='#005B9E'
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M15.8792 16.23C16.9451 16.23 17.8092 15.3659 17.8092 14.3C17.8092 13.2341 16.9451 12.37 15.8792 12.37C14.8133 12.37 13.9492 13.2341 13.9492 14.3C13.9492 15.3659 14.8133 16.23 15.8792 16.23Z'
        fill='#005B9E'
        stroke='#005B9E'
        strokeWidth='1.6'
        strokeLinejoin='round'
      />
      <path
        d='M13.4144 25.6378L12.1211 25.1563L10.8685 28.5207L12.1618 29.0022L13.4144 25.6378Z'
        stroke='#005B9E'
        strokeLinejoin='round'
      />
      <path
        d='M19.7985 29.0045L21.0918 28.523L19.8392 25.1586L18.546 25.6401L19.7985 29.0045Z'
        stroke='#005B9E'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default PurposeSvg;
