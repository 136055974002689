import * as React from 'react';

const RightToWithdrawSvg = (props) => (
  <svg id='Layer_36' data-name='Layer 36' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>{'.cls-1{fill:none;stroke:#010202;stroke-linejoin:round}'}</style>
    </defs>
    <path
      className='cls-1'
      d='m29.22 10.23-3.19 1.85-3.2 1.84v-2.34H2.57v-2.7h20.26V6.54l3.2 1.85 3.19 1.84zM2.57 21.53l3.19 1.85 3.2 1.84v-2.34h20.26v-2.7H8.96v-2.34l-3.2 1.85-3.19 1.84z'
    />
  </svg>
);

export default RightToWithdrawSvg;
