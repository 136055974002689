import * as React from 'react';

const DataControllerSvg = (props) => (
  <svg id='Layer_24' data-name='Layer 24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <defs>
      <style>
        {
          '.cls-1-datacontroller{stroke-width:.8px}.cls-1-datacontroller,.cls-2-datacontroller{stroke:#231f20;stroke-miterlimit:10}.cls-1-datacontroller,.cls-3-datacontroller{fill:#fff}.cls-4-datacontroller{stroke:#010101;stroke-linejoin:round;stroke-width:1.6px;fill:none}.cls-2-datacontroller{fill:none;stroke-width:.5px}'
        }
      </style>
    </defs>
    <path
      className='cls-4-datacontroller'
      d='M8.73 11.27h14.76v10.4c0 1.66-1.34 3-3 3h-8.76c-1.66 0-3-1.34-3-3v-10.4ZM20.9 11.17V9.03c0-2.56-2.08-4.64-4.64-4.64s-4.64 2.08-4.64 4.64v2.14'
    />
    <circle className='cls-3-datacontroller' cx={15.97} cy={15.65} r={1.72} stroke='black' />
    <path
      className='cls-3-datacontroller'
      d='M15.41 17.27h1.12c1.1 0 2 .9 2 2v3.12h-5.12v-3.12c0-1.1.9-2 2-2Z'
      stroke='black'
    />
    <path className='cls-1-datacontroller' d='M8.13 16H5.77' />
    <circle className='cls-2-datacontroller' cx={4.93} cy={16} r={0.79} />
    <path className='cls-1-datacontroller' d='M24.11 13.14h2.35' />
    <circle className='cls-2-datacontroller' cx={27.3} cy={13.14} r={0.79} />
    <path className='cls-1-datacontroller' d='M12.22 25.28v2.36' />
    <circle className='cls-2-datacontroller' cx={12.22} cy={28.48} r={0.79} />
    <path className='cls-1-datacontroller' d='M8.29 19.83h-3.3' />
    <circle className='cls-2-datacontroller' cx={4.15} cy={19.83} r={0.79} />
  </svg>
);

export default DataControllerSvg;
