import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { parseDateDMY } from '../../../../assets/helpers/dateTimeUtils';
import { useWindowDimensions } from '../../../../assets/helpers/useWindowDimensions';
import LogoSvg from '../../../../assets/images/svg/LogoSvg';
import AppComponentContext from '../../../../contexts/AppComponentContext';
import Button from '../../../UI/Button/Button';
import WholePageLoader from '../../../UI/Loader/WholePageLoader';
import styles from './TSA.module.css';

const TSA = ({ data }) => {
  const pageStyle = `
  @page {
    size: A4 portrait;
    margin: 0mm !important;
  }

  body {
    margin: 0px
  }
`;
  const localization = useContext(AppComponentContext).strings;
  const {
    date_created,
    c1_legal_name,
    c1_cin,
    c1_postal_code,
    c1_city,
    c1_country,
    c2_legal_name,
    c2_postal_code,
    c2_city,
    c2_country,
    c2_cin,
    u1_first_name,
    u1_last_name,
    u2_first_name,
    u2_last_name,
    payment_deadline
  } = data || {};

  const { isPC, isMobile } = useWindowDimensions();
  const tsaRef = useRef();
  const [initDownload, setInitDownload] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handlePrint = useReactToPrint({ content: () => tsaRef.current, pageStyle: pageStyle });

  useEffect(() => {
    if (initDownload) {
      setShowLoader(true);
      html2canvas(tsaRef.current, { scale: 2 }).then((canvas) => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }
        doc.save('TSA.pdf');
        setShowLoader(false);
      });
    }
    return () => setInitDownload(false);
  }, [initDownload]);

  return (
    <>
      <div className={[styles.wrapper, !isPC ? styles.responsiveWidth : ''].join(' ')} ref={tsaRef}>
        <div className={[styles.container, isMobile ? styles.paddingMobile : ''].join(' ')}>
          <div className={styles.header}>
            <LogoSvg className={styles.logo} />
            <p className={styles.headingData}>Transport Service Agreement</p>
          </div>
          <p>
            This <b>TRANSPORTATION SERVICES AGREEMENT</b> {`(hereinafter: "Agreement")`} is entered via KleverCargo
            Platform on {parseDateDMY(date_created, localization)} (hereinafter: <b>{'"EfectiveDate"'}</b>), by and
            between:
          </p>
          <ol className={styles.listItemsContainer}>
            <li>
              <p>
                {c2_legal_name}, with its registered seat at {c2_postal_code}, {c2_city}, {c2_country}, corporate ID
                number: {c2_cin}, represented here by {u2_first_name} {u2_last_name}. (hereinafter: <b>{'"Carrier'}</b>)
              </p>
            </li>
            <p>and</p>
            <li>
              <p>
                {c1_legal_name}, with its registered seat at {c1_postal_code}, {c1_city}, {c1_country}, corporate ID
                number: {c1_cin}, represented here by {u1_first_name} {u1_last_name}. (hereinafter: <b>{'"Sender'}</b>),
              </p>
            </li>
            <p>
              hereinafter jointly referred to as the <b>{'"Parties"'}</b> and individually a <b>{'"Party"'}</b>
            </p>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>PREAMBLE</p>
          </div>
          <ol type='A'>
            <li>
              <b>WHEREAS</b> carrier is an independent contractor engaged in the business of transporting in interstate
              and interstate commerce and desires to provide carrier services to Sender for the transportation of goods
              by road in vehicle for reward.
            </li>
            <li>
              <b>WHEREAS</b> Sender desires Carrier to provide carrier services of transportation, as set forth in order
              form created via KleverCargo Platform (as defined below) and incorporated by reference herein, to
              consignee of certain goods.
            </li>
            <li>
              <b>NOW, THEREFORE</b>, in consideration of the mutual covenants and conditions hereinafter set forth, and
              other good and valuable consideration, the receipt and sufficiency of which the Parties hereby
              acknowledge, the Parties hereto agree as follows:
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>1. DEFINITIONS</p>
          </div>
          <p>In this Agreement, the given terms shall have the following meaning:</p>
          <ul>
            <li>
              <b>{'"Agreement"'}</b> shall mean this Transport Services Agreement made in writing via the KleverCargo
              Platform, including all the appendixes to the Agreement and any amendments.
            </li>
            <li>
              <b>{'"Cargo"'}</b> shall mean Sender’s goods or merchandise conveyed in Carrier’s vehicle as specified in
              order form created via KleverCargo Platform and incorporated by reference herein.
            </li>
            <li>
              <b>{'"CMR"'}</b> shall mean a consignment note with a standard set of transport and liability conditions,
              which replaces individual businesses terms and conditions. The CMR confirms that the Carrier has received
              the goods and that an Agreement exists between the Carrier and Sender.
            </li>
            <li>
              <b>{'"Consignee"'}</b> shall mean the person or company determined by the Sender to whom goods or
              documents are officially sent or delivered.
            </li>
            <li>
              <b>{'"Fees"'}</b> shall have the meaning defined in Article 6 of the Agreement.
            </li>
            <li>
              <b>{'"Convention"'}</b> shall mean the Convention on the Contract for the International Carriage of Goods
              by Road.
            </li>
            <li>
              <b>{'"Effective Date"'}</b> shall mean the date of signing of the Agreement by both Parties via
              KleverCargo Platform.
            </li>
            <li>
              <b>{'"KleverCargo Platform"'}</b> shall mean a web application owned by KleverCargo Innovation B.V., with
              the registered seat at Veemkade 328 1019 HD, Amsterdam, the Netherlands, which serves for organizing
              logistics in transportation between carriers and senders.
            </li>
            <li>
              <b>{'"Vehicle"'}</b> shall mean motor vehicles, articulated vehicles, trailers, and semi-trailers as
              defined in article 4 of the Convention on Road Traffic dated 19 September 1949.
            </li>
          </ul>
          <div className={styles.header}>
            <p className={styles.headingData}>2. TRANSPORTATION SERVICES</p>
          </div>
          <ol>
            <li>
              The Carrier shall provide the transportation services of goods to designated area(s) as provided by the
              Sender and specified in order form created via KleverCargo Platform and incorporated by reference herein
              (hereinafter: “Transportation” or “Services”).{' '}
            </li>
            <li>
              Transportation shall commence on the date agreed by the Parties as specified in order form created via
              KleverCargo Platform and incorporated by reference herein.
            </li>
            <li>
              Time is of the essence in Carrier’s performance of its obligations hereunder, and Carrier agrees to
              provide timely communication relative to Transportation of the cargo with prompt and reasonable dispatch
              in accordance with scheduled delivery date set by Sender (hereinafter: “Agreed Timing”), as communicated
              to Carrier in the order form created via KleverCargo Platform and incorporated by reference herein,
              subject, however, to compliance by Carrier with applicable laws and regulations.
            </li>
            <li>
              Delay in delivery shall be said to occur when the goods have not been delivered within the Agreed Timing
              or when, failing an Agreed Timing, the actual duration of the transport having regard to the circumstances
              of the case, and in particular, in the case of partial loads, the time required for making up a complete
              load in the normal way, exceeds the time it would be reasonable to allow a diligent Carrier.
            </li>
            <li>
              The Carrier has an obligation, duty, and responsibility to check and ensure through its drivers, servants,
              and agents that the cargo is properly loaded, positioned, and secured at all times.{' '}
            </li>
            <li>
              The Carrier shall ensure that the route to be followed within the Transportation set out in the Agreement
              is safe, suitable and practicable according to the conditions of the cargo, the vehicle and the
              requirements of the Agreement. In case that because of the conditions of the cargo special measure shall
              be undertaken in order to conduct the Transportation, the Carrier shall notify the Sender about the means
              to be provided.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>3. CONCLUSION AND PERFORMANCE OF THE AGREEMENT</p>
          </div>
          <ol>
            <li>
              The Agreement shall be confirmed by the making out of a CMR. The absence, irregularity, or loss of the CMR
              shall not affect the existence or the validity of the Agreement.
            </li>
            <li>
              The CMR shall be made out in three original copies signed by the Sender and by the Carrier. These
              signatures may be printed or replaced by the stamps of the Sender and the Carrier if the law of the
              country in which the CRM has been made out so permits. The first copy shall be handed to the Sender, the
              second shall accompany the goods and the third shall be retained by the Carrier.
            </li>
            <li>
              When the goods which are to be carried have to be loaded in different vehicles, or are of different kinds
              or are divided into different lots, the Sender or the Carrier shall have the right to require a separate
              CMR to be made out for each vehicle used, or for each kind or lot of goods.
            </li>
            <li>The CMR shall contain particulars as defined under the Convention.</li>
            <li>
              The Sender shall be responsible for all expenses, loss and damage sustained by the Carrier by reason of
              the inaccuracy or inadequacy of the following particularities in the CMR:
              <ul style={{ paddingInlineStart: 40, gap: 0 }}>
                <li>the name and address of the Sender;</li>
                <li>the place and the date of taking over of the goods and the place designated for delivery;</li>
                <li>The name and address of the consignee;</li>
                <li>
                  The description in common use of the nature of the goods and the method of packing, and, in the case
                  of dangerous goods, their generally recognized description;
                </li>
                <li>The number of packages and their special marks and numbers;</li>
                <li>The gross weight of the goods or their quantity otherwise expressed;</li>
                <li>The requisite instructions for customs and other formalities;</li>
                <li>A statement that transshipment is not allowed;</li>
                <li>The charges which the Sender undertakes to pay;</li>
                <li>The amount of “cash on delivery” charges;</li>
                <li>
                  A declaration of the value of the goods and the amount representing special interest in delivery;
                </li>
                <li>The Sender’s instructions to the Carrier regarding insurance of the goods;</li>
                <li>The agreed time limit within which the cargo is to be carried out;</li>
                <li>A list of the documents handed to the Carrier.</li>
                <li>
                  Any other particulars or instructions given by him to enable the CMR to be made out or for the purpose
                  of their being entered therein.
                </li>
              </ul>
            </li>
            <li>
              On taking over the goods, the Carrier shall check:
              <ol type='a' style={{ paddingInlineStart: 40, gap: 0 }}>
                <li>
                  The accuracy of the statements in the CRM as to the number of packages and their marks and numbers,
                  and
                </li>
                <li>The apparent condition of the goods and their packaging.</li>
              </ol>
            </li>
            <li>
              Where the Carrier has no reasonable means of checking the accuracy of the statements referred to in
              paragraph 6 (a) of this Section, he shall enter his reservations in the CMR together with the grounds on
              which they are based. The Carrier shall likewise specify the grounds for any reservations which he makes
              with regard to the apparent condition of the goods and their packaging. Such reservations shall not bind
              the Sender unless he has expressly agreed to be bound by them in the CMR.
            </li>
            <li>
              The Sender shall be entitled to require the Carrier to check the gross weight of the goods or their
              quantity otherwise expressed. The Sender may also require the contents of the packages to be checked. The
              Carrier shall be entitled to claim the cost of such checking. The result of the checks shall be entered in
              the CMR.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>4. CARRIER’S OBLIGATIONS AND RESPONSIBILITIES</p>
          </div>
          <ol>
            <li>
              The Carrier shall be responsible for ensuring that all statutory licenses, authorizations or permits
              required for performance of its obligations and execution of the transport under this Agreement have been
              obtained at its own cost and with reasonable prior time in order to complete the Transportation within the
              Agreed Timing.
            </li>
            <li>
              The Carrier shall ensure that the vehicles used for the carriage of goods entrusted to it are licensed,
              taxed, constructed, maintained, loaded, properly secured for loads, operated, and used in conformity with
              the law of the country of loading, and the law of the country through which the goods are transported and
              the law of the country of delivery.
            </li>
            <li>
              The Carrier shall maintain insurance of a vehicle being deployed under this Agreement and upon request
              shall furnish proof of such insurance to the Sender.
            </li>
            <li>
              The Carrier is obliged to provide a suitable and well-maintained vehicle and sufficient load and securing
              equipment (including the provision of all necessary tackle and facilities for securing the load to the
              vehicle) and to ensure that the drivers and loading staff (if applicable) are competent, trained, and
              licensed, and have received sufficient instructions in the safe use of the vehicle and securing equipment
              in relation to the specific loads involved.
            </li>
            <li>
              The Carrier shall provide the Sender with satisfactory evidence of compliance with this Section 4 whenever
              the Sender reasonably requests and shall inform the Sender of any significant movement in the loads in
              relation to the vehicle in transit.
            </li>
            <li>
              The Carrier shall be responsible for the acts and omissions of his agents and servants and of any other
              persons of whose services he makes use for the performance of the Transportation services, when such
              agents, servants or other persons are acting within the scope of their employment, as if such acts or
              omissions were his own.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>5. SENDER’S OBLIGATIONS AND RESPONSIBILITIES</p>
          </div>
          <ol>
            <li>
              The Sender shall be liable to the Carrier for damage to persons, equipment, or other goods, and for any
              expenses due to defective packing of the goods, unless the defect was apparent or known to the Carrier at
              the time when he took over the goods and he made no reservations concerning it.
            </li>
            <li>
              For the purposes of the customs or other formalities which have to be completed before delivery of the
              goods, the Sender shall attach the necessary documents to the CMR or place them at the disposal of the
              Carrier and shall furnish him with all the information which he requires.
            </li>
            <li>
              The Sender shall be liable to the Carrier for any damage caused by the absence, inadequacy or irregularity
              of such documents and information, except in the case of some wrongful act or neglect on the part of the
              Carrier.
            </li>
            <li>
              The liability of the Carrier for the consequences arising from the loss or incorrect use of the documents
              specified in and accompanying the CMR or deposited with the Carrier shall be that of an agent, provided
              that the compensation payable by the Carrier shall not exceed that payable in the event of loss of the
              goods.
            </li>
            <li>
              When the Sender hands goods of a dangerous nature to the Carrier, the Sender shall inform the Carrier of
              the exact nature of the danger and indicate, if necessary, precautions to be taken. Goods of a dangerous
              nature which the Carrier did not know were dangerous, may, at any time or place, be unloaded, destroyed,
              or rendered harmless by the Carrier without compensation; further, the Sender shall be liable for all
              expenses, loss or damage arising out of their handing over for transportation or of their transportation.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>6. FEES AND CHARGES</p>
          </div>
          <ol>
            <li>
              Carrier shall be compensated for each shipment in accordance with the rates set forth in the order form
              created via KleverCargo Platform (hereinafter: “Fees”).
            </li>
            <li>
              At the time the shipment is fully delivered, an invoice covering that shipment will be generated by
              Carrier and delivered to Sender, in accordance with the rates Carrier places in the order form created via
              KleverCargo Platform. The invoice will be due and payable <b>{payment_deadline} days</b> from the date of
              receipt of the invoice, provided that on or before said date Sender has received from Carrier (i) duly
              executed receipt(s) from whom delivery was made concerning the applicable invoice, and (ii) such other
              supporting materials as Sender may reasonably require.
            </li>
            <li>
              Carrier shall maintain complete and accurate records of each shipment and all transactions under this
              Agreement, including all supporting documentation and proof of delivery, and in a manner consistent with
              generally acceptable accounting procedures. Within two (2) days notice by Sender, Carrier shall provide
              Sender and/or its representatives with access to and the right to examine all records involving any
              transaction related to the Agreement. Such right shall be for the period covered by this Agreement and
              until the expiration of five (5) years after termination of this Agreement.
            </li>
            <li>
              Carrier will pay all licenses, fees, taxes, fuel tax payments, road tax, equipment use fees or taxes,
              equipment license fees, driver’s license fees, tolls and any other fees and fines that may be assessed on
              its equipment or its operations, unless otherwise agreed by the Parties.
            </li>
            <li>
              All payments of the Fees shall be made via bank transfer to Carrier’s bank account in accordance with the
              instructions in each invoice. If legally required, VAT will be added to the amount of Fee according to the
              applicable laws.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>7. RIGHT OF DISPOSAL</p>
          </div>
          <ol>
            <li>
              The Sender has the right to dispose of the goods, in particular by asking the Carrier to stop the goods in
              transit, to change the place at which delivery is to take place or to deliver the goods to a consignee
              other than the consignee indicated in the CMR. This right shall cease to exist when the second copy of the
              CMR is handed to the consignee; from that time onwards the Carrier shall obey the orders of the consignee.{' '}
            </li>
            <li>
              The consignee shall, however, have the right of disposal from the time when the CMR is drawn up, if the
              Sender makes an entry to that effect in the CMR.
            </li>
            <li>
              If in exercising his right of disposal the consignee has ordered the delivery of the goods to another
              person, that other person shall not be entitled to name other consignees.
            </li>
            <li>
              The exercise of the right of disposal shall be subject to the following conditions:
              <ol type='a' style={{ paddingInlineStart: 40, gap: 0 }}>
                <li>
                  That the Sender or the consignee who wishes to exercise the right produces the first copy of the CMR
                  on which the new instructions to the Carrier have been entered and indemnifies the Carrier against all
                  expenses, loss and damage involved in carrying out such instructions;
                </li>
                <li>
                  That the carrying out of such instructions is possible at the time when the instructions reach the
                  person who is to carry them out and does not either interfere with the normal working of the Carriers’
                  undertaking or prejudice the Senders or consignees of other consignments;
                </li>
                <li>That the instructions do not result in a division of the cargo.</li>
              </ol>
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>8. INSTRUCTIONS AND SALE OF GOODS</p>
          </div>
          <ol>
            <li>
              If for any reason it is or becomes impossible to carry out the Agreement in accordance with the terms laid
              down in the CMR before the goods reach the place designated for delivery, the Carrier shall ask for
              instructions from the Sender. Nevertheless, if circumstances are such as to allow the Transportation to be
              carried out under conditions differing from those laid down in the Agreement and CMR and if the Carrier
              has been unable to obtain instructions in reasonable time from the Sender or consignee, he shall take such
              steps as seem to him to be in the best interests of the Sender.
            </li>
            <li>
              Where circumstances prevent delivery of the goods after their arrival at the place designated for
              delivery, the Carrier shall ask the Sender for his instructions. If the consignee refuses the goods, the
              Sender shall be entitled to dispose of them without being obliged to produce the first copy of the CMR.
              Even if the consignee has refused the goods, the consignee may nevertheless require delivery so long as
              the Carrier has not received instructions to the contrary from the Sender.
            </li>
            <li>
              The Carrier shall be entitled to recover the cost of his request for instructions and any expenses
              entailed in carrying out such instructions, unless such expenses were caused by the wrongful act or
              neglect of the Carrier.
            </li>
            <li>
              In the cases referred to in paragraph 1 and 2 of this Section, the Carrier may immediately unload the
              goods for account of the person entitled to dispose of them in accordance with Section 7 and thereupon the
              Transportation shall be deemed to be at an end. The Carrier shall then hold the goods on behalf of the
              person so entitled. The Carrier may, however, entrust them to a third party, and in that case he shall not
              be under any liability except for the exercise of reasonable care in the choice of such third party. The
              charges due under the CMR and all other expenses shall remain chargeable against the goods.
            </li>
            <li>
              The Carrier may sell the goods, without awaiting instructions from the person entitled to dispose of them,
              if the goods are perishable or their condition warrants such a course, or when the storage expenses would
              be out of proportion to the value of the goods.{' '}
            </li>
            <li>
              The procedure in the case of sale shall be determined by the law or custom of the place where the goods
              are situated.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>9. LOSS OF THE GOODS</p>
          </div>
          <ol>
            <li>
              The fact that goods have not been delivered within thirty days following the expiry of the Agreed Timing,
              or, if there is no Agreed Timing, within sixty days from the time when the Carrier took over the goods,
              shall be conclusive evidence of the loss of the goods, and the person entitled to make a claim may
              thereupon treat them as lost.
            </li>
            <li>
              The person so entitled may, on receipt of compensation for the missing goods, request in writing that he
              shall be notified immediately should the goods be recovered in the course of the year following the
              payment of fees. The entitled person shall be given a written acknowledgement of such request.
            </li>
            <li>
              Within the thirty days following receipt of such notification, the person entitled as aforesaid may
              require the goods to be delivered to him against payment of the charges shown to be due on the CMR and
              also against refund of the fees he received less any charges included therein but without prejudice to any
              claims to compensation for delay in delivery under this Agreement.
            </li>
            <li>
              In the absence of the request mentioned in paragraph 2 or of any instructions given within the period of
              thirty days specified in paragraph 3, or if the goods are not recovered until more than one year after the
              payment of fees, the Carrier shall be entitled to deal with them in accordance with the law of the place
              where the goods are situated.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>10. LIABILITY OF THE CARRIER</p>
          </div>
          <ol>
            <li>
              The Carrier shall be liable for the total or partial loss of the goods and for damage thereto occurring
              between the time when he takes over the goods and the time of delivery, as well as for any delay in
              delivery.
            </li>
            <li>
              The Carrier shall, however, be relieved of liability if the loss, damage or delay was caused by the
              wrongful act or neglect of the Sender or consignee, by the instructions of the Sender or consignee given
              otherwise than as the result of a wrongful act or neglect on the part of the Carrier, by inherent vice of
              the goods or through circumstances which the Carrier could not avoid and the consequences of which he was
              unable to prevent (Force Majeure).
            </li>
            <li>
              The Carrier shall not be relieved of liability by reason of the defective condition of the vehicle used by
              him in order to perform the transport, or by reason of the wrongful act or neglect of the person from whom
              he may have hired the vehicle or of the agents or servants of the latter.
            </li>
            <li>
              The Carrier shall be relieved of liability when the loss or damage arises from the special risks inherent
              in one more of the following circumstances:
              <ol type='a' style={{ paddingInlineStart: 40, gap: 0 }}>
                <li>
                  use of open unsheeted vehicles, when their use has been expressly agreed and specified in the CMR;
                </li>
                <li>
                  the lack of, or defective condition of packing in the case of goods which, by their nature, are liable
                  to wastage or to be damaged when not packed or when not properly packed;
                </li>
                <li>
                  handling, loading, stowage or unloading of the goods by the Sender, the consignee or person acting on
                  behalf of the Sender or the consignee;
                </li>
                <li>
                  the nature of certain kinds of goods which particularly exposes them to total or partial loss or to
                  damage, especially through breakage, rust, decay, desiccation, leakage, normal wastage, or the action
                  of moth or vermin;
                </li>
                <li>insufficiency or inadequacy of marks or numbers on the packages;</li>
                <li>the carriage of livestock.</li>
              </ol>
            </li>
            <li>
              Where under this Section the Carrier is not under any liability in respect of some of the factors causing
              the loss, damage, or delay, he shall only be liable to the extent that those factors for which he is
              liable under this Section have contributed to the loss, damage, or delay.
            </li>
            <li>
              When, under the provisions of this Agreement, a Carrier is liable for compensation in respect of total or
              partial loss of goods, such compensation shall be calculated by reference to the value of the goods at the
              place and time at which they were accepted for transport. Compensation shall not exceed 25 francs per
              kilogram of gross weight short. “Franc” means the gold franc weighing 10/31 of a gramme and being of
              millesimal fineness 900. However, the Sender may, against payment of a surcharge to be agreed upon,
              declare in the CMR a value for the goods exceeding the limit laid down above, and in that case the amount
              of the declared value shall be substituted for that limit.
            </li>
            <li>
              In addition, the transport charges, customs duties, and other charges incurred in respect of the transport
              of the goods shall be refunded by the Carrier in full in case of total loss and in proportion to the loss
              sustained in case of partial loss, but no further damage shall be payable.
            </li>
            <li>
              In case of damage, the Carrier shall be liable for the amount by which the goods have diminished in value.
              The compensation may not, however, exceed: (a) If the whole cargo has been damaged, the amount payable in
              the case of total loss; (b) If part only of the cargo has been damaged, the amount payable in the case of
              loss of the part affected.
            </li>
            <li>
              The Carrier shall not be entitled to avail himself of the provisions of this Section which exclude or
              limit his liability or which shift the burden of proof if the damage was caused by his willful misconduct
              or by such default on his part as, in accordance with the law of the court or tribunal seized of the case,
              is considered as equivalent to willful misconduct.
            </li>
            <li>
              The same provision shall apply if the willful misconduct or default is committed by the agents or servants
              of the Carrier or by any other persons of whose services he makes use for the performance of the
              transport, when such agents, servants or other persons are acting within the scope of their employment.
              Furthermore, in such a case such agents, servants or other persons shall not be entitled to avail
              themselves, with regard to their personal liability, of the provisions of this Section
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>11. CLAIMS AND ACTIONS</p>
          </div>
          <ol>
            <li>
              If the consignee takes delivery of the goods without duly checking their condition with the Carrier or
              without sending him reservations giving a general indication of the loss or damage, not later than the
              time of delivery in the case of apparent loss or damage and within seven days of delivery, Sundays and
              public holidays excepted, in the case of loss or damage which is not apparent, the fact of this taking
              delivery shall be prima facie evidence that he has received the goods in the condition described in the
              CMR. In the case of loss or damage which is not apparent the reservations referred to shall be made in
              writing.
            </li>
            <li>
              When the condition of the goods has been duly checked by the consignee and the Carrier, evidence
              contradicting the result of this checking shall only be admissible in the case of loss or damage which is
              not apparent and provided that the consignee has duly sent reservations in writing to the Carrier within
              seven days, Sundays and public holidays excepted, from the date of checking.
            </li>
            <li>
              No compensation shall be payable for delay in delivery unless a reservation has been sent in writing to
              the Carrier, within twenty-one days from the time that the goods were placed at the disposal of the
              consignee.
            </li>
            <li>
              In calculating the time-limits provided for in this article the date of delivery, or the date of checking,
              or the date when the goods were placed at the disposal of the consignee, as the case may be, shall not be
              included.
            </li>
            <li>
              The Carrier and the consignee shall give each other every reasonable facility for making the requisite
              investigations and checks.
            </li>
            <li>The periods of limitation for an action prescribed under the Convention shall apply.</li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>12. FORCE MAJEURE</p>
          </div>
          <ol>
            <li>
              Parties shall not be held liable in the event that the non-execution or delay in the fulfilment of their
              obligations under this Agreement results from Force Majeure. For the purposes of this Agreement, Force
              Majeure means events beyond the control of the Parties and that the Parties could not foresee, avoid, or
              eliminate, including but not limited to:
              <ul style={{ paddingInlineStart: 40, gap: 0 }}>
                <li>any kind of riot or war in the countries where the Parties are located;</li>
                <li>natural disasters that lead to the failure of delivery;</li>
                <li>employee strike longer than fifteen (15) workdays;</li>
                <li>any strike that impedes the transport of goods for a period of ten (10) workdays. </li>
              </ul>
            </li>
            <li>
              The Party whose obligations are affected by a case of Force Majeure shall notify the other Party within
              forty five (45) hours by any means (telex, fax, e-mail etc.), from the occurrence of the event and shall
              confirm it as soon as possible by registered letter, submitting evidence of its unforeseeable,
              irresistible, uncontrollable and insurmountable nature, which, in its view, makes it impossible to
              undertake or continue performance of all or part of its obligations. The prevented Party shall, in the
              case of a government agency involvement, enclose the certificate issued by competent government
              authorities for examination and confirmation by the other Party. This notification shall further include
              an indication of its expected duration as well as informing the other Party of the measures taken or to be
              taken.
            </li>
            <li>
              In the event of Force Majeure, the execution of Parties’ contractual obligations under this Agreement
              shall be suspended during this period. As soon as the Force Majeure expires, the Parties shall make every
              effort to continue as quickly as possible with the regular performance of the contractual duties.
            </li>
            <li>
              If the Force Majeure is permanent or lasts longer than forty-five (45) days, the Parties may agree to
              terminate the Agreement.
            </li>
            <li>
              In the event of the occurrence of circumstances considered to be a Force Majeure, failure to fulfil
              obligations or delay in execution shall not be the cause of liability for damage or payment of penalties
              to the other Party.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>13. REPRESENTATIONS AND WARRANTIES OF CARRIER</p>
          </div>
          <ol>
            <li>
              Carrier hereby represents and warrants to Sender, that as of the Effective Date:
              <ol type='a' style={{ paddingInlineStart: 40, gap: 0 }}>
                <li>
                  If Carrier is a corporation, general partnership, limited partnership, joint venture, trust, or other
                  type of business association, as the case may be, Carrier (i) if either a corporation duly
                  incorporated or a partnership or trust, joint venture or other type of business association duly
                  organized, is validly existing, and in good standing under the laws of the state of its formation or
                  existence, and has complied with all conditions prerequisite to its doing business in any other state
                  where Carrier conducts business, and (ii) has all requisite power and all governmental certificates of
                  authority, licenses, permits, qualifications and documentation to operate its vehicles and to carry on
                  its business as now being, and as proposed to be, conducted.
                </li>
                <li>
                  The execution, delivery, and performance by Carrier of this Agreement (i) is within Carrier’s powers
                  and have been duly authorized by Carrier, (ii) will not violate, be in conflict with, result in a
                  breach of, or constitute (with due notice or lapse of time, or both) a default under a violation of
                  any agreement of Carrier, and (iii) this Agreement constitutes the legal, valid, and binding
                  obligations of Carrier, enforceable in accordance with its respective terms.
                </li>
                <li>
                  All information, financial statements, insurance certificates, and any other data and instruments
                  given or to be given to Sender are, or at the time of delivery will be, accurate, complete, and
                  correct in all material respects and do not, or will not, omit any fact, the inclusion of which is
                  necessary to prevent the facts contained therein from being materially misleading.
                </li>
              </ol>
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>14. TERM AND TERMINATION</p>
          </div>
          <ol>
            <li>
              <b>Term</b>. The term of this Agreement shall commence on the Effective Date and shall end on the date of
              fulfilment of the obligations by both Parties.{' '}
            </li>
            <li>
              <b>Termination by both Parties</b>. The Parties may agree in writing to terminate the Agreement at any
              time.
            </li>
            <li>
              <b>Termination caused by breach of Agreement</b>. Each Party is entitled to terminate this Agreement with
              immediate effect from the date of delivery of a written statement of termination in case of breach of the
              provisions of this Agreement that has not been corrected within 3 workdays from the date of delivery of
              the notice to the Party which is alleged to have violated the contractual obligation, provided that the
              notice unambiguously and clearly states what constitutes the infringement of the contractual obligation.{' '}
            </li>
            <li>
              Upon the termination of this Agreement, no matter the reason, the Carrier shall return all the documents
              to Sender, received during the execution of the Agreement.
            </li>
            <li>
              The provisions of the Agreement that explicitly or implicitly stipulate the effect after the termination
              of the Agreement shall continue to apply.
            </li>
            <li>
              Termination of this Agreement shall not affect any rights, remedies, obligations or liabilities of the
              Parties that have been obtained to the date of termination, including the right to claim damages in
              respect of breach of the Agreement which existed before the date of termination.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>15. CORRESPONDENCE</p>
          </div>
          <ol>
            <li>
              The entire correspondence, notices or other correspondence arising from this Agreement shall be delivered
              in person or sent by registered e-mail address or by general courier service to the addresses indicated in
              this Agreement or via e-mail to addresses indicated on each Party’s profile on the KleverCargo Platform.
            </li>
            <li>
              Any notice served by hand, fax or post shall be deemed to have been duly given:
              <ol type='a' style={{ paddingInlineStart: 40, gap: 0 }}>
                <li>If by hand: when delivered;</li>
                <li>
                  If by email: at the time indicated in the receipt notice generated by the receiving {"party's"}{' '}
                  system;
                </li>
                <li>In the case of registered delivery: on the fifth workday following the date of posting. </li>
                <li>
                  Either Party may change its notice address or email address under this Agreement with prior prompt
                  written notice to the other Party sent in accordance with Section 15.1.
                </li>
              </ol>
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>16. APPLICABLE LAW AND DISPUTE RESOLUTION</p>
          </div>
          <ol>
            <li>
              All the issues not regulated by the provisions of this Agreement shall be governed by the Convention and
              the UNIDROIT Principles of International Commercial Contracts (2016) unless the Parties agree otherwise in
              writing.
            </li>
            <li>
              All disputes arising out of or in connection with the present Agreement shall be finally settled by
              arbitration organized in accordance with the Rules of the Belgrade Arbitration Center (Belgrade Rules).
              The number of arbitrators shall be one. The place of arbitration shall be at the registered seat of the
              defendant. The language of arbitration shall be English.{' '}
            </li>
            <li>
              The Parties agree that any dispute shall be decided in accordance with the UNIDROIT Principles of
              International Commercial Contracts (2016).
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>17. ENTIRE AGREEMENT</p>
          </div>
          <ol>
            <li>
              The provisions of the Agreement together with the order form created via KleverCargo Platform make the
              entire agreement between the Parties. This Agreement replaces all previous written or oral correspondence,
              offers or proposals exchanged between the Parties.
            </li>
            <li>
              Unless otherwise specifically prescribed in this Agreement, the Agreement may be amended only in writing
              in the form of a separate Annex signed by both Parties.
            </li>
            <li>
              The Parties hereby expressly agree that the use of electronic media to indicate confirmation, consent,
              signature, acceptance, agreement and delivery shall be legally valid and have the same legal force and
              effect as if the Parties executed this Agreement in paper form.
            </li>
            <li>
              The execution, delivery and performance of this Agreement and the completion of the transactions
              contemplated by this Agreement have been authorized by all necessary corporate action on the part of each
              Party and no other corporate proceedings or approvals are required to authorize this Agreement or to
              consummate the transactions contemplated by this Agreement.
            </li>
          </ol>
          <div className={styles.header}>
            <p className={styles.headingData}>18. INTERPRETATION</p>
          </div>
          <ol>
            <li>
              The headings or titles preceding the text of the Articles are inserted solely for the convenience of
              reference, and shall not constitute a part of this Agreement, nor shall they affect the meaning,
              construction or effect of this Agreement.
            </li>
            <li>
              Each Party hereto has had the opportunity to have this Agreement reviewed by the legal counsel of its
              choice and has participated in the drafting of this Agreement. In the event an ambiguity or question of
              intent or interpretation arises, this Agreement shall be construed as if drafted by both of the Parties
              and no presumption or burden of proof shall arise favouring or disfavouring either Party by virtue of the
              authorship of any of the provisions of this Agreement.
            </li>
            <li>
              The language used in this Agreement shall be deemed to be the language chosen by the Parties hereto to
              express their mutual intent, and no rule of strict construction shall be applied against any Party hereto.{' '}
            </li>
            <li>Each Party can download this Agreement via KleverCargo Platform. </li>
          </ol>
        </div>
      </div>
      <div className={styles.buttonControls}>
        <Button onClick={() => setInitDownload(true)}>{localization.download}</Button>
        <Button onClick={handlePrint}>{localization.printPage}</Button>
      </div>
      {showLoader ? <WholePageLoader /> : null}
    </>
  );
};

export default TSA;
