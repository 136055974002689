import packageInfo from '../../package.json';

const version = packageInfo.version;

const config = {
  API_TIMEOUT: 120000,
  VERSION: version,
  ENABLE_SERVICE_WORKER: true
};

const makeConfig = (newConfig) => {
  let resConfig = Object.assign({}, config, newConfig);

  // overrides
  if (process.env.REACT_APP_ENV === 'prod') {
    resConfig.FRONT_URL = 'https://klevercargo.app/';
    resConfig.API_URL = 'https://api.klevercargo.app/v1/';
    resConfig.WS_API_URL = 'wss://socket.etl.biz/';
    resConfig.RECAPTCHA = 'Insert production captcha key here';
    resConfig.ENABLE_SOCKET_SERVER = true;
    resConfig.CDN_URL = 'https://static.klevercargo.app/';
    resConfig.MAP_VEHICLE = 'ENTER_API_KEY';
    resConfig.MAP_CARGO = 'ENTER_API_KEY';
    resConfig.MAP_WAREHOUSE = 'ENTER_API_KEY';
    resConfig.MAP_COMPANY = 'ENTER_API_KEY';
    resConfig.COUNTRY_CODE = 'ENTER_API_KEY';
  } else if (process.env.REACT_APP_ENV === 'dev') {
    resConfig.FRONT_URL = 'https://dev.klevercargo.app/';
    resConfig.API_URL = 'https://dev.api.klevercargo.app/v1/';
    resConfig.WS_API_URL = 'wss://dev.socket.klevercargo.app/';
    resConfig.RECAPTCHA = 'Insert development captcha key here';
    resConfig.ENABLE_SOCKET_SERVER = true;
    resConfig.CDN_URL = 'https://dev.static.klevercargo.app/';
    resConfig.MAP_VEHICLE = '81240d6a70e64d208cb32f15f63786eb';
    resConfig.MAP_CARGO = '472ac3cbf995486e914b836c7d90738f';
    resConfig.MAP_WAREHOUSE = 'b0213cd4285948d5946cea5a48db6ef4';
    resConfig.MAP_COMPANY = '1843c69a85e54ae588f099e821cccb13';
    resConfig.COUNTRY_CODE = '1e7256c84d014ff5b7f5fe7d666d4f91';
  } else if (process.env.REACT_APP_ENV === 'stage') {
    resConfig.FRONT_URL = 'https://staging.klevercargo.app/';
    resConfig.API_URL = 'https://staging.api.klevercargo.app/v1/';
    resConfig.WS_API_URL = 'wss://staging.socket.klevercargo.app/';
    resConfig.RECAPTCHA = 'Insert development captcha key here';
    resConfig.ENABLE_SOCKET_SERVER = true;
    resConfig.CDN_URL = 'https://staging.static.klevercargo.app/';
    resConfig.MAP_VEHICLE = '81240d6a70e64d208cb32f15f63786eb';
    resConfig.MAP_CARGO = '472ac3cbf995486e914b836c7d90738f';
    resConfig.MAP_WAREHOUSE = 'b0213cd4285948d5946cea5a48db6ef4';
    resConfig.MAP_COMPANY = '1843c69a85e54ae588f099e821cccb13';
    resConfig.COUNTRY_CODE = '1e7256c84d014ff5b7f5fe7d666d4f91';
    resConfig.PAYMENT_PUBLISHABLE_KEY =
      'pk_test_51NKcTTC7TJwBrsm0rAYNA4rFwQleHVMSnOHiZMsrhrc8jo7lgaSKZ64CN5hMQfFf6rzRE3NxbLMltfU0jBeLwtS100uVuMbrF3'; // Test mode key
  } else if (process.env.REACT_APP_ENV === 'loc') {
    try {
      resConfig = require('./locConfig.js').locConfig;
    } catch (error) {
      console.error(
        'You need a loc config file, make one from the template /src/constants/locConfig.template.js',
        error
      );
    }
  } else if (process.env.REACT_APP_ENV === 'locBuild') {
    try {
      resConfig = require('./locConfig.js').locBuildConfig;
    } catch (error) {
      console.error(
        'You need a loc config file, make one from the template /src/constants/locConfig.template.js',
        error
      );
    }
  }
  return resConfig;
};

export { makeConfig };
