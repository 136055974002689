import api from '../../assets/helpers/api';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as duration from 'dayjs/plugin/duration';
import * as utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import {
  ADD_BACKEND_DATE_TIME_LISTENER,
  REMOVE_BACKEND_DATE_TIME_LISTENER,
  BACKEND_DATE_TIME_REQUEST,
  BACKEND_DATE_TIME_SUCCESS,
  BACKEND_DATE_TIME_FAILURE,
  UPDATE_BACKEND_DATE_TIME
} from '../../constants/actions';

import { utilsEndpoint } from '../../constants/apiEndpoints';
import store from '../store';

let intervalId = null;

function backendDateTimeRequest() {
  return { type: BACKEND_DATE_TIME_REQUEST };
}

function backendDateTimeSuccess(response) {
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  dayjs.extend(duration);

  const dateTime = dayjs.utc(response.data);

  dateTime.add(response.requestDuration, 'milliseconds');

  backendDateTimeTick();

  return {
    type: BACKEND_DATE_TIME_SUCCESS,
    data: dateTime
  };
}

function backendDateTimeTick() {
  let lastUpdate = Date.now();

  const state = store.getState();
  const numberOfListeners = state.backendDateTime.numberOfListeners;

  if (numberOfListeners > 0) {
    intervalId = setInterval(() => {
      let now = Date.now();
      let dt = now - lastUpdate;
      lastUpdate = now;

      store.dispatch({ type: UPDATE_BACKEND_DATE_TIME, data: dt });
    }, 1000);
  }
}

function backendDateTimeError(error) {
  return {
    type: BACKEND_DATE_TIME_FAILURE,
    error
  };
}

export function addBackendDateTimeListener() {
  return async (dispatch) => {
    const state = store.getState();

    const numberOfListeners = state.backendDateTime.numberOfListeners;

    if (numberOfListeners === 0) {
      dispatch(getBackendDateTime());
    }

    dispatch({
      type: ADD_BACKEND_DATE_TIME_LISTENER
    });
  };
}

export function removeBackendDateTimeListener() {
  return async (dispatch) => {
    const state = store.getState();

    const numberOfListeners = state.backendDateTime.numberOfListeners;

    if (numberOfListeners === 1 && intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }

    dispatch({
      type: REMOVE_BACKEND_DATE_TIME_LISTENER
    });
  };
}

function getBackendDateTime() {
  const method = 'GET';
  const headerData = {};

  return async (dispatch) => {
    dispatch(backendDateTimeRequest());

    try {
      const response = await api(utilsEndpoint.GET_BACKEND_DATE_TIME, {}, method, headerData);

      return dispatch(backendDateTimeSuccess(response));
    } catch (error) {
      return dispatch(backendDateTimeError(error));
    }
  };
}
