import { useCallback, useEffect, useState } from 'react';
import { register, unregister } from '../serviceWorker';
import config from '../constants/config';
import packageInfo from '../../package.json';
import { useSelector } from 'react-redux';

/**
 * @returns [isUpdateAvailable, updateCache, hideUpdateAvailable, isNewPatch]
 */
const useServiceWorker = () => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isNewPatch, setIsNewPatch] = useState(false);
  const updateVersion = window.localStorage.getItem('sw_show_update_for_v');
  const userLoggedIn = useSelector(({ session }) => session.loggedInStatus);

  const minVersion = useSelector(({ minVersion }) => minVersion.version);

  const updateReady = Boolean(waitingServiceWorker);

  const hideUpdateAvailable = useCallback(() => {
    setIsUpdateAvailable(false);
  }, []);

  const checkForServiceWorkerUpdate = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration) {
          await registration.update();
          console.log('Service worker update check initiated.');
        } else {
          console.warn('No service worker is currently registered.');
        }
      } catch (error) {
        console.error('Error during service worker update check:', error);
      }
    }
  };

  const updateCache = useCallback(() => {
    if (waitingServiceWorker) {
      setIsUpdating(true);
      // We send the SKIP_WAITING message to tell the Service Worker
      // to update its cache and flush the old one
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }, [waitingServiceWorker]);

  // A new effect to listen for UPDATE_AVAILABLE message from the service worker
  useEffect(() => {
    const handleServiceWorkerMessage = (event) => {
      if (event.data && event.data.type === 'UPDATE_AVAILABLE') {
        window.localStorage.setItem('sw_show_update_for_v', event.data.version);
        // Extract major and minor parts of the version
        // const [, , patch] = event.data.version.split('.').map(Number);

        // if (patch === 0) {
        //   console.log('New major or minor update available:', event.data.version);
        setIsUpdateAvailable(true);
        // }
      }
    };

    navigator.serviceWorker?.addEventListener('message', handleServiceWorkerMessage);

    // Clean up the listener when the component is unmounted
    return () => {
      navigator.serviceWorker?.removeEventListener('message', handleServiceWorkerMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (config.ENABLE_SERVICE_WORKER) {
      register({
        onSuccess: () => console.log('Page has been saved for offline use.'),
        onUpdate: (registration) => {
          console.log('Update available');

          if (registration && registration.waiting) {
            setWaitingServiceWorker(registration.waiting);
            // setIsUpdateAvailable(true);
          }
        },

        onWaiting: (waiting) => {
          setWaitingServiceWorker(waiting);
          // setIsUpdateAvailable(true);
        }
      });
    } else {
      unregister();
    }
  }, []);

  useEffect(() => {
    waitingServiceWorker?.addEventListener('statechange', (event) => {
      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingServiceWorker]);

  useEffect(() => {
    if (updateVersion) {
      if (packageInfo.version == updateVersion) {
        window.localStorage.removeItem('sw_show_update_for_v');
        hideUpdateAvailable();
      } else if (waitingServiceWorker) {
        // const [, , patch] = updateVersion.split('.').map(Number);

        // if (patch === 0) {
        setIsUpdateAvailable(true);
        // } else if (!userLoggedIn) {
        // setUpdate(true);
        // }
      }
    }
  }, [updateVersion, updateCache, userLoggedIn, waitingServiceWorker, hideUpdateAvailable]);

  useEffect(() => {
    if (update) {
      setUpdate(false);
      updateCache();
    }
  }, [update, updateCache]);

  useEffect(() => {
    if (minVersion) {
      const isLower = isVersionLowerThanMinimum(packageInfo.version, minVersion, setIsNewPatch);

      if (isLower) {
        setIsUpdateAvailable(true);
        // Trigger the service worker update check
        checkForServiceWorkerUpdate();
      }
    }
  }, [minVersion]);

  return [isUpdateAvailable, updateCache, hideUpdateAvailable, isNewPatch, isUpdating, updateReady];
};

function isVersionLowerThanMinimum(version, minVersion, setIsNewPatch) {
  const versionArray = version.split('.').map(Number);
  const minVersionArray = minVersion.split('.').map(Number);

  // Compare major version
  if (versionArray[0] < minVersionArray[0]) {
    return true;
  } else if (versionArray[0] > minVersionArray[0]) {
    return false;
  }

  // Compare minor version
  if (versionArray[1] < minVersionArray[1]) {
    return true;
  } else if (versionArray[1] > minVersionArray[1]) {
    return false;
  }

  // Compare patch version
  if (versionArray[2] < minVersionArray[2]) {
    setIsNewPatch(true);
    return true;
  } else if (versionArray[2] > minVersionArray[2]) {
    return false;
  }

  return false;
}

export default useServiceWorker;
