import React, { useCallback, useEffect, useMemo, useState, createContext } from 'react';
import { createPortal } from 'react-dom';
import styles from './FormDrawer.module.css';
import CloseSvg from '../../../assets/images/svg/CloseSvg';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';

export const drawerContext = createContext();

const FormDrawer = ({
  formTitle = '',
  children,
  contentClassName = '',
  headerStyle = 'gray',
  apiStatus = 'IDLE',
  closeDrawer,
  customClick /*onClose*/,
  onClose,
  globalDrawer = false,
  chatDrawer = false,
  closeChat = false,
  singleChat = false,
  className = ''
}) => {
  const formRoot = useMemo(() => document.getElementById('FormDrawerRoot'), []);
  const el = useMemo(() => document.createElement('div'), []);
  const [hideClass, setHideClass] = useState(false);
  const [title, setTitle] = useState(formTitle);
  const [style, setStyle] = useState(headerStyle);
  const [, setStatus] = useState(apiStatus);
  const [closeIsClicked, setCloseIsClicked] = useState(false);
  const { isPC, columnCount } = useWindowDimensions();

  useEffect(() => {
    if (closeChat) {
      setCloseIsClicked(true);
    }
  }, [closeChat]);

  const navigate = useNavigate();
  const hideHeader = chatDrawer && columnCount === 1 && !singleChat;

  const setDrawerProps = useCallback((data = {}) => {
    const { title, style } = data;

    title && setTitle(title);
    style && setStyle(style);
  }, []);

  const setApiStatus = useCallback((data = {}) => {
    const { status } = data;
    status && setStatus(status);
  }, []);

  const close = useCallback(() => {
    setCloseIsClicked(true);
  }, []);

  useEffect(() => {
    setStatus(apiStatus);
  }, [apiStatus]);

  useEffect(() => {
    formRoot.appendChild(el);
  }, [el, formRoot]);

  useEffect(() => {
    if (formRoot.children.length > 0 && (closeIsClicked || customClick)) {
      setHideClass(true);
      const timer = setTimeout(() => {
        if (closeDrawer) {
          closeDrawer(false);
        } else globalDrawer ? null : navigate('..');

        onClose && onClose();
        formRoot.removeChild(el);
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [el, formRoot, closeIsClicked, navigate, closeDrawer, customClick, onClose, globalDrawer]);

  return createPortal(
    <div
      className={
        isPC
          ? styles.drawerWrapper
          : hideHeader
            ? [styles.drawerWrapper, styles.drawerWrapperResponsive, styles.headerless].join(' ')
            : [styles.drawerWrapper, styles.drawerWrapperResponsive].join(' ')
      }
    >
      <div
        className={`
          ${
            isPC
              ? `${styles.drawer} ${hideClass ? styles.hide : ''}`
              : singleChat && columnCount === 1
                ? [styles.drawer, styles.drawerResponsive, styles.chatWrapper].join(' ')
                : [styles.drawer, styles.drawerResponsive].join(' ')
          } ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        {!hideHeader ? (
          <div className={`${styles.drawerHeader} ${styles[style]} ${''}`}>
            <div className={styles.formTitle}>{title}</div>
            <CloseSvg
              onClick={() => {
                setCloseIsClicked(true);
              }}
              className={
                ['primary', 'success', 'danger'].includes(style)
                  ? [styles.closeSvg, styles.closeSvgWhite].join(' ')
                  : styles.closeSvg
              }
            />
          </div>
        ) : null}
        <drawerContext.Provider
          value={{
            setDrawerTitle: setTitle,
            setDrawerStyle: setStyle,
            setDrawerProps,
            setApiStatus,
            closeDrawer: close
          }}
        >
          <div
            className={`${styles.content} 
              ${contentClassName}`}
          >
            {children}
          </div>
        </drawerContext.Provider>
      </div>
    </div>,
    el
  );
};

export default FormDrawer;
