import { NOTIFICATION_NOTIFY_RECEIVED } from '../../../constants/actions';

function setNotificationNotifyReceived(notification) {
  return {
    type: NOTIFICATION_NOTIFY_RECEIVED,
    notification: notification
  };
}

export function notificationNotifyReceived(notification) {
  return (dispatch) => {
    return dispatch(setNotificationNotifyReceived(notification));
  };
}
