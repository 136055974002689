import { useCallback, useEffect, useState } from 'react';
import packageInfo from '../../package.json';
import CHANGES from '../CHANGES.md';
import api from '../assets/helpers/api';
import { utilsEndpoint } from '../constants/apiEndpoints';
import { getPathWithParams } from '../assets/helpers/common';

const version = packageInfo.version;

/**
 * @returns [showNewVersionMessage, hideNewVersionMessage]
 */
const useAppVersion = () => {
  const [showNewVersionMessage, setShowNewVersionMessage] = useState(false);
  const [text, setText] = useState('');
  const [data, setData] = useState(null);

  const saveCurrentVersion = useCallback(() => {
    try {
      window.localStorage.setItem('LAST_APP_VERSION', version);
    } catch (e) {
      console.log('CANNOT SAVE VERSION TO LOCAL STORAGE', e);
    }
  }, []);

  const getUpdateInfo = useCallback(async (version) => {
    try {
      const res = await api(
        getPathWithParams(utilsEndpoint.GET_UPDATE_INFO, { version: version }),
        {},
        'GET',
        {},
        3000
      );
      setData(res);
    } catch (error) {
      // console.error(error);
    }
  }, []);

  const checkVersion = useCallback(() => {
    try {
      const lastAppVersion = window.localStorage.getItem('LAST_APP_VERSION');
      saveCurrentVersion();

      getUpdateInfo(version);

      if (lastAppVersion === null) {
        return undefined;
      } else if (lastAppVersion != version) {
        const [, , patch] = version.split('.').map(Number);

        if (patch === 0) {
          setShowNewVersionMessage(true);
          getText();
        }
      }

      return lastAppVersion;
    } catch (e) {
      return undefined;
    }
  }, [saveCurrentVersion, getUpdateInfo]);

  const hideNewVersionMessage = () => {
    setShowNewVersionMessage(false);
  };

  const getText = () => {
    fetch(CHANGES)
      .then((r) => r.text())
      .then((text) => {
        setText(text);
      });
  };

  useEffect(() => {
    checkVersion();
  }, [checkVersion]);

  return { showNewVersionMessage, hideNewVersionMessage, text, data };
};

export default useAppVersion;
