import * as React from 'react';

function CustomsSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} viewBox='0 0 24 24' {...props}>
      <rect x='5' y='19' width='14' height='2' rx='1' style={{ fill: 'var(--secondary)' }} />
      <path
        d='M12 3C13 3 15 4.03448 15 6.10345C15 7.69671 14.6205 8.50474 14.2121 9.3741C13.9576 9.91589 13.692 10.4815 13.5 11.2759C13.1 12.931 16.1667 13.3448 18 13.3448C18.6667 13.3448 20 13.7586 20 15.4138V17.0002C20 17.5525 19.5523 18 19 18H13H11H5C4.44772 18 4 17.5525 4 17.0002V15.4138C4 13.7586 5.33333 13.3448 6 13.3448C7.83333 13.3448 10.9 12.931 10.5 11.2759C10.308 10.4815 10.0424 9.91589 9.78787 9.3741C9.37953 8.50474 9 7.69671 9 6.10345C9 4.03448 11 3 12 3Z'
        style={{ fill: 'var(--primary)' }}
      />
    </svg>
  );
}

export default CustomsSvg;
