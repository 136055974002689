import { WHOLE_PAGE_LOADER_SHOW, WHOLE_PAGE_LOADER_HIDE } from '../../constants/actions';

export function wholePageLoaderShow(state) {
  return (dispatch) => {
    return dispatch({
      state,
      type: WHOLE_PAGE_LOADER_SHOW
    });
  };
}

export function wholePageLoaderHide(state) {
  return (dispatch) => {
    return dispatch({
      state,
      type: WHOLE_PAGE_LOADER_HIDE
    });
  };
}
