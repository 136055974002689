import { SET_LANGUAGE } from '../../../constants/actions';

const initialState = {
  selectedLanguage: 'en_GB'
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.language
      };
    default:
      return state;
  }
}
