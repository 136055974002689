import * as React from 'react';

function OtherSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' style={{ outline: 'none' }} viewBox='0 0 24 24' {...props}>
      <path
        d='M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z '
        style={{ fill: 'var(--primary)' }}
      />
      <path
        d='M 8 11 C 7.4477153 11 7 11.447715 7 12 C 7 12.552285 7.4477153 13 8 13 C 8.5522847 13 9 12.552285 9 12 C 9 11.447715 8.5522847 11 8 11 z M 12 11 C 11.447715 11 11 11.447715 11 12 C 11 12.552285 11.447715 13 12 13 C 12.552285 13 13 12.552285 13 12 C 13 11.447715 12.552285 11 12 11 z M 16 11 C 15.447715 11 15 11.447715 15 12 C 15 12.552285 15.447715 13 16 13 C 16.552285 13 17 12.552285 17 12 C 17 11.447715 16.552285 11 16 11 z '
        style={{ fill: 'var(--secondary)' }}
      />
    </svg>
  );
}

export default OtherSvg;
