import {
  GET_USER_PROFILE_PICTURE_REQUEST,
  GET_USER_PROFILE_PICTURE_SUCCESS,
  GET_USER_PROFILE_PICTURE_ERROR
} from '../../../../constants/actions';

const initialState = {
  isFetching: null,
  error: null,
  timestamp: null,
  response: {
    imagePath: ''
  },
  status: 'IDLE',
  initialFetching: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        timestamp: new Date().toUTCString(),
        status: 'FETCHING'
      };
    case GET_USER_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        response: { imagePath: action.data },
        timestamp: new Date().toUTCString(),
        status: 'SUCCESS'
      };
    case GET_USER_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: new Date().toUTCString(),
        status: 'FAIL'
      };
    default:
      return state;
  }
}
