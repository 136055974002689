import { createSelector } from 'reselect';

const sessionSelector = (state) => state.session;
const userCompaniesSelector = (state) => state.userCompanies;

export const userIdSelector = createSelector([sessionSelector], (session) => session.userId);

export const HtSelector = createSelector([sessionSelector], (session) => session.Ht);

export const EtSelector = createSelector([sessionSelector], (session) => session.Et);

export const CtSelector = createSelector([sessionSelector], (session) => session.Ct);

export const companyIdSelector = createSelector(
  [userCompaniesSelector],
  (userCompanies) => +userCompanies?.selected?.id
);
