import React, { useState, useContext, useCallback, useEffect } from 'react';
import AppComponentContext from '../../../contexts/AppComponentContext';
import styles from './CookiePolicy.module.css';
import Button from '../Button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactGA from 'react-ga4';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import CookieSettings from './CookieSettings';

export const defaultCookieValues = {
  necessary: true,
  statistic: false,
  marketing: false,
  unclassified: false,
  chat: false
};

const CookiePolicy = ({ setShowCookiePolicy = () => {}, setShowPrivacy = () => {} }) => {
  const { isPC, isMobile, isTablet } = useWindowDimensions();
  const [showSettings, setShowSettings] = useState(false);
  const [showCookie, setShowCookie] = useState();
  const localization = useContext(AppComponentContext).strings;

  useEffect(() => {
    setShowCookie(
      !localStorage.getItem('cookies') ||
        new Date(JSON.parse(localStorage.getItem('cookies')).showAgainDate) < new Date()
    );
  }, []);

  const validationSchema = yup.object().shape({});

  const formMethods = useForm({
    defaultValues: defaultCookieValues,
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    shouldFocusError: true,
    criteriaMode: 'all'
  });

  const { handleSubmit } = formMethods;

  const handleRejectCookie = useCallback(() => {
    removeAllCookies();

    const today = new Date();
    const notifyAgain = new Date(today.setDate(today.getDate() + 30));

    localStorage.setItem(
      'cookies',
      JSON.stringify({
        statistic: false,
        marketing: false,
        unclassified: false,
        chat: false,
        rejectedDate: new Date(new Date().setHours(0, 0, 0, 0)),
        showAgainDate: new Date(notifyAgain.setHours(0, 0, 0, 0))
      })
    );
    setShowCookie(false);
    setShowSettings(false);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('cookies')) {
      removeAllCookies();
    }
  }, []);

  const onSubmit = useCallback((data) => {
    handleCookie(data);
    setShowSettings(false);
    setShowCookie(false);
  }, []);

  const handleModal = useCallback(() => {
    setShowSettings(true);
    setShowCookie(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowSettings(false);
    setShowCookie(true);
  }, []);

  const handleAcceptAll = useCallback(() => {
    ReactGA.initialize([
      {
        trackingId: 'G-074J69T5HZ'
      }
    ]);

    const googleTagManager = document.createElement('script');

    let googleTagManagerCode = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MT3FZL8');`;

    googleTagManager.appendChild(document.createTextNode(googleTagManagerCode));
    document.body.appendChild(googleTagManager);

    const zohoLiveChat = document.createElement('script');
    zohoLiveChat.setAttribute('type', 'text/javascript');
    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "cea67b38dcff16598a0aebe2be4bcacb844708b6c77488deb650d2a2c9e241e547b52a2e43100f69b4ef8ea66cf0022f", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

    zohoLiveChat.appendChild(document.createTextNode(code));
    document.body.appendChild(zohoLiveChat);

    localStorage.setItem(
      'cookies',
      JSON.stringify({
        statistic: true,
        marketing: true,
        unclassified: true,
        chat: true
      })
    );

    setShowCookie(false);
    setShowSettings(false);
    window.location.reload();
  }, []);

  return (
    <>
      {showCookie ? (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={[styles.textWrapper].join(' ')}>
              {localization.formatString(localization.cookieText, {
                cookiePolicy: (
                  <label className={styles.link} onClick={() => setShowCookiePolicy(true)}>
                    {localization.cookiePolicy}
                  </label>
                )
              })}
            </div>
            <div
              className={[
                styles.sectionWrapper,
                isTablet ? styles.buttonsTablet : '',
                isMobile ? styles.buttonsResponsive : ''
              ].join(' ')}
            >
              <Button
                onClick={handleRejectCookie}
                buttonClassName={[styles.buttons, isMobile ? styles.button : ''].join(' ')}
                buttonType='primaryOutline'
              >
                {localization.acceptNecessaryCookies}
              </Button>
              <Button
                onClick={handleModal}
                buttonClassName={[styles.buttons, isMobile ? styles.button : ''].join(' ')}
                buttonType='primary'
              >
                {localization.cookieSettings}
              </Button>
              <Button
                onClick={handleAcceptAll}
                buttonClassName={[styles.buttons, isMobile ? styles.button : ''].join(' ')}
                buttonType='primary'
              >
                {localization.acceptAll}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {showSettings ? (
        <>
          <div
            className={[
              styles.settingsModal,
              !isPC && !isMobile ? styles.modalResponsiveTablet : isMobile ? styles.modalResponsive : ''
            ].join(' ')}
          >
            <FormProvider {...formMethods}>
              <form
                className={[styles.registerForm, !isPC ? styles.registerFormResponsive : ''].join(' ')}
                onSubmit={handleSubmit(onSubmit)}
              >
                <CookieSettings
                  setShowCookiePolicy={setShowCookiePolicy}
                  setShowPrivacy={setShowPrivacy}
                  handleModal={handleModal}
                  handleCloseModal={handleCloseModal}
                  isModal
                />
              </form>
            </FormProvider>
          </div>
        </>
      ) : null}
    </>
  );
};

export const removeAllCookies = () => {
  var cookies = document.cookie.split('; ');
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split('.');
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      var p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
};

export const handleCookie = (data) => {
  if (data.marketing) {
    ReactGA.initialize([
      {
        trackingId: 'G-074J69T5HZ'
      }
    ]);

    const googleTagManager = document.createElement('script');

    let googleTagManagerCode = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MT3FZL8');`;

    googleTagManager.appendChild(document.createTextNode(googleTagManagerCode));
    document.body.appendChild(googleTagManager);
  }

  if (data.chat) {
    const zohoLiveChat = document.createElement('script');
    zohoLiveChat.setAttribute('type', 'text/javascript');
    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "cea67b38dcff16598a0aebe2be4bcacb844708b6c77488deb650d2a2c9e241e547b52a2e43100f69b4ef8ea66cf0022f", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

    zohoLiveChat.appendChild(document.createTextNode(code));
    document.body.appendChild(zohoLiveChat);
  }

  const today = new Date();
  const notifyAgain = new Date(today.setDate(today.getDate() + 30));

  if (data.statistic && data.marketing && data.unclassified && data.chat) {
    localStorage.setItem(
      'cookies',
      JSON.stringify({
        necessary: true,
        statistic: data.statistic,
        marketing: data.marketing,
        unclassified: data.unclassified,
        chat: data.chat
      })
    );
  } else {
    localStorage.setItem(
      'cookies',
      JSON.stringify({
        necessary: true,
        statistic: data.statistic,
        marketing: data.marketing,
        unclassified: data.unclassified,
        chat: data.chat,
        rejectedDate: new Date(new Date().setHours(0, 0, 0, 0)),
        showAgainDate: new Date(notifyAgain.setHours(0, 0, 0, 0))
      })
    );
  }
  window.location.reload();
};

export default CookiePolicy;
