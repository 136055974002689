import React from 'react';
import styles from './EllipsisText.module.css';
import { useRef, useState, useEffect } from 'react';
import { generateRandomId } from '../../../assets/helpers/common';
import Tooltip from '../Tooltip/Tooltip';

function isElipsisActive(element) {
  return element.scrollWidth > element.offsetWidth;
}

const EllipsisText = ({
  children,
  minWidth = '0',
  maxWidth = 'none',
  tooltipParams = { position: 'unset' },
  isParagraph = false,
  className,
  parentWidth,
  disableTooltip = false
}) => {
  const ellipsisId = generateRandomId();
  const [isOverflowing, setIsOverflowing] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const container = inputRef.current;
      if (container) {
        setIsOverflowing(isElipsisActive(container));
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOverflowing]);

  return isParagraph ? (
    <>
      <p>
        <span
          style={{
            overflow: 'hidden',
            maxWidth: maxWidth,
            minWidth: minWidth,
            display: 'inline-block',
            width: parentWidth - 5 + `rem`
          }}
          className={className}
        >
          <span
            id={ellipsisId}
            ref={inputRef}
            className={[styles.ellipsis, className].join(' ')}
            style={{ display: 'block' }}
          >
            {children}
          </span>
        </span>
        {isOverflowing && !disableTooltip ? (
          <Tooltip id={ellipsisId} position={tooltipParams.position}>
            {children}
          </Tooltip>
        ) : null}
      </p>
    </>
  ) : (
    <>
      <div
        style={{ overflow: 'hidden', maxWidth: maxWidth, minWidth: minWidth, width: parentWidth - 5 + `rem` }}
        className={className}
      >
        <div id={ellipsisId} ref={inputRef} className={[styles.ellipsis, className].join(' ')}>
          {children}
        </div>
      </div>
      {isOverflowing && !disableTooltip ? (
        <Tooltip id={ellipsisId} position={tooltipParams.position}>
          {children}
        </Tooltip>
      ) : null}
    </>
  );
};

export default EllipsisText;
