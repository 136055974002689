import * as React from 'react';

const CompanySvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} viewBox=' 0 0 20 20' {...props}>
    <g data-name='Group 16398'>
      <path fill='none' d='M0 0h20v20H0z' />
      <path
        data-name='Path 13774'
        d='M19.213 1.584h-12.3a.789.789 0 0 0-.8.8v2.8h-5.3a.789.789 0 0 0-.8.8v11.6a.86.86 0 0 0 .8.8h1v-11.4h4.3v11.4h13a.789.789 0 0 0 .8-.8v-15.2a.666.666 0 0 0-.7-.8Zm-1 14.9h-10.3v-13.1h10.2v13.1Z'
        fill='#0c2138'
      />
      <path
        data-name='Line 453'
        d='M11.454 7.584H9.971a.9.9 0 0 1-.9-.9.9.9 0 0 1 .9-.9h1.483a.9.9 0 0 1 .9.9.9.9 0 0 1-.9.9Z'
        fill='#ffc60a'
      />
      <path
        data-name='Line 455'
        d='M11.454 10.885H9.971a.9.9 0 0 1-.9-.9.9.9 0 0 1 .9-.9h1.483a.9.9 0 0 1 .9.9.9.9 0 0 1-.9.9Z'
        fill='#ffc60a'
      />
      <path
        data-name='Line 458'
        d='M3.913 18.4a.9.9 0 0 1-.9-.9v-3.115a.9.9 0 0 1 .9-.9.9.9 0 0 1 .9.9V17.5a.9.9 0 0 1-.9.9Z'
        fill='#ffc60a'
      />
      <path
        data-name='Line 459'
        d='M16.124 7.584h-1.483a.9.9 0 0 1-.9-.9.9.9 0 0 1 .9-.9h1.483a.9.9 0 0 1 .9.9.9.9 0 0 1-.9.9Z'
        fill='#ffc60a'
      />
      <path
        data-name='Line 460'
        d='M16.124 10.885h-1.483a.9.9 0 0 1-.9-.9.9.9 0 0 1 .9-.9h1.483a.9.9 0 0 1 .9.9.9.9 0 0 1-.9.9Z'
        fill='#ffc60a'
      />
    </g>
  </svg>
);

export const CompanySvgHTML = `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="markerIcon">
  <g id="Group_16648" data-name="Group 16648" transform="translate(-462 -631)">
    <g id="Icons" transform="translate(462 631)">
      <g id="Rounded" transform="translate(0 0)">
        <g id="Communication" transform="translate(0)">
          <g id="_-Round-_-Communication-_-chat_bubble_outline" data-name="-Round-/-Communication-/-chat_bubble_outline" transform="translate(0)">
            <g id="Group_16629" data-name="Group 16629">
              <path id="Path" d="M0,0H20V20H0Z" fill="none" fill-rule="evenodd"/>
              <path id="Union_37" data-name="Union 37" d="M10347.5,22032.2v-9.4a1.3,1.3,0,0,1,1.3-1.3h12.4a1.3,1.3,0,0,1,1.3,1.3v9.4a1.3,1.3,0,0,1-1.3,1.3h-10.12l-3.579,3.578Z" transform="translate(-10345 -22019)" fill="#fff" stroke="#0c2138" stroke-linejoin="round" stroke-width="1"/>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Company" transform="translate(467.164 635.021)">
      <g id="Group_16398" data-name="Group 16398" transform="translate(0.007 -0.347)">
        <rect id="Rectangle_2273_00000170265176084672148040000004724044969863315389_" width="9.654" height="9.654" transform="translate(0.003 0)" fill="none"/>
        <g id="Group_16364" transform="translate(0 1.074)">
          <g id="icon-company">
            <path id="Path_13774" data-name="Path 13774" d="M11.345,7.4H5.614a.367.367,0,0,0-.373.373v1.3H2.773A.367.367,0,0,0,2.4,9.45v5.4a.4.4,0,0,0,.373.373h.466V9.916h2v5.311H11.3a.367.367,0,0,0,.373-.373V7.773A.31.31,0,0,0,11.345,7.4Zm-.466,6.941H6.08v-6.1h4.752v6.1Z" transform="translate(-2.4 -7.4)" fill="#0c2138"/>
            <path id="Line_453" data-name="Line 453" d="M.21-.061H-.481A.419.419,0,0,1-.9-.481.419.419,0,0,1-.481-.9H.21A.419.419,0,0,1,.63-.481.419.419,0,0,1,.21-.061Z" transform="translate(5.12 2.857)" fill="#ffc60a"/>
            <path id="Line_455" data-name="Line 455" d="M.21-.061H-.481A.419.419,0,0,1-.9-.481.419.419,0,0,1-.481-.9H.21A.419.419,0,0,1,.63-.481.419.419,0,0,1,.21-.061Z" transform="translate(5.12 4.394)" fill="#ffc60a"/>
            <path id="Line_458" data-name="Line 458" d="M-.481,1.39A.419.419,0,0,1-.9.971V-.481A.419.419,0,0,1-.481-.9a.419.419,0,0,1,.419.419V.971A.419.419,0,0,1-.481,1.39Z" transform="translate(2.298 6.444)" fill="#ffc60a"/>
            <path id="Line_459" data-name="Line 459" d="M.21-.061H-.481A.419.419,0,0,1-.9-.481.419.419,0,0,1-.481-.9H.21A.419.419,0,0,1,.63-.481.419.419,0,0,1,.21-.061Z" transform="translate(7.296 2.857)" fill="#ffc60a"/>
            <path id="Line_460" data-name="Line 460" d="M.21-.061H-.481A.419.419,0,0,1-.9-.481.419.419,0,0,1-.481-.9H.21A.419.419,0,0,1,.63-.481.419.419,0,0,1,.21-.061Z" transform="translate(7.296 4.394)" fill="#ffc60a"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

`;

export default CompanySvg;
